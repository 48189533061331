import {useRef,forwardRef,useEffect} from "react"
import AirDatepicker from "air-datepicker"
import "../assets/air-datepicker.css"
import localeEs from "air-datepicker/locale/es"
import search from "../assets/search-2.svg"

const Textbox=forwardRef(({type,label,val,setVal,icon,disabled,max},ref)=>{

    const caption=useRef()

    let iconImage=""
    if(icon=="search"){
        iconImage=search
    }

    const focus=()=>{
        ref.current.focus()
    }

    const blur=()=>{
        if(ref.current.value==""){
            caption.current.classList.remove("textbox-label-on")
        }
    }

    const change=(event)=>{
        if(type=="number"){
            if(event.target.validity.valid){
                setVal(event.target.value)
            }
        }else{
            setVal(event.target.value)
        }
    }

    useEffect(()=>{
        if(ref.current.value!=""){
            caption.current.classList.add("textbox-label-on")
        }
    })

    useEffect(()=>{
        if(type=="date"){
            let datepicker=new AirDatepicker(ref.current,{
                locale:localeEs,
                isMobile:true,
                dateFormat:"dd/MM/yyyy",
                autoClose:true,
                buttons:["clear"],
                onSelect:({formattedDate})=>{
                    if(formattedDate==undefined){
                        setVal("")
                    }else{
                        setVal(formattedDate)
                    }    
                }
            })
        }
    },[])

    return(
        <div className="form-block noselect" onClick={focus}>
            <div ref={caption} className="textbox-label">{label}</div>
            <input 
                ref={ref} 
                className={icon?"textbox textbox-with-icon":"textbox"}
                type={type=="password"?"password":"text"}
                pattern={type=="number"?"[0-9]*":""}
                maxLength={max!=""?max:""}
                disabled={disabled}
                value={val}
                onChange={change}
                onBlur={blur}
                onFocus={()=>{caption.current.classList.add("textbox-label-on")}}
            />
            {icon && <div className="textbox-icon"><img src={iconImage} /></div>}
        </div>
    )
    
})

export default Textbox