import {useState,useEffect,useContext,Fragment} from "react"
import {useParams} from "react-router-dom"
import RefreshContext from "../contexts/RefreshContext"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import useRefresh from "../hooks/useRefresh"
import useModal from "../hooks/useModal"
import {formatDate,formtatCuit} from "../hooks/Commons"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonCard from "../commons/ButtonCard"

export default function Shop(){

    const {restrictSection,checkPermission}=usePermissions()
    useEffect(()=>{
        restrictSection("shop",params.shopId)
    },[])

    const [refreshContext]=useContext(RefreshContext)
    const {refreshClear}=useRefresh()
    let params=useParams()
    const requestApi=useApi()
    const {changeModal}=useModal()
    const [info,setInfo]=useState()
    const [points,setPoints]=useState()
    const [team,setTeam]=useState()

    const loadInfo=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/info",{
                        shop_id:params.shopId
                    })
                    .then(data=>{
                        return data.data
                    })
            )
        })
    }

    const loadPoints=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/points",{
                        shop_id:params.shopId
                    })
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }
    
    const loadTeam=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/team",{
                        shop_id:params.shopId
                    })
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }

    const loadData=()=>{
        Promise.all([loadInfo,loadPoints,loadTeam].map(func=>func())).then((result)=>{
            setInfo(result[0])
            setPoints(result[1])
            setTeam(result[2])
        })
    }

    useEffect(()=>{
        loadData()
    },[])

    useEffect(()=>{
        if(refreshContext["info"].refresh==1){
            refreshClear("info")
            loadData()
        }
    },[refreshContext["info"]])

    return(
        <Section>
            <SectionHeader
                title={info?info["name"]:""}
                intro=""
                back="/c24/comercios"
                button=""
                tabs={[
                    {label:"Comercio",to:"/c24/comercios/"+params.shopId}
                ]}
            />
            <SectionContent>
                <div className="content-frame-cards">
                    {info &&
                    <Fragment>
                        <Card title="Información" icon={{element:"store"}} action={checkPermission({alias:"master_admin",reference:""})?"Modificar":""} actionIcon="edit" actionClickEvent={()=>{changeModal("shop-edit","",info["id"])}}>
                            <CardRow label="Nombre" detail="">
                                {info["name"]?info["name"]:"‒"}
                            </CardRow>
                            <CardRow label="Detalle" detail="">
                                {info["description"]?info["description"]:"‒"}
                            </CardRow>
                            <CardRow label="Etiquetas" detail="">
                                {info["tags"].length>0?
                                info["tags"].map((item,i)=>{
                                    return(
                                        <div key={i} className="card-tag">{item["name"]}</div>
                                    )
                                })
                                :"‒"}
                            </CardRow>
                            <CardRow label="Identificador" detail="">
                                #{String(info["id"]).padStart(4,"0")}
                            </CardRow>
                            <CardRow label="Fecha de creación" detail="">
                                {formatDate(info["created"],"short")}
                            </CardRow>
                            <CardRow label="Total de transacciones" detail="">
                                {info["transactions"]}
                            </CardRow>
                        </Card>
                        <Card title="Facturación" icon={{element:"billing"}} action={checkPermission({alias:"master_admin",reference:""})?"Modificar":""} actionIcon="edit" actionClickEvent={()=>{changeModal("shop-billing-edit","",info["id"])}}>
                            <CardRow label="CUIT" detail="">
                                {info["cuit"]?formtatCuit(info["cuit"]):"‒"}
                            </CardRow>
                            <CardRow label="Razón social" detail="">
                                {info["legal_name"]?info["legal_name"]:"‒"}
                            </CardRow>
                            <CardRow label="Tipo de persona" detail="">
                                {!info["person_type"] && "‒"}
                                {info["person_type"]=="person"?"Persona física":info["person_type"]=="company"?"Persona jurídica":""}
                            </CardRow>
                            <CardRow label="Condición de IVA" detail="">
                                {!info["iva_condition"] && "‒"}
                                {info["iva_condition"]=="responsable_inscripto"?"Responsable Inscripto":info["iva_condition"]=="monotributista"?"Monotributista":info["iva_condition"]=="responsable_no_inscripto"?"Responsable no Inscripto":info["iva_condition"]=="exento"?"Exento":info["iva_condition"]=="consumidor_final"?"Consumidor Final":""}
                            </CardRow>
                            <CardRow label="Tipo de factura" detail="">
                                {!info["voucher_type"] && "‒"}
                                {info["voucher_type"]=="A"?"Factura A":info["voucher_type"]=="B"?"Factura B":info["voucher_type"]=="FCE-A"?"Factura Crédito MiPyme A":info["voucher_type"]=="FCE-B"?"Factura Crédito MiPyme B":""}
                            </CardRow>
                            <CardRow label="CBU (Para FCE)" detail="">
                                {info["cbu"]?info["cbu"]:"‒"}
                            </CardRow>
                            <CardRow label="Domicilio fiscal" detail="">
                                {info["tax_residence"]?info["tax_residence"]:"‒"}
                            </CardRow>
                            <CardRow label="Entidad facturante" detail="">
                                {info["billing_entity_name"]?info["billing_entity_name"]:"‒"}
                            </CardRow>
                        </Card>
                        <Card title="Contacto" icon={{element:"contact"}} action="Actualizar" actionIcon="edit" actionClickEvent={()=>{changeModal("shop-contact","",info["id"])}}>
                            <CardRow label="Dirección" detail="">
                                {info.location.street?info.location.street:""} {info.location.numeration?""+info.location.numeration:"‒"}{info.location.additional?" ("+info.location.additional+")":""}
                            </CardRow>
                            <CardRow label="Región" detail="">
                                {info.location.city?info.location.city:"‒"}, {info.location.region?info.location.region:"‒"}, {info.location.country?info.location.country:"‒"}
                            </CardRow>
                            <CardRow label="Código postal" detail="">
                                {info.location.postal_code?info.location.postal_code:"‒"}
                            </CardRow>
                            <CardRow label="Teléfono" detail="">
                                {info["phone"]?info["phone"]:"‒"}
                            </CardRow>
                            <CardRow label="Sitio web" detail="">
                                {info["website"]?info["website"]:"‒"}
                            </CardRow>
                        </Card>
                    </Fragment>}
                    {points &&
                    <Card title="Puntos de cobro" icon={{element:"point"}} action={checkPermission({alias:"master_admin",reference:""})?"Agregar":""} actionIcon="add" actionClickEvent={()=>{changeModal("shop-point-edit","create",{shop:info["id"]})}}>
                        {points.length>0?
                            points.map((item,i)=>{
                                return(
                                    <CardRow key={i} label={item["name"]} detail={(item["is_default"]?"Principal":"Adicional")+" ("+item["transactions"]+" "+(item["transactions"]!=1?"transacciones":"transacción")+")"} off={item["status"]}>
                                        <ButtonCard label="Configurar" style="primary" clickEvent={()=>{changeModal("shop-point","",item["id"])}} />
                                    </CardRow>
                                )
                            })
                        :<CardRow label="Sin puntos de cobro." detail=""></CardRow>}
                    </Card>}
                    {team &&
                    <Card title="Equipo" icon={{element:"team"}} action={checkPermission({alias:"master_admin",reference:""})?"Asignar":""} actionIcon="add" actionClickEvent={()=>{changeModal("shop-assign","create",{shop:info["id"],team:0})}}>
                        {team.length>0?
                            team.map((item,i)=>{
                                return(
                                    <CardRow key={i} label={item["name"]+" ("+item["email"]+")"} detail={item["role"]=="shop_admin"?"Administrador ("+item["shop_name"]+")":item["role"]=="point_admin"?"Administrador de punto de cobro ("+item["point_name"]+")":item["role"]=="point_operator"?"Operador de punto de cobro ("+item["point_name"]+")":""}>
                                        {checkPermission({alias:"master_admin",reference:""}) &&
                                        <ButtonCard label="Editar" style="secondary" clickEvent={()=>{changeModal("shop-assign","edit",{shop:info["id"],team:item["id"]})}} />}
                                    </CardRow>
                                )
                            })
                        :<CardRow label="Sin asignaciones." detail=""></CardRow>}
                    </Card>}
                </div>
            </SectionContent>
        </Section>
    )
    
}