import {useState,useRef,useEffect,useContext,Fragment} from "react"
import ModalContext from "../contexts/ModalContext"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"

export default function ShopPointEdit(){

    const {checkPermission}=usePermissions()
    const [modalContext]=useContext(ModalContext)
    const {changeModal,manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const requestApi=useApi()
    const mode=modalContext.context
    const nameInput=useRef()
    const agreementsInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [nameVal,setNameVal]=useState("")
    const [isDefaultVal,setIsDefaultVal]=useState()
    const [statusVal,setStatusVal]=useState(1)
    const [agreementsVal,setAgreementsVal]=useState()

    const [agreementsOptions,setAgreementsOptions]=useState([])
    const loadAgreements=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/agreements",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(agreement){
                            newOptions.push({label:agreement["name"],value:agreement["id"]})
                        })
                        return newOptions
                    })
            )
        })
    }

    const [defaultData,setDefaultData]=useState("")
    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("shops/point_info",{
                            point_id:modalContext.reference["point"]
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadAgreements,loadData].map(func=>func())).then((result)=>{
            setAgreementsOptions(result[0])
            setDefaultData(result[1])
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData["id"])
            setNameVal(defaultData["name"])
            setIsDefaultVal(defaultData["is_default"])
            setStatusVal(defaultData["status"])
            let newAgreementsValue=[]
            agreementsOptions.forEach(function(item){
                if(defaultData["agreement"]==item.value){
                    newAgreementsValue.push({label:defaultData["agreement_name"],value:defaultData["agreement"]})
                }
                agreementsInput.current.setValue(newAgreementsValue)
            })
        }
    },[ready])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            if(agreementsInput.current.getValue()==""){
                agreementsInput.current.focus()
            }else{
                requestApi("shops/point_save",{
                        id:idVal,
                        mode:mode,
                        shop:modalContext.reference["shop"],
                        name:nameVal,
                        agreement:agreementsInput.current.getValue()[0]?agreementsInput.current.getValue()[0].value:0,
                        status:statusVal
                    })
                    .then(data=>{
                        if(data.status=="success"){
                            if(mode=="create"){
                                changeToast("created_m","")
                                manageModal("hide")
                            }else if(mode=="edit"){
                                changeModal("shop-point",modalContext.context,idVal)
                                changeToast("saved","")
                            }
                            refresh(["info"],"")
                        }else{
                            changeDialog("generic_error","","")
                        }
                    })
            }
        }
    }

    const remove=()=>{
        requestApi("shops/point_remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["info"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                {mode=="edit" && <ButtonBack clickEvent={()=>{changeModal("shop-point","",idVal)}} />}
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nuevo punto de cobro":"Editar punto de cobro"} />
                    <Textbox ref={nameInput} label="Nombre" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Selector ref={agreementsInput} val={agreementsVal} setVal={setAgreementsVal} placeholder="Acuerdo comercial" disabled={checkPermission({alias:"master_admin",reference:""})?false:true} multiple={0} searchable={0} options={agreementsOptions} />
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {(mode=="edit" && isDefaultVal==0 && checkPermission({alias:"master_admin",reference:""})) &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}