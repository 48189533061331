import {useState,forwardRef} from "react"
import useToast from "../hooks/useToast"
import ButtonPrimary from "../commons/ButtonPrimary"
import upload from "../assets/upload.svg"

const Uploader=forwardRef(({label,note,restrict,multiple,action},ref)=>{
    
    const {changeToast}=useToast()
    const [hasFile,setHasFile]=useState(false)

    const select=()=>{
        ref.current.click()
    }

    const change=()=>{
        for(let i=0;i<ref.current.files.length;i++){
            let file=ref.current.files[i]
            if(restrict!=""){
                if(!restrict.includes(file.type)){
                    changeToast("file_not_allow","")
                    ref.current.value=""
                    return false
                }
            }
            var reader=new FileReader()
            reader.readAsDataURL(file)
            reader.onload=()=>{
                setHasFile(file.name)
            }
        }
    }

    return(
        <div className="form-block uploader noselect">
            <div className="uploader-button" onClick={select}>
                <div className="uploader-button-layout">
                    <div className="uploader-icon"><img src={upload} /></div>
                    <div>{label}</div>
                </div>
            </div>
            {note!="" && <div className="uploader-note">{note}</div>}
            {hasFile && <div className="uploader-name">{hasFile}</div>}
            <input ref={ref} type="file" className="uploader-input" name="file" multiple={multiple?"multiple":null} onChange={change} />
            {(action!="" && hasFile) && <div className="uploader-submit">
                <ButtonPrimary label={action.label} clickEvent={action.clickEvent} />
            </div>}
        </div>
    )
    
})

export default Uploader