import IgImg from "../assets/ig.svg";
import FbImg from "../assets/fb.svg";
import InImg from "../assets/in.svg";
import YtImg from "../assets/yt.svg";
import WaImg from "../assets/wa.svg";

export default function Networks({context}){

    return(
        <div className={"networks"+(context=="support"?" networks-alt":context=="home"?" networks-alt-2":context=="mobile"?" networks-alt-2 networks-alt-3":context=="news"?" networks-alt-4":"")}>
            <a className="network" href="https://wa.me/542612185757" target="_blank"><img src={WaImg} alt="WhatsApp" /></a>
            <a className="network" href="https://instagram.com/cajero24" target="_blank"><img src={IgImg} alt="Instagram" /></a>
            <a className="network" href="https://facebook.com/cajero24" target="_blank"><img src={FbImg} alt="Facebook" /></a>
            <a className="network" href="https://youtube.com/c/Cajero24" target="_blank"><img src={YtImg} alt="YouTube" /></a>
            <a className="network" href="https://linkedin.com/company/cajero24com" target="_blank"><img src={InImg} alt="LinkedIn" /></a>
        </div>
    )
    
}