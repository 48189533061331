import {Fragment} from "react"

export default function SectionContent({children}){

    return(
        <Fragment>
            {children}
        </Fragment>
    )
    
}