export default function Switch({onLabel,offLabel,val,compact,nomargin,clickEvent}){

    const [statusVal,setStatusVal]=val

    const changeStatus=()=>{
        if(statusVal){
            setStatusVal(0)
        }else{
            setStatusVal(1)
        }
        if(clickEvent!=""){
            clickEvent()
        }
    }

    return(
        <div className={"noselect switch"+(statusVal?" switch-on":"")+(compact==1?" switch-compact":"")+(nomargin?" switch-nomargin":"")} onClick={changeStatus}>
            <div className="switch-chip">
                <div className="switch-chip-bg">
                    <div className="switch-chip-point"></div>
                </div>
            </div>
            <div className="switch-label">{statusVal?onLabel:offLabel}</div>
        </div>
    )
    
}