import {useState,useRef,useEffect,useContext,Fragment} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import {formatDate} from "../hooks/Commons"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonCommand from "../commons/ButtonCommand"
import Textarea from "../commons/Textarea"
import Message from "../components/Message"
import Switch from "../commons/Switch"
import Advice from "../components/Advice"
import Icon from "../commons/Icon"

export default function MessageInfo(){

    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const [message,setMessage]=useState()
    const contentInput=useRef()
    const [contentVal,setContentVal]=useState("")
    const [closeVal,setCloseVal]=useState(0)

    useEffect(()=>{
        modalReady(0)
        requestApi("messages/info",{
                message_id:modalContext.reference
            })
            .then(data=>{
                setMessage(data.data)
                modalReady(1)
            })
    },[])    
    
    const saveData=()=>{
        if(contentVal==""){
            contentInput.current.focus()
        }else{
            requestApi("messages/response",{
                    user_id:globalContext.user_id,
                    message_id:modalContext.reference,
                    content:contentVal,
                    close:closeVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        changeToast("message_sent","")
                        manageModal("hide")
                        refresh(["list"],"")
                    }else{
                        changeDialog("generic_error","","")
                    }    
                })
        }
    }

    const remove=()=>{
        requestApi("messages/remove",{
                id:modalContext.reference
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    {message &&
                    <Fragment>
                        <ModalTitle title={"Mensaje de contacto #"+message["id"]} />
                        {message.status==1 &&
                        <Advice color="orange">
                            <strong>Recibido.</strong> A espera de respuesta...
                        </Advice>}
                        {message.status==2 &&
                        <Advice color="green">
                            <strong>En proceso.</strong> El mensaje está siendo revisado...
                        </Advice>}
                        {message.status==3 &&
                        <Advice color="yellow">
                            <strong>Finalizado.</strong> El mensaje está cerrado.
                        </Advice>}
                        {(message["status"]==1 || message["status"]==2) &&
                        <Fragment>
                            <Textarea ref={contentInput} label="Escribí una respuesta..." val={contentVal} setVal={setContentVal} disabled={false} nomargin={true} />
                            <div className="modal-inline-actions">
                                <div className="modal-inline-actions-left">
                                    <Switch onLabel="Finalizar mensaje" offLabel="Finalizar mensaje" val={[closeVal,setCloseVal]} nomargin={true} clickEvent={()=>{closeVal==0&&changeDialog("message_close","","")}} />
                                </div>
                                <div className="modal-inline-actions-right">
                                    <ButtonPrimary label="Enviar respuesta" clickEvent={saveData} />
                                </div>
                            </div>
                        </Fragment>}
                        {message.responses.map((item,i)=>{
                            return(
                                <Message key={i} message={item} type="response" connector={(i==0 && message.status==3)?false:true} />
                            )
                        })}
                        <Message message={message} type="main" connector={(message.status==3 && message.responses.length==0)?false:true} />
                        <div className="ticket-info">
                            <div className="ticket-avatar">
                                <Icon element="message" context="medium" />
                            </div>
                            <div className="ticket-shop">{message["name"]!=""?message["name"]:"‒"} ({message["company"]!=""?message["company"]:"‒"})</div>
                            <div>Categoría/rubro: <span className="ticket-strong">{message["category"]!=""?message["category"]:"‒"}</span></div>
                            <div>Teléfono: <span className="ticket-strong">{message["phone"]!=""?message["phone"]:"‒"}</span></div>
                            <div>Email: <span className="ticket-strong">{message["email"]!=""?message["email"]:"‒"}</span></div>
                            <div>Recibido el {formatDate(message["created"],"long")}</div>
                            <div>({message["responses"].length+1} {message["responses"].length+1!=1?"mensajes":"mensaje"})</div>
                        </div>
                        <div className="modal-commands">
                            <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                        </div>
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}