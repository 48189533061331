import {useState,useRef,useEffect,useContext,Fragment} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import Textbox from "../commons/Textbox"
import Divisor from "../commons/Divisor"

export default function ShopCredentialsEdit(){

    const [modalContext]=useContext(ModalContext)
    const {changeModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const requestApi=useApi()
    const mode=modalContext.context
    const payzenIdInput=useRef()
    const payzenPrivateProdInput=useRef()
    const payzenPublicProdInput=useRef()
    const payzenHmacProdInput=useRef()
    const payzenPrivateTestInput=useRef()
    const payzenPublicTestInput=useRef()
    const payzenHmacTestInput=useRef()
    const payzenFormApiKeyInput=useRef()
    const dataposIdInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [payzenIdVal,setPayzenIdVal]=useState("")
    const [payzenPrivateProdVal,setPayzenPrivateProdVal]=useState("")
    const [payzenPublicProdVal,setPayzenPublicProdVal]=useState("")
    const [payzenHmacProdVal,setPayzenHmacProdVal]=useState("")
    const [payzenPrivateTestVal,setPayzenPrivateTestVal]=useState("")
    const [payzenPublicTestVal,setPayzenPublicTestVal]=useState("")
    const [payzenHmacTestVal,setPayzenHmacTestVal]=useState("")
    const [payzenFormApiKeyVal,setPayzenFormApiKeyVal]=useState("")
    const [dataposIdVal,setDataposIdVal]=useState("")

    useEffect(()=>{
        if(mode=="edit"){
            modalReady(0)
            requestApi("shops/credentials",{
                    point_id:modalContext.reference["point"]
                })
                .then(data=>{
                    setIdVal(data.data.id)
                    setPayzenIdVal(data.data.payzen_id)
                    setPayzenPrivateProdVal(data.data.payzen_private_prod)
                    setPayzenPublicProdVal(data.data.payzen_public_prod)
                    setPayzenHmacProdVal(data.data.payzen_hmac_prod)
                    setPayzenPrivateTestVal(data.data.payzen_private_test)
                    setPayzenPublicTestVal(data.data.payzen_public_test)
                    setPayzenHmacTestVal(data.data.payzen_hmac_test)
                    setDataposIdVal(data.data.datapos_id)
                    setPayzenFormApiKeyVal(data.data.payzen_form_api_key)
                    modalReady(1)
                })
        }
    },[])

    const saveData=()=>{
        requestApi("shops/credentials_save",{
                id:idVal,
                mode:mode,
                payzen_id:payzenIdVal,
                payzen_private_prod:payzenPrivateProdVal,
                payzen_public_prod:payzenPublicProdVal,
                payzen_hmac_prod:payzenHmacProdVal,
                payzen_private_test:payzenPrivateTestVal,
                payzen_public_test:payzenPublicTestVal,
                payzen_hmac_test:payzenHmacTestVal,
                payzen_form_api_key:payzenFormApiKeyVal,
                datapos_id:dataposIdVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeModal("shop-point","",modalContext.reference["point"])
                    changeToast("saved","")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("shop-point","",modalContext.reference["point"])}} />
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={"Credenciales APIs"} />
                    <Textbox ref={payzenIdInput} label="PayZen: ID de tienda" type="text" val={payzenIdVal} setVal={setPayzenIdVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={payzenPrivateProdInput} label="PayZen: Clave privada (Produccción)" type="text" val={payzenPrivateProdVal} setVal={setPayzenPrivateProdVal} disabled={false} />
                    <Textbox ref={payzenPublicProdInput} label="PayZen: Clave pública (Producción)" type="text" val={payzenPublicProdVal} setVal={setPayzenPublicProdVal} disabled={false} />
                    <Textbox ref={payzenHmacProdInput} label="PayZen: Clave HMAC (Producción)" type="text" val={payzenHmacProdVal} setVal={setPayzenHmacProdVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={payzenPrivateTestInput} label="PayZen: Clave privada (Test)" type="text" val={payzenPrivateTestVal} setVal={setPayzenPrivateTestVal} disabled={false} />
                    <Textbox ref={payzenPublicTestInput} label="PayZen: Clave pública (Test)" type="text" val={payzenPublicTestVal} setVal={setPayzenPublicTestVal} disabled={false} />
                    <Textbox ref={payzenHmacTestInput} label="PayZen: Clave HMAC (Test)" type="text" val={payzenHmacTestVal} setVal={setPayzenHmacTestVal} disabled={false} />
                    <Textbox ref={payzenFormApiKeyInput} label="PayZen: V2 Key" type="text" val={payzenFormApiKeyVal} setVal={setPayzenFormApiKeyVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={dataposIdInput} label="Datapos: ID de comercio" type="text" val={dataposIdVal} setVal={setDataposIdVal} disabled={false} />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}