import {Fragment} from "react"
import Item from "./Item"
import meh from "../assets/meh.svg"

export default function Items({config,items}){

    return(
        <div className={"items"+(config.section=="shops"?" items-block":"")}>
            {items.length==0?
            <div className="items-empty noselect">
                <img src={meh} />
                Nada por aquí...
            </div>
            :<Fragment>
                {config.section=="records" &&
                <div className="items-header items-header-records">
                    <div className="items-header-1">Fecha</div>
                    <div className="items-header-full">Detalle</div>
                    <div className="items-header-2">Valor</div>
                    <div className="items-header-3">Saldo</div>
                </div>}
                {config.section=="analytics" &&
                <div className="items-header items-header-analytics">
                    <div className="items-header-analytics-1">Comercios</div>
                    <div className="items-header-analytics-2">Cantidad</div>
                    <div className="items-header-analytics-3">Base</div>
                    <div className="items-header-analytics-4">Totales</div>
                    <div className="items-header-analytics-5">Cargos</div>
                </div>}
                {items.map((item,i)=>{
                    return(
                        <Item key={i} config={config} item={item} />
                    )
                })}
            </Fragment>}
        </div>
    )
    
}