import brand from "../assets/brand.svg"
import {Link} from "react-router-dom"

export default function EntranceBar({children}){

    return(
        <div className="entrance-bar">
            <Link to="/"><img src={brand} className="entrance-logo" alt="Cajero24" /></Link>
            {children}
        </div>
    )
    
}