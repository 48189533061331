import {useState,useContext,useEffect,Fragment,useRef} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useModal from "../hooks/useModal"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import ModalTitle from "../components/ModalTitle"
import SelectorPlace from "../commons/SelectorPlace"
import Textbox from "../commons/Textbox"

export default function UserLocation(){

    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const {changeDialog}=useDialog()
    const {changeModal,modalReady}=useModal()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const [streetVal,setStreetVal]=useState("")
    const [numerationVal,setNumerationVal]=useState("")
    const [additionalVal,setAdditionalVal]=useState("")
    const [cityVal,setCityVal]=useState("")
    const [regionVal,setRegionVal]=useState("")
    const [countryVal,setCountryVal]=useState("")
    const [postalcodeVal,setPostalcodeVal]=useState("")
    const [latitudeVal,setLatitudeVal]=useState("")
    const [longitudeVal,setLongitudeVal]=useState("")
    const [placeidVal,setPlaceidVal]=useState("")
    const additionalInput=useRef()
    const postalcodeInput=useRef()
    const context=modalContext.context
    let userId=0
    if(context=="account"){
        userId=globalContext.user_id
    }else if(context=="user"){
        userId=modalContext.reference
    }

    useEffect(()=>{
        modalReady(0)
        requestApi("users/info",{
                user_id:userId
            })
            .then(data=>{
                setStreetVal(data.data.location.street)
                setNumerationVal(data.data.location.numeration)
                setAdditionalVal(data.data.location.additional)
                setCityVal(data.data.location.city)
                setRegionVal(data.data.location.region)
                setCountryVal(data.data.location.country)
                setPostalcodeVal(data.data.location.postal_code)
                setLatitudeVal(data.data.location.latitude)
                setLongitudeVal(data.data.location.longitude)
                setPlaceidVal(data.data.location.place_id)
                modalReady(1)
            })
    },[])

    const updateLocation=()=>{
        requestApi("users/update_location",{
                user_id:userId,
                street:streetVal,
                numeration:numerationVal,
                additional:additionalVal,
                city:cityVal,
                region:regionVal,
                country:countryVal,
                postal_code:postalcodeVal,
                latitude:latitudeVal,
                longitude:longitudeVal,
                place_id:placeidVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeModal("user-info",modalContext.context,userId)
                    changeToast("saved","")
                    if(context=="user"){
                        refresh(["list"],"")
                    }
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("user-info",modalContext.context,userId)}} />
                <ButtonPrimary label="Guardar" clickEvent={updateLocation} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title="Actualizar ubicación" />
                    <div className="card-info">
                        <div>
                            <strong>{streetVal!=""?streetVal:""}
                            {numerationVal!=""?" "+numerationVal:""}</strong>
                            {additionalVal!=""?" ("+additionalVal+")":""}
                        </div>
                        <div>
                            <strong>{cityVal!=""?cityVal:""}
                            {regionVal!=""?", "+regionVal:""}
                            {countryVal!=""?", "+countryVal:""}</strong>
                        </div>
                        <div>
                            {postalcodeVal!=""?"CP: ":""}<strong>{postalcodeVal!=""?postalcodeVal:""}</strong>
                        </div>
                    </div>
                    <SelectorPlace placeholder="Buscar una dirección..." types={["address"]} modifiers={[setStreetVal,setNumerationVal,setCityVal,setRegionVal,setCountryVal,setPostalcodeVal,setLatitudeVal,setLongitudeVal,setPlaceidVal]} />
                    <Textbox ref={additionalInput} label="Adicional: (local/piso/dpto/barrio)" type="text" val={additionalVal} disabled={false} setVal={setAdditionalVal} />
                    <Textbox ref={postalcodeInput} label="Código postal:" type="text" val={postalcodeVal} disabled={false} setVal={setPostalcodeVal} />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}