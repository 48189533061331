import {useRef,forwardRef,useEffect} from "react"

const Textarea=forwardRef(({label,val,setVal,disabled,max,nomargin},ref)=>{

    const caption=useRef()

    const focus=()=>{
        ref.current.focus()
    }

    const blur=()=>{
        if(ref.current.value==""){
            caption.current.classList.remove("textarea-label-on")
        }
    }

    const change=(event)=>{
        setVal(event.target.value)
    }

    useEffect(()=>{
        if(ref.current.value!=""){
            caption.current.classList.add("textarea-label-on")
        }
    })

    return(
        <div className={"form-block noselect"+(nomargin?" form-block-nomargin":"")} onClick={focus}>
            <div ref={caption} className="textarea-label">{label}</div>
            <textarea ref={ref} className="textarea" maxLength={max!=""?max:""} disabled={disabled} value={val} onChange={change} onBlur={blur} onFocus={()=>{caption.current.classList.add("textarea-label-on")}}></textarea>
        </div>
    )
    
})

export default Textarea