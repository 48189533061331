import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Activity(){

    return(
        <Section>
            <SectionHeader
                title="Actividad"
                intro="Pagos realizados con Cajero24."
                back=""
                button=""
                tabs={[
                    {label:"Transacciones",to:"/c24/actividad"},
                    {label:"Liquidaciones",labelShort:"Liquida...",to:"/c24/actividad/liquidaciones"},
                    {label:"Cuenta comercial",labelShort:"Cuenta",to:"/c24/actividad/cuenta"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"activity",
                    endpoint:"transactions/list",
                    limit:10,
                    counter:{
                        singular:"movimiento",
                        plural:"movimientos"
                    },
                    action:{
                        mode:"modal",
                        context:"transaction",
                        reference:"transaction-info"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}