import {Fragment} from "react";
import Loader from "./Loader";
import Dialog from "../components/Dialog"
import Modal from "../components/Modal"
import entrance_1 from "../assets/entrance_1.svg"
import entrance_2 from "../assets/entrance_2.svg"
import entrance_3 from "../assets/entrance_3.svg"
import entrance_4 from "../assets/entrance_4.svg"
import entrance_5 from "../assets/entrance_5.svg"
import entrance_6 from "../assets/entrance_6.svg"
import entrance_7 from "../assets/entrance_7.svg"

export default function Entrance({children}){

    return(
        <Fragment>
            <div className="entrance noselect">
                <div className="entrance-intro">
                    <div className="entrance-intro-pic-frame">
                        <div className="entrance-intro-picture">
                            <div className="entrance-intro-elements">
                                <img className="entrance-intro-element-1" src={entrance_1} />
                                <img className="entrance-intro-element-2" src={entrance_2} />
                                <img className="entrance-intro-element-3" src={entrance_3} />
                                <img className="entrance-intro-element-4" src={entrance_4} />
                                <img className="entrance-intro-element-5" src={entrance_5} />
                                <img className="entrance-intro-element-6" src={entrance_6} />
                                <img className="entrance-intro-element-7" src={entrance_7} />
                            </div>
                        </div>
                    </div>
                    <div className="entrance-intro-caption">
                        <div>Operamos con <strong>todas las tarjetas de débito y crédito</strong> sin costos de setup ni cargos fijos mensuales.</div>
                    </div>
                </div>
                <div className="entrance-content">
                    {children}
                </div>
            </div>
            <Dialog />
            <Modal />
            <Loader />
        </Fragment>
    )
    
}