import {useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Agreements(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])

    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("agreement-edit","",0)
    }
    
    return(
        <Section>
            <SectionHeader
                title="Acuerdos comerciales"
                intro="Porcentaje de comisiones por el uso del servicio."
                back=""
                button={{label:"Nuevo acuerdo",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Todos los comercios",labelShort:"Comercios",to:"/c24/comercios"},
                    {label:"Etiquetas",to:"/c24/comercios/etiquetas"},
                    {label:"Acuerdos comerciales",labelShort:"Acuerdos",to:"/c24/comercios/acuerdos"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"agreements",
                    endpoint:"shops/agreements",
                    limit:0,
                    counter:{
                        singular:"acuerdo",
                        plural:"acuerdos"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"agreement-edit"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}