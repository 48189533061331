import {useState,useContext,useEffect,Fragment,useRef} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useModal from "../hooks/useModal"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import ModalTitle from "../components/ModalTitle"
import SelectorPlace from "../commons/SelectorPlace"
import Textbox from "../commons/Textbox"
import Divisor from "../commons/Divisor"

export default function ShopContact(){

    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const [phoneVal,setPhoneVal]=useState("")
    const [websiteVal,setWebsiteVal]=useState("")
    const [streetVal,setStreetVal]=useState("")
    const [numerationVal,setNumerationVal]=useState("")
    const [additionalVal,setAdditionalVal]=useState("")
    const [cityVal,setCityVal]=useState("")
    const [regionVal,setRegionVal]=useState("")
    const [countryVal,setCountryVal]=useState("")
    const [postalcodeVal,setPostalcodeVal]=useState("")
    const [latitudeVal,setLatitudeVal]=useState("")
    const [longitudeVal,setLongitudeVal]=useState("")
    const [placeidVal,setPlaceidVal]=useState("")
    const phoneInput=useRef()
    const websiteInput=useRef()
    const additionalInput=useRef()
    const postalcodeInput=useRef()
    let shopId=modalContext.reference

    useEffect(()=>{
        modalReady(0)
        requestApi("shops/info",{
                shop_id:shopId
            })
            .then(data=>{
                setPhoneVal(data.data["phone"])
                setWebsiteVal(data.data["website"])
                setStreetVal(data.data.location["street"])
                setNumerationVal(data.data.location["numeration"])
                setAdditionalVal(data.data.location["additional"])
                setCityVal(data.data.location["city"])
                setRegionVal(data.data.location["region"])
                setCountryVal(data.data.location["country"])
                setPostalcodeVal(data.data.location["postal_code"])
                setLatitudeVal(data.data.location["latitude"])
                setLongitudeVal(data.data.location["longitude"])
                setPlaceidVal(data.data.location["place_id"])
                modalReady(1)
            })
    },[])

    const updateLocation=()=>{
        requestApi("shops/update_location",{
                shop_id:shopId,
                phone:phoneVal,
                website:websiteVal,
                street:streetVal,
                numeration:numerationVal,
                additional:additionalVal,
                city:cityVal,
                region:regionVal,
                country:countryVal,
                postal_code:postalcodeVal,
                latitude:latitudeVal,
                longitude:longitudeVal,
                place_id:placeidVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("saved","")
                    manageModal("hide")
                    refresh(["info"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={updateLocation} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title="Datos de contacto" />
                    <div className="card-info">
                        <div>
                            <strong>{streetVal!=""?streetVal:""}
                            {numerationVal!=""?" "+numerationVal:""}</strong>
                            {additionalVal!=""?" ("+additionalVal+")":""}
                        </div>
                        <div>
                            <strong>{cityVal!=""?cityVal:""}
                            {regionVal!=""?", "+regionVal:""}
                            {countryVal!=""?", "+countryVal:""}</strong>
                        </div>
                        <div>
                            {postalcodeVal!=""?"CP: ":""}<strong>{postalcodeVal!=""?postalcodeVal:""}</strong>
                        </div>
                    </div>
                    <SelectorPlace placeholder="Buscar una dirección..." types={["address"]} modifiers={[setStreetVal,setNumerationVal,setCityVal,setRegionVal,setCountryVal,setPostalcodeVal,setLatitudeVal,setLongitudeVal,setPlaceidVal]} />
                    <Textbox ref={additionalInput} label="Adicional: (local/piso/dpto/barrio)" type="text" val={additionalVal} disabled={false} setVal={setAdditionalVal} />
                    <Textbox ref={postalcodeInput} label="Código postal:" type="text" val={postalcodeVal} disabled={false} setVal={setPostalcodeVal} />
                    <Divisor />
                    <Textbox ref={phoneInput} label="Teléfono:" type="text" val={phoneVal} disabled={false} setVal={setPhoneVal} />
                    <Textbox ref={websiteInput} label="Sitio web:" type="text" val={websiteVal} disabled={false} setVal={setWebsiteVal} />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}