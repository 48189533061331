import {useRef} from "react"
import usePlacesAutocomplete,{getDetails} from "use-places-autocomplete"
import useOnclickOutside from "react-cool-onclickoutside"
import Textbox from "../commons/Textbox"

export default function SelectorPlace({placeholder,types,modifiers}){

    const [setStreetVal,setNumerationVal,setCityVal,setRegionVal,setCountryVal,setPostalcodeVal,setLatitudeVal,setLongitudeVal,setPlaceidVal]=modifiers
    const placeInput=useRef()
    const {ready,value,suggestions:{status,data},setValue,clearSuggestions}=usePlacesAutocomplete({
        requestOptions:{
            types:types,
            language:"es"
        },
        debounce:200,
        cache:0
    })

    const handleInput=(val)=>{
        setValue(val)
    }

    const renderSuggestions=()=>
        data.map((suggestion)=>{
        const {place_id,structured_formatting:{main_text,secondary_text}}=suggestion
        return(
            <div className="selector-places-option" key={place_id} onClick={handleSelect(suggestion)}>
                <strong>{main_text}</strong> {secondary_text}
            </div>
        )
    })

    const ref=useOnclickOutside(()=>{
        clearSuggestions()
    })

    const handleSelect=({description,place_id})=>()=>{
        setValue(description,false)
        clearSuggestions()
        getDetails({placeId:place_id,fields:["place_id","address_components","geometry"]})
            .then((details)=>{
                setStreetVal("")
                setNumerationVal("")
                setCityVal("")
                setRegionVal("")
                setCountryVal("")
                setPostalcodeVal("")
                details?.address_components?.forEach(entry=>{
                    if(entry.types?.[0]==="route"){
                        setStreetVal(entry.long_name)
                    }
                    if(entry.types?.[0]==="street_number"){
                        setNumerationVal(entry.long_name)
                    }
                    if(entry.types?.[0]==="administrative_area_level_2"){
                        setCityVal(entry.long_name)
                    }
                    if(entry.types?.[0]==="administrative_area_level_1"){
                        setRegionVal(entry.long_name)
                    }
                    if(entry.types?.[0]==="country"){
                        setCountryVal(entry.long_name)
                    }
                    if(entry.types?.[0]==="postal_code"){
                        setPostalcodeVal(entry.long_name)
                    }
                })
                setLatitudeVal(details.geometry.location.lat())
                setLongitudeVal(details.geometry.location.lng())
                setPlaceidVal(details.place_id)
                setValue("")
                placeInput.current.focus()
                placeInput.current.blur()
            })
            .catch((error)=>{
                setValue("")
            })
    }

    return(
        <div className="selector-places" ref={ref}>
            <Textbox ref={placeInput} label={placeholder} type="text" val={value} icon="search" disabled={!ready} setVal={handleInput} />
            {status==="OK" && <div className="selector-places-options">{renderSuggestions()}</div>}
        </div>
    )
    
}