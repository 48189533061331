import {useState,useRef,useEffect,useContext,Fragment} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import ButtonCommand from "../commons/ButtonCommand"

export default function ShopBillingEdit(){

    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const requestApi=useApi()
    let shopId=modalContext.reference
    const cuitInput=useRef()
    const legalNameInput=useRef()
    const personTypeInput=useRef()
    const ivaConditionInput=useRef()
    const voucherTypeInput=useRef()
    const cbuInput=useRef()
    const taxResidenceInput=useRef()
    const billingEntityInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [cuitVal,setCuitVal]=useState("")
    const [legalNameVal,setLegalNameVal]=useState("")
    const [personTypeVal,setPersonTypeVal]=useState()
    const [ivaConditionVal,setIvaConditionVal]=useState()
    const [voucherTypeVal,setVoucherTypeVal]=useState()
    const [cbuVal,setCbuVal]=useState("")
    const [taxResidenceVal,setTaxResidenceVal]=useState("")
    const [billingEntityVal,setBillingEntityVal]=useState()
    const personTypeOptions=[
        {label:"Persona física",value:"person"},
        {label:"Persona jurídica",value:"company"}
    ]
    const ivaConditionOptions=[
        {label:"Responsable Inscripto",value:"responsable_inscripto"},
        {label:"Monotributista",value:"monotributista"},
        {label:"Responsable no Inscripto",value:"responsable_no_inscripto"},
        {label:"Exento",value:"exento"},
        {label:"Consumidor Final",value:"consumidor_final"}
    ]
    const voucherTypeOptions=[
        {label:"Factura A",value:"A"},
        {label:"Factura B",value:"B"},
        {label:"Factura Crédito MiPyme A",value:"FCE-A"},
        {label:"Factura Crédito MiPyme B",value:"FCE-B"}
    ]

    const [billingEntityOptions,setBillingEntityOptions]=useState([])
    const loadBillingEntity=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/billing_entities",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(entity){
                            newOptions.push({label:entity["name"],value:entity["id"]})
                        })
                        return newOptions
                    })
            )
        })
    }

    const [defaultData,setDefaultData]=useState("")
    const loadData=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/info",{
                        shop_id:shopId
                    })
                    .then(data=>{
                        return data.data
                    })
            )
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadBillingEntity,loadData].map(func=>func())).then((result)=>{
            setBillingEntityOptions(result[0])
            setDefaultData(result[1])
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(ready){
            setIdVal(defaultData["id"])
            setCuitVal(defaultData["cuit"])
            setLegalNameVal(defaultData["legal_name"])
            setCbuVal(defaultData["cbu"])
            setTaxResidenceVal(defaultData["tax_residence"])
            personTypeOptions.forEach(function(item){
                if(item.value==defaultData.person_type){
                    personTypeInput.current.setValue({label:item.label,value:defaultData.person_type})
                }
            })
            ivaConditionOptions.forEach(function(item){
                if(item.value==defaultData.iva_condition){
                    ivaConditionInput.current.setValue({label:item.label,value:defaultData.iva_condition})
                }
            })
            voucherTypeOptions.forEach(function(item){
                if(item.value==defaultData.voucher_type){
                    voucherTypeInput.current.setValue({label:item.label,value:defaultData.voucher_type})
                }
            })
            billingEntityOptions.forEach(function(item){
                if(item.value==defaultData.billing_entity){
                    billingEntityInput.current.setValue({label:item.label,value:defaultData.billing_entity})
                }
            })
        }
    },[ready])

    const checkCuit=()=>{
        requestApi("shops/cuit_check",{
                cuit:cuitVal
            })
            .then(data=>{
                if(data["verified"]==1){
                    changeToast("cuit_verified","")
                }else{
                    changeToast("cuit_unverified","")
                }
                setLegalNameVal(data["legal_name"])
                setTaxResidenceVal(data["tax_residence"])
                personTypeOptions.forEach(function(item){
                    if(item.value==data["person_type"]){
                        personTypeInput.current.setValue({label:item.label,value:item.value})
                    }
                })
            })
    }

    const saveData=()=>{
        requestApi("shops/billing_save",{
                shop_id:idVal,
                cuit:cuitVal,
                legal_name:legalNameVal,
                cbu:cbuVal,
                person_type:personTypeInput.current.getValue()[0]?personTypeInput.current.getValue()[0].value:"",
                iva_condition:ivaConditionInput.current.getValue()[0]?ivaConditionInput.current.getValue()[0].value:"",
                voucher_type:voucherTypeInput.current.getValue()[0]?voucherTypeInput.current.getValue()[0].value:"",
                tax_residence:taxResidenceVal,
                billing_entity:billingEntityInput.current.getValue()[0]?billingEntityInput.current.getValue()[0].value:0
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("saved","")
                    manageModal("hide")
                    refresh(["info"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title="Modificar facturación" />
                    <Textbox ref={cuitInput} label="CUIT" type="number" val={cuitVal} setVal={setCuitVal} disabled={false} />
                    <ButtonCommand label="Verificar CUIT" action="verify" clickEvent={checkCuit} />
                    <Textbox ref={legalNameInput} label="Razón social" type="text" val={legalNameVal} setVal={setLegalNameVal} disabled={false} />
                    <Selector ref={personTypeInput} val={personTypeVal} setVal={setPersonTypeVal} placeholder="Tipo de persona" searchable={0} options={personTypeOptions} />
                    <Selector ref={ivaConditionInput} val={ivaConditionVal} setVal={setIvaConditionVal} placeholder="Condición de IVA" searchable={0} options={ivaConditionOptions} />
                    <Selector ref={voucherTypeInput} val={voucherTypeVal} setVal={setVoucherTypeVal} placeholder="Tipo de factura" searchable={0} options={voucherTypeOptions} />
                    <Textbox ref={cbuInput} label="CBU (Requerido para factura FCE)" type="text" val={cbuVal} setVal={setCbuVal} disabled={false} />
                    <Textbox ref={taxResidenceInput} label="Domicilio fiscal" type="text" val={taxResidenceVal} setVal={setTaxResidenceVal} disabled={false} />
                    <Selector ref={billingEntityInput} val={billingEntityVal} setVal={setBillingEntityVal} placeholder="Entidad facturante" multiple={0} searchable={0} options={billingEntityOptions} />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}