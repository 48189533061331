import {Fragment,useContext,useRef,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Numbox from "../commons/Numbox"
import ModalTitle from "../components/ModalTitle"
import Divisor from "../commons/Divisor"
import ButtonCommand from "../commons/ButtonCommand"

export default function AgreementEdit(){

    const [modalContext]=useContext(ModalContext)
    const {modalReady,manageModal}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const nameInput=useRef()
    const feeDebitInput=useRef()
    const feeCreditInput=useRef()
    const feeDebinInput=useRef()
    const feeInstallmentsInput=useRef()
    const minAmountInput=useRef()
    const maxAmountInput=useRef()
    const fixedAmountInput=useRef()
    const feeQrInteroperableInput = useRef()
    const [idVal,setIdVal]=useState(0)
    const [nameVal,setNameVal]=useState("")
    const [feeDebitVal,setFeeDebitVal]=useState("")
    const [feeCreditVal,setFeeCreditVal]=useState("")
    const [feeDebinVal,setFeeDebinVal]=useState("")
    const [feeInstallmentsVal,setFeeInstallmentsVal]=useState("")
    const [minAmountVal,setMinAmountVal]=useState("")
    const [maxAmountVal,setMaxAmountVal]=useState("")
    const [fixedAmountVal,setFixedAmountVal]=useState("")
    const [feeQrInteroperableVal, setFeeQrInteroperableVal] = useState("")
    const mode=modalContext.reference==0?"create":"edit"

    useEffect(()=>{
        if(mode=="edit"){
            modalReady(0)
            requestApi("shops/agreement_info",{
                    agreement_id:modalContext.reference
                })
                .then(data=>{
                    setIdVal(data.data.id)
                    setNameVal(data.data.name)
                    setFeeDebitVal(data.data.fee_debit)
                    setFeeCreditVal(data.data.fee_credit)
                    setFeeDebinVal(data.data.fee_debin)
                    setFeeInstallmentsVal(data.data.fee_installments)
                    setMinAmountVal(data.data.min_amount)
                    setMaxAmountVal(data.data.max_amount)
                    setFixedAmountVal(data.data.fixed_amount)
                    setFeeQrInteroperableVal(data.data.fee_qr_interoperable)
                    modalReady(1)
                })
        }
    },[])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("shops/agreement_save",{
                    id:idVal,
                    mode:mode,
                    name:nameVal,
                    fee_debit:feeDebitVal,
                    fee_credit:feeCreditVal,
                    fee_debin:feeDebinVal,
                    fee_installments:feeInstallmentsVal,
                    min_amount:minAmountVal,
                    max_amount:maxAmountVal,
                    fixed_amount:fixedAmountVal,
                    fee_qr_interoperable: feeQrInteroperableVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_m","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        manageModal("hide")
                        refresh(["list"],"")
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("shops/agreement_remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nuevo acuerdo comercial":"Editar acuerdo comercial"} />
                    <Textbox ref={nameInput} label="Nombre" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Divisor />
                    <Numbox ref={feeDebitInput} label="Porcentaje un pago (Débito)" val={feeDebitVal} setVal={setFeeDebitVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={""} suffix={"%"} placeholder="" />
                    <Numbox ref={feeCreditInput} label="Porcentaje un pago (Crédito)" val={feeCreditVal} setVal={setFeeCreditVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={""} suffix={"%"} placeholder="" />
                    <Numbox ref={feeInstallmentsInput} label="Porcentaje en cuotas" val={feeInstallmentsVal} setVal={setFeeInstallmentsVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={""} suffix={"%"} placeholder="" />
                    <Divisor />
                    <Numbox ref={feeDebinInput} label="Porcentaje Debin" val={feeDebinVal} setVal={setFeeDebinVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={""} suffix={"%"} placeholder="" />
                    <Divisor />
                    <Divisor />
                    <Numbox ref={feeQrInteroperableInput} label="Porcentaje QR Interoperable" val={feeQrInteroperableVal} setVal={setFeeQrInteroperableVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={""} suffix={"%"} placeholder="" />
                    <Divisor />
                    <Numbox ref={minAmountInput} label="Monto mínimo" val={minAmountVal} setVal={setMinAmountVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                    <Numbox ref={maxAmountInput} label="Monto máximo" val={maxAmountVal} setVal={setMaxAmountVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                    <Divisor />
                    <Numbox ref={fixedAmountInput} label="Monto fijo" val={fixedAmountVal} setVal={setFixedAmountVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}