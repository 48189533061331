export default function Chart({title,subtitle,note,legends,children}){

    return(
        <div className="chart noselect">
            <div className="chart-title">{title}</div>
            {subtitle && <div className="chart-subtitle">{subtitle}</div>}
            <div className="chart-graphic">{children}</div>
            {note && <div className="chart-note"><div>{note}</div></div>}
            {legends.length>0 &&
            <div className="chart-legends">
                {legends.map((legend,i)=>{
                    return(
                        <div key={i} className="chart-legend">
                            <div className="chart-legend-shape" style={{backgroundColor:legend.color}}></div>
                            <div className="chart-legend-caption">{legend.label}</div>
                        </div>
                    )
                })}
            </div>}
        </div>
    )
    
}