import {useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Articles(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("article-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="Blog"
                intro="Artículos del blog Cajero24."
                back=""
                button={{label:"Agregar artículo",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Artículos",to:"/c24/articulos"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"articles",
                    endpoint:"blog/list",
                    reference:"",
                    limit:0,
                    counter:{
                        singular:"artículo",
                        plural:"artículos"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"article-edit"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}