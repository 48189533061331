import {useContext} from "react"
import ToastContext from "../contexts/ToastContext"

export default function useToast(){

    const [toastContext,setToastContext]=useContext(ToastContext)
    let toastTimer

    const changeToast=(toast,params)=>{
        let toastContextNew={...toastContext}
        toastContextNew["toast"]=toast
        toastContextNew["params"]=params
        setToastContext(toastContextNew)
        manageToast("show")
    }

    const manageToast=(action)=>{
        if(toastTimer){
            clearTimeout(toastTimer)
        }
        if(action=="show"){
            document.getElementById("toast").style.display="block"
            toastTimer=setTimeout(()=>{
                document.getElementById("toast").classList.add("toast-show")
            },50)
            setTimeout(()=>{
                manageToast("hide")
            },3000)
        }else if(action=="hide"){
            document.getElementById("toast").classList.remove("toast-show")
            toastTimer=setTimeout(()=>{
                document.getElementById("toast").style.display="none"
                let toastContextNew={...toastContext}
                toastContextNew["toast"]=""
                toastContextNew["params"]=""
                setToastContext(toastContextNew)
            },250)
        }
    }

    return {manageToast,changeToast}
    
}