import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Tickets(){
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("ticket-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="Asistencia"
                intro="Seguimiento de tickets de soporte."
                back=""
                button={{label:"Solicitar asistencia",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Atención al cliente",labelShort:"Atención",to:"/c24/soporte"},
                    {label:"Preguntas frecuentes",labelShort:"FAQs",to:"/c24/soporte/faqs"},
                    {label:"Tickets",to:"/c24/soporte/tickets"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"tickets",
                    endpoint:"tickets/list",
                    limit:100,
                    counter:{
                        singular:"ticket",
                        plural:"tickets"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"ticket-info"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}