import {useContext,Fragment} from "react"
import LoaderContext from "../contexts/LoaderContext"

export default function Loader(){

    const [loaderContext]=useContext(LoaderContext)

    return(
        <Fragment>
            {loaderContext.show==1 &&
            <div className="loader">
                <svg className="spinner" viewBox="0 0 50 50">
                    <circle className="spinner-path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
                </svg>
            </div>}
        </Fragment>
    )
    
}