import {useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import List from "../components/List"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"

export default function Analytics(){

    const {restrictSection,checkPermission}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    return(
        <Section>
            <SectionHeader
                title="Analítica"
                intro="Estadísticas globales de la operación de Cajero24."
                back=""
                button=""
                tabs={
                    checkPermission({alias:"master_admin",reference:""})?
                    [{label:"Reportes",to:"/c24/reportes"},
                    {label:"Analítica",to:"/c24/reportes/analitica"}]:
                    [{label:"Reportes",to:"/c24/reportes"}]
                }
            />
            <SectionContent>
                <List config={{
                    section:"analytics",
                    endpoint:"transactions/analytics",
                    limit:10000,
                    counter:{
                        singular:"comercio",
                        plural:"comercios"
                    },
                    action:""
                }} />
            </SectionContent>
        </Section>
    )
    
}