import {Fragment,useContext,useEffect,useState} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonBack from "../commons/ButtonBack"
import Amount from "../commons/Amount"

export default function RejectionInfo(){
    
    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {changeModal,modalReady}=useModal()
    const [info,setInfo]=useState()
    let rejectionId=modalContext.context=="from-transaction"?modalContext.reference["rejection"]:modalContext.reference
    let transactionId=modalContext.context=="from-transaction"?modalContext.reference["transaction"]:0

    useEffect(()=>{
        modalReady(0)
        requestApi("liquidations/rejection",{
                rejection_id:rejectionId
            })
            .then(data=>{
                setInfo(data.data)
                modalReady(1)
            })
    },[])

    const openTransaction=(transaction)=>{
        changeModal("transaction-info","from-rejection",{transaction:transaction,rejection:rejectionId})
    }

    return(
        <Fragment>
            <ModalHeader>
                {modalContext.context=="from-transaction" && <ButtonBack clickEvent={()=>{changeModal("transaction-info","",transactionId)}} />}
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {info &&
                    <Fragment>
                        <ModalTitle title={"Contracargo #"+info["datapos_id"]} />
                        <Card title={info["card"]["name"]+" ("+info["company"]+")"} icon={{element:"payment",reference:info["card"]["has_picture"]?info["card"]["id"]:0}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Fecha" detail="">
                                <span className="card-row-featured card-row-medium">{info["rejection_date"]!=""?info["rejection_date"]:"(Desconocida)"}</span>
                            </CardRow>
                            <CardRow label="Fecha de presentación" detail="">
                                <span className="card-row-featured card-row-medium">{info["presentation_date"]!=""?info["presentation_date"]:"(Desconocida)"}</span>
                            </CardRow>
                            <CardRow label="Importe" detail="">
                                <Amount number={info["amount"]} currency="ARS" operation="liquidation" />
                            </CardRow>
                            <CardRow label="Transacción" detail="">
                                <div className="card-link" onClick={()=>{openTransaction(info["transaction"])}}>Transacción #{String(info["transaction"]).padStart(6,"0")}</div>
                            </CardRow>
                            <CardRow label="Número de tarjeta" detail="">
                                {info["card_number"]!=""?("***"+info["card_number"]):"‒"}
                            </CardRow>
                            <CardRow label="Lote" detail="">
                                {info["batch"]!=""?("#"+info["batch"]):"‒"}
                            </CardRow>
                            <CardRow label="Comprobante" detail="">
                                {info["voucher"]!=""?("#"+info["voucher"]):"‒"}
                            </CardRow>
                            <CardRow label="Comercio" detail="">
                                {info["shop"]!=""?info["shop"]["name"]:"(Desconocido)"}
                            </CardRow>
                            <CardRow label="Punto de cobro" detail="">
                                {info["point"]!=""?info["point"]["name"]:"(Desconocido)"}
                            </CardRow>
                        </Card>
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}