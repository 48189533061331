import {useState,useRef,useContext,Fragment,useEffect} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ReactCrop,{centerCrop,makeAspectCrop} from "react-image-crop"
import {downScaleCanvas} from "../hooks/Commons"
import useApi from "../hooks/useApi"
import useToast from "../hooks/useToast"
import "react-image-crop/dist/ReactCrop.css"
import ButtonPrimary from "./ButtonPrimary"
import ButtonSecondary from "./ButtonSecondary"

export default function UploaderPicture({element,id,label,hasPicture,aspect,cropWidth,cropHeight,pictureChanger,setPictureChanger}){

    const [globalContext]=useContext(GlobalContext)
    const requestApi=useApi()
    const {changeToast}=useToast()
    const uploader=useRef()
    const imageRef=useRef(null)
    const canvasRef=useRef(null)
    const [image,setImage]=useState("")
    const [loading,setLoading]=useState(0)
    const [crop,setCrop]=useState()
    const [completed,setCompleted]=useState()
    const [scale,setScale]=useState(1)
    const [forceBg,setForceBg]=useState(false)
    let resizedCanvas=""

    let bgPicture=""
    if(hasPicture){
        if(element=="article-cover"){
            bgPicture="url("+globalContext["base_url"]+"resources/articles/bg/"+id+".jpg)"
        }
    }
    if(forceBg){
        bgPicture="url("+globalContext["base_url"]+"resources/temp/"+forceBg+")"
    }
    
    const change=()=>{
        for(let i=0;i<uploader.current.files.length;i++){
            let file=uploader.current.files[i]
            let restrict=[
                "image/jpeg",
                "image/png",
                "image/jpg",
                "image/x-png"
            ]
            if(!restrict.includes(file.type)){
                changeToast("file_not_allow","")
                uploader.current.value=""
                return false
            }
            setCrop(undefined)
            var reader=new FileReader()
            reader.readAsDataURL(file)
            reader.onload=()=>{
                setImage(reader.result?.toString() || "")
            }
        }
    }

    const imageLoad=(event)=>{
        const {width,height}=event.currentTarget
        setCrop(
            centerCrop(
                makeAspectCrop({unit:"%",width:100},aspect,width,height),width,height
            )
        )
    }

    const canvasPreview=(image,canvas,crop,scale=1)=>{
        const ctx=canvas.getContext("2d")
        if(!ctx){
            changeToast("image_not_uploaded","")
        }
        const scaleX=image.naturalWidth/image.width
        const scaleY=image.naturalHeight/image.height
        const pixelRatio=window.devicePixelRatio
        canvas.width=Math.floor(crop.width*scaleX*pixelRatio)
        canvas.height=Math.floor(crop.height*scaleY*pixelRatio)
        ctx.scale(pixelRatio,pixelRatio)
        ctx.imageSmoothingQuality="high"
        const cropX=crop.x*scaleX
        const cropY=crop.y*scaleY
        const centerX=image.naturalWidth/2
        const centerY=image.naturalHeight/2
        ctx.save()
        ctx.translate(-cropX,-cropY)
        ctx.translate(centerX,centerY)
        ctx.scale(scale,scale)
        ctx.translate(-centerX,-centerY)
        ctx.drawImage(image,0,0,image.naturalWidth,image.naturalHeight,0,0,image.naturalWidth,image.naturalHeight)
        ctx.restore()
        if(canvas.width>cropWidth*2 || canvas.height>cropHeight*2){
            resizedCanvas=downScaleCanvas(canvasRef.current,0.5)
            if(resizedCanvas.width>cropWidth*2 || resizedCanvas.height>cropHeight*2){
                resizedCanvas=downScaleCanvas(canvasRef.current,0.25)
            }
        }else{
            resizedCanvas=canvasRef.current
        }
    }
    
    const getBlob=()=>{
        setLoading(1)
        canvasPreview(imageRef.current,canvasRef.current,completed,scale)
        if(canvasRef.current){
            resizedCanvas.toBlob((blob)=>{
                if(blob){
                    requestApi("general/upload_picture",{
                            reference:id,
                            context:element,
                            upload:blob
                        })
                        .then(data=>{
                            setPictureChanger(data.uploaded)
                            setForceBg(data.uploaded.unique+"."+data.uploaded.extension)
                            cancel()
                        })
                }else{
                    changeToast("image_not_uploaded","")
                }
            })
        }else{
            changeToast("image_not_uploaded","")
        }
    }

    const cancel=()=>{
        setCrop(undefined)
        setCompleted(undefined)
        setImage("")
        setScale(1)
        setLoading(0)
    }

    useEffect(()=>{
        cancel()
    },[pictureChanger])

    return(
        <div className="uploader-picture noselect">
            <div>
                {!image ?
                <Fragment>
                    <div className="uploader-picture-display uploader-picture-display-article" style={{backgroundImage:bgPicture}}></div>
                    <div className="uploader-picture-btn" onClick={()=>{uploader.current.click()}}>{label}</div>
                    <input ref={uploader} className="uploader-input" type="file" accept="image/*" name="file" multiple={false} onChange={change} />
                </Fragment>:
                <div>
                    <ReactCrop className="uploader-picture-crop" crop={crop} circularCrop={false} locked={true} onChange={(_,percentCrop)=>setCrop(percentCrop)} onComplete={(c)=>setCompleted(c)} aspect={aspect}>
                        <img ref={imageRef} src={image} style={{transform:"scale("+scale+")"}} onLoad={imageLoad} />
                    </ReactCrop>
                    <div>
                        <input type="range" className="uploader-picture-slider" min="1" max="3" value={scale} step="0.1" disabled={!image} onChange={(event)=>{if(Number(event.target.value)>=1){setScale(Number(event.target.value))}}} />
                        <div className="uploader-picture-buttons">
                            <ButtonPrimary label="Listo" clickEvent={getBlob} />
                            <ButtonSecondary label="Cancelar" clickEvent={cancel} />
                        </div>
                    </div>
                    {!!completed && <canvas ref={canvasRef} style={{display:"none"}} />}
                </div>}
            </div>
            {loading==1 &&
            <div className="uploader-picture-loading">
                <div>Aguardá un momento...</div>
            </div>}
        </div>
    )
    
}