import {useState,useRef,useContext,useEffect,Fragment} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import Textbox from "../commons/Textbox"
import ButtonCommand from "../commons/ButtonCommand"
import AvatarChanger from "../commons/AvatarChanger"

export default function UserEdit(){

    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const {changeDialog}=useDialog()
    const {changeModal,manageModal,modalReady}=useModal()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const mode=modalContext.reference==0?"create":"edit"
    const emailInput=useRef()
    const firstnameInput=useRef()
    const lastnameInput=useRef()
    const dniInput=useRef()
    const phoneInput=useRef()
    const cityInput=useRef()
    const regionInput=useRef()
    const countryInput=useRef()
    const [emailVal,setEmailVal]=useState("")
    const [firstnameVal,setFirstnameVal]=useState("")
    const [lastnameVal,setLastnameVal]=useState("")
    const [dniVal,setDniVal]=useState("")
    const [phoneVal,setPhoneVal]=useState("")
    const [cityVal,setCityVal]=useState("")
    const [regionVal,setRegionVal]=useState("")
    const [countryVal,setCountryVal]=useState("")
    const [pictureVal,setPictureVal]=useState(0)
    const [capitalsVal,setCapitalsVal]=useState("")
    const [pictureChanger,setPictureChanger]=useState("")
    const [statusVal,setStatusVal]=useState("")
    const context=modalContext.context
    let userId=0
    if(context=="account"){
        userId=globalContext.user_id
    }else if(context=="user"){
        userId=modalContext.reference
    }

    useEffect(()=>{
        if(mode=="edit"){
            modalReady(0)
            requestApi("users/info",{
                    user_id:userId
                })
                .then(data=>{
                    setEmailVal(data.data.email)
                    setFirstnameVal(data.data.first_name)
                    setLastnameVal(data.data.last_name)
                    setDniVal(data.data.dni)
                    setPhoneVal(data.data.phone)
                    setPictureVal(data.data.has_picture)
                    setCapitalsVal(data.data.capitals)
                    setStatusVal(data.data.status)
                    modalReady(1)
                })
        }
    },[])

    const updateInfo=()=>{
        if(firstnameVal==""){
            firstnameInput.current.focus()
        }else{
            if(lastnameVal==""){
                lastnameInput.current.focus()
            }else{
                if(dniVal==""){
                    dniInput.current.focus()
                }else{
                    if(mode=="create"){
                        requestApi("users/create",{
                                context:"admin",
                                email:emailVal,
                                first_name:firstnameVal,
                                last_name:lastnameVal,
                                dni:dniVal,
                                phone:phoneVal,
                                city:cityVal,
                                region:regionVal,
                                country:countryVal,
                                picture:pictureChanger
                            })
                            .then(data=>{
                                if(data.status=="success"){
                                    manageModal("hide")
                                    changeToast("created_m","")
                                    refresh(["list"],"")
                                }else if(data.status=="email_exists"){
                                    changeDialog("email_exists","","")
                                }else{
                                    changeDialog("generic_error","","")
                                }
                            })
                    }else if(mode=="edit"){
                        requestApi("users/update_info",{
                                user_id:userId,
                                first_name:firstnameVal,
                                last_name:lastnameVal,
                                dni:dniVal,
                                phone:phoneVal,
                                picture:pictureChanger
                            })
                            .then(data=>{
                                if(data.status=="success"){
                                    changeModal("user-info",modalContext.context,userId)
                                    changeToast("saved","")
                                    if(pictureChanger!=""){
                                        if(context=="user"){
                                            refresh(["avatar","list"],{element:"user",id:userId})
                                        }else{
                                            refresh(["avatar"],{element:"user",id:userId})
                                        }
                                    }else{
                                        if(context=="user"){
                                            refresh(["list"],"")
                                        }
                                    }
                                }else{
                                    changeDialog("generic_error","","")
                                }
                            })
                    }
                }
            }
        }
    }

    const remove=()=>{
        requestApi("users/remove",{
                id:userId
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const deactivate=()=>{
        requestApi("users/deactivate",{
                id:userId
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("deactivated","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const activate=()=>{
        requestApi("users/activate",{
                id:userId
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("activated","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const block=()=>{
        requestApi("users/block",{
                id:userId
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("blocked","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const unblock=()=>{
        requestApi("users/unblock",{
                id:userId
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("unblocked","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("user-info",modalContext.context,userId)}} />
                <ButtonPrimary label="Guardar" clickEvent={updateInfo} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nuevo usuario":"Modificar datos personales"} />
                    <AvatarChanger element="user" id={userId} picture={pictureVal} capitals={capitalsVal} pictureChanger={pictureChanger} setPictureChanger={setPictureChanger} />
                    <Textbox ref={emailInput} label="Email" type="text" val={emailVal} setVal={setEmailVal} disabled={mode=="create"?false:true} />
                    <Textbox ref={firstnameInput} label="Nombre" type="text" val={firstnameVal} setVal={setFirstnameVal} disabled={false} />
                    <Textbox ref={lastnameInput} label="Apellido" type="text" val={lastnameVal} setVal={setLastnameVal} disabled={false} />
                    <Textbox ref={dniInput} label="DNI" type="number" max={8} val={dniVal} setVal={setDniVal} disabled={false} />
                    <Textbox ref={phoneInput} label="Teléfono/WhatsApp" type="text" val={phoneVal} setVal={setPhoneVal} disabled={false} />
                    {mode=="create" &&
                    <Fragment>
                        <Textbox ref={cityInput} label="Ciudad" type="text" val={cityVal} setVal={setCityVal} disabled={false} />
                        <Textbox ref={regionInput} label="Provincia" type="text" val={regionVal} setVal={setRegionVal} disabled={false} />
                        <Textbox ref={countryInput} label="País" type="text" val={countryVal} setVal={setCountryVal} disabled={false} />
                    </Fragment>}
                    {(mode=="edit" && context=="user") &&
                    <div className="modal-commands">
                        {statusVal==0 &&
                        <Fragment>
                            <ButtonCommand label="Activar" action="activate" clickEvent={()=>{activate()}} />
                            <ButtonCommand label="Bloquear" action="block" clickEvent={()=>{block()}} />
                        </Fragment>}
                        {statusVal==1 &&
                        <Fragment>
                            <ButtonCommand label="Desactivar" action="deactivate" clickEvent={()=>{deactivate()}} />
                            <ButtonCommand label="Bloquear" action="block" clickEvent={()=>{block()}} />
                        </Fragment>}
                        {statusVal==2 &&
                        <Fragment>
                            <ButtonCommand label="Desbloquear" action="unblock" clickEvent={()=>{unblock()}} />
                        </Fragment>}
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}