import {useContext} from "react"
import LoaderContext from "../contexts/LoaderContext"

export default function useToast(){

    const [loaderContext,setLoaderContext]=useContext(LoaderContext)

    const manageLoader=(action)=>{
        let loaderContextNew={...loaderContext}
        if(action=="show"){
            loaderContextNew["show"]=1
        }else if(action=="hide"){
            loaderContextNew["show"]=0
        }
        setLoaderContext(loaderContextNew)
    }

    return {manageLoader}
    
}