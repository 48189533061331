import {useState,useEffect,useContext,Fragment} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import {nl2p} from "../hooks/Commons"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"

export default function FaqsInfo(){

    const [modalContext]=useContext(ModalContext)
    const {modalReady}=useModal()
    const requestApi=useApi()
    const [topicVal,setTopicVal]=useState("")
    const [contentVal,setContentVal]=useState("")

    useEffect(()=>{
        modalReady(0)
        requestApi("faqs/topic_info",{
                topic_id:modalContext.reference
            })
            .then(data=>{
                setTopicVal(data.data.topic)
                setContentVal(data.data.content)
                modalReady(1)
            })
    },[])

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={topicVal} />
                    <div className="modal-text" dangerouslySetInnerHTML={{__html:nl2p(contentVal)}}></div>
                </div>
            </ModalContent>
        </Fragment>
    )
    
}