import PaymentIcon from "../commons/PaymentIcon"
import arrow from "../assets/arrow-violet.svg"

export default function PaymentItem({name,detail,type,reference,clickEvent}){

    return(
        <div className="payment-method" onClick={clickEvent}>
            <div className="payment-method-icon">
                <PaymentIcon context="list" type={type} reference={reference} />
            </div>
            <div className="payment-method-caption">
                <div>
                    <div className="payment-method-name">{name}</div>
                    {detail!="" && <div className="payment-method-detail">{detail}</div>}
                </div>
            </div>
            <div className="payment-method-arrow"><img src={arrow} /></div>
        </div>
    )
    
}