import {Fragment,useContext,useState,useEffect,useRef} from "react"
import {CopyToClipboard} from "react-copy-to-clipboard"
import useApi from "../hooks/useApi"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import useToast from "../hooks/useToast"
import copyImg from "../assets/copy.svg"
import facebookImg from "../assets/share-facebook.svg"
import twitterImg from "../assets/share-twitter.svg"
import whatsappImg from "../assets/share-whatsapp.svg"
import messengerImg from "../assets/share-messenger.svg"
import telegramImg from "../assets/share-telegram.svg"
import skypeImg from "../assets/share-skype.svg"
import linkedinImg from "../assets/share-linkedin.svg"
import emailImg from "../assets/share-email.svg"
import smsImg from "../assets/share-sms.svg"

export default function Share(){

    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const {modalReady}=useModal()
    const {changeToast}=useToast()
    const {changeDialog,manageDialog}=useDialog()
    const requestApi=useApi()
    const [idVal,setIdVal]=useState()
    const [linkVal,setLinkVal]=useState("")
    const [nameVal,setNameVal]=useState()
    const [payerEmailVal,setPayerEmailVal]=useState()
    const context=modalContext["context"]
    const link=useRef()

    useEffect(()=>{
        if(context=="link"){
            modalReady(0)
            requestApi("links/info",{
                    link_id:modalContext.reference
                })
                .then(data=>{
                    setIdVal(data.data.id)
                    setNameVal(data.data.name)
                    setPayerEmailVal(data.data.payer_email)
                    setLinkVal(globalContext.base_url+"p/link/"+data.data.token)
                    modalReady(1)
                    link.current.focus()
                    link.current.select()
                })
        }else if(context=="subscription"){
            modalReady(0)
            requestApi("subscriptions/info",{
                    subscription_id:modalContext.reference
                })
                .then(data=>{
                    setIdVal(data.data.id)
                    setNameVal(data.data.name)
                    setLinkVal(globalContext.base_url+"p/subscription/"+data.data.id)
                    modalReady(1)
                    link.current.focus()
                    link.current.select()
                })
        }
    },[])

    const downloadQr=(event,reference)=>{
        event.preventDefault()
        let link=document.createElement("a")
        link.setAttribute("download","")
        link.href=globalContext["base_url"]+"api/plain/qr/link/"+reference
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const sendLink=()=>{
        requestApi("links/send",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    manageDialog("hide")
                    changeToast("link_sent","")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    <ModalTitle title="Compartir" />
                    <div className="share-link">
                        <div className="share-link-box"><input ref={link} type="text" value={linkVal} readOnly /></div>
                    </div>
                    <div className="share-link-buttons">
                        <CopyToClipboard text={linkVal} onCopy={()=>{changeToast("link_copied","")}}>
                            <div className="share-link-copy"><img src={copyImg} /></div>
                        </CopyToClipboard>
                        {payerEmailVal && <div onClick={()=>{changeDialog("link_send",payerEmailVal,sendLink)}} className="share-link-qr"><svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145.5 145.5"><g id="c"><g><circle cx="72.75" cy="72.75" r="72.75" transform="translate(-9.3 10.68) rotate(-7.89)" fill="none"/><path d="m109.59,35.98c-.71-.71-1.79-.93-2.72-.54l-58.76,24.23c-.97.4-1.59,1.36-1.54,2.41.04,1.05.73,1.96,1.73,2.28l24.92,8,8,24.92c.32,1,1.23,1.69,2.28,1.73.03,0,.07,0,.1,0,1.01,0,1.92-.61,2.31-1.55l24.23-58.76c.38-.93.17-2.01-.54-2.72Zm-25.7,53.26l-6.3-19.64c-.25-.77-.85-1.37-1.62-1.62l-19.64-6.3,46.9-19.33-19.33,46.9Zm-23.36-.52l-14.47,14.17c-.49.48-1.12.71-1.75.71s-1.3-.25-1.79-.75c-.97-.99-.95-2.57.04-3.54l14.47-14.17c.99-.97,2.57-.95,3.54.04.97.99.95,2.57-.04,3.54Zm14.25,3.11c.97.99.95,2.57-.04,3.54l-14.47,14.17c-.49.48-1.12.71-1.75.71s-1.3-.25-1.79-.75c-.97-.99-.95-2.57.04-3.54l14.47-14.17c.99-.97,2.57-.95,3.54.04Zm-20.89-17.32l-14.47,14.17c-.49.48-1.12.71-1.75.71s-1.3-.25-1.79-.75c-.97-.99-.95-2.57.04-3.54l14.47-14.17c.99-.97,2.57-.95,3.54.04.97.99.95,2.57-.04,3.54Z" fill="#624de3"/></g></g></svg></div>}
                        <a href={globalContext["base_url"]+"api/plain/qr/link/"+idVal} onClick={(event)=>{downloadQr(event,idVal)}} target="_blank" className="share-link-qr"><svg xmlns="http://www.w3.org/2000/svg" width="145.5" height="145.5" viewBox="0 0 145.5 145.5"><circle cx="72.75" cy="72.75" r="72.75" transform="translate(-9.3 10.68) rotate(-7.89)" fill="none"/><path d="M67.43,35.68H38.18a2.49,2.49,0,0,0-2.5,2.5V67.43a2.49,2.49,0,0,0,2.5,2.5H67.43a2.5,2.5,0,0,0,2.5-2.5V38.18A2.5,2.5,0,0,0,67.43,35.68Zm-2.5,29.25H40.68V40.68H64.93ZM47.27,60.84H58.34a2.5,2.5,0,0,0,2.5-2.5V47.27a2.5,2.5,0,0,0-2.5-2.5H47.27a2.5,2.5,0,0,0-2.5,2.5V58.34A2.5,2.5,0,0,0,47.27,60.84Zm2.5-11.07h6.07v6.07H49.77Zm30.8,38.45v19.1a2.5,2.5,0,0,1-5,0V88.22a2.5,2.5,0,0,1,5,0ZM67.43,75.57H38.18a2.49,2.49,0,0,0-2.5,2.5v29.25a2.49,2.49,0,0,0,2.5,2.5H67.43a2.5,2.5,0,0,0,2.5-2.5V78.07A2.5,2.5,0,0,0,67.43,75.57Zm-2.5,29.25H40.68V80.57H64.93Zm44.89-26.75V88.22a2.49,2.49,0,0,1-2.5,2.5h-21a2.5,2.5,0,1,1,0-5h18.46V78.07a2.5,2.5,0,0,1,5,0Zm-2.5-42.39H78.07a2.5,2.5,0,0,0-2.5,2.5V67.43a2.5,2.5,0,0,0,2.5,2.5h29.25a2.49,2.49,0,0,0,2.5-2.5V38.18A2.49,2.49,0,0,0,107.32,35.68Zm-2.5,29.25H80.57V40.68h24.25Zm5,32.84a2.49,2.49,0,0,1-2.5,2.5H86.53a2.5,2.5,0,0,1,0-5h20.79A2.49,2.49,0,0,1,109.82,97.77ZM75.57,78.07a2.5,2.5,0,0,1,2.5-2.5h19.1a2.5,2.5,0,1,1,0,5H78.07A2.5,2.5,0,0,1,75.57,78.07ZM95.2,107.32a2.5,2.5,0,0,1-2.5,2.5H86.53a2.5,2.5,0,0,1,0-5H92.7A2.5,2.5,0,0,1,95.2,107.32Zm14.62,0a2.49,2.49,0,0,1-2.5,2.5h-6.16a2.5,2.5,0,0,1,0-5h6.16A2.49,2.49,0,0,1,109.82,107.32Z" fill="#624DE3"/></svg></a>
                    </div>
                    <div className="share-buttons">
                        <a className="share-button" href={"https://facebook.com/dialog/share?app_id=123456789&redirect_uri="+encodeURIComponent(globalContext["base_url"])+"&href="+linkVal} target="_blank"><img src={facebookImg} /></a>
                        <a className="share-button" href={"https://twitter.com/intent/tweet?text=&via=cajero24&related=cajero24&url="+linkVal} target="_blank"><img src={twitterImg} /></a>
                        <a className="share-button" href={"https://web.whatsapp.com/send?text="+linkVal} target="_blank"><img src={whatsappImg} /></a>
                        <a className="share-button" href={"https://facebook.com/dialog/send?app_id=123456789&redirect_uri="+encodeURIComponent(globalContext["base_url"])+"&link="+linkVal} target="_blank"><img src={messengerImg} /></a>
                        <a className="share-button" href={"https://t.me/share/url?text="+nameVal+"&url="+linkVal} target="_blank"><img src={telegramImg} /></a>
                        <a className="share-button" href={"https://web.skype.com/share?text="+nameVal+"&url="+linkVal} target="_blank"><img src={skypeImg} /></a>
                        <a className="share-button" href={"https://www.linkedin.com/sharing/share-offsite/?url="+linkVal} target="_blank"><img src={linkedinImg} /></a>
                        <a className="share-button" href={"mailto:?subject="+nameVal+"&body="+linkVal} target="_blank"><img src={emailImg} /></a>
                        <a className="share-button" href={"sms:?body="+linkVal} target="_blank"><img src={smsImg} /></a>
                    </div>
                </div>
            </ModalContent>
        </Fragment>
    )
    
}