import {useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function NewsAdm(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("news-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="Novedades"
                intro="Feed de avisos, noticias y nuevas funciones de la plataforma."
                back=""
                button={{label:"Agregar novedad",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Tickets",to:"/c24/asistencia"},
                    {label:"Preguntas frecuentes",labelShort:"FAQs",to:"/c24/asistencia/faqs"},
                    {label:"Novedades",to:"/c24/asistencia/novedades"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"news",
                    endpoint:"news/list",
                    reference:"",
                    limit:0,
                    counter:{
                        singular:"novedad",
                        plural:"novedades"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"news-edit"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}