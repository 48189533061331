import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useApi from "../hooks/useApi"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Dropdown from "../commons/Dropdown"
import SwitchButton from "../commons/SwitchButton"
import close from "../assets/close-pink.svg"

export default function ShopsFilters(){

    const {manageFloating,floatingReady}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const requestApi=useApi()
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const filter4=useRef()
    const filter5=useRef()
    const filter6=useRef()
    const filter7=useRef()
    const filter8=useRef()
    const filter9=useRef()
    const [filter1Val,setFilter1Val]=useState([])
    const [filter2Val,setFilter2Val]=useState("")
    const [filter3Val,setFilter3Val]=useState("")
    const [filter4Val,setFilter4Val]=useState("")
    const [filter5Val,setFilter5Val]=useState([])
    const [filter6Val,setFilter6Val]=useState([])
    const [filter7Val,setFilter7Val]=useState([])
    const [filter8Val,setFilter8Val]=useState("")
    const [filter9Val,setFilter9Val]=useState([])

    const [cityOptions,setCityOptions]=useState([])
    const [regionOptions,setRegionOptions]=useState([])
    const [countryOptions,setCountryOptions]=useState([])
    const [tagOptions,setTagOptions]=useState([])
    const [agreementOptions,setAgreementOptions]=useState([])

    const loadCities=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/cities",{context:"shops"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(city){
                            newOptions.push({label:city.name,value:city.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadRegions=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/regions",{context:"shops"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(region){
                            newOptions.push({label:region.name,value:region.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadCountries=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/countries",{context:"shops"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(country){
                            newOptions.push({label:country.name,value:country.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadTags=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/tags",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(tag){
                            newOptions.push({label:tag.name,value:tag.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadAgreements=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/agreements",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(agreement){
                            newOptions.push({label:agreement.name,value:agreement.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    useEffect(()=>{
        floatingReady(0)
        Promise.all([loadTags,loadCities,loadRegions,loadCountries,loadAgreements].map(func=>func())).then((result)=>{
            setTagOptions(result[0])
            setCityOptions(result[1])
            setRegionOptions(result[2])
            setCountryOptions(result[3])
            let agreementOptionsNew=result[4]
            agreementOptionsNew.unshift({label:"Todos",value:""})
            setAgreementOptions(agreementOptionsNew)
            floatingReady(1)
        })
    },[])

    const apply=()=>{
        let filter1List=[]
        if(filter1.current.getValue()){
            filter1.current.getValue().forEach(function(item){
                filter1List.push(item["value"])
            })
        }
        let filter5List=[]
        if(filter5.current.getValue()){
            filter5.current.getValue().forEach(function(item){
                filter5List.push(item["value"])
            })
        }
        let filter6List=[]
        if(filter6.current.getValue()){
            filter6.current.getValue().forEach(function(item){
                filter6List.push(item["value"])
            })
        }
        let filter7List=[]
        if(filter7.current.getValue()){
            filter7.current.getValue().forEach(function(item){
                filter7List.push(item["value"])
            })
        }
        let filter9List=[]
        if(filter9.current.getValue()){
            filter9.current.getValue().forEach(function(item){
                filter9List.push(item["value"])
            })
        }
        setValue({
            tag:filter1List,
            status:filter2Val,
            activity:filter3.current.getValue()[0]?filter3.current.getValue()[0].value:"",
            points:filter4Val,
            city:filter5List,
            region:filter6List,
            country:filter7List,
            agreement:filter8.current.getValue()[0]?filter8.current.getValue()[0].value:"",
            iva:filter9List
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val([])
        setFilter2Val("")
        setFilter3Val("")
        setFilter4Val("")
        setFilter5Val([])
        setFilter6Val([])
        setFilter7Val([])
        setFilter8Val("")
        setFilter9Val([])
        filter1.current.setValue("")
        filter3.current.setValue("")
        filter5.current.setValue("")
        filter6.current.setValue("")
        filter7.current.setValue("")
        filter8.current.setValue("")
        filter9.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(value!=""){
            if(value["status"].length>0){
                setFilter2Val(value["status"])
            }
            if(value["activity"]!=""){
                filter3.current.props["options"].forEach(function(item){
                    if(value["activity"]==item.value){
                        filter3.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["points"].length>0){
                setFilter4Val(value["points"])
            }
            if(value["iva"].length>0){
                let newValue=[];
                filter9.current.props["options"].forEach(function(item){
                    if(value["iva"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter9.current.setValue(newValue)
            }
        }
    },[value])

    useEffect(()=>{
        if(value){
            if(value["tag"].length>0){
                let newValue=[];
                filter1.current.props["options"].forEach(function(item){
                    if(value["tag"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter1.current.setValue(newValue)
            }
        }
    },[tagOptions])

    useEffect(()=>{
        if(value){
            if(value["city"].length>0){
                let newValue=[];
                filter5.current.props["options"].forEach(function(item){
                    if(value["city"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter5.current.setValue(newValue)
            }
        }
    },[cityOptions])

    useEffect(()=>{
        if(value){
            if(value["region"].length>0){
                let newValue=[];
                filter6.current.props["options"].forEach(function(item){
                    if(value["region"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter6.current.setValue(newValue)
            }
        }
    },[regionOptions])

    useEffect(()=>{
        if(value){
            if(value["country"].length>0){
                let newValue=[];
                filter7.current.props["options"].forEach(function(item){
                    if(value["country"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter7.current.setValue(newValue)
            }
        }
    },[countryOptions])


    useEffect(()=>{
        if(value){
            if(value["agreement"]!=""){
                filter8.current.props["options"].forEach(function(item){
                    if(value["agreement"]==item.value){
                        filter8.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
        }
    },[agreementOptions])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-group">Etiquetas</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter1} val={filter1Val} setVal={setFilter1Val} placeholder="Buscar..." multiple={1} searchable={1} options={tagOptions} />
                    </div>
                </div>
                <div className="floating-group">Actividad</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter2} val={filter2Val} setVal={setFilter2Val} 
                            options={[
                                {label:"Activos",value:"active"},
                                {label:"Inactivos",value:"inactive"}
                        ]} />
                    </div>
                    <div className="floating-full">
                        <Dropdown ref={filter3} val={filter3Val} setVal={setFilter3Val} placeholder="Última transacción" multiple={0} searchable={0} options={[
                            {label:"Indiferente",value:""},
                            {label:"Hace una hora",value:"hour_ago"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Esta semana",value:"this_week"},
                            {label:"Hace quince días",value:"15_days_ago"},
                            {label:"Hace un mes",value:"month_ago"},
                            {label:"Hace tres meses",value:"three_months_ago"},
                            {label:"Hace seis meses",value:"six_months_ago"},
                            {label:"Hace un año",value:"year_ago"},
                            {label:"Más de un año",value:"more_than_year"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Puntos de cobro</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter4} val={filter4Val} setVal={setFilter4Val}
                            options={[
                                {label:"Sólo uno",value:"one"},
                                {label:"Entre 2~5",value:"2_5"},
                                {label:"Entre 5~10",value:"5_10"},
                                {label:"Más de 10",value:"10"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Ubicación</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter5} val={filter5Val} setVal={setFilter5Val} placeholder="Ciudad" multiple={1} searchable={1} options={cityOptions} />
                        <Dropdown ref={filter6} val={filter6Val} setVal={setFilter6Val} placeholder="Provincia" multiple={1} searchable={1} options={regionOptions} />
                        <Dropdown ref={filter7} val={filter7Val} setVal={setFilter7Val} placeholder="País" multiple={1} searchable={0} options={countryOptions} />
                    </div>
                </div>
                <div className="floating-group">Otros</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter8} val={filter8Val} setVal={setFilter8Val} placeholder="Acuerdo comercial" multiple={0} searchable={0} options={agreementOptions} />
                    </div>
                    <div className="floating-full">
                        <Dropdown ref={filter9} val={filter9Val} setVal={setFilter9Val} placeholder="Condición de IVA" multiple={1} searchable={0} options={[
                            {label:"Responsable Inscripto",value:"responsable_inscripto"},
                            {label:"Monotributista",value:"monotributista"},
                            {label:"Responsable no Inscripto",value:"responsable_no_inscripto"},
                            {label:"Exento",value:"exento"},
                            {label:"Consumidor Final",value:"consumidor_final"}
                        ]} />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}