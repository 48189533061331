function formatAmount(amount,currency,response){
    var components={
        integerPart:0,
        decimalPart:0,
        currencySymbol:currency=="ARS"?"$":""
    }
    components["integerPart"]=parseInt(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g,".")
    components["decimalPart"]=","+(amount-Math.floor(amount)).toFixed(2).toString().split(".")[1]
    if(response=="components"){
        return components
    }else if(response=="display"){
        return components["currencySymbol"]+components["integerPart"]+components["decimalPart"]
    }
}

function formatDate(timestamp,style){
    let date=""
    const jsDate=new Date(timestamp*1000);
    const days=["lunes","martes","miércoles","jueves","viernes","sábado","domingo"]
    const months=["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"]
    const dateDay=jsDate.getDate()
    const dateWeekday=jsDate.getDay()
    const dateMonth=jsDate.getMonth()
    const dateYear=jsDate.getFullYear()
    const dateTime=String(jsDate.getHours()).padStart(2,"0")+":"+String(jsDate.getMinutes()).padStart(2,"0")
    if(style=="long"){
        date=days[dateWeekday]+" "+dateDay+" de "+months[dateMonth]+" de "+dateYear+" a las "+dateTime
    }else if(style=="semilong"){
        date=dateDay+" de "+months[dateMonth]+" de "+dateYear+" ("+dateTime+")"
    }else if(style=="short"){
        date=dateDay+" de "+months[dateMonth]+" de "+dateYear
    }else if(style=="standard"){
        date=String(dateDay).padStart(2,"0")+"/"+String((dateMonth+1)).padStart(2,"0")+"/"+dateYear
    }else if(style=="minimal"){
        date=dateDay+"/"+(dateMonth+1)+"/"+dateYear.toString().substring(2,4)
    }else if(style=="time"){
        date=dateTime
    }
    return date
}

function humanDate(timestamp){
    let human=""
    const jsDate=new Date(timestamp*1000);
    const dateDay=jsDate.getDate()
    const dateMonth=jsDate.getMonth()
    const dateYear=jsDate.getFullYear()
    const dateTime=jsDate.getHours()+":"+jsDate.getMinutes()
    const months=["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"]
    const diff=Math.floor(Date.now()/1000)-parseInt(timestamp)
    if(diff<60){
        //Now (less than 60 segundos)
        human="En este momento"
    }else if(diff<120){
        //One minute (less than 120 seconds)
        human="Hace un minuto"
    }else if(diff<300){
        //Few minutes (less than 5 minutes)
        human="Hace algunos minutos"
    }else if(diff<3600){
        //Minutes (within the hour)
        let minutes=Math.round(diff/60)
        human="Hace {MINUTES} minutos"
        human=human.replace("{MINUTES}",minutes)
    }else if(diff<86400){
        //Hours (less than 24 hours)
        let hours=Math.round(diff/3600)
        if(hours==1){
            human="Hace una hora"
        }else{
            human="Hace {HOURS} horas"
            human=human.replace("{HOURS}",hours)
        }
    }else if(diff<172800){
        //Yesterday (less than 2 days)
        human="Ayer a las {TIME}"
        human=human.replace("{TIME}",dateTime)
    }else if(diff<432000){
        //Few days (less than 5 days)
        let days=Math.round(diff/86400)
        human="Hace {DAYS} días"
        human=human.replace("{DAYS}",days)
    }else{
        let today=new Date()
        let year=today.getFullYear()
        if(dateYear==year){
            //Current year
            human="El {DAY} de {MONTH}"
            human=human.replace("{DAY}",dateDay)
            human=human.replace("{MONTH}",months[dateMonth])
        }else{
            //Old years
            human="El {DAY} de {MONTH} de {YEAR}"
            human=human.replace("{DAY}",dateDay)
            human=human.replace("{MONTH}",months[dateMonth])
            human=human.replace("{YEAR}",dateYear)
        }
    }
    return human
}

function timeAgo(timestamp){
    let ago=""
    let diff=Math.floor(Date.now()/1000)-parseInt(timestamp)
    let min_singular=["seg","min","h","d","sem","mes","año","década"]
    let min_plural=["seg","min","h","d","sem","meses","años","décadas"]
    let lengths=["60","60","24","7","4.35","12","10"]
    let j=0
    for(j=0;diff>=lengths[j];j++)diff/=lengths[j]
    let rounded=Math.round(diff)
    if(rounded!=1){
        ago=rounded+" "+min_plural[j]
    }else{
        ago=rounded+" "+min_singular[j]
    }
    if(rounded==0){
        ago="ahora"
    }
    return ago
}

function formtatCuit(cuit){
    cuit=cuit.slice(0,2)+"-"+cuit.slice(2,10)+"-"+cuit.slice(10,11)
    return cuit
}

function nl2p(text){
    let array=text.split("\n")
    let tagged=""
    array.forEach(function(element){
        if(element.length>1){
            tagged+="<p>"+element+"</p>"
        }
    })
    return tagged
}

const downScaleCanvas=(cv,scale)=>{
    var sqScale=scale*scale
    var sw=cv.width
    var sh=cv.height
    var tw=Math.floor(sw*scale)
    var th=Math.floor(sh*scale)
    var sx=0,sy=0,sIndex=0
    var tx=0,ty=0,yIndex=0,tIndex=0
    var tX=0,tY=0
    var w=0,nw=0,wx=0,nwx=0,wy=0,nwy=0
    var crossX=false
    var crossY=false
    var sBuffer=cv.getContext("2d").
    getImageData(0,0,sw,sh).data
    var tBuffer=new Float32Array(3*tw*th)
    var sR=0,sG=0,sB=0
    for(sy=0;sy<sh;sy++){
        ty=sy*scale
        tY=0 | ty
        yIndex=3*tY*tw
        crossY=(tY!=(0 | ty+scale))
        if(crossY){
            wy=(tY+1-ty)
            nwy=(ty+scale-tY-1)
        }
        for(sx=0;sx<sw;sx++,sIndex+=4){
            tx=sx*scale
            tX=0 | tx
            tIndex=yIndex+tX*3
            crossX=(tX!=(0 | tx+scale))
            if(crossX){
                wx=(tX+1-tx)
                nwx=(tx+scale-tX-1)
            }
            sR=sBuffer[sIndex]
            sG=sBuffer[sIndex+1]
            sB=sBuffer[sIndex+2]
            if(!crossX && !crossY){
                tBuffer[tIndex]+=sR*sqScale
                tBuffer[tIndex+1]+=sG*sqScale
                tBuffer[tIndex+2]+=sB*sqScale
            }else if(crossX && !crossY){
                w=wx*scale
                tBuffer[tIndex]+=sR*w
                tBuffer[tIndex+1]+=sG*w
                tBuffer[tIndex+2]+=sB*w
                nw=nwx*scale
                tBuffer[tIndex+3]+=sR*nw
                tBuffer[tIndex+4]+=sG*nw
                tBuffer[tIndex+5]+=sB*nw
            }else if(crossY && !crossX){
                w=wy*scale
                tBuffer[tIndex]+=sR*w
                tBuffer[tIndex+1]+=sG*w
                tBuffer[tIndex+2]+=sB*w
                nw=nwy*scale
                tBuffer[tIndex+3*tw]+=sR*nw
                tBuffer[tIndex+3*tw+1]+=sG*nw
                tBuffer[tIndex+3*tw+2]+=sB*nw
            }else{
                w=wx*wy
                tBuffer[tIndex]+=sR*w
                tBuffer[tIndex+1]+=sG*w
                tBuffer[tIndex+2]+=sB*w
                nw=nwx*wy
                tBuffer[tIndex+3]+=sR*nw
                tBuffer[tIndex+4]+=sG*nw
                tBuffer[tIndex+5]+=sB*nw
                nw=wx*nwy
                tBuffer[tIndex+3*tw]+=sR*nw
                tBuffer[tIndex+3*tw+1]+=sG*nw
                tBuffer[tIndex+3*tw+2]+=sB*nw
                nw=nwx*nwy
                tBuffer[tIndex+3*tw+3]+=sR*nw
                tBuffer[tIndex+3*tw+4]+=sG*nw
                tBuffer[tIndex+3*tw+5]+=sB*nw
            }
        }
    }
    var resCV=document.createElement("canvas")
    resCV.width=tw
    resCV.height=th
    var resCtx=resCV.getContext("2d")
    var imgRes=resCtx.getImageData(0,0,tw,th)
    var tByteBuffer=imgRes.data
    var pxIndex=0  
    for(sIndex=0,tIndex=0;pxIndex<tw*th;sIndex+=3,tIndex+=4,pxIndex++){
        tByteBuffer[tIndex]=Math.ceil(tBuffer[sIndex])
        tByteBuffer[tIndex+1]=Math.ceil(tBuffer[sIndex+1])
        tByteBuffer[tIndex+2]=Math.ceil(tBuffer[sIndex+2])
        tByteBuffer[tIndex+3]=255
    }
    resCtx.putImageData(imgRes,0,0)
    return resCV
}

export {formatAmount,formatDate,humanDate,timeAgo,formtatCuit,nl2p,downScaleCanvas}