import next from "../assets/next.svg"

export default function EntranceButton({label,style,arrow,clickEvent}){

    return(
        <button type="submit" className={style=="secondary"?"entrance-btn-secondary":"entrance-btn-primary"} onClick={clickEvent}>
            {label}{arrow=="next" && <img src={next} />}
        </button>
    )

}