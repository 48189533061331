import {useState,useRef,useCallback,useEffect,useContext,Fragment} from "react"
import {createReactEditorJS} from "react-editor-js"
import Header from "@editorjs/header"
import List from "@editorjs/list"
import Embed from "@editorjs/embed"
import Marker from "@editorjs/marker"
import Image from "@editorjs/image"
import DragDrop from "editorjs-drag-drop"
import Undo from "editorjs-undo"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Textarea from "../commons/Textarea"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"
import UploaderPicture from "../commons/UploaderPicture"

export default function ArticleEdit(){

    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const mode=modalContext.reference==0?"create":"edit"
    const taglineInput=useRef()
    const titleInput=useRef()
    const summaryInput=useRef()
    const authorInput=useRef()
    const releasedInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [taglineVal,setTaglineVal]=useState("")
    const [titleVal,setTitleVal]=useState("")
    const [summaryVal,setSummaryVal]=useState("")
    const [authorVal,setAuthorVal]=useState("")
    const [contentVal,setContentVal]=useState({"blocks":[]})
    const [releasedVal,setReleasedVal]=useState("")
    const [statusVal,setStatusVal]=useState(0)
    const [hasPictureVal,setHasPictureVal]=useState(0)
    const [readyVal,setReadyVal]=useState(0)
    const [pictureChanger,setPictureChanger]=useState("")

    const editorCore=useRef(null)
    const handleInitialize=useCallback((instance)=>{
        editorCore.current=instance
    },[])
    const handleReady=()=>{
        const editor=editorCore.current._editorJS
        const undo=new Undo({editor})
        undo.initialize(contentVal)
        new DragDrop(editor)
    }
    const ReactEditorJS=createReactEditorJS()

    useEffect(()=>{
        if(mode=="edit"){
            modalReady(0)
            requestApi("blog/info",{
                    article_id:modalContext.reference
                })
                .then(data=>{
                    setIdVal(data.data["id"])
                    setTaglineVal(data.data["tagline"])
                    setTitleVal(data.data["title"])
                    setSummaryVal(data.data["summary"])
                    setAuthorVal(data.data["author"])
                    setContentVal(JSON.parse(data.data["content"]))
                    setReleasedVal(data.data["released_formatted"])
                    setStatusVal(data.data["status"])
                    setHasPictureVal(data.data["has_picture"])
                    modalReady(1)
                    setReadyVal(1)
                })
        }else{
            setReadyVal(1)
        }
    },[])

    const saveData=()=>{
        if(taglineVal==""){
            taglineInput.current.focus()
        }else{
            if(titleVal==""){
                titleInput.current.focus()
            }else{
                if(summaryVal==""){
                    summaryInput.current.focus()
                }else{
                    if(authorVal==""){
                        authorInput.current.focus()
                    }else{
                        if(releasedVal==""){
                            releasedInput.current.focus()
                        }else{
                            editorCore.current.save().then((outputData)=>{
                                requestApi("blog/save",{
                                        id:idVal,
                                        mode:mode,
                                        released:releasedVal,
                                        tagline:taglineVal,
                                        title:titleVal,
                                        summary:summaryVal,
                                        author:authorVal,
                                        content:outputData,
                                        status:statusVal,
                                        picture:pictureChanger
                                    })
                                    .then(data=>{
                                        if(data.status=="success"){
                                            if(mode=="create"){
                                                changeToast("created_m","")
                                            }else if(mode=="edit"){
                                                changeToast("saved","")
                                            }
                                            manageModal("hide")
                                            refresh(["list"],"")
                                        }else{
                                            changeDialog("generic_error","","")
                                        }
                                    })
                            }).catch((error)=>{
                                changeDialog("generic_error","","")
                            })
                        }
                    }
                }
            }
        }
    }

    const remove=()=>{
        requestApi("blog/remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Agregar artículo":"Editar artículo"} />
                    <UploaderPicture element="article-cover" id={idVal} hasPicture={hasPictureVal} aspect={14/9} label="Elegir imagen principal" cropWidth={1400} cropHeight={900} pictureChanger={pictureChanger} setPictureChanger={setPictureChanger} />
                    <Textbox ref={taglineInput} label="Subtítulo" type="text" val={taglineVal} setVal={setTaglineVal} disabled={false} />
                    <Textbox ref={titleInput} label="Título" type="text" val={titleVal} setVal={setTitleVal} disabled={false} />
                    <Textbox ref={authorInput} label="Autor" type="text" val={authorVal} setVal={setAuthorVal} disabled={false} />
                    <Textbox ref={releasedInput} label="Fecha de publicación" type="date" val={releasedVal} setVal={setReleasedVal} disabled={false} />
                    <Textarea ref={summaryInput} label="Introducción" val={summaryVal} setVal={setSummaryVal} disabled={false} />
                    {readyVal==1 &&
                    <div className="form-block editor">
                        <ReactEditorJS 
                            onInitialize={handleInitialize}
                            onReady={handleReady}
                            defaultValue={contentVal}
                            placeholder="Contenido del artículo..."
                            logLevel="ERROR"
                            minHeight={100}
                            tools={{
                                header:{
                                    class:Header,
                                    config:{
                                        levels:[2,3],
                                        defaultLevel:2
                                    }
                                },
                                list:{
                                    class:List,
                                    inlineToolbar:true,
                                    config:{
                                        defaultStyle:"unordered"
                                    }
                                },
                                marker:Marker,
                                image:{
                                    class:Image,
                                    config:{
                                        uploader:{
                                            uploadByFile(file){
                                                return requestApi("general/upload_picture",{
                                                        id:idVal,
                                                        context:"article-upload",
                                                        upload:file
                                                    })
                                                    .then(data=>{
                                                        if(data.status=="success"){
                                                            return {success:1,file:{url:data.uploaded.url}}
                                                        }else{
                                                            changeDialog("generic_error","","")
                                                            return {success:0,file:{url:""}}
                                                        }
                                                    })
                                            },
                                            uploadByUrl(url){
                                                return requestApi("general/upload_picture",{
                                                        id:idVal,
                                                        context:"article-url",
                                                        upload:url
                                                    })
                                                    .then(data=>{
                                                        if(data.status=="success"){
                                                            return {success:1,file:{url:data.uploaded.url}}
                                                        }else{
                                                            changeDialog("generic_error","","")
                                                            return {success:0,file:{url:""}}
                                                        }
                                                    })
                                            }
                                        },
                                        types:"image/jpeg,image/png,image/jpg,image/x-png",
                                        captionPlaceholder:"Epígrafe:",
                                        buttonContent:"Subir una imagen"
                                    }
                                },
                                embed:{
                                    class:Embed,
                                    inlineToolbar:true,
                                    config:{
                                        services:{
                                            instagram:true,
                                            facebook:true,
                                            twitter:true,
                                            pinterest:true,
                                            youtube:true,
                                            vimeo:true
                                        }
                                    }
                                }
                            }}
                            i18n={{
                                messages:{
                                    ui:{
                                        "blockTunes":{
                                            "toggler":{
                                                "Click to tune":"Clic para configurar",
                                                "or drag to move":"o arrastra para mover"
                                            }
                                        },
                                        "inlineToolbar":{
                                            "converter":{
                                                "Convert to":"Convertir a"
                                            }
                                        },
                                        "toolbar":{
                                            "toolbox":{
                                                "Add":"Agregar"
                                            }
                                        }
                                    },
                                    toolNames:{
                                        "Text":"Párrafo",
                                        "Heading":"Título",
                                        "List":"Lista",
                                        "Link":"Enlace",
                                        "Marker":"Resaltar",
                                        "Bold":"Negrita",
                                        "Italic":"Cursiva",
                                        "Image":"Imagen"
                                    },
                                    tools:{
                                        "link":{
                                            "Add a link":"Ingresar URL"
                                        },
                                        "image":{
                                            "With border":"Con borde",
                                            "With background":"Con fondo",
                                            "Stretch image":"Ajustar al ancho"
                                        },
                                        "embed":{
                                            "Enter a caption":"Agrega una descripción"
                                        }
                                    },
                                    blockTunes:{
                                        "delete":{
                                            "Delete":"Eliminar",
                                            "Click to delete":"Clic para eliminar"
                                        },
                                        "moveUp":{
                                            "Move up":"Mover arriba"
                                        },
                                        "moveDown":{
                                            "Move down":"Mover abajo"
                                        }
                                    }
                                }
                            }}
                        />
                    </div>}
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}