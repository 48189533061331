import {useContext} from "react"
import axios from "axios"
import GlobalContext from "../contexts/GlobalContext"
import useLoader from "../hooks/useLoader"

export default function useFetch(){

    const [globalContext]=useContext(GlobalContext)
    const {manageLoader}=useLoader()

    const fetchApi=async (endpoint,post)=>{

        let url=globalContext.base_url+"api/"+endpoint

        manageLoader("show")
        
        let postData={
            ...post,
            session_token:globalContext.session_token
        }

        return new Promise((resolve,reject)=>{
            axios({
                method:"post",
                url:url,
                data:postData
            }).then((response)=>{
                if(globalContext.dev_mode){
                    console.log(endpoint)
                    console.log(postData)
                    console.log(response.data)
                    console.log("-------------------------")
                }
                manageLoader("hide")
                resolve(response.data);
            }).catch((error)=>{
                manageLoader("hide")
                reject(error)
            })
        })

    }

    return fetchApi
    
}