import {useState,useEffect,Fragment} from "react"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import {nl2p} from "../hooks/Commons"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import ModalTitle from "../components/ModalTitle"
import Card from "../components/Card"

export default function Faqs(){

    const {modalReady}=useModal(0)
    const requestApi=useApi()
    const [data,setData]=useState()

    useEffect(()=>{
        modalReady(0)
        requestApi("faqs/reader",{})
            .then(data=>{
                setData(data.items)
                modalReady(1)
            })
    },[])

    const display=(group,topic)=>{
        let newData=[...data]
        if(newData[group]["topics"][topic]["shown"]==0){
            newData[group]["topics"][topic]["shown"]=1
        }else{
            newData[group]["topics"][topic]["shown"]=0
        }
        setData(newData)
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Más ayuda" clickEvent={()=>{window.open("mailto:hola@cajero24.co")}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title="Preguntas frecuentes" />
                    {data &&
                        data.map((item,i)=>{
                            return(
                                <Card title={item["name"]} icon={{element:""}} action="" actionIcon="" actionClickEvent="">
                                    <div className="home-faqs">
                                        {item["topics"].length>0?
                                        item["topics"].map((topic,ix)=>{
                                            return(
                                                <div className="home-faqs-block" key={ix}>
                                                    <div className="home-faqs-topic" onClick={()=>{display(i,ix)}}>{topic["topic"]}</div>
                                                    {topic["shown"]==1 &&
                                                    <div className="home-faqs-content" dangerouslySetInnerHTML={{__html:nl2p(topic["content"])}}></div>}
                                                </div>
                                            )})
                                        :<div className="home-faqs-content" key={i}>Aún no hay temas disponibles...</div>}
                                    </div>
                                </Card>
                            )
                        })
                    }
                </div>
            </ModalContent>
        </Fragment>
    )
    
}