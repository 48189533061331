import useModal from "../hooks/useModal"
import close from "../assets/close-pink.svg"

export default function ModalHeader({children}){

    const {manageModal}=useModal()

    return(
        <div className="modal-header noselect">
            <div className="modal-actions">
                {children}
            </div>
            <div className="modal-close">
                <div className="modal-close-btn" onClick={()=>{manageModal("hide")}}><img src={close} /></div>
            </div>
        </div>
    )
    
}