import {Fragment,useContext,useRef,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import Textbox from "../commons/Textbox"
import Divisor from "../commons/Divisor"
import ButtonCommand from "../commons/ButtonCommand"

export default function ShopBarcodeEdit(){

    const [modalContext]=useContext(ModalContext)
    const {changeModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const requestApi=useApi()
    const mode=modalContext.context
    const nameInput=useRef()
    const lengthInput=useRef()
    const identifierInput=useRef()
    const identifierStartInput=useRef()
    const amountIntegerStartInput=useRef()
    const amountIntegerLengthInput=useRef()
    const amountDecimalStartInput=useRef()
    const amountDecimalLengthInput=useRef()
    const expirationDayStartInput=useRef()
    const expirationDayLengthInput=useRef()
    const expirationMonthStartInput=useRef()
    const expirationMonthLengthInput=useRef()
    const expirationYearStartInput=useRef()
    const expirationYearLengthInput=useRef()
    const amount2IntegerStartInput=useRef()
    const amount2IntegerLengthInput=useRef()
    const amount2DecimalStartInput=useRef()
    const amount2DecimalLengthInput=useRef()
    const expiration2DayStartInput=useRef()
    const expiration2DayLengthInput=useRef()
    const expiration2MonthStartInput=useRef()
    const expiration2MonthLengthInput=useRef()
    const expiration2YearStartInput=useRef()
    const expiration2YearLengthInput=useRef()
    const clientStartInput=useRef()
    const clientLengthInput=useRef()
    const referenceStartInput=useRef()
    const referenceLengthInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [nameVal,setNameVal]=useState("")
    const [lengthVal,setLengthVal]=useState("")
    const [identifierVal,setIdentifierVal]=useState("")
    const [identifierStartVal,setIdentifierStartVal]=useState("")
    const [amountIntegerStartVal,setAmountIntegerStartVal]=useState("")
    const [amountIntegerLengthVal,setAmountIntegerLengthVal]=useState("")
    const [amountDecimalStartVal,setAmountDecimalStartVal]=useState("")
    const [amountDecimalLengthVal,setAmountDecimalLengthVal]=useState("")
    const [expirationDayStartVal,setExpirationDayStartVal]=useState("")
    const [expirationDayLengthVal,setExpirationDayLengthVal]=useState("")
    const [expirationMonthStartVal,setExpirationMonthStartVal]=useState("")
    const [expirationMonthLengthVal,setExpirationMonthLengthVal]=useState("")
    const [expirationYearStartVal,setExpirationYearStartVal]=useState("")
    const [expirationYearLengthVal,setExpirationYearLengthVal]=useState("")
    const [amount2IntegerStartVal,setAmount2IntegerStartVal]=useState("")
    const [amount2IntegerLengthVal,setAmount2IntegerLengthVal]=useState("")
    const [amount2DecimalStartVal,setAmount2DecimalStartVal]=useState("")
    const [amount2DecimalLengthVal,setAmount2DecimalLengthVal]=useState("")
    const [expiration2DayStartVal,setExpiration2DayStartVal]=useState("")
    const [expiration2DayLengthVal,setExpiration2DayLengthVal]=useState("")
    const [expiration2MonthStartVal,setExpiration2MonthStartVal]=useState("")
    const [expiration2MonthLengthVal,setExpiration2MonthLengthVal]=useState("")
    const [expiration2YearStartVal,setExpiration2YearStartVal]=useState("")
    const [expiration2YearLengthVal,setExpiration2YearLengthVal]=useState("")
    const [clientStartVal,setClientStartVal]=useState("")
    const [clientLengthVal,setClientLengthVal]=useState("")
    const [referenceStartVal,setReferenceStartVal]=useState("")
    const [referenceLengthVal,setReferenceLengthVal]=useState("")

    useEffect(()=>{
        if(mode=="edit"){
            modalReady(0)
            requestApi("shops/barcode_info",{
                    point_id:modalContext.reference["point"],
                    barcode_id:modalContext.reference["barcode"]
                })
                .then(data=>{
                    setIdVal(data.data.id)
                    setNameVal(data.data.name)
                    setLengthVal(data.data.length)
                    setIdentifierVal(data.data.identifier)
                    setIdentifierStartVal(data.data.identifier_start)
                    setAmountIntegerStartVal(data.data.amount_integer_start)
                    setAmountIntegerLengthVal(data.data.amount_integer_length)
                    setAmountDecimalStartVal(data.data.amount_decimal_start)
                    setAmountDecimalLengthVal(data.data.amount_decimal_length)
                    setExpirationDayStartVal(data.data.expiration_day_start)
                    setExpirationDayLengthVal(data.data.expiration_day_length)
                    setExpirationMonthStartVal(data.data.expiration_month_start)
                    setExpirationMonthLengthVal(data.data.expiration_month_length)
                    setExpirationYearStartVal(data.data.expiration_year_start)
                    setExpirationYearLengthVal(data.data.expiration_year_length)
                    setAmount2IntegerStartVal(data.data.amount_2_integer_start)
                    setAmount2IntegerLengthVal(data.data.amount_2_integer_length)
                    setAmount2DecimalStartVal(data.data.amount_2_decimal_start)
                    setAmount2DecimalLengthVal(data.data.amount_2_decimal_length)
                    setExpiration2DayStartVal(data.data.expiration_2_day_start)
                    setExpiration2DayLengthVal(data.data.expiration_2_day_length)
                    setExpiration2MonthStartVal(data.data.expiration_2_month_start)
                    setExpiration2MonthLengthVal(data.data.expiration_2_month_length)
                    setExpiration2YearStartVal(data.data.expiration_2_year_start)
                    setExpiration2YearLengthVal(data.data.expiration_2_year_length)
                    setClientStartVal(data.data.client_start)
                    setClientLengthVal(data.data.client_length)
                    setReferenceStartVal(data.data.reference_start)
                    setReferenceLengthVal(data.data.reference_length)
                    modalReady(1)
                })
        }
    },[])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("shops/barcode_save",{
                    id:idVal,
                    mode:mode,
                    point:modalContext.reference["point"],
                    name:nameVal,
                    length:lengthVal,
                    identifier:identifierVal,
                    identifier_start:identifierStartVal,
                    amount_integer_start:amountIntegerStartVal,
                    amount_integer_length:amountIntegerLengthVal,
                    amount_decimal_start:amountDecimalStartVal,
                    amount_decimal_length:amountDecimalLengthVal,
                    expiration_day_start:expirationDayStartVal,
                    expiration_day_length:expirationDayLengthVal,
                    expiration_month_start:expirationMonthStartVal,
                    expiration_month_length:expirationMonthLengthVal,
                    expiration_year_start:expirationYearStartVal,
                    expiration_year_length:expirationYearLengthVal,
                    amount_2_integer_start:amount2IntegerStartVal,
                    amount_2_integer_length:amount2IntegerLengthVal,
                    amount_2_decimal_start:amount2DecimalStartVal,
                    amount_2_decimal_length:amount2DecimalLengthVal,
                    expiration_2_day_start:expiration2DayStartVal,
                    expiration_2_day_length:expiration2DayLengthVal,
                    expiration_2_month_start:expiration2MonthStartVal,
                    expiration_2_month_length:expiration2MonthLengthVal,
                    expiration_2_year_start:expiration2YearStartVal,
                    expiration_2_year_length:expiration2YearLengthVal,
                    client_start:clientStartVal,
                    client_length:clientLengthVal,
                    reference_start:referenceStartVal,
                    reference_length:referenceLengthVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_f","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        changeModal("shop-point","",modalContext.reference["point"])
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("shops/barcode_remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    changeModal("shop-point","",modalContext.reference["point"])
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("shop-point","",modalContext.reference["point"])}} />
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nuevo código de barra":"Editar código de barra"} />
                    <div className="modal-explanation modal-explanation-center">
                        
                    </div>
                    <Textbox ref={nameInput} label="Nombre" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Textbox ref={lengthInput} label="Longitud total" type="number" max={2} val={lengthVal} setVal={setLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={identifierInput} label="Identificador del código (Valor)" type="number" max={20} val={identifierVal} setVal={setIdentifierVal} disabled={false} />
                    <Textbox ref={identifierStartInput} label="Identificador del código (Inicio)" type="number" max={2} val={identifierStartVal} setVal={setIdentifierStartVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={referenceStartInput} label="Referencia del pago (Inicio)" type="number" max={2} val={referenceStartVal} setVal={setReferenceStartVal} disabled={false} />
                    <Textbox ref={referenceLengthInput} label="Referencia del pago (Longitud)" type="number" max={2} val={referenceLengthVal} setVal={setReferenceLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={clientStartInput} label="Identificación del cliente (Inicio)" type="number" max={2} val={clientStartVal} setVal={setClientStartVal} disabled={false} />
                    <Textbox ref={clientLengthInput} label="Identificación del cliente (Longitud)" type="number" max={2} val={clientLengthVal} setVal={setClientLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={amountIntegerStartInput} label="Monto parte entera (Inicio)" type="number" max={2} val={amountIntegerStartVal} setVal={setAmountIntegerStartVal} disabled={false} />
                    <Textbox ref={amountIntegerLengthInput} label="Monto parte entera (Longitud)" type="number" max={2} val={amountIntegerLengthVal} setVal={setAmountIntegerLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={amountDecimalStartInput} label="Monto parte decimal (Inicio)" type="number" max={2} val={amountDecimalStartVal} setVal={setAmountDecimalStartVal} disabled={false} />
                    <Textbox ref={amountDecimalLengthInput} label="Monto parte decimal (Longitud)" type="number" max={2} val={amountDecimalLengthVal} setVal={setAmountDecimalLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={expirationDayStartInput} label="Día de vencimiento (Inicio)" type="number" max={2} val={expirationDayStartVal} setVal={setExpirationDayStartVal} disabled={false} />
                    <Textbox ref={expirationDayLengthInput} label="Día de vencimiento (Longitud)" type="number" max={2} val={expirationDayLengthVal} setVal={setExpirationDayLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={expirationMonthStartInput} label="Mes de vencimiento (Inicio)" type="number" max={2} val={expirationMonthStartVal} setVal={setExpirationMonthStartVal} disabled={false} />
                    <Textbox ref={expirationMonthLengthInput} label="Mes de vencimiento (Longitud)" type="number" max={2} val={expirationMonthLengthVal} setVal={setExpirationMonthLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={expirationYearStartInput} label="Año de vencimiento (Inicio)" type="number" max={2} val={expirationYearStartVal} setVal={setExpirationYearStartVal} disabled={false} />
                    <Textbox ref={expirationYearLengthInput} label="Año de vencimiento (Longitud)" type="number" max={2} val={expirationYearLengthVal} setVal={setExpirationYearLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={amount2IntegerStartInput} label="Monto 2 parte entera (Inicio)" type="number" max={2} val={amount2IntegerStartVal} setVal={setAmount2IntegerStartVal} disabled={false} />
                    <Textbox ref={amount2IntegerLengthInput} label="Monto 2 parte entera (Longitud)" type="number" max={2} val={amount2IntegerLengthVal} setVal={setAmount2IntegerLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={amount2DecimalStartInput} label="Monto 2 parte decimal (Inicio)" type="number" max={2} val={amount2DecimalStartVal} setVal={setAmount2DecimalStartVal} disabled={false} />
                    <Textbox ref={amount2DecimalLengthInput} label="Monto 2 parte decimal (Longitud)" type="number" max={2} val={amount2DecimalLengthVal} setVal={setAmount2DecimalLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={expiration2DayStartInput} label="Día de vencimiento 2 (Inicio)" type="number" max={2} val={expiration2DayStartVal} setVal={setExpiration2DayStartVal} disabled={false} />
                    <Textbox ref={expiration2DayLengthInput} label="Día de vencimiento 2 (Longitud)" type="number" max={2} val={expiration2DayLengthVal} setVal={setExpiration2DayLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={expiration2MonthStartInput} label="Mes de vencimiento 2 (Inicio)" type="number" max={2} val={expiration2MonthStartVal} setVal={setExpiration2MonthStartVal} disabled={false} />
                    <Textbox ref={expiration2MonthLengthInput} label="Mes de vencimiento 2 (Longitud)" type="number" max={2} val={expiration2MonthLengthVal} setVal={setExpiration2MonthLengthVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={expiration2YearStartInput} label="Año de vencimiento 2 (Inicio)" type="number" max={2} val={expiration2YearStartVal} setVal={setExpiration2YearStartVal} disabled={false} />
                    <Textbox ref={expiration2YearLengthInput} label="Año de vencimiento 2 (Longitud)" type="number" max={2} val={expiration2YearLengthVal} setVal={setExpiration2YearLengthVal} disabled={false} />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}