import {formatAmount} from "../hooks/Commons"

export default function Amount({number,currency,operation}){

    let amount=formatAmount(number,currency,"components")

    let className="amount"
    let sign=""

    if(operation=="payment"){
        className+=" amount-green"
        sign="+ "
    }else if(operation=="pending_payment"){
        className+=" amount-yellow"
        sign=""
    }else if(operation=="refund"){
        className+=" amount-orange"
        sign="- "
    }else if(operation=="canceled"){
        className+=" amount-red"
        sign="- "
    }else if(operation=="rejected"){
        className+=" amount-red"
        sign=""
    }else if(operation=="positive"){
        className+=" amount-green"
        sign=""
    }else if(operation=="negative"){
        className+=" amount-red"
        sign=""
    }else if(operation=="unpaid"){
        className+=" amount-pink"
        sign=""
    }else if(operation=="balance"){
        className+=" amount-transparent"
        sign=""
    }else if(operation=="liquidation"){
        className+=""
        sign=""
    }else if(operation=="analytics"){
        className+=" amount-analytics"
        sign=""
    }else if(operation=="analytics-alt"){
        className+=" amount-analytics-alt"
        sign=""
    }else if(operation=="charges"){
        className+=" amount-charges"
        sign=""
    }

    return (
        <div className={className}>
            <div>
                <span>{sign}</span>
                <span>{amount["currencySymbol"]}{amount["integerPart"]}</span>
                <span className="amount-decimal">{amount["decimalPart"]}</span>
            </div>
        </div>
    )
    
}