import {useContext} from "react"
import ModalContext from "../contexts/ModalContext"

export default function useModal(){

    const [modalContext,setModalContext]=useContext(ModalContext)
    let modalTimer

    const changeModal=(modal,context,reference)=>{
        let modalContextNew={...modalContext}
        modalContextNew["modal"]=modal
        modalContextNew["context"]=context
        modalContextNew["reference"]=reference
        setModalContext(modalContextNew)
        manageModal("show")
    }

    const modalReady=(ready)=>{
        let modalContextNew={...modalContext}
        modalContextNew["ready"]=ready
        setModalContext(modalContextNew)
    }

    const manageModal=(action)=>{
        if(modalTimer){
            clearTimeout(modalTimer)
        }
        if(action=="show"){
            document.getElementById("modal").style.display="block"
            modalTimer=setTimeout(()=>{
                document.getElementById("modal").classList.add("modal-show")
            },50)
        }else if(action=="hide"){
            document.getElementById("modal").classList.remove("modal-show")
            modalTimer=setTimeout(()=>{
                document.getElementById("modal").style.display="none"
                let modalContextNew={...modalContext}
                modalContextNew["modal"]=""
                modalContextNew["context"]=""
                modalContextNew["reference"]=""
                modalContextNew["ready"]=1
                setModalContext(modalContextNew)
            },250)
        }
    }

    return {manageModal,changeModal,modalReady}
    
}