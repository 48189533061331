import {Fragment} from "react"
import {Link} from "react-router-dom"
import arrow from "../assets/paging.svg"

export default function Paging({current,pages}){

    let prev=0
    let next=0
    let numbers=[]

    if(current<3){
        if(pages>0){numbers.push(1)}
        if(pages>1){numbers.push(2)}
        if(pages>2){numbers.push(3)}
        if(pages>3){numbers.push(4)}
        if(pages>4){numbers.push(5)}
    }else{
        if(pages>=current+2){
            numbers.push(current-2)
            numbers.push(current-1)
            numbers.push(current)
            numbers.push(current+1)
            numbers.push(current+2)
        }else{
            if(pages>=current+1){
                if(current-3>0){
                    numbers.push(current-3)
                }
                if(current-2>0){
                    numbers.push(current-2)
                }
                if(current-1>0){
                    numbers.push(current-1)
                }
                numbers.push(current)
                numbers.push(current+1)
            }else{
                if(current-3>0){
                    numbers.push(current-3)
                }
                if(current-2>0){
                    numbers.push(current-2)
                }
                if(current-1>0){
                    numbers.push(current-1)
                }
                numbers.push(current)
            }
        }
    }

    if(current>1){
        prev=current-1
    }

    next=current+1
    if(next>pages){
        next=0
    }

    return(
        <div className="paging noselect">
            {prev>0 && <Link className="paging-page paging-page-prev" to={"?p="+prev}><img src={arrow} /></Link>}
            {pages>1 && <Fragment>{numbers.map((number,index)=>(<Link key={index} className={current==number?"paging-page paging-page-on":"paging-page"} to={"?p="+number}>{number}</Link>))}</Fragment>}
            {next>0 && <Link className="paging-page paging-page-next" to={"?p="+next}><img src={arrow} /></Link>}
        </div>
    )
    
}