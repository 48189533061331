import {useEffect,useState,useContext,Fragment} from "react"
import {useSearchParams} from "react-router-dom"
import RefreshContext from "../contexts/RefreshContext"
import usePermissions from "../hooks/usePermissions"
import useFetch from "../hooks/useFetch"
import useRefresh from "../hooks/useRefresh"
import Items from "./Items"
import Tools from "./Tools"
import Paging from "./Paging"
import Spotlight from "./Spotlight"

export default function List({config}){
    
    const [refreshContext]=useContext(RefreshContext)
    const {checkPermission}=usePermissions()
    const {refreshClear}=useRefresh()

    const fetchApi=useFetch()
    const [response,setResponse]=useState()
    
    const [searchParams]=useSearchParams()
    const [keyword,setKeyword]=useState("")
    const [filters,setFilters]=useState("")
    const [changers,setChangers]=useState("")
    const [swapper,setSwapper]=useState({label:"",value:""})

    let page=1
    if(searchParams.get("p")!=null){
        page=parseInt(searchParams.get("p"))
    }

    useEffect(()=>{
        requestApi()
    },[page,keyword,filters,changers,swapper])

    useEffect(()=>{
        if(refreshContext["list"].refresh==1){
            refreshClear("list")
            requestApi()
        }
    },[refreshContext["list"]])

    const requestApi=()=>{
        fetchApi(config.endpoint,{
            page:page,
            limit:config.limit,
            keywords:keyword,
            filters:filters,
            changers:changers,
            swapper:swapper,
            reference:config.reference
        })
        .then(data=>{
            setResponse(data)
            document.getElementById("section").scrollTo(0,0)
        })
    }

    return(
        <Fragment>
            {!(config.section=="shops" && !checkPermission({alias:"master_admin",reference:""})) ?
            <Tools config={config} keyword={[keyword,setKeyword]} filter={[filters,setFilters]} changer={[changers,setChangers]} swapper={[swapper,setSwapper]} total={(response && response.total)?response.total:""} />
            :<div className="tools-fill"></div>}
            <div className="content-frame">
                {(response && "spotlight" in response && config.section=="records") && <Spotlight context="vouchers" data={response.spotlight} />}
                {(response && "spotlight" in response && config.section=="analytics") && <Spotlight context="analytics" data={response.spotlight} />}
                {(response && "items" in response) && <Items config={config} items={response.items} />}
                {(response && "pages" in response) && <Paging current={page} pages={response.pages} />}
            </div>
        </Fragment>
    )

}