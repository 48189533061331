import share from "../assets/share.svg"

export default function ButtonRounded({label,color,textColor,icon,clickEvent}){

    if(icon=="share"){
        icon=share
    }

    return(
        <div className="rounded-button" onClick={clickEvent} style={{"--color":color,"--textColor":textColor}}>
            {icon!="" && <div className="rounded-button-icon"><img src={icon} /></div>}
            <div>{label}</div>
        </div>
    )
    
}