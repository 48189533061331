import {Fragment,useContext,useRef,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonSecondary from "../commons/ButtonSecondary"
import Textbox from "../commons/Textbox"
import Numbox from "../commons/Numbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import Divisor from "../commons/Divisor"
import ButtonCommand from "../commons/ButtonCommand"

export default function LinkEdit(){

    const [modalContext]=useContext(ModalContext)
    const {changeModal,manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const requestApi=useApi()
    const mode=modalContext.reference==0?"create":"edit"
    const pointInput=useRef()
    const nameInput=useRef()
    const descriptionInput=useRef()
    const referenceInput=useRef()
    const amountInput=useRef()
    const payerEmailInput=useRef()
    const startDateInput=useRef()
    const endDateInput=useRef()
    const usageLimitInput=useRef()
    const urlSuccessInput=useRef()
    const urlPendingInput=useRef()
    const urlFailureInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [pointVal,setPointVal]=useState()
    const [nameVal,setNameVal]=useState("")
    const [descriptionVal,setDescriptionVal]=useState("")
    const [referenceVal,setReferenceVal]=useState("")
    const [amountVal,setAmountVal]=useState("")
    const [payerEmailVal,setPayerEmailVal]=useState("")
    const [startDateVal,setStartDateVal]=useState("")
    const [endDateVal,setEndDateVal]=useState("")
    const [usageLimitVal,setUsageLimitVal]=useState("")
    const [urlSuccessVal,setUrlSuccessVal]=useState("")
    const [urlPendingVal,setUrlPendingVal]=useState("")
    const [urlFailureVal,setUrlFailureVal]=useState("")
    const [statusVal,setStatusVal]=useState(1)
    const [defaultData,setDefaultData]=useState("")
    const [pointOptions,setPointOptions]=useState([])

    const loadPoints=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/shops_points",{context:"only_points"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(shop){
                            newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("links/info",{
                            link_id:modalContext.reference
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadPoints,loadData].map(func=>func())).then((result)=>{
            let pointOptionsNew=result[0]
            setPointOptions(pointOptionsNew)
            setDefaultData(result[1])
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            setDescriptionVal(defaultData.description)
            setReferenceVal(defaultData.reference)
            setAmountVal(defaultData.amount)
            setPayerEmailVal(defaultData.payer_email)
            setStartDateVal(defaultData.start_date=="0"?"":defaultData.start_date_formatted)
            setEndDateVal(defaultData.end_date=="0"?"":defaultData.end_date_formatted)
            setUsageLimitVal(defaultData.usage_limit=="0"?"":defaultData.usage_limit)
            setUrlSuccessVal(defaultData.url_success)
            setUrlPendingVal(defaultData.url_pending)
            setUrlFailureVal(defaultData.url_failure)
            setStatusVal(defaultData.status)
            pointOptions.forEach(function(item){
                if(item.value["point_id"]==defaultData.point){
                    pointInput.current.setValue({label:item.label,value:{shop_id:item.value["shop_id"],point_id:item.value["point_id"]}})
                }
            })
        }
    },[ready])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            if(pointInput.current.getValue().length==0){
                pointInput.current.focus()
            }else{
                if(amountVal==""){
                    amountInput.current.focus()
                }else{
                    requestApi("links/save",{
                            id:idVal,
                            mode:mode,
                            point:pointInput.current.getValue()[0]?pointInput.current.getValue()[0].value:0,
                            name:nameVal,
                            description:descriptionVal,
                            reference:referenceVal,
                            amount:amountVal,
                            payer_email:payerEmailVal,
                            start_date:startDateVal,
                            end_date:endDateVal,
                            usage_limit:usageLimitVal,
                            url_success:urlSuccessVal,
                            url_pending:urlPendingVal,
                            url_failure:urlFailureVal,
                            status:statusVal
                        })
                        .then(data=>{
                            if(data.status=="success"){
                                if(mode=="create"){
                                    changeToast("created_m","")
                                }else if(mode=="edit"){
                                    changeToast("saved","")
                                }
                                manageModal("hide")
                                refresh(["list"],"")
                            }else{
                                changeDialog("generic_error","","")
                            }
                        })
                }
            }
        }
    }

    const remove=()=>{
        requestApi("links/remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
                <ButtonSecondary label="Importar" clickEvent={()=>{changeModal("link-import","",0)}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nuevo link de pago":"Editar link de pago"} />
                    <Textbox ref={nameInput} label="Nombre" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Textbox ref={descriptionInput} label="Descripción (Opcional)" type="text" val={descriptionVal} setVal={setDescriptionVal} disabled={false} />
                    <Textbox ref={referenceInput} label="Referencia (Opcional)" type="text" val={referenceVal} setVal={setReferenceVal} disabled={false} />
                    <Divisor />
                    <Selector ref={pointInput} val={pointVal} setVal={setPointVal} placeholder="Punto de cobro" searchable={1} options={pointOptions} />
                    <Numbox ref={amountInput} label="Monto a cobrar" val={amountVal} setVal={setAmountVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                    <Divisor />
                    <Textbox ref={payerEmailInput} label="Email del pagador (Opcional)" type="text" val={payerEmailVal} setVal={setPayerEmailVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={startDateInput} label="Disponible desde (Opcional)" type="date" val={startDateVal} setVal={setStartDateVal} disabled={false} />
                    <Textbox ref={endDateInput} label="Disponible hasta (Opcional)" type="date" val={endDateVal} setVal={setEndDateVal} disabled={false} />
                    <Textbox ref={usageLimitInput} label="Límite de usos (Opcional)" type="number" val={usageLimitVal} setVal={setUsageLimitVal} disabled={false} />
                    <Divisor />
                    <Textbox ref={urlSuccessInput} label="URL pago completado (Opcional)" type="text" val={urlSuccessVal} setVal={setUrlSuccessVal} disabled={false} />
                    <Textbox ref={urlPendingInput} label="URL pago pendiente (Opcional)" type="text" val={urlPendingVal} setVal={setUrlPendingVal} disabled={false} />
                    <Textbox ref={urlFailureInput} label="URL error en el pago (Opcional)" type="text" val={urlFailureVal} setVal={setUrlFailureVal} disabled={false} />
                    <Divisor />
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}