import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Dropdown from "../commons/Dropdown"
import SwitchButton from "../commons/SwitchButton"
import close from "../assets/close-pink.svg"

export default function ArticlesFilters(){

    const {manageFloating,floatingReady}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const [filter1Val,setFilter1Val]=useState([])
    const [filter2Val,setFilter2Val]=useState("")
    const [filter3Val,setFilter3Val]=useState("")

    useEffect(()=>{
        floatingReady(1)
    },[])

    const apply=()=>{
        setValue({
            status:filter1Val,
            released:filter2.current.getValue()[0]?filter2.current.getValue()[0].value:"",
            created:filter3.current.getValue()[0]?filter3.current.getValue()[0].value:""
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val([])
        setFilter2Val("")
        setFilter3Val("")
        filter2.current.setValue("")
        filter3.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(value!=""){
            if(value["status"].length>0){
                setFilter1Val(value["status"])
            }
            if(value["released"]!=""){
                filter2.current.props["options"].forEach(function(item){
                    if(value["released"]==item.value){
                        filter2.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["created"]!=""){
                filter3.current.props["options"].forEach(function(item){
                    if(value["created"]==item.value){
                        filter3.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
        }
    },[value])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-options floating-options-space">
                    <div className="floating-half">
                        <SwitchButton ref={filter1} val={filter1Val} setVal={setFilter1Val} multiple={true}
                            options={[
                                {label:"Activos",value:"active"},
                                {label:"Inactivos",value:"inactive"},
                                {label:"Publicados",value:"released"},
                                {label:"Sin publicar",value:"not_released"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Fecha de publicación</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter2} val={filter2Val} setVal={setFilter2Val} placeholder="Período" multiple={0} searchable={1} options={[
                            {label:"Indiferente",value:""},
                            {label:"Última hora",value:"last_hour"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Última semana",value:"last_week"},
                            {label:"Últimos quince días",value:"last_15_days"},
                            {label:"Este mes",value:"this_month"},
                            {label:"Últimos tres meses",value:"last_three_months"},
                            {label:"Últimos seis meses",value:"last_six_months"},
                            {label:"Este año",value:"this_year"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Fecha de creación</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter3} val={filter3Val} setVal={setFilter3Val} placeholder="Período" multiple={0} searchable={1} options={[
                            {label:"Indiferente",value:""},
                            {label:"Última hora",value:"last_hour"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Última semana",value:"last_week"},
                            {label:"Últimos quince días",value:"last_15_days"},
                            {label:"Este mes",value:"this_month"},
                            {label:"Últimos tres meses",value:"last_three_months"},
                            {label:"Últimos seis meses",value:"last_six_months"},
                            {label:"Este año",value:"this_year"}
                        ]} />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}