import {useState,useEffect} from "react"
import {useParams} from "react-router-dom"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function FaqsAdmTopics(){

    let params=useParams()
    const requestApi=useApi()
    const {changeModal}=useModal()
    const [info,setInfo]=useState()

    useEffect(()=>{
        requestApi("faqs/group_info",{
                group_id:params.groupId
            })
            .then(data=>{
                setInfo(data.data)
            })
    },[])

    const edit=()=>{
        changeModal("ticket-edit","",0)
    }
    
    return(
        <Section>
            <SectionHeader
                title={info?info["name"]:""}
                intro="¿Con qué podemos ayudarte?"
                back="/c24/soporte/faqs"
                button={{label:"Solicitar asistencia",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Atención al cliente",labelShort:"Atención",to:"/c24/soporte"},
                    {label:"Preguntas frecuentes",labelShort:"FAQs",to:"/c24/soporte/faqs/"+params.groupId},
                    {label:"Tickets",to:"/c24/soporte/tickets"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"faqs-topics",
                    endpoint:"faqs/topics",
                    reference:{context:"public",group_id:params.groupId},
                    limit:0,
                    counter:{
                        singular:"tema",
                        plural:"temas"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"faqs-info"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}