import {useContext} from "react"
import GlobalContext from "../contexts/GlobalContext"
import card from "../assets/card.svg"
import transfer from "../assets/transfer.svg"
import bank from "../assets/bank.svg"
import qr from "../assets/qr-payment.svg"

export default function PaymentIcon({context,type,reference,status}){

    const [globalContext]=useContext(GlobalContext)
    let icon=""

    if(type=="new-card"){
        icon=card
    }else if(type=="debin"){
        icon=transfer
    }else if(type=="bank"){
        icon=bank
    }else if(type=="card"){
        if(reference==0){
            icon=card
        }else{
            icon=globalContext["base_url"]+"resources/cards/"+reference+".svg"
        }
    }else if(type=="entity"){
        if(reference==0){
            icon=bank
        }else{
            icon=globalContext["base_url"]+"resources/entities/"+reference+".svg"
        }
    } else if (type == "qr") {
        icon = qr
    }

    return(
        <div className={"noselect payment-method-pic"+(type=="entity"?" payment-method-pic-entity":"")+(context=="nav"?" payment-method-pic-nav":"")+(status=="on"?" payment-method-pic-on":"")}>
            {(type!="installment" && type!="installment-ahora") && <img src={icon} />}
            {(type=="installment" || type=="installment-ahora") && 
                <div className={type=="installment"?(reference==1?"payment-installment-icon":"payment-installments-icon"):"payment-installments-ahora-icon"}>{reference}</div>
            }
        </div>
    )
    
}