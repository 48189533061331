import {useState,useEffect,Fragment} from "react"
import {useSearchParams,useNavigate} from "react-router-dom"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import Entrance from "../components/Entrance"
import EntranceBar from "../commons/EntranceBar"
import EntranceSteps from "../commons/EntranceSteps"
import EntranceButton from "../commons/EntranceButton"
import EntranceHelp from "../commons/EntranceHelp"

export default function Verification(){

    const requestApi=useApi()
    const {changeDialog}=useDialog()
    const [searchParams]=useSearchParams()
    let token=""
    if(searchParams.get("t")!=null){
        token=searchParams.get("t")
    }
    const navigate=useNavigate()
    const [tokenStatus,setTokenStatus]=useState("")
    
    useEffect(()=>{
        requestApi("users/email_verification",{
                verification_token:token
            })
            .then(data=>{
                if(data.status=="success"){
                    setTokenStatus("success")
                }else if(data.status=="missed_token"){
                    setTokenStatus("missed_token")
                }else if(data.status=="already_verified"){
                    setTokenStatus("already_verified")
                }
            })
    },[])

    return(
        <Fragment>
            <Entrance>
                <EntranceBar />
                <EntranceSteps>
                    <div className="entrance-step">
                        {tokenStatus=="success" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>¡Listo!</strong> La dirección de email fue verificada correctamente.
                                <div className="entrance-caption-note">
                                    Podés seguir usando <strong>Cajero24</strong> con normalidad.
                                </div>
                            </div>
                            <EntranceButton label="Entrar" clickEvent={()=>{navigate("/entrar")}} style="primary" arrow="none" />
                        </Fragment>}
                        {tokenStatus=="missed_token" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>Algo no anduvo bien.</strong> No pudimos validar el enlace para verificar tu cuenta de email.
                                <div className="entrance-caption-note">
                                    Revisá que el link sea exactamente igual al que te enviamos por correo.
                                </div>
                            </div>
                            <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                        </Fragment>}
                        {tokenStatus=="already_verified" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>¡Listo!</strong> La dirección de email ya estaba verificada.
                                <div className="entrance-caption-note">
                                    Podés seguir usando <strong>Cajero24</strong> con normalidad.
                                </div>
                            </div>
                            <EntranceButton label="Entrar" clickEvent={()=>{navigate("/entrar")}} style="primary" arrow="none" />
                        </Fragment>}
                    </div>
                </EntranceSteps>
            </Entrance>
            <Ga />
            <ConnectionChecker />
        </Fragment>
    )
    
}