import {useEffect} from "react"
import {useSearchParams} from "react-router-dom"
import useModal from "../hooks/useModal"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import Networks from "../components/Networks"
import SupportImg from "../assets/support.svg"
import ButtonBox from "../commons/ButtonBox"

export default function Support(){

    const {changeModal}=useModal()
    const [searchParams]=useSearchParams()
    let openTicket=""
    if(searchParams.get("t")!=null){
        openTicket=searchParams.get("t")
    }

    useEffect(()=>{
        if(openTicket!=""){
            changeModal("ticket-info","",openTicket)
        }
    },[])

    const edit=()=>{
        changeModal("ticket-edit","",0)
    }
    
    return(
        <Section>
            <SectionHeader
                title="Centro de ayuda"
                intro="Preguntas frecuentes, conceptos importantes y asistencia técnica."
                back=""
                button={{label:"Solicitar asistencia",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Atención al cliente",labelShort:"Atención",to:"/c24/soporte"},
                    {label:"Preguntas frecuentes",labelShort:"FAQs",to:"/c24/soporte/faqs"},
                    {label:"Tickets",to:"/c24/soporte/tickets"}
                ]}
            />
            <SectionContent>
                <div className="boxes">
                    <div className="box">
                        <div className="box-info">
                            <div className="box-title">¿Con qué podemos ayudarte?</div>
                            <div className="box-description">Resolvé dudas frecuentes o problemas con tu cuenta, pagos, ventas, códigos QR y mucho más. Comunicate a través de nuestras redes sociales y solicitá asistencia personalizada.</div>
                            <ButtonBox label="Preguntas frecuentes" clickEvent="" to="/c24/soporte/faqs" link="" />
                        </div>
                        <div className="box-picture">
                            <img src={SupportImg} />
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-info">
                            <Networks context="support" />
                        </div>
                    </div>
                </div>
            </SectionContent>
        </Section>
    )
    
}