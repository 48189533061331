import {Fragment,useContext,useRef,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import ModalTitle from "../components/ModalTitle"
import ButtonCommand from "../commons/ButtonCommand"

export default function PaymentEdit(){
    
    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const requestApi=useApi()
    const mode=modalContext.reference==0?"create":"edit"
    const nameInput=useRef()
    const cardInput=useRef()
    const entityInput=useRef()
    const liquidationDelayInput=useRef()
    const aliasInput=useRef()
    const classInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [nameVal,setNameVal]=useState("")
    const [cardVal,setCardVal]=useState()
    const [entityVal,setEntityVal]=useState()
    const [liquidationDelayVal,setLiquidationDelayVal]=useState()
    const [aliasVal,setAliasVal]=useState("")
    const [statusVal,setStatusVal]=useState(0)
    const [defaultData,setDefaultData]=useState("")
    const [cardOptions,setCardOptions]=useState([])
    const [entityOptions,setEntityOptions]=useState([])
    const [classVal,setClassVal]=useState()
    const [classOptions]=useState([
        {label:"Crédito",value:"credit"},
        {label:"Débito",value:"debit"},
        {label:"Debin",value:"debin"}
    ])

    const loadCards=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("payments/cards",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(card){
                            newOptions.push({label:card.name,value:card.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadEntities=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("payments/entities",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(entity){
                            newOptions.push({label:entity.name,value:entity.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("payments/payment_info",{
                            payment_id:modalContext.reference
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadCards,loadEntities,loadData].map(func=>func())).then((result)=>{
            let cardOptionsNew=result[0]
            cardOptionsNew.unshift({label:"Ninguna",value:0})
            setCardOptions(cardOptionsNew)
            let entityOptionsNew=result[1]
            entityOptionsNew.unshift({label:"Ninguna",value:0})
            setEntityOptions(entityOptionsNew)
            setDefaultData(result[2])
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            setLiquidationDelayVal(defaultData.liquidation_delay)
            setAliasVal(defaultData.alias)
            setStatusVal(defaultData.status)
            cardOptions.forEach(function(item){
                if(item.value==defaultData.card){
                    cardInput.current.setValue({label:item.label,value:defaultData.card})
                }
            })
            entityOptions.forEach(function(item){
                if(item.value==defaultData.entity){
                    entityInput.current.setValue({label:item.label,value:defaultData.entity})
                }
            })
            classOptions.forEach(function(item){
                if(item.value==defaultData.class){
                    classInput.current.setValue({label:item.label,value:item.value})
                }
            })
        }
    },[ready])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            requestApi("payments/payment_save",{
                    id:idVal,
                    mode:mode,
                    name:nameVal,
                    class:classInput.current.getValue()[0]?classInput.current.getValue()[0].value:"",
                    card:cardInput.current.getValue()[0]?cardInput.current.getValue()[0].value:0,
                    entity:entityInput.current.getValue()[0]?entityInput.current.getValue()[0].value:0,
                    liquidation_delay:liquidationDelayVal,
                    alias:aliasVal,
                    status:statusVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_m","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        manageModal("hide")
                        refresh(["list"],"")
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("payments/payment_remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nuevo medio de pago":"Editar medio de pago"} />
                    <Textbox ref={nameInput} label="Nombre" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Selector ref={classInput} val={classVal} setVal={setClassVal} placeholder="Tipo" searchable={0} options={classOptions} />
                    <Selector ref={cardInput} val={cardVal} setVal={setCardVal} placeholder="Tarjeta" searchable={1} options={cardOptions} />
                    <Selector ref={entityInput} val={entityVal} setVal={setEntityVal} placeholder="Entidad emisora" searchable={1} options={entityOptions} />
                    <Textbox ref={aliasInput} label="Alias único (PayZen)" type="text" val={aliasVal} setVal={setAliasVal} disabled={false} />
                    <Textbox ref={liquidationDelayInput} label="Días estimados para la liquidación" type="number" val={liquidationDelayVal} setVal={setLiquidationDelayVal} disabled={false} />
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}