import {useState,useEffect,useRef,Fragment} from "react"
import {useSearchParams,useNavigate} from "react-router-dom"
import {osName,osVersion,browserName,browserVersion,mobileVendor,mobileModel,deviceType} from "react-device-detect"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import useApi from "../hooks/useApi"
import useSession from "../hooks/useSession"
import useDialog from "../hooks/useDialog"
import Entrance from "../components/Entrance"
import EntranceBar from "../commons/EntranceBar"
import EntranceSteps from "../commons/EntranceSteps"
import EntranceButton from "../commons/EntranceButton"
import EntranceTextbox from "../commons/EntranceTextbox"
import EntranceHelp from "../commons/EntranceHelp"

export default function PasswordReset(){

    const {validateSession}=useSession()
    useEffect(()=>{
        validateSession()
    },[])
    const [searchParams]=useSearchParams()
    let token=""
    if(searchParams.get("t")!=null){
        token=searchParams.get("t")
    }
    const navigate=useNavigate()
    const [tokenStatus,setTokenStatus]=useState("")
    const [userEmail,setUserEmail]=useState("")
    const {changeDialog}=useDialog()
    const {initSession}=useSession()
    const requestApi=useApi()
    const firstnameInput=useRef()
    const lastnameInput=useRef()
    const dniInput=useRef()
    const phoneInput=useRef()
    const passwordInput=useRef()
    const repasswordInput=useRef()
    const [firstnameVal,setFirstnameVal]=useState("")
    const [lastnameVal,setLastnameVal]=useState("")
    const [dniVal,setDniVal]=useState("")
    const [phoneVal,setPhoneVal]=useState("")
    const [passwordVal,setPasswordVal]=useState("")
    const [repasswordVal,setRepasswordVal]=useState("")
    
    useEffect(()=>{
        requestApi("users/activation",{
                activation_token:token
            })
            .then(data=>{
                if(data.status=="valid_token"){
                    setUserEmail(data.email)
                    setTokenStatus("valid_token")
                }else if(data.status=="missed_token"){
                    setTokenStatus("missed_token")
                }else if(data.status=="expired_token"){
                    setTokenStatus("expired_token")
                }
            })
    },[])

    const createAccount=(event)=>{
        event.preventDefault()
        if(firstnameVal==""){
            firstnameInput.current.focus()
        }else{
            if(lastnameVal==""){
                lastnameInput.current.focus()
            }else{
                if(dniVal==""){
                    dniInput.current.focus()
                }else{
                    if(phoneVal==""){
                        phoneInput.current.focus()
                    }else{
                        if(passwordVal==""){
                            passwordInput.current.focus()
                        }else{
                            if(repasswordVal==""){
                                repasswordInput.current.focus()
                            }else{
                                if(passwordVal!=repasswordVal){
                                    changeDialog("password_mismatch","","")
                                }else{
                                    if(passwordVal.length<7){
                                        changeDialog("password_length","","")
                                    }else{
                                        requestApi("users/create",{
                                                context:"activation",
                                                activation_token:token,
                                                email:userEmail,
                                                first_name:firstnameVal,
                                                last_name:lastnameVal,
                                                dni:dniVal,
                                                phone:phoneVal,
                                                street:"",
                                                numeration:"",
                                                additional:"",
                                                city:"",
                                                region:"",
                                                country:"",
                                                postal_code:"",
                                                latitude:"",
                                                longitude:"",
                                                place_id:"",
                                                password:passwordVal,
                                                os_name:osName,
                                                os_version:osVersion,
                                                browser_name:browserName,
                                                browser_version:browserVersion,
                                                device_type:deviceType,
                                                device_vendor:mobileVendor,
                                                device_model:mobileModel
                                            })
                                            .then(data=>{
                                                if(data.status=="success"){
                                                    initSession("activation",data.user_id,data.session_token,data.session_token_id,"/c24/tablero")
                                                }else if(data.status=="failed"){
                                                    changeDialog("generic_error","","")
                                                }
                                            })
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return(
        <Fragment>
            <Entrance>
                <EntranceBar />
                <EntranceSteps>
                    <div className="entrance-step">
                        {tokenStatus=="valid_token" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>¡Hola!</strong>  Completá estos datos para continuar con la activación de tu cuenta.
                                <div className="entrance-caption-note">
                                    Email: <strong>{userEmail}</strong>
                                </div>
                            </div>
                            <form>
                                <EntranceTextbox ref={firstnameInput} placeholder="Nombre:" type="text" val={firstnameVal} onChange={(event)=>{setFirstnameVal(event.target.value)}} />
                                <EntranceTextbox ref={lastnameInput} placeholder="Apellido:" type="text" val={lastnameVal} onChange={(event)=>{setLastnameVal(event.target.value)}} />
                                <EntranceTextbox ref={dniInput} placeholder="DNI:" type="text" val={dniVal} onChange={(event)=>{setDniVal(event.target.value.replace(/\D/g,""))}} />
                                <EntranceTextbox ref={phoneInput} placeholder="Teléfono/WhatsApp:" type="text" val={phoneVal} onChange={(event)=>{setPhoneVal(event.target.value.replace(/\D/g,""))}} />
                                <EntranceTextbox ref={passwordInput} placeholder="Contraseña:" type="password" val={passwordVal} onChange={(event)=>{setPasswordVal(event.target.value)}} />
                                <EntranceTextbox ref={repasswordInput} placeholder="Repetir contraseña:" type="password" val={repasswordVal} onChange={(event)=>{setRepasswordVal(event.target.value)}} />
                                <EntranceButton label="Activar mi cuenta" clickEvent={createAccount} style="primary" arrow="none" />
                                <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                            </form>
                        </Fragment>}
                        {tokenStatus=="missed_token" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>Algo no anduvo bien.</strong> No pudimos validar el enlace para activar la cuenta.
                                <div className="entrance-caption-note">
                                    Revisá que el link sea exactamente igual al que te enviamos por correo.
                                </div>
                            </div>
                            <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                        </Fragment>}
                        {tokenStatus=="expired_token" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>Algo no anduvo bien.</strong> El enlace para activar la cuenta ya no puede utilizarse.
                                <div className="entrance-caption-note">
                                    Asegurate de ingresar al link a los pocos minutos de que te llegue el correo.
                                </div>
                            </div>
                            <EntranceButton label="Reintentar" clickEvent={()=>{navigate("/entrar")}} style="primary" arrow="none" />
                            <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                        </Fragment>}
                    </div>
                </EntranceSteps>
            </Entrance>
            <Ga />
            <ConnectionChecker />
        </Fragment>
    )
    
}