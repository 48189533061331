export default function ButtonCard({label,style,clickEvent}){

    let classNames="card-button noselect"
    if(style=="secondary"){
        classNames="card-button card-button-secondary noselect"
    }else if(style=="violet"){
        classNames="card-button card-button-violet noselect"
    }

    return(
        <div className={classNames} onClick={clickEvent}>
            {label}
        </div>
    )
    
}