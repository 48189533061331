import {useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"

export default function useFloating(){

    const [floatingContext,setFloatingContext]=useContext(FloatingContext)
    let floatingTimer

    const changeFloating=(floating,context,reference,value,setValue)=>{
        let floatingContextNew={...floatingContext}
        floatingContextNew["floating"]=floating
        floatingContextNew["context"]=context
        floatingContextNew["reference"]=reference
        floatingContextNew["value"]=value
        floatingContextNew["setValue"]=setValue
        setFloatingContext(floatingContextNew)
        manageFloating("show")
    }

    const floatingReady=(ready)=>{
        let floatingContextNew={...floatingContext}
        floatingContextNew["ready"]=ready
        setFloatingContext(floatingContextNew)
    }

    const manageFloating=(action)=>{
        if(floatingTimer){
            clearTimeout(floatingTimer)
        }
        if(action=="show"){
            document.getElementById("floating").style.display="block"
            floatingTimer=setTimeout(()=>{
                document.getElementById("floating").classList.add("floating-show")
            },50)
        }else if(action=="hide"){
            document.getElementById("floating").classList.remove("floating-show")
            floatingTimer=setTimeout(()=>{
                document.getElementById("floating").style.display="none"
                let floatingContextNew={...floatingContext}
                floatingContextNew["floating"]=""
                floatingContextNew["context"]=""
                floatingContextNew["reference"]=""
                floatingContextNew["value"]=""
                floatingContextNew["setValue"]=""
                floatingContextNew["ready"]=1
                setFloatingContext(floatingContextNew)
            },250)
        }
    }

    return {manageFloating,changeFloating,floatingReady}
    
}