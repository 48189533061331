import {useState,useRef,useEffect,useContext,Fragment} from "react"
import {useNavigate} from "react-router-dom"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import Divisor from "../commons/Divisor"
import ButtonCommand from "../commons/ButtonCommand"
import AvatarChanger from "../commons/AvatarChanger"

export default function ShopEdit(){

    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const navigate=useNavigate()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const requestApi=useApi()
    const mode=modalContext.reference==0?"create":"edit"
    let shopId=modalContext.reference
    const nameInput=useRef()
    const descriptionInput=useRef()
    const tagsInput=useRef()
    const agreementsInput=useRef()
    const websiteInput=useRef()
    const phoneInput=useRef()
    const cityInput=useRef()
    const regionInput=useRef()
    const countryInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [nameVal,setNameVal]=useState("")
    const [descriptionVal,setDescriptionVal]=useState("")
    const [tagsVal,setTagsVal]=useState()
    const [agreementsVal,setAgreementsVal]=useState()
    const [websiteVal,setWebsiteVal]=useState("")
    const [phoneVal,setPhoneVal]=useState("")
    const [cityVal,setCityVal]=useState("")
    const [regionVal,setRegionVal]=useState("")
    const [countryVal,setCountryVal]=useState("")
    const [pictureVal,setPictureVal]=useState(0)
    const [pictureChanger,setPictureChanger]=useState("")
    const [statusVal,setStatusVal]=useState(1)

    const [tagsOptions,setTagsOptions]=useState([])
    const loadTags=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/tags",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(tag){
                            newOptions.push({label:tag["name"],value:tag["id"]})
                        })
                        return newOptions
                    })
            )
        })
    }

    const [agreementsOptions,setAgreementsOptions]=useState([])
    const loadAgreements=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/agreements",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(agreement){
                            newOptions.push({label:agreement["name"],value:agreement["id"]})
                        })
                        return newOptions
                    })
            )
        })
    }

    const [defaultData,setDefaultData]=useState("")
    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("shops/info",{
                            shop_id:shopId
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadTags,loadAgreements,loadData].map(func=>func())).then((result)=>{
            setTagsOptions(result[0])
            setAgreementsOptions(result[1])
            setDefaultData(result[2])
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData["id"])
            setNameVal(defaultData["name"])
            setDescriptionVal(defaultData["description"])
            let newTagsValue=[]
            tagsOptions.forEach(function(item){
                defaultData["tags"].forEach(function(itemx){
                    if(itemx.id==item.value){
                        newTagsValue.push({label:itemx.name,value:itemx.id})
                    }
                })
            })
            tagsInput.current.setValue(newTagsValue)
            setPictureVal(defaultData["has_picture"])
            setStatusVal(defaultData["status"])
        }
    },[ready])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            if(descriptionVal==""){
                descriptionInput.current.focus()
            }else{
                if(agreementsInput.current && agreementsInput.current.getValue()==""){
                    agreementsInput.current.focus()
                }else{
                    let agreement=0
                    if(mode=="create"){
                        agreement=agreementsInput.current.getValue()[0]?agreementsInput.current.getValue()[0].value:0
                    }
                    requestApi("shops/save",{
                            shop_id:idVal,
                            mode:mode,
                            name:nameVal,
                            description:descriptionVal,
                            tags:tagsInput.current.getValue(),
                            agreement:agreement,
                            website:websiteVal,
                            phone:phoneVal,
                            city:cityVal,
                            region:regionVal,
                            country:countryVal,
                            status:statusVal,
                            picture:pictureChanger
                        })
                        .then(data=>{
                            if(data.status=="success"){
                                changeToast("saved","")
                                manageModal("hide")
                                if(mode=="create"){
                                    refresh(["list"],"")
                                    if(pictureChanger!=""){
                                        refresh(["avatar","list"],{element:"shop",id:idVal})
                                    }else{
                                        refresh(["list"],"")
                                    }
                                }else if(mode=="edit"){
                                    if(pictureChanger!=""){
                                        refresh(["avatar","list"],{element:"shop",id:idVal})
                                    }else{
                                        refresh(["info"],"")
                                    }
                                }
                            }else{
                                changeDialog("generic_error","","")
                            }
                        })
                }
            }
        }
    }

    const remove=()=>{
        requestApi("shops/remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    navigate("/c24/comercios")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Crear comercio":"Modificar comercio"} />
                    <AvatarChanger element="shop" id={idVal} picture={pictureVal} capitals="" pictureChanger={pictureChanger} setPictureChanger={setPictureChanger} />
                    <Textbox ref={nameInput} label="Nombre" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Textbox ref={descriptionInput} label="Detalle" type="text" val={descriptionVal} setVal={setDescriptionVal} disabled={false} />
                    <Selector ref={tagsInput} val={tagsVal} setVal={setTagsVal} placeholder="Etiquetas" multiple={1} searchable={1} options={tagsOptions} />
                    {mode=="create" &&
                    <Fragment>
                        <Divisor />
                        <Selector ref={agreementsInput} val={agreementsVal} setVal={setAgreementsVal} placeholder="Acuerdo comercial" multiple={0} searchable={0} options={agreementsOptions} />
                    </Fragment>}
                    {mode=="create" &&
                    <Fragment>
                        <Divisor />
                        <Textbox ref={websiteInput} label="Sitio web" type="text" val={websiteVal} setVal={setWebsiteVal} disabled={false} />
                        <Textbox ref={phoneInput} label="Teléfono/WhatsApp" type="text" val={phoneVal} setVal={setPhoneVal} disabled={false} />
                        <Textbox ref={cityInput} label="Ciudad" type="text" val={cityVal} setVal={setCityVal} disabled={false} />
                        <Textbox ref={regionInput} label="Provincia" type="text" val={regionVal} setVal={setRegionVal} disabled={false} />
                        <Textbox ref={countryInput} label="País" type="text" val={countryVal} setVal={setCountryVal} disabled={false} />
                    </Fragment>}
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}