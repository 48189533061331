import {useRef,forwardRef} from "react"
import show from "../assets/show.svg"
import hide from "../assets/hide.svg"

const EntranceTextbox=forwardRef(({type,val,placeholder,onChange},ref)=>{

    const on=useRef()
    const off=useRef()

    const visibilityOn=()=>{
        on.current.classList.add("entrance-box-visibility-hidden")
        off.current.classList.remove("entrance-box-visibility-hidden")
        ref.current.type="text"
    }

    const visibilityOff=()=>{
        on.current.classList.remove("entrance-box-visibility-hidden")
        off.current.classList.add("entrance-box-visibility-hidden")
        ref.current.type="password"
    }

    return(
        <div className="entrance-box-frame">
            <input ref={ref} type={type=="password"?"password":"text"} value={val} placeholder={placeholder} onChange={onChange} className={type=="password"?"entrance-box entrance-box-password":"entrance-box"} />
            {type=="password" &&
            <div className="entrance-box-visibility">
                <div ref={on} className="entrance-box-visibility-on" onClick={visibilityOn}><img src={show} /></div>
                <div ref={off} className="entrance-box-visibility-off entrance-box-visibility-hidden" onClick={visibilityOff}><img src={hide} /></div>
            </div>}
        </div>
    )
    
})

export default EntranceTextbox