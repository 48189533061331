import {useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function TicketsAdm(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    return(
        <Section>
            <SectionHeader
                title="Asistencia"
                intro="Seguimiento de tickets de soporte."
                back=""
                button=""
                tabs={[
                    {label:"Tickets",to:"/c24/asistencia"},
                    {label:"Preguntas frecuentes",labelShort:"FAQs",to:"/c24/asistencia/faqs"},
                    {label:"Novedades",to:"/c24/asistencia/novedades"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"tickets",
                    endpoint:"tickets/list",
                    limit:100,
                    counter:{
                        singular:"ticket",
                        plural:"tickets"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"ticket-info"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}