import {forwardRef} from "react"

const ButtonSquare=forwardRef(({icon,on,clickEvent},ref)=>{

    return (
        <div ref={ref} className={"button-square"+(on==true?" button-square-on":"")} onClick={clickEvent}>
            {icon=="filters" && <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><path d="M11,14h2A3.68,3.68,0,0,0,20,14H31a1,1,0,0,0,0-2H20a3.68,3.68,0,0,0-7.09,0H11a1,1,0,0,0,0,2Zm5.52-2.7a1.7,1.7,0,1,1-1.7,1.7A1.7,1.7,0,0,1,16.48,11.33ZM31,20h-2A3.68,3.68,0,0,0,22,20H11a1,1,0,0,0,0,2H22a3.68,3.68,0,0,0,7.09,0h2a1,1,0,0,0,0-2Zm-5.52,2.7a1.7,1.7,0,1,1,1.7-1.7A1.7,1.7,0,0,1,25.52,22.7ZM31,28H20a3.68,3.68,0,0,0-7.09,0H11a1,1,0,0,0,0,2h2A3.68,3.68,0,0,0,20,30H31a1,1,0,0,0,0-2Zm-14.56,2.7a1.7,1.7,0,1,1,1.7-1.7A1.7,1.7,0,0,1,16.48,30.67Z" fill="#624de3"/><rect width="42" height="42" fill="none"/></svg>}
            {icon=="shops" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><g id="c"><g><path d="M33.5,16.29s0,0,0-.01c0-.07-.03-.13-.04-.2-.01-.06-.02-.13-.04-.19,0,0,0,0,0-.01l-2.02-4.42c-.4-.88-1.28-1.45-2.23-1.45H12.84c-.95,0-1.83,.57-2.23,1.45l-2.02,4.42s0,0,0,.01c-.03,.06-.03,.13-.04,.19-.01,.07-.04,.13-.04,.2,0,0,0,0,0,.01,0,1.46,1.03,2.67,2.41,3.05v9.78c0,1.59,1.27,2.89,2.83,2.89h14.52c1.56,0,2.83-1.3,2.83-2.89v-9.78c1.39-.38,2.41-1.59,2.41-3.05Zm-5.24,13.71H13.74c-.46,0-.83-.4-.83-.89v-9.84c.45-.16,.85-.39,1.19-.7,.59,.56,1.41,.9,2.3,.9s1.71-.34,2.3-.9c.59,.56,1.41,.9,2.3,.9s1.71-.34,2.3-.9c.59,.56,1.41,.9,2.3,.9s1.7-.34,2.3-.9c.33,.31,.74,.55,1.19,.7v9.84c0,.49-.37,.89-.83,.89Zm1.94-12.53c-.72,0-1.3-.53-1.3-1.18,0-.55-.45-1-1-1s-1,.45-1,1c0,.65-.58,1.18-1.3,1.18s-1.3-.53-1.3-1.18c0-.55-.45-1-1-1s-1,.45-1,1c0,.65-.58,1.18-1.3,1.18s-1.3-.53-1.3-1.18c0-.55-.45-1-1-1s-1,.45-1,1c0,.65-.58,1.18-1.3,1.18s-1.3-.53-1.3-1.18c0-.55-.45-1-1-1s-1,.45-1,1c0,.65-.58,1.18-1.3,1.18-.66,0-1.18-.46-1.27-1.04l1.9-4.15c.08-.17,.24-.28,.41-.28H29.16c.17,0,.33,.11,.41,.28l1.9,4.15c-.08,.58-.61,1.04-1.27,1.04Zm-3.86,3.39H15.66c-.55,0-1,.45-1,1v5.37c0,.55,.45,1,1,1h10.68c.55,0,1-.45,1-1v-5.37c0-.55-.45-1-1-1Zm-1,5.37h-8.68v-3.37h8.68v3.37Z" fill="#624de3"/><rect width="42" height="42" fill="none"/></g></g></svg>}
        </div>
    )
    
})

export default ButtonSquare