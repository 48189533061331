import {Fragment,useRef,useState} from "react"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Textarea from "../commons/Textarea"
import whatsappImg from "../assets/wa.svg"
import supportImg from "../assets/support.svg"

export default function Contact(){

    const requestApi=useApi()
    const {manageModal}=useModal()
    const {changeDialog}=useDialog()
    const nameInput=useRef()
    const companyInput=useRef()
    const categoryInput=useRef()
    const phoneInput=useRef()
    const emailInput=useRef()
    const messageInput=useRef()
    const [nameVal,setNameVal]=useState("")
    const [companyVal,setCompanyVal]=useState("")
    const [categoryVal,setCategoryVal]=useState("")
    const [phoneVal,setPhoneVal]=useState("")
    const [emailVal,setEmailVal]=useState("")
    const [messageVal,setMessageVal]=useState("")

    const submit=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            if(companyVal==""){
                companyInput.current.focus()
            }else{
                if(phoneVal==""){
                    phoneInput.current.focus()
                }else{
                    if(emailVal==""){
                        emailInput.current.focus()
                    }else{
                        if(messageVal==""){
                            messageInput.current.focus()
                        }else{
                            requestApi("messages/create",{
                                    name:nameVal,
                                    company:companyVal,
                                    category:categoryVal,
                                    phone:phoneVal,
                                    email:emailVal,
                                    message:messageVal
                                })
                                .then(data=>{
                                    if(data.status=="success"){
                                        changeDialog("contact_sent","","")

                                        manageModal("hide")
                                    }else{
                                        changeDialog("generic_error","","")
                                    }
                                })
                        }
                    }
                }
            }
        }
    }

    return(
        <Fragment>
            <ModalHeader>
                <a href="https://wa.me/542612185757" target="_blank" className="home-whatsapp-btn"><img src={whatsappImg} /></a>
            </ModalHeader>
            <ModalContent>
                <div className="modal-form noselect">
                    <div className="home-contact-caption">
                        <img src={supportImg} />
                        <div>
                            <strong>Completá el siguiente formulario.</strong> El equipo de Cajero24 te contactará al instante para ofrecerte una solución de pagos adaptada a las necesidades de tu negocio.
                        </div>
                    </div>
                    <div className="home-contact-title">¡Empezá a operar con Cajero24!</div>
                    <Textbox ref={nameInput} label="Nombre completo" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Textbox ref={companyInput} label="Empresa, comercio o institución" type="text" val={companyVal} setVal={setCompanyVal} disabled={false} />
                    <Textbox ref={categoryInput} label="Categoría o rubro" type="text" val={categoryVal} setVal={setCategoryVal} disabled={false} />
                    <Textbox ref={phoneInput} label="Teléfono/WhatsApp" type="text" val={phoneVal} setVal={setPhoneVal} disabled={false} />
                    <Textbox ref={emailInput} label="Email" type="text" val={emailVal} setVal={setEmailVal} disabled={false} />
                    <Textarea ref={messageInput} label="Mensaje" val={messageVal} setVal={setMessageVal} disabled={false} />
                    <ButtonPrimary label="Enviar solicitud" clickEvent={submit} />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}