import {useEffect,useState,useContext,Fragment} from "react"
import {Link} from "react-router-dom"
import GlobalContext from "../contexts/GlobalContext"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import HomeBar from "../components/HomeBar"
import HomeFooter from "../components/HomeFooter"
import {Swiper,SwiperSlide} from "swiper/react"
import SwiperCore,{Pagination,Autoplay} from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import nextImg from "../assets/next.svg"
import next2Img from "../assets/next-2.svg"
import tickImg from "../assets/tick-2.svg"
import tick2Img from "../assets/tick-3.svg"
import loop from "../assets/loop.mp4"
import poster from "../assets/loop.jpg"
import client1 from "../assets/client-1.svg"
import client2 from "../assets/client-2.svg"
import client3 from "../assets/client-3.svg"
import client4 from "../assets/client-4.svg"
import client5 from "../assets/client-5.svg"
import client6 from "../assets/client-6.svg"
import client7 from "../assets/client-7.svg"
import client8 from "../assets/client-8.svg"
import client9 from "../assets/client-9.svg"
import client10 from "../assets/client-10.svg"
import client11 from "../assets/client-11.svg"
import client12 from "../assets/client-12.svg"
import client13 from "../assets/client-13.svg"
import client14 from "../assets/client-14.svg"
import client15 from "../assets/client-15.svg"
import client16 from "../assets/client-16.svg"
import qrImg from "../assets/qr-2.svg"
import integrationsImg from "../assets/integrations.svg"
import pluginsImg from "../assets/plugins.svg"
import webposImg from "../assets/webpos-2.svg"
import linksImg from "../assets/links-2.svg"
import debitsImg from "../assets/debits.svg"
import onlineImg from "../assets/online.svg"
import storesImg from "../assets/stores.svg"
import stores2Img from "../assets/stores-2.svg"
import developersImg from "../assets/developers.svg"
import governmentImg from "../assets/government.svg"
import government2Img from "../assets/government-2.svg"
import argImg from "../assets/argentina.png"

export default function Home(){

    const [globalContext]=useContext(GlobalContext)
    const {changeModal}=useModal()
    const requestApi=useApi()
    const [pricesGrid,setPricesGrid]=useState("stores")
    SwiperCore.use([Autoplay])
    const [articles,setArticles]=useState()

    const contact=(e)=>{
        e.preventDefault()
        changeModal("contact","","")
    }

    const about=(e)=>{
        e.preventDefault()
        changeModal("about","","")
    }

    const faqs=(e)=>{
        e.preventDefault()
        changeModal("faqs","","")
    }

    useEffect(()=>{
        window.scrollTo(0,0)
        setInterval(()=>{
            if(document.getElementById("home-caption")){
                const show=document.querySelector("span[data-show]")
                const next=show.nextElementSibling || document.querySelector("span[data-first]")
                const up=document.querySelector("span[data-up]")
                if(up){
                    up.removeAttribute("data-up")
                }
                show.removeAttribute("data-show")
                show.setAttribute("data-up","")
                next.setAttribute("data-show","")                
            }
        },2000)
        requestApi("blog/articles",{context:"home",exclude:""})
            .then(data=>{
                setArticles(data.items)
            })
    },[])

    return (
        <Fragment>
            <HomeBar context="home" contactOpen={contact} aboutOpen={about} faqsOpen={faqs} />
            <div className="home-intro noselect">
                <div className="home-intro-video">
                    <video className="home-video" src={loop} poster={poster} video="true" autobuffer="true" autoPlay loop muted playsInline></video>
                </div>
                <div className="home-intro-video-overlay"></div>
                <div className="home-intro-content">
                    <div>
                        <div className="home-intro-text"><img src={argImg} />ARGENTINA</div>
                        <div id="home-caption" className="home-intro-caption">
                            Soluciones<br />de cobro
                            <div className="home-intro-mask">
                                <div className="home-intro-guide">adaptables.</div>
                                <span className="home-intro-word" data-show data-first>simples.</span>
                                <span className="home-intro-word">adaptables.</span>
                                <span className="home-intro-word">dinámicas.</span>
                                <span className="home-intro-word">eficaces.</span>
                            </div>
                        </div>
                        <div className="home-intro-text">
                            Operamos con todas las tarjetas de débito/crédito, qr de billeteras y transferencias bancarias. Sin costos de setup ni cargos mensuales
                        </div>
                        <div className="home-intro-button" onClick={(e)=>{contact(e)}}>Empezá ahora<img src={next2Img} /></div>
                    </div>
                </div>
            </div>
            <div id="clientes" className="home-marquee noselect">
                <div className="home-marquee-title">Estas instituciones confían en nuestros servicios:</div>
                <div className="home-marquee-frame">
                    <div className="home-marquee-logos">
                        <div><img src={client2} /></div>
                        <div><img src={client4} /></div>
                        <div><img src={client6} /></div>
                        <div><img src={client8} /></div>
                        <div><img src={client10} /></div>
                        <div><img src={client12} /></div>
                        <div><img src={client14} /></div>
                        <div><img src={client16} /></div>
                    </div>
                    <div className="home-marquee-logos">
                        <div><img src={client2} /></div>
                        <div><img src={client4} /></div>
                        <div><img src={client6} /></div>
                        <div><img src={client8} /></div>
                        <div><img src={client10} /></div>
                        <div><img src={client12} /></div>
                        <div><img src={client14} /></div>
                        <div><img src={client16} /></div>
                    </div>
                </div>
                <div className="home-marquee-frame home-marquee-frame-reverse">
                    <div className="home-marquee-logos">
                        <div><img src={client1} /></div>
                        <div><img src={client3} /></div>
                        <div><img src={client5} /></div>
                        <div><img src={client7} /></div>
                        <div><img src={client9} /></div>
                        <div><img src={client11} /></div>
                        <div><img src={client13} /></div>
                        <div><img src={client15} /></div>
                    </div>
                    <div className="home-marquee-logos">
                        <div><img src={client1} /></div>
                        <div><img src={client3} /></div>
                        <div><img src={client5} /></div>
                        <div><img src={client7} /></div>
                        <div><img src={client9} /></div>
                        <div><img src={client11} /></div>
                        <div><img src={client13} /></div>
                        <div><img src={client15} /></div>
                    </div>
                </div>
            </div>
            <div id="canales-de-cobro" className="home-channels noselect">
                <div className="home-channels-title">Múltiples canales de cobro que se ajustan a las necesidades de tu comercio.</div>
                <div className="home-channels-grid">
                    <div className="home-channel">
                        <div className="home-channel-pic">
                            <div className="home-channel-icon home-channel-pulse"><img src={integrationsImg} /></div>
                        </div>
                        <div className="home-channel-info">
                            <div className="home-channel-title">Integraciones</div>
                            <div className="home-channel-caption">Agregá el flujo de pagos de Cajero24 a cualquier sitio web, la implementación es sencilla y se adapta al diseño de tu aplicación.</div>
                        </div>
                    </div>
                    <div className="home-channel">
                        <div className="home-channel-pic">
                            <div className="home-channel-icon home-channel-pulse-2"><img src={webposImg} /></div>
                        </div>
                        <div className="home-channel-info">
                            <div className="home-channel-title">Webpos</div>
                            <div className="home-channel-caption">Utilizá nuestro sistema exclusivo para realizar cobros presenciales de manera intuitiva, simple y segura con cualquier medio de pago.</div>
                        </div>
                    </div>
                    <div className="home-channel">
                        <div className="home-channel-pic home-channel-pulse">
                            <div className="home-channel-icon"><img src={linksImg} /></div>
                        </div>
                        <div className="home-channel-info">
                            <div className="home-channel-title">Links de pago</div>
                            <div className="home-channel-caption">Creá enlaces de pago personalizados para enviar por correo electrónico, por WhatsApp o para compartir en redes sociales.</div>
                        </div>
                    </div>
                    <div className="home-channel">
                        <div className="home-channel-pic home-channel-pulse-2">
                            <div className="home-channel-icon"><img src={qrImg} /></div>
                        </div>
                        <div className="home-channel-info">
                            <div className="home-channel-title">Pagos con QR</div>
                            <div className="home-channel-caption">Tus clientes te pagan en segundos, sólo necesitan sus teléfonos. No intercambiás tarjetas, comprobantes ni billetes.</div>
                        </div>
                    </div>
                    <div className="home-channel">
                        <div className="home-channel-pic">
                            <div className="home-channel-icon home-channel-pulse"><img src={debitsImg} /></div>
                        </div>
                        <div className="home-channel-info">
                            <div className="home-channel-title">Débitos automáticos</div>
                            <div className="home-channel-caption">Realizá pagos recurrentes periódicos con nuestro servicio de débitos a cuentas bancarias o tarjetas de débito/crédito.</div>
                        </div>
                    </div>
                    <div className="home-channel">
                        <div className="home-channel-pic">
                            <div className="home-channel-icon home-channel-pulse-2"><img src={pluginsImg} /></div>
                        </div>
                        <div className="home-channel-info">
                            <div className="home-channel-title">Complementos</div>
                            <div className="home-channel-caption">Instalá nuestros módulos de pago en soluciones de comercio electrónico como WooCommerce, Adobe Commerce, entre otras.</div>
                        </div>
                    </div>
                </div>
                <div className="home-channel-button-frame">
                    <div className="home-channel-button" onClick={(e)=>{contact(e)}}>Comunicate con nosotros<img src={nextImg} /></div>
                </div>
            </div>
            <div id="entidades" className="home-entities noselect">
                <div className="home-entities-title">¡Procesamos cobros online las 24 horas!</div>
                <Swiper
                    className="home-entities-swiper"
                    modules={[Pagination]}
                    spaceBetween={0}
                    slidesPerView={"auto"}
                    autoplay={{delay:3000}}
                    pagination={{
                        clickable:true,
                        horizontalClass:"swiper-pagination-horizontal",
                        bulletClass:"swiper-pagination-bullet",
                        bulletActiveClass:"swiper-pagination-bullet-active",
                    }}>
                    <SwiperSlide className="home-entities-slide">
                        <div className="home-entity">
                            <div className="home-entity-icon"><img className="home-entity-floating" src={onlineImg} /></div>
                            <div className="home-entity-title">Ecommerce</div>
                            <div className="home-entity-content">
                                <div>Procesamos ventas online con tarjetas de crédito en un pago, en cuotas o con transferencias bancarias; integración fácil, segura y flexible.</div>
                                <div className="home-entity-button" onClick={(e)=>{contact(e)}}>Conocé más<img src={next2Img} /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="home-entities-slide">
                        <div className="home-entity">
                            <div className="home-entity-icon"><img className="home-entity-floating" src={storesImg} /></div>
                            <div className="home-entity-title">Comercios</div>
                            <div className="home-entity-content">
                                <div>Ofrecemos soluciones para cobros presenciales o con QR que mejoran la operatividad de tu negocio y reducen los costos de las comisiones.</div>
                                <div className="home-entity-button" onClick={(e)=>{contact(e)}}>Conocé más<img src={next2Img} /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="home-entities-slide">
                        <div className="home-entity">
                            <div className="home-entity-icon"><img className="home-entity-floating" src={governmentImg} /></div>
                            <div className="home-entity-title">Administración pública</div>
                            <div className="home-entity-content">
                                <div>Proveemos herramientas para facilitar el cobro de tributos e impuestos que ya son usadas por más de 40 gobiernos y municipalidades en todo el país.</div>
                                <div className="home-entity-button" onClick={(e)=>{contact(e)}}>Conocé más<img src={next2Img} /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="home-entities-slide">
                        <div className="home-entity">
                            <div className="home-entity-icon"><img className="home-entity-floating" src={developersImg} /></div>
                            <div className="home-entity-title">Desarrolladores</div>
                            <div className="home-entity-content">
                                <div>¡Agregá Cajero24 a tus proyectos! Con acuerdos de participación en la rentabilidad de las transacciones y asistencia en el proceso de implementación.</div>
                                <div className="home-entity-button" onClick={(e)=>{contact(e)}}>Conocé más<img src={next2Img} /></div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="home-payments noselect">
                <div className="home-payments-caption">
                    Operamos <strong>con todas las tarjetas de débito, prepagas y crédito</strong> en un pago, en cuotas regulares o Ahora 12, con la posibilidad de <strong>ajustar los costos de financiación</strong>.
                </div>
                <div className="home-payments-cards">
                    <div className="home-payments-card home-payments-pulse"></div>
                    <div className="home-payments-card home-payments-pulse-2"></div>
                    <div className="home-payments-card home-payments-pulse"></div>
                    <div className="home-payments-card home-payments-pulse-2"></div>
                    <div className="home-payments-card home-payments-pulse"></div>
                </div>
            </div>
            <div id="precios" className="home-prices noselect">
                <div className="home-prices-title">¡Las comisiones más bajas del mercado!</div>
                <div className="home-prices-switch">
                    <div className={"home-prices-switch-option"+(pricesGrid=="stores"?" home-prices-switch-option-on":"")} onClick={()=>{setPricesGrid("stores")}}>
                        <div><img src={stores2Img} /></div>
                        <div>Comercios</div>
                    </div>
                    <div className={"home-prices-switch-option"+(pricesGrid=="government"?" home-prices-switch-option-on":"")} onClick={()=>{setPricesGrid("government")}}>
                        <div><img src={government2Img} /></div>
                        <div>Gobiernos</div>
                    </div>
                </div>
                <div className={"home-prices-grid"+(pricesGrid=="stores"?" home-prices-grid-on":"")}>
                    <div className="home-prices-item">
                        <div className="home-prices-info">
                            <div className="home-prices-heading">Débito</div>
                            <div className="home-prices-percent">1.8%</div>
                            <div className="home-prices-note">IVA no incluido.</div>
                            <div className="home-prices-content">
                                <div className="home-prices-list">
                                    <img src={tickImg} />Liquidación en 48 horas hábiles.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Incluye el costo de procesamiento y la comisión de Cajero24.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Cobros con todas las tarjetas de débito, prepagas y Debin.
                                </div>
                                <div className="home-prices-list home-prices-list-blue">
                                    <img src={tick2Img} />¡Incluye todas las herramientas de cobro de Cajero24!
                                </div>
                            </div>
                        </div>
                        <div className="home-prices-action">
                            <div className="home-prices-button home-pulse-2" onClick={(e)=>{contact(e)}}>Empezar<img src={next2Img} /></div>
                        </div>
                    </div>
                    <div className="home-prices-item">
                        <div className="home-prices-info">
                            <div className="home-prices-heading">Crédito</div>
                            <div className="home-prices-percent">2.8%</div>
                            <div className="home-prices-note">IVA no incluido.</div>
                            <div className="home-prices-content">
                                <div className="home-prices-list">
                                    <img src={tickImg} />Liquidación en 10 días hábiles.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Incluye el costo de procesamiento y la comisión de Cajero24.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Cobros con todas las tarjetas de crédito de cualquier banco.
                                </div>
                                <div className="home-prices-list home-prices-list-blue">
                                    <img src={tick2Img} />¡Incluye todas las herramientas de cobro de Cajero24!
                                </div>                                
                            </div>
                        </div>
                        <div className="home-prices-action">
                            <div className="home-prices-button home-pulse-2" onClick={(e)=>{contact(e)}}>Empezar<img src={next2Img} /></div>
                        </div>
                    </div>
                    <div className="home-prices-item">
                        <div className="home-prices-info">
                            <div className="home-prices-heading">Crédito en cuotas</div>
                            <div className="home-prices-percent">2%</div>
                            <div className="home-prices-note">IVA no incluido.</div>
                            <div className="home-prices-content">
                                <div className="home-prices-list">
                                    <img src={tickImg} />Liquidación en 48 horas hábiles.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Incluye el costo de procesamiento y la comisión de Cajero24.
                                </div>                                
                                <div className="home-prices-list">
                                    <img src={tickImg} />Se suman cargos financieros variables de acuerdo a la cantidad de cuotas (que pueden ser absorbidos por el pagador o por el comercio).
                                </div>
                                <div className="home-prices-list home-prices-list-blue">
                                    <img src={tick2Img} />¡Incluye todas las herramientas de cobro de Cajero24!
                                </div>
                            </div>
                        </div>
                        <div className="home-prices-action">
                            <div className="home-prices-button home-pulse-2" onClick={(e)=>{contact(e)}}>Empezar<img src={next2Img} /></div>
                        </div>
                    </div>
                    <div className="home-prices-item">
                        <div className="home-prices-info">
                            <div className="home-prices-heading">Crédito con Ahora 12</div>
                            <div className="home-prices-percent">2%</div>
                            <div className="home-prices-note">IVA no incluido.</div>
                            <div className="home-prices-content">
                                <div className="home-prices-list">
                                    <img src={tickImg} />Liquidación en 10 días hábiles.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Incluye el costo de procesamiento y la comisión de Cajero24.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Se suman cargos financieros variables de acuerdo a la cantidad de cuotas (que pueden ser absorbidos por el pagador o por el comercio).
                                </div>
                                <div className="home-prices-list home-prices-list-blue">
                                    <img src={tick2Img} />¡Incluye todas las herramientas de cobro de Cajero24!
                                </div>                                
                            </div>
                        </div>
                        <div className="home-prices-action">
                            <div className="home-prices-button home-pulse-2" onClick={(e)=>{contact(e)}}>Empezar<img src={next2Img} /></div>
                        </div>
                    </div>
                </div>
                <div className={"home-prices-grid home-prices-grid-alt"+(pricesGrid=="government"?" home-prices-grid-on":"")}>
                    <div className="home-prices-item">
                        <div className="home-prices-info">
                            <div className="home-prices-heading">Débito</div>
                            <div className="home-prices-percent">1.35%</div>
                            <div className="home-prices-note">IVA no incluido.</div>
                            <div className="home-prices-content">
                                <div className="home-prices-list">
                                    <img src={tickImg} />Liquidación en 48 horas hábiles.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Incluye el costo de procesamiento y la comisión de Cajero24.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Cobros con todas las tarjetas de débito, prepagas y Debin.
                                </div>
                                <div className="home-prices-list home-prices-list-blue">
                                    <img src={tick2Img} />¡Incluye todas las herramientas de cobro de Cajero24!
                                </div>
                            </div>
                        </div>
                        <div className="home-prices-action">
                            <div className="home-prices-button home-pulse-2" onClick={(e)=>{contact(e)}}>Empezar<img src={next2Img} /></div>
                        </div>
                    </div>
                    <div className="home-prices-item">
                        <div className="home-prices-info">
                            <div className="home-prices-heading">Crédito</div>
                            <div className="home-prices-percent">2%</div>
                            <div className="home-prices-note">IVA no incluido.</div>
                            <div className="home-prices-content">
                                <div className="home-prices-list">
                                    <img src={tickImg} />Liquidación en 10 días hábiles.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Incluye el costo de procesamiento y la comisión de Cajero24.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Cobros con todas las tarjetas de crédito de cualquier banco.
                                </div>
                                <div className="home-prices-list home-prices-list-blue">
                                    <img src={tick2Img} />¡Incluye todas las herramientas de cobro de Cajero24!
                                </div>                                
                            </div>
                        </div>
                        <div className="home-prices-action">
                            <div className="home-prices-button home-pulse-2" onClick={(e)=>{contact(e)}}>Empezar<img src={next2Img} /></div>
                        </div>
                    </div>
                    <div className="home-prices-item">
                        <div className="home-prices-info">
                            <div className="home-prices-heading">Crédito en cuotas</div>
                            <div className="home-prices-percent">2%</div>
                            <div className="home-prices-note">IVA no incluido.</div>
                            <div className="home-prices-content">
                                <div className="home-prices-list">
                                    <img src={tickImg} />Liquidación en 48 horas hábiles.
                                </div>
                                <div className="home-prices-list">
                                    <img src={tickImg} />Incluye el costo de procesamiento y la comisión de Cajero24.
                                </div>                                
                                <div className="home-prices-list">
                                    <img src={tickImg} />Se suman cargos financieros variables de acuerdo a la cantidad de cuotas (que pueden ser absorbidos por el pagador o por el comercio).
                                </div>
                                <div className="home-prices-list home-prices-list-blue">
                                    <img src={tick2Img} />¡Incluye todas las herramientas de cobro de Cajero24!
                                </div>
                            </div>
                        </div>
                        <div className="home-prices-action">
                            <div className="home-prices-button home-pulse-2" onClick={(e)=>{contact(e)}}>Empezar<img src={next2Img} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-articles-frame blog-articles-frame-alt">
                <div className="blog-title blog-title-alt">
                    ¡Descubrí todo lo que necesitás saber sobre pagos online en el blog de Cajero24!
                </div>
                {articles &&
                <div className="blog-articles">
                    {articles.length>0?
                        articles.map((item,i)=>{
                            return(
                                <Link key={i} className="blog-article" to={"/blog/"+item["alias"]}>
                                    <div>
                                        <div className="blog-article-picture" style={{backgroundImage:"url("+globalContext["base_url"]+"resources/articles/"+item["id"]+".jpg"}}></div>
                                    </div>
                                    <div>
                                        <div className="blog-article-tagline">{item["tagline"]}</div>
                                        <div className="blog-article-title">{item["title"]}</div>
                                    </div>
                                    <div>
                                        <div className="blog-article-button">Seguir leyendo<img src={next2Img} /></div>
                                    </div>
                                </Link>
                            )
                        })
                    :<div>No hay artículos</div>}
                </div>}
                <div className="home-channel-button-frame">
                    <Link to="/blog" className="home-entity-button">Ver todo el blog<img src={next2Img} /></Link>
                </div>
            </div>
            <HomeFooter context="home" contactOpen={contact} aboutOpen={about} faqsOpen={faqs} />
            <Ga />
            <ConnectionChecker />
        </Fragment>
    )
    
}