import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Datepicker from "../commons/Datepicker"
import Dropdown from "../commons/Dropdown"
import close from "../assets/close-pink.svg"

export default function ReportsFilters(){

    const {manageFloating}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const filter4=useRef()
    const filter5=useRef()
    const filter6=useRef()
    const [filter1Val,setFilter1Val]=useState("")
    const [filter2Val,setFilter2Val]=useState("")
    const [filter3Val,setFilter3Val]=useState("")
    const [filter4Val,setFilter4Val]=useState("")
    const [filter5Val,setFilter5Val]=useState("")
    const [filter6Val,setFilter6Val]=useState("")

    const apply=()=>{
        setValue({
            period:filter1.current.getValue()[0]?filter1.current.getValue()[0].value:"",
            month:filter2.current.getValue()[0]?filter2.current.getValue()[0].value:"",
            year:filter3.current.getValue()[0]?filter3.current.getValue()[0].value:"",
            date:filter4Val,
            date_start:filter5Val,
            date_end:filter6Val
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val("")
        setFilter2Val("")
        setFilter3Val("")
        setFilter4Val("")
        setFilter5Val("")
        setFilter6Val("")
        filter1.current.setValue("")
        filter2.current.setValue("")
        filter3.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(filter1Val!="" && filter1Val!=undefined){
            restrict("period")
        }
    },[filter1Val])

    useEffect(()=>{
        if((filter2Val!="" && filter2Val!=undefined) || (filter3Val!="" && filter3Val!=undefined)){
            restrict("month_year")
        }
    },[filter2Val])

    useEffect(()=>{
        if((filter2Val!="" && filter2Val!=undefined) || (filter3Val!="" && filter3Val!=undefined)){
            restrict("month_year")
        }
    },[filter3Val])

    useEffect(()=>{
        if(filter4Val!=""){
            restrict("date")
        }
    },[filter4Val])

    useEffect(()=>{
        if(filter5Val!="" || filter6Val!=""){
            restrict("interval")
        }
    },[filter5Val])

    useEffect(()=>{
        if(filter5Val!="" || filter6Val!=""){
            restrict("interval")
        }
    },[filter6Val])

    const restrict=(filter)=>{
        if(filter=="period"){
            setFilter2Val("")
            filter2.current.setValue("")
            setFilter3Val("")
            filter3.current.setValue("")
            setFilter4Val("")
            setFilter5Val("")
            setFilter6Val("")
        }else if(filter=="month_year"){
            setFilter1Val("")
            filter1.current.setValue("")
            setFilter4Val("")
            setFilter5Val("")
            setFilter6Val("")
        }else if(filter=="date"){
            setFilter1Val("")
            filter1.current.setValue("")
            setFilter2Val("")
            filter2.current.setValue("")
            setFilter3Val("")
            filter3.current.setValue("")
            setFilter5Val("")
            setFilter6Val("")
        }else if(filter=="interval"){
            setFilter1Val("")
            filter1.current.setValue("")
            setFilter2Val("")
            filter2.current.setValue("")
            setFilter3Val("")
            filter3.current.setValue("")
            setFilter4Val("")
        }
    }

    useEffect(()=>{
        if(value!=""){
            if(value["period"]!=""){
                filter1.current.props["options"].forEach(function(item){
                    if(value["period"]==item.value){
                        filter1.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["month"]!=""){
                filter2.current.props["options"].forEach(function(item){
                    if(value["month"]==item.value){
                        filter2.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["year"]!=""){
                filter3.current.props["options"].forEach(function(item){
                    if(value["year"]==item.value){
                        filter3.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["date"]!=""){
                setFilter4Val(value["date"])
            }
            if(value["date_start"]!=""){
                setFilter5Val(value["date_start"])
            }
            if(value["date_end"]!=""){
                setFilter6Val(value["date_end"])
            }
        }
    },[value])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-options floating-options-space">
                    <div className="floating-full">
                        <Dropdown ref={filter1} val={filter1Val} setVal={setFilter1Val} placeholder="Período" multiple={0} searchable={0} options={[
                            {label:"Última hora",value:"last_hour"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Última semana",value:"last_week"},
                            {label:"Últimos quince días",value:"last_15_days"},
                            {label:"Este mes",value:"this_month"},
                            {label:"Últimos tres meses",value:"last_three_months"},
                            {label:"Últimos seis meses",value:"last_six_months"},
                            {label:"Este año",value:"this_year"}
                        ]} />
                    </div>
                    <div className="floating-half" >
                        <Dropdown ref={filter2} val={filter2Val} setVal={setFilter2Val} placeholder="Mes" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"Enero",value:"01"},
                            {label:"Febrero",value:"02"},
                            {label:"Marzo",value:"03"},
                            {label:"Abril",value:"04"},
                            {label:"Mayo",value:"05"},
                            {label:"Junio",value:"06"},
                            {label:"Julio",value:"07"},
                            {label:"Agosto",value:"08"},
                            {label:"Septiembre",value:"09"},
                            {label:"Octubre",value:"10"},
                            {label:"Noviembre",value:"11"},
                            {label:"Diciembre",value:"12"}
                        ]} />
                        <Dropdown ref={filter3} val={filter3Val} setVal={setFilter3Val} placeholder="Año" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"2022",value:"2019"},
                            {label:"2023",value:"2023"},
                            {label:"2024",value:"2024"},
                            {label:"2025",value:"2025"},
                            {label:"2026",value:"2026"},
                            {label:"2027",value:"2027"},
                            {label:"2028",value:"2028"},
                            {label:"2029",value:"2029"},
                            {label:"2030",value:"2030"},
                            {label:"2031",value:"2031"},
                            {label:"2032",value:"2032"},
                            {label:"2033",value:"2033"},
                            {label:"2034",value:"2034"},
                            {label:"2034",value:"2034"}
                        ]} />
                    </div>
                    <div className="floating-full">
                        <Datepicker ref={filter4} val={filter4Val} setVal={setFilter4Val} label="Fecha" />
                    </div>
                </div>
                <div className="floating-group">Intervalo</div>
                <div className="floating-options">
                    <div className="floating-half" >
                        <Datepicker ref={filter5} val={filter5Val} setVal={setFilter5Val} label="Desde" />
                        <Datepicker ref={filter6} val={filter6Val} setVal={setFilter6Val} label="Hasta" />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}