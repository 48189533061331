import {useContext,useEffect,useState} from "react"
import GlobalContext from "../contexts/GlobalContext"
import RefreshContext from "../contexts/RefreshContext"
import useRefresh from "../hooks/useRefresh"
import unknownIcon from "../assets/unknown.svg"
import unknownShopIcon from "../assets/unknown-shop.svg"

export default function Avatar({element,context,id,picture,color,capitals,forceBg}){

    const [globalContext]=useContext(GlobalContext)
    const [refreshContext]=useContext(RefreshContext)
    const {refreshClear}=useRefresh()
    const [refreshPicture,setRefreshPicture]=useState()

    let bgPicture=""
    if(picture){
        bgPicture="url("+globalContext["base_url"]+"resources/"+(element=="user"?"users":element=="shop"?"shops":"")+"/"+id+(element=="user"?".jpg":element=="shop"?".png":"")+")"
    }
    if(forceBg){
        bgPicture="url("+globalContext["base_url"]+"resources/temp/"+forceBg+")"
    }

    useEffect(()=>{
        if(refreshContext.avatar.refresh==1 ){
            if(refreshContext.avatar.reference.element=="user" && refreshContext.avatar.reference.id==id){
                setRefreshPicture("url("+globalContext["base_url"]+"resources/users/"+id+".jpg?"+Math.random()+")")
            }else if(refreshContext.avatar.reference.element=="shop" && refreshContext.avatar.reference.id==id){
                setRefreshPicture("url("+globalContext["base_url"]+"resources/shops/"+id+".png?"+Math.random()+")")
            }
            refreshClear("avatar")
        }
    },[refreshContext["avatar"]])

    return(
        <div className={"avatar "+
            (context=="login"?" avatar-entrance"
            :context=="panel"?" avatar-panel"
            :context=="bar"?" avatar-bar"
            :context=="big"?" avatar-big"
            :context=="xbig"?" avatar-xbig"
            :context=="medium"?" avatar-medium"
            :context=="small"?" avatar-small"
            :context=="card"?" avatar-card"
            :context=="payment"?" avatar-payment"
            : context=="payment-shop"?" avatar-payment-shop"
            :context=="payment-success"?" avatar-payment-success"
            :"")+(element=="user"?" avatar-user":"")
        } style={{
            backgroundColor:color!=""?color:"#B7FAFF",
            backgroundImage:refreshPicture?refreshPicture:bgPicture
        }}>
            {(!bgPicture && element=="user") &&
            <div className="avatar-capitals">
                <svg width="100%" height="100%" viewBox="0 0 100 100">
                    <text x="50%" y="50%" fontSize="36" fill="#FFFFFF" dominantBaseline="middle" textAnchor="middle">{capitals}</text>
                </svg>
            </div>}
            {(!bgPicture && element=="shop") &&
            <img className="avatar-unknown" src={unknownShopIcon} />}
            {(!bgPicture && element=="user" && id=="") &&
            <img className="avatar-unknown" src={unknownIcon} />}
        </div>
    )
    
}