import {useContext} from "react"
import {Link} from "react-router-dom"
import GlobalContext from "../contexts/GlobalContext"
import useModal from "../hooks/useModal"
import Avatar from "../commons/Avatar"
import brand from "../assets/brand-inverse.svg"

export default function Bar(){

    const [globalContext,setGlobalContext]=useContext(GlobalContext)
    const {changeModal}=useModal()

    const openPanel=()=>{
        let globalContextNew={...globalContext}
        globalContextNew["panel_opened"]=1
        setGlobalContext(globalContextNew)
    }

    return(
        <div className="bar">
            <div className="bar-menu" onClick={openPanel}>
                <div>
                    <div className="bar-menu-line"></div>
                    <div className="bar-menu-line"></div>
                    <div className="bar-menu-line"></div>
                </div>
            </div>
            <div className="bar-brand">
                <Link to="/c24/tablero" className="bar-brand-link"><img src={brand} /></Link>
            </div>
            <div className="bar-account" onClick={()=>{changeModal("user-info","account",globalContext.user_id)}}>
                <Avatar element="user" context="bar" id={globalContext.user_id} picture={globalContext.user_has_picture} color="" capitals={globalContext.user_capitals} />
            </div>
        </div>
    )
    
}