import {useState,useRef,useEffect,useContext,Fragment} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textarea from "../commons/Textarea"
import Selector from "../commons/Selector"

export default function TicketEdit(){

    const [globalContext]=useContext(GlobalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const pointInput=useRef()
    const contentInput=useRef()
    const [pointVal,setPointVal]=useState()
    const [contentVal,setContentVal]=useState("")
    const [pointOptions,setPointOptions]=useState([])

    useEffect(()=>{
        modalReady(0)
        requestApi("shops/shops_points",{context:"only_points"})
            .then(data=>{
                let newOptions=[{label:"Ninguno",value:0}]
                data.items.forEach(function(shop){
                    newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                })
                setPointOptions(newOptions)
                modalReady(1)
            })
    },[])

    const saveData=()=>{
        if(contentVal==""){
            contentInput.current.focus()
        }else{
            requestApi("tickets/create",{
                    user_id:globalContext.user_id,
                    point:pointInput.current.getValue()[0]?pointInput.current.getValue()[0].value:0,
                    content:contentVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        changeToast("ticket_created","")
                        manageModal("hide")
                        refresh(["list"],"")
                    }else{
                        changeDialog("generic_error","","")
                    }    
                })
        }
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Enviar solicitud" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title="Nueva solicitud de asistencia" />
                    <div className="modal-explanation modal-explanation-intro modal-explanation-center">
                        Para mejorar el tiempo de resolución, explicanos tu problema en forma clara y concisa. Nuestro equipo de atención al cliente te ayudará a la brevedad.
                    </div>
                    <Selector ref={pointInput} val={pointVal} setVal={setPointVal} placeholder="Comercio relacionado a la consulta" searchable={1} options={pointOptions} />
                    <Textarea ref={contentInput} label="¿Qué problema estás teniendo?" val={contentVal} setVal={setContentVal} disabled={false} />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}