import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Subscriptions(){
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("subscription-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="Suscripciones"
                intro="Pagos recurrentes administrados por Cajero24."
                back=""
                button={{label:"Nueva suscripción",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Suscripciones",to:"/c24/suscripciones"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"subscriptions",
                    endpoint:"subscriptions/list",
                    limit:100,
                    counter:{
                        singular:"suscripción",
                        plural:"suscripciones"
                    },
                    action:{
                        mode:"link",
                        context:"subscription",
                        reference:"/c24/suscripciones/"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}