import {Fragment,useContext} from "react"
import ModalContext from "../contexts/ModalContext"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import {formatAmount} from "../hooks/Commons"
import {format} from "date-fns"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import Amount from "../commons/Amount"

export default function ProjectionInfo(){

    const [modalContext]=useContext(ModalContext)
    const projection=modalContext.reference

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    <ModalTitle title={"Proyección para el "+format(projection["date"],"dd")+"/"+format(projection["date"],"MM")+"/"+format(projection["date"],"yyyy")} />
                    <Card title="" icon={{element:""}} action="" actionIcon="" actionClickEvent={()=>{}}>
                        <CardRow label={"Total estimado"} detail="(No incluye descuentos por aranceles, impuestos, retenciones o percepciones que aplican las tarjetas)" symbol="" symbolExtra="">
                            <Amount number={projection["total"]} currency={"ARS"} operation={""} />
                        </CardRow>
                        {projection["projections"].map((item,i)=>{
                            return(
                                <Fragment key={i}>
                                    {item["total_amount"]!=0 &&
                                    <CardRow label={item["payment_name"]} detail="" symbol={{element:"card",context:"small",reference:item["payment_card_id"]}} symbolExtra={item["payment_entity_id"]!=0?{element:"entity",context:"small",reference:item["payment_entity_id"]}:null}>
                                        <span className="card-row-featured card-row-semibold">{formatAmount(item["total_amount"],"ARS","display")}</span>
                                    </CardRow>}
                                </Fragment>
                            )
                        })}
                    </Card>
                </div>
            </ModalContent>
        </Fragment>
    )
    
}