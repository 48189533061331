import {useState,useEffect,useRef} from "react"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import {formatAmount} from "../hooks/Commons"
import Section from "../components/Section"
import SectionContent from "../components/SectionContent"
import Payment from "../components/Payment"
import WebposItem from "../commons/WebposItem"
import EntranceButton from "../commons/EntranceButton"
import Changer from "../commons/Changer"
import webposIcon from "../assets/webpos-icon.svg";
import addIcon from "../assets/add.svg";

export default function Webpos(){

    const requestApi=useApi()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const [stage,setStage]=useState("webpos")
    const [payReference,setPayReference]=useState("")
    const changerInput=useRef()
    const [changerVal,setChangerVal]=useState({label:"",value:""})
    const [barcodes,setBarcodes]=useState("")
    const [totalVal,setTotalVal]=useState(formatAmount(0,"ARS","display"))
    const [items,setItems]=useState(1)
    const [itemMain,setItemMain]=useState(1)
    const totalDisplay=useRef()
    const addButton=useRef()
    const [item2Shown,setItem2ShownVal]=useState(0)
    const [item3Shown,setItem3ShownVal]=useState(0)
    const [item4Shown,setItem4ShownVal]=useState(0)
    const [item5Shown,setItem5ShownVal]=useState(0)
    const [item6Shown,setItem6ShownVal]=useState(0)
    const [item7Shown,setItem7ShownVal]=useState(0)
    const [item8Shown,setItem8ShownVal]=useState(0)
    const [item9Shown,setItem9ShownVal]=useState(0)
    const [item10Shown,setItem10ShownVal]=useState(0)
    const [item1Val,setItem1Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item2Val,setItem2Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item3Val,setItem3Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item4Val,setItem4Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item5Val,setItem5Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item6Val,setItem6Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item7Val,setItem7Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item8Val,setItem8Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item9Val,setItem9Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [item10Val,setItem10Val]=useState({detail:"",reference:"",number:0,formatted:""})
    const [detail1Val,setDetail1Val]=useState("")
    const [detail2Val,setDetail2Val]=useState("")
    const [detail3Val,setDetail3Val]=useState("")
    const [detail4Val,setDetail4Val]=useState("")
    const [detail5Val,setDetail5Val]=useState("")
    const [detail6Val,setDetail6Val]=useState("")
    const [detail7Val,setDetail7Val]=useState("")
    const [detail8Val,setDetail8Val]=useState("")
    const [detail9Val,setDetail9Val]=useState("")
    const [detail10Val,setDetail10Val]=useState("")
    const [reference1Val,setReference1Val]=useState("")
    const [reference2Val,setReference2Val]=useState("")
    const [reference3Val,setReference3Val]=useState("")
    const [reference4Val,setReference4Val]=useState("")
    const [reference5Val,setReference5Val]=useState("")
    const [reference6Val,setReference6Val]=useState("")
    const [reference7Val,setReference7Val]=useState("")
    const [reference8Val,setReference8Val]=useState("")
    const [reference9Val,setReference9Val]=useState("")
    const [reference10Val,setReference10Val]=useState("")
    const [amount1Val,setAmount1Val]=useState({number:0,formatted:""})
    const [amount2Val,setAmount2Val]=useState({number:0,formatted:""})
    const [amount3Val,setAmount3Val]=useState({number:0,formatted:""})
    const [amount4Val,setAmount4Val]=useState({number:0,formatted:""})
    const [amount5Val,setAmount5Val]=useState({number:0,formatted:""})
    const [amount6Val,setAmount6Val]=useState({number:0,formatted:""})
    const [amount7Val,setAmount7Val]=useState({number:0,formatted:""})
    const [amount8Val,setAmount8Val]=useState({number:0,formatted:""})
    const [amount9Val,setAmount9Val]=useState({number:0,formatted:""})
    const [amount10Val,setAmount10Val]=useState({number:0,formatted:""})

    useEffect(()=>{
        requestApi("shops/barcodes",{shop_id:changerVal.value.shop_id,point_id:changerVal.value.point_id})
            .then(data=>{
                setBarcodes(data.items)
            })
    },[changerVal])

    const addItem=()=>{
        if(items<10){
            let newItems=items+1
            setItems(newItems)
        }
    }

    useEffect(()=>{
        updateAmount()
    },[item1Val,item2Val,item3Val,item4Val,item5Val,item6Val,item7Val,item8Val,item9Val,item10Val])

    useEffect(()=>{
        detectBarcode(1)
    },[detail1Val])

    useEffect(()=>{
        detectBarcode(2)
    },[detail2Val])

    useEffect(()=>{
        detectBarcode(3)
    },[detail3Val])

    useEffect(()=>{
        detectBarcode(4)
    },[detail4Val])

    useEffect(()=>{
        detectBarcode(5)
    },[detail5Val])

    useEffect(()=>{
        detectBarcode(6)
    },[detail6Val])

    useEffect(()=>{
        detectBarcode(7)
    },[detail7Val])

    useEffect(()=>{
        detectBarcode(8)
    },[detail8Val])

    useEffect(()=>{
        detectBarcode(9)
    },[detail9Val])

    useEffect(()=>{
        detectBarcode(10)
    },[detail10Val])

    const detectBarcode=(item)=>{
        let fieldValue=""
        if(item==1){
            fieldValue=detail1Val
        }else if(item==2){
            fieldValue=detail2Val
        }else if(item==3){
            fieldValue=detail3Val
        }else if(item==4){
            fieldValue=detail4Val
        }else if(item==5){
            fieldValue=detail5Val
        }else if(item==6){
            fieldValue=detail6Val
        }else if(item==7){
            fieldValue=detail7Val
        }else if(item==8){
            fieldValue=detail8Val
        }else if(item==9){
            fieldValue=detail9Val
        }else if(item==10){
            fieldValue=detail10Val
        }
        if(barcodes.length>0 && fieldValue!=""){
            let barcodeDetected="none"
            barcodes.forEach(function(barcode,index){
                var length=new RegExp("^[a-zA-Z0-9]{"+barcode["length"]+"}$")
                if(length.test(fieldValue)){
                    if(barcode["identifier"]==0){
                        barcodeDetected=index
                    }else{
                        if(fieldValue.indexOf(barcode["identifier"],barcode["identifier_start"])>=0){
                            barcodeDetected=index
                        }                        
                    }
                }
            })
            if(barcodeDetected!="none"){
                let barcode_amount_integer=""
                let barcode_amount_decimal=""
                let barcode_expiration_day=""
                let barcode_expiration_month=""
                let barcode_expiration_year=""
                let barcode_amount_2_integer=""
                let barcode_amount_2_decimal=""
                let barcode_expiration_2_day=""
                let barcode_expiration_2_month=""
                let barcode_expiration_2_year=""
                let barcode_client=""
                let barcode_reference=""
                if(barcodes[barcodeDetected]["amount_integer_length"]!=0){
                    barcode_amount_integer=parseInt(fieldValue.substring(barcodes[barcodeDetected]["amount_integer_start"],barcodes[barcodeDetected]["amount_integer_start"]+barcodes[barcodeDetected]["amount_integer_length"]))
                }
                if(barcodes[barcodeDetected]["amount_decimal_length"]!=0){
                    barcode_amount_decimal=fieldValue.substring(barcodes[barcodeDetected]["amount_decimal_start"],barcodes[barcodeDetected]["amount_decimal_start"]+barcodes[barcodeDetected]["amount_decimal_length"]).replace(/0+$/,"")
                }
                if(barcodes[barcodeDetected]["expiration_day_length"]!=0){
                    barcode_expiration_day=fieldValue.substring(barcodes[barcodeDetected]["expiration_day_start"],barcodes[barcodeDetected]["expiration_day_start"]+barcodes[barcodeDetected]["expiration_day_length"])
                }
                if(barcodes[barcodeDetected]["expiration_month_length"]!=0){
                    barcode_expiration_month=fieldValue.substring(barcodes[barcodeDetected]["expiration_month_start"],barcodes[barcodeDetected]["expiration_month_start"]+barcodes[barcodeDetected]["expiration_month_length"])
                }
                if(barcodes[barcodeDetected]["expiration_year_length"]!=0){
                    barcode_expiration_year=fieldValue.substring(barcodes[barcodeDetected]["expiration_year_start"],barcodes[barcodeDetected]["expiration_year_start"]+barcodes[barcodeDetected]["expiration_year_length"])
                }
                if(barcodes[barcodeDetected]["amount_2_integer_length"]!=0){
                    barcode_amount_2_integer=parseInt(fieldValue.substring(barcodes[barcodeDetected]["amount_2_integer_start"],barcodes[barcodeDetected]["amount_2_integer_start"]+barcodes[barcodeDetected]["amount_2_integer_length"]))
                }
                if(barcodes[barcodeDetected]["amount_2_decimal_length"]!=0){
                    barcode_amount_2_decimal=fieldValue.substring(barcodes[barcodeDetected]["amount_2_decimal_start"],barcodes[barcodeDetected]["amount_2_decimal_start"]+barcodes[barcodeDetected]["amount_2_decimal_length"]).replace(/0+$/,"")
                }
                if(barcodes[barcodeDetected]["expiration_2_day_length"]!=0){
                    barcode_expiration_2_day=fieldValue.substring(barcodes[barcodeDetected]["expiration_2_day_start"],barcodes[barcodeDetected]["expiration_2_day_start"]+barcodes[barcodeDetected]["expiration_2_day_length"])
                }
                if(barcodes[barcodeDetected]["expiration_2_month_length"]!=0){
                    barcode_expiration_2_month=fieldValue.substring(barcodes[barcodeDetected]["expiration_2_month_start"],barcodes[barcodeDetected]["expiration_2_month_start"]+barcodes[barcodeDetected]["expiration_2_month_length"])
                }
                if(barcodes[barcodeDetected]["expiration_2_year_length"]!=0){
                    barcode_expiration_2_year=fieldValue.substring(barcodes[barcodeDetected]["expiration_2_year_start"],barcodes[barcodeDetected]["expiration_2_year_start"]+barcodes[barcodeDetected]["expiration_2_year_length"])
                }
                if(barcodes[barcodeDetected]["client_length"]!=0){
                    barcode_client=fieldValue.substring(barcodes[barcodeDetected]["client_start"],barcodes[barcodeDetected]["client_start"]+barcodes[barcodeDetected]["client_length"])
                }
                if(barcodes[barcodeDetected]["reference_length"]!=0){
                    barcode_reference=fieldValue.substring(barcodes[barcodeDetected]["reference_start"],barcodes[barcodeDetected]["reference_start"]+barcodes[barcodeDetected]["reference_length"])
                }
                let barcode_amount="0"
                if(barcode_amount_integer!=""){
                    barcode_amount=barcode_amount_integer
                }
                if(barcode_amount_decimal!=""){
                    barcode_amount=barcode_amount+","+barcode_amount_decimal
                }
                let barcode_amount_2="0"
                if(barcode_amount_2_integer!=""){
                    barcode_amount_2=barcode_amount_2_integer
                }
                if(barcode_amount_2_decimal!=""){
                    barcode_amount_2=barcode_amount_2+","+barcode_amount_2_decimal
                }
                let barcode_expiration=""
                if(barcode_expiration_day!="" && barcode_expiration_month!="" && barcode_expiration_year!=""){
                    if(barcode_expiration_year.length==2){
                        barcode_expiration_year="20"+barcode_expiration_year
                    }
                    barcode_expiration=new Date(barcode_expiration_year+"-"+barcode_expiration_month+"-"+barcode_expiration_day+" 00:00:00")
                }
                let barcode_expiration_2=""
                if(barcode_expiration_2_day!="" && barcode_expiration_2_month!="" && barcode_expiration_2_year!=""){
                    if(barcode_expiration_2_year.length==2){
                        barcode_expiration_2_year="20"+barcode_expiration_2_year
                    }
                    barcode_expiration_2=new Date(barcode_expiration_2_year+"-"+barcode_expiration_2_month+"-"+barcode_expiration_2_day+" 00:00:00")
                }
                let today=new Date()
                today.setHours(0,0,0,0)
                if(barcode_expiration!="" && barcode_expiration!="Invalid Date"){
                    if(today>barcode_expiration){
                        changeToast("webpos_expired_1",{date:barcode_expiration_day+"/"+barcode_expiration_month+"/"+barcode_expiration_year})
                        if(barcode_amount_2!="0"){
                            barcode_amount=barcode_amount_2
                        }
                        if(barcode_expiration_2!="" && barcode_expiration_2!="Invalid Date"){
                            if(today>barcode_expiration_2){
                                changeToast("webpos_expired_2",{date:barcode_expiration_2_day+"/"+barcode_expiration_2_month+"/"+barcode_expiration_2_year})
                                if(barcode_amount_2!="0"){
                                    barcode_amount=0
                                }
                            }
                        }
                    }
                }
                let barcode_detail=""
                if(barcode_reference!=""){
                    barcode_detail=barcode_reference
                }
                if(barcode_client!=""){
                    if(barcode_detail==""){
                        barcode_detail=barcode_client
                    }else{
                        barcode_detail+=" Cliente: "+barcode_client
                    }
                }
                if(item==1){
                    if(barcode_detail!=""){
                        setReference1Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount1Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==2){
                    if(barcode_detail!=""){
                        setReference2Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount2Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==3){
                    if(barcode_detail!=""){
                        setReference3Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount3Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==4){
                    if(barcode_detail!=""){
                        setReference4Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount4Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==5){
                    if(barcode_detail!=""){
                        setReference5Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount5Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==6){
                    if(barcode_detail!=""){
                        setReference6Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount6Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==7){
                    if(barcode_detail!=""){
                        setReference7Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount7Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==8){
                    if(barcode_detail!=""){
                        setReference8Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount8Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==9){
                    if(barcode_detail!=""){
                        setReference9Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount9Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }else if(item==10){
                    if(barcode_detail!=""){
                        setReference10Val(barcode_detail)
                    }
                    if(barcode_amount!=""){
                        setAmount10Val({number:parseFloat(barcode_amount),formatted:barcode_amount})
                    }
                }
            }
        }
    }

    useEffect(()=>{
        if(items==1){
            setItemMain(1)
            setItem2ShownVal(0)
            setItem3ShownVal(0)
            setItem4ShownVal(0)
            setItem5ShownVal(0)
            setItem6ShownVal(0)
            setItem7ShownVal(0)
            setItem8ShownVal(0)
            setItem9ShownVal(0)
            setItem10ShownVal(0)
        }else if(items==2){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(0)
            setItem4ShownVal(0)
            setItem5ShownVal(0)
            setItem6ShownVal(0)
            setItem7ShownVal(0)
            setItem8ShownVal(0)
            setItem9ShownVal(0)
            setItem10ShownVal(0)
        }else if(items==3){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(1)
            setItem4ShownVal(0)
            setItem5ShownVal(0)
            setItem6ShownVal(0)
            setItem7ShownVal(0)
            setItem8ShownVal(0)
            setItem9ShownVal(0)
            setItem10ShownVal(0)
        }else if(items==4){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(1)
            setItem4ShownVal(1)
            setItem5ShownVal(0)
            setItem6ShownVal(0)
            setItem7ShownVal(0)
            setItem8ShownVal(0)
            setItem9ShownVal(0)
            setItem10ShownVal(0)
        }else if(items==5){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(1)
            setItem4ShownVal(1)
            setItem5ShownVal(1)
            setItem6ShownVal(0)
            setItem7ShownVal(0)
            setItem8ShownVal(0)
            setItem9ShownVal(0)
            setItem10ShownVal(0)
        }else if(items==6){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(1)
            setItem4ShownVal(1)
            setItem5ShownVal(1)
            setItem6ShownVal(1)
            setItem7ShownVal(0)
            setItem8ShownVal(0)
            setItem9ShownVal(0)
            setItem10ShownVal(0)
        }else if(items==7){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(1)
            setItem4ShownVal(1)
            setItem5ShownVal(1)
            setItem6ShownVal(1)
            setItem7ShownVal(1)
            setItem8ShownVal(0)
            setItem9ShownVal(0)
            setItem10ShownVal(0)
        }else if(items==8){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(1)
            setItem4ShownVal(1)
            setItem5ShownVal(1)
            setItem6ShownVal(1)
            setItem7ShownVal(1)
            setItem8ShownVal(1)
            setItem9ShownVal(0)
            setItem10ShownVal(0)
        }else if(items==9){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(1)
            setItem4ShownVal(1)
            setItem5ShownVal(1)
            setItem6ShownVal(1)
            setItem7ShownVal(1)
            setItem8ShownVal(1)
            setItem9ShownVal(1)
            setItem10ShownVal(0)
        }else if(items==10){
            setItemMain(0)
            setItem2ShownVal(1)
            setItem3ShownVal(1)
            setItem4ShownVal(1)
            setItem5ShownVal(1)
            setItem6ShownVal(1)
            setItem7ShownVal(1)
            setItem8ShownVal(1)
            setItem9ShownVal(1)
            setItem10ShownVal(1)
        }
    },[items])

    const updateAmount=()=>{
        let total=0
        total+=item1Val["number"]
        total+=item2Val["number"]
        total+=item3Val["number"]
        total+=item4Val["number"]
        total+=item5Val["number"]
        total+=item6Val["number"]
        total+=item7Val["number"]
        total+=item8Val["number"]
        total+=item9Val["number"]
        total+=item10Val["number"]
        setTotalVal(formatAmount(total,"ARS","display"))
    }
    
    const request=()=>{
        if(changerInput.current.getValue()[0]["value"]==""){
            changeToast("webpos_select_point","")
        }else{
            if((item1Val["detail"]=="" || item1Val["number"]==0) && (item2Val["detail"]=="" || item2Val["number"]==0) && (item3Val["detail"]=="" || item3Val["number"]==0) && (item4Val["detail"]=="" || item4Val["number"]==0) && (item5Val["detail"]=="" || item5Val["number"]==0) && (item6Val["detail"]=="" || item6Val["number"]==0) && (item7Val["detail"]=="" || item7Val["number"]==0) && (item8Val["detail"]=="" || item8Val["number"]==0) && (item9Val["detail"]=="" || item9Val["number"]==0) && (item10Val["detail"]=="" || item10Val["number"]==0)){
                changeToast("webpos_empty","")
            }else{
                requestApi("pay/webpos",{
                        point:changerVal.value,
                        item1:item1Val,
                        item2:item2Val,
                        item3:item3Val,
                        item4:item4Val,
                        item5:item5Val,
                        item6:item6Val,
                        item7:item7Val,
                        item8:item8Val,
                        item9:item9Val,
                        item10:item10Val
                    })
                    .then(data=>{
                        if(data.status=="success"){
                            setPayReference(data["token"])
                        }else{
                            changeDialog("generic_error","","")
                        }
                    })
            }
        }
    }

    useEffect(()=>{
        if(payReference!=""){
            setStage("pay")
        }
    },[payReference])

    const cancel=()=>{
        requestApi("pay/webpos_cancel",{
                request_id:payReference,
                point:changerVal.value
            })
            .then(data=>{
                if(data.status=="success"){
                    setStage("webpos")
                    setPayReference("")
                    setTotalVal(formatAmount(0,"ARS","display"))
                    setItems(1)
                    setItemMain(1)
                    setItem1Val({detail:"",reference:"",number:0,formatted:""})
                    setItem2Val({detail:"",reference:"",number:0,formatted:""})
                    setItem3Val({detail:"",reference:"",number:0,formatted:""})
                    setItem4Val({detail:"",reference:"",number:0,formatted:""})
                    setItem5Val({detail:"",reference:"",number:0,formatted:""})
                    setItem6Val({detail:"",reference:"",number:0,formatted:""})
                    setItem7Val({detail:"",reference:"",number:0,formatted:""})
                    setItem8Val({detail:"",reference:"",number:0,formatted:""})
                    setItem9Val({detail:"",reference:"",number:0,formatted:""})
                    setItem10Val({detail:"",reference:"",number:0,formatted:""})
                    setDetail1Val("")
                    setDetail2Val("")
                    setDetail3Val("")
                    setDetail4Val("")
                    setDetail5Val("")
                    setDetail6Val("")
                    setDetail7Val("")
                    setDetail8Val("")
                    setDetail9Val("")
                    setDetail10Val("")
                    setReference1Val("")
                    setReference2Val("")
                    setReference3Val("")
                    setReference4Val("")
                    setReference5Val("")
                    setReference6Val("")
                    setReference7Val("")
                    setReference8Val("")
                    setReference9Val("")
                    setReference10Val("")
                    setAmount1Val({number:0,formatted:""})
                    setAmount2Val({number:0,formatted:""})
                    setAmount3Val({number:0,formatted:""})
                    setAmount4Val({number:0,formatted:""})
                    setAmount5Val({number:0,formatted:""})
                    setAmount6Val({number:0,formatted:""})
                    setAmount7Val({number:0,formatted:""})
                    setAmount8Val({number:0,formatted:""})
                    setAmount9Val({number:0,formatted:""})
                    setAmount10Val({number:0,formatted:""})
                    setItem2ShownVal(0)
                    setItem3ShownVal(0)
                    setItem4ShownVal(0)
                    setItem5ShownVal(0)
                    setItem6ShownVal(0)
                    setItem7ShownVal(0)
                    setItem8ShownVal(0)
                    setItem9ShownVal(0)
                    setItem10ShownVal(0)
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    useEffect(()=>{
        if(stage=="webpos"){
            changerInput.current.setValue(changerVal)
        }
    },[stage])

    return(
        <Section>
            <SectionContent>
                {stage=="webpos"?
                 <div className="webpos noselect">
                    <div className="webpos-point">
                        <Changer ref={changerInput} context="webpos" changer={[changerVal,setChangerVal]} placeholder="Punto de cobro:" searchable={1} />
                    </div>
                    <div className="webpos-icon"><img src={webposIcon} /></div>
                    <div className="webpos-block">
                        <div className="webpos-label">¿Qué vas a cobrar?</div>
                    </div>
                    <div className="webpos-items">
                        <WebposItem detailVal={detail1Val} setDetailVal={setDetail1Val} referenceVal={reference1Val} setReferenceVal={setReference1Val} amountVal={amount1Val} setAmountVal={setAmount1Val} itemVal={item1Val} setItemVal={setItem1Val} shown={1} main={itemMain} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail2Val} setDetailVal={setDetail2Val} referenceVal={reference2Val} setReferenceVal={setReference2Val} amountVal={amount2Val} setAmountVal={setAmount2Val} itemVal={item2Val} setItemVal={setItem2Val} shown={item2Shown} main={0} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail3Val} setDetailVal={setDetail3Val} referenceVal={reference3Val} setReferenceVal={setReference3Val} amountVal={amount3Val} setAmountVal={setAmount3Val} itemVal={item3Val} setItemVal={setItem3Val} shown={item3Shown} main={0} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail4Val} setDetailVal={setDetail4Val} referenceVal={reference4Val} setReferenceVal={setReference4Val} amountVal={amount4Val} setAmountVal={setAmount4Val} itemVal={item4Val} setItemVal={setItem4Val} shown={item4Shown} main={0} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail5Val} setDetailVal={setDetail5Val} referenceVal={reference5Val} setReferenceVal={setReference5Val} amountVal={amount5Val} setAmountVal={setAmount5Val} itemVal={item5Val} setItemVal={setItem5Val} shown={item5Shown} main={0} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail6Val} setDetailVal={setDetail6Val} referenceVal={reference6Val} setReferenceVal={setReference6Val} amountVal={amount6Val} setAmountVal={setAmount6Val} itemVal={item6Val} setItemVal={setItem6Val} shown={item6Shown} main={0} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail7Val} setDetailVal={setDetail7Val} referenceVal={reference7Val} setReferenceVal={setReference7Val} amountVal={amount7Val} setAmountVal={setAmount7Val} itemVal={item7Val} setItemVal={setItem7Val} shown={item7Shown} main={0} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail8Val} setDetailVal={setDetail8Val} referenceVal={reference8Val} setReferenceVal={setReference8Val} amountVal={amount8Val} setAmountVal={setAmount8Val} itemVal={item8Val} setItemVal={setItem8Val} shown={item8Shown} main={0} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail9Val} setDetailVal={setDetail9Val} referenceVal={reference9Val} setReferenceVal={setReference9Val} amountVal={amount9Val} setAmountVal={setAmount9Val} itemVal={item9Val} setItemVal={setItem9Val} shown={item9Shown} main={0} changeEvent={updateAmount} />
                        <WebposItem detailVal={detail10Val} setDetailVal={setDetail10Val} referenceVal={reference10Val} setReferenceVal={setReference10Val} amountVal={amount10Val} setAmountVal={setAmount10Val} itemVal={item10Val} setItemVal={setItem10Val} shown={item10Shown} main={0} changeEvent={updateAmount} />
                    </div>
                    <div className="webpos-total" style={items>1?{"display":"flex"}:{"display":"none"}}>
                        <div className="webpos-total-caption">Total:</div>
                        <div className="webpos-total-value" ref={totalDisplay}>{totalVal}</div>
                    </div>
                    <div className="webpos-actions" style={items<10?{"display":"block"}:{"display":"none"}}>
                        <div ref={addButton} className="webpos-add noselect" onClick={addItem}>
                            <div><img src={addIcon} /></div>
                            <div>Agregar otro concepto</div>
                        </div>
                    </div>
                    <div className="webpos-continue">
                        <EntranceButton label="Continuar" style="primary" arrow="next" clickEvent={request} />
                    </div>
                </div>
                :stage=="pay"?
                <div className="pay pay-alt">
                    <div style={{width:"100%"}}>
                        <Payment context="webpos" reference={payReference} event={cancel} />
                    </div>
                </div>
                :<div></div>}
            </SectionContent>
        </Section>
    )
    
}