import {useState,useRef,useEffect,useContext,Fragment} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Textarea from "../commons/Textarea"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"

export default function NewsEdit(){

    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const mode=modalContext.reference==0?"create":"edit"
    const tagInput=useRef()
    const titleInput=useRef()
    const contentInput=useRef()
    const releasedInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [tagVal,setTagVal]=useState("")
    const [titleVal,setTitleVal]=useState("")
    const [contentVal,setContentVal]=useState("")
    const [releasedVal,setReleasedVal]=useState("")
    const [statusVal,setStatusVal]=useState(0)

    useEffect(()=>{
        if(mode=="edit"){
            modalReady(0)
            requestApi("news/info",{
                    news_id:modalContext.reference
                })
                .then(data=>{
                    setIdVal(data.data["id"])
                    setTagVal(data.data["tag"])
                    setTitleVal(data.data["title"])
                    setContentVal(data.data["content"])
                    setReleasedVal(data.data["released_formatted"])
                    setStatusVal(data.data["status"])
                    modalReady(1)
                })
        }
    },[])

    const saveData=()=>{
        if(titleVal==""){
            titleInput.current.focus()
        }else{
            if(contentVal==""){
                contentInput.current.focus()
            }else{
                if(releasedVal==""){
                    releasedInput.current.focus()
                }else{
                    requestApi("news/save",{
                            id:idVal,
                            mode:mode,
                            tag:tagVal,
                            title:titleVal,
                            content:contentVal,
                            released:releasedVal,
                            status:statusVal
                        })
                        .then(data=>{
                            if(data.status=="success"){
                                if(mode=="create"){
                                    changeToast("created_f","")
                                }else if(mode=="edit"){
                                    changeToast("saved","")
                                }
                                manageModal("hide")
                                refresh(["list"],"")
                            }else{
                                changeDialog("generic_error","","")
                            }
                        })
                }
            }
        }
    }

    const remove=()=>{
        requestApi("news/remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Agregar novedad":"Editar novedad"} />
                    <Textbox ref={tagInput} label="Etiqueta" type="text" val={tagVal} setVal={setTagVal} disabled={false} />
                    <Textbox ref={titleInput} label="Título" type="text" val={titleVal} setVal={setTitleVal} disabled={false} />
                    <Textarea ref={contentInput} label="Contenido" val={contentVal} setVal={setContentVal} disabled={false} />
                    <Textbox ref={releasedInput} label="Fecha de publicación" type="date" val={releasedVal} setVal={setReleasedVal} disabled={false} />
                    <Switch onLabel="Activa" offLabel="Inactiva" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}