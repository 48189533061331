import {Fragment,useContext,useEffect,useState} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonCard from "../commons/ButtonCard"

export default function Vouchers(){

    const [modalContext]=useContext(ModalContext)
    const {changeModal,modalReady}=useModal()
    const requestApi=useApi()
    let shopId=modalContext.reference
    const [vouchers,setVouchers]=useState()

    const loadVouchers=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("records/vouchers",{
                        shop_id:shopId
                    })
                    .then(data=>{
                        return data
                    })
            )
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadVouchers].map(func=>func())).then((result)=>{
            setVouchers(result[0])
            modalReady(1)
        })
    },[])

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {vouchers &&
                    <Card title={"Facturas de "+vouchers["shop"]["name"]} icon={{element:"billing"}} action="" actionIcon="" actionClickEvent="">
                        {vouchers["items"].length>0?
                            vouchers["items"].map((item,i)=>{
                                return(
                                    <CardRow key={i} label={
                                        <Fragment>
                                            {item["label"]}&nbsp;
                                            {item["status"]==0 &&
                                            <Fragment>
                                                <span className="item-point item-point-red"></span>&nbsp;<span className="item-xsmall item-medium item-uppercase item-red">Sin pagar</span>
                                            </Fragment>}
                                            {item["status"]==1 &&
                                            <Fragment>
                                                <span className="item-point item-point-green"></span>&nbsp;<span className="item-xsmall item-medium item-uppercase item-green">Pagada</span>
                                            </Fragment>}
                                        </Fragment>
                                    } detail={item["detail"]}>
                                        <ButtonCard label="Ver factura" style="primary" clickEvent={()=>{changeModal("voucher","edit",{shop:shopId,voucher:item["id"]})}} />
                                    </CardRow>
                                )
                            })
                        :<CardRow label="Aún no se han generado facturas." detail="" />}
                    </Card>}
                </div>
            </ModalContent>
        </Fragment>
    )

}