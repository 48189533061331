import {Fragment,forwardRef} from "react"

const SwitchButton=forwardRef(({options,val,setVal,multiple},ref)=>{

    const changeValue=(newVal)=>{
        if(multiple){
            if(val.includes(newVal)){
                let valNew=[...val]
                valNew=valNew.filter(arrayItem=>arrayItem!==newVal)
                setVal(valNew)
            }else{
                setVal([...val,newVal])
            }
        }else{
            if(val==newVal){
                setVal("")
            }else{
                setVal(newVal)
            }
        }
    }

    return(
        <Fragment>
            {options.map((item,i)=>
                <div key={i} className={"switch-button"+(multiple==true?val.includes(item["value"])?" switch-button-on":"":item["value"]==val?" switch-button-on":"")} onClick={()=>{changeValue(item["value"])}}>{item["label"]}</div>
            )}
        </Fragment>
    )
    
})

export default SwitchButton