import {Fragment,useState,useRef,useContext} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Numbox from "../commons/Numbox"

export default function Record(){

    const {refresh}=useRefresh()
    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {manageModal}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    let shopId=modalContext.reference["value"]["shop_id"]

    const detailInput=useRef()
    const [detailVal,setDetailVal]=useState("")
    const amountInput=useRef()
    const [amountVal,setAmountVal]=useState("")

    const saveData=()=>{
        if(detailVal==""){
            detailInput.current.focus()
        }else{
            if(amountVal==""){
                amountInput.current.focus()
            }else{
                requestApi("records/save",{
                        shop_id:shopId,
                        detail:detailVal,
                        amount:amountVal
                    })
                    .then(data=>{
                        if(data.status=="success"){
                            changeToast("created_m","")
                            manageModal("hide")
                            refresh(["list"],"")
                        }else{
                            changeDialog("generic_error","","")
                        }
                    })
            }
        }
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info noselect">
                    <ModalTitle title="Nuevo registro" />
                    <Textbox ref={detailInput} label="Detalle" type="text" val={detailVal} setVal={setDetailVal} disabled={false} />
                    <Numbox ref={amountInput} label="Valor" val={amountVal} setVal={setAmountVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}