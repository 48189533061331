import {useEffect,useRef,useState,useContext,Fragment} from "react"
import usePermissions from "../hooks/usePermissions"
import FloatingContext from "../contexts/FloatingContext"
import useApi from "../hooks/useApi"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Dropdown from "../commons/Dropdown"
import close from "../assets/close-pink.svg"

export default function ShopsChangers(){

    const {checkPermission}=usePermissions()
    const {manageFloating,floatingReady}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const requestApi=useApi()
    const setValue=floatingContext.setValue
    const context=floatingContext.context

    const filter1=useRef()
    const [filter1Val,setFilter1Val]=useState("")
    const filter2=useRef()
    const [filter2Val,setFilter2Val]=useState("")
    const filter3=useRef()
    const [filter3Val,setFilter3Val]=useState("")
    const filter4=useRef()
    const [filter4Val,setFilter4Val]=useState("")

    const [shopOptions,setShopOptions]=useState([])
    const [tagOptions,setTagOptions]=useState([])
    const [agreementOptions,setAgreementOptions]=useState([])
    const [operatorOptions,setOperatorOptions]=useState([])

    const loadShops=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/shops_points",{context:"all"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(shop){
                            newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadTags=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/tags",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(tag){
                            newOptions.push({label:tag.name,value:tag.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadOperators=()=>{
        return new Promise(function(resolve){
            if(context=="activity" || context=="reports"){
                resolve(
                    requestApi("shops/operators",{})
                        .then(data=>{
                            let newOptions=[]
                            data.items.forEach(function(operator){
                                newOptions.push({label:operator.name,value:operator.id})
                            })
                            return newOptions
                        })
                )    
            }else{
                resolve()
            }
        })
    }

    const loadAgreements=()=>{
        return new Promise(function(resolve){
            if(context=="activity" || context=="records" || context=="reports" || context=="analytics" || context=="links" || context=="subscriptions"){
                resolve(
                    requestApi("shops/agreements",{})
                        .then(data=>{
                            let newOptions=[]
                            data.items.forEach(function(agreement){
                                newOptions.push({label:agreement.name,value:agreement.id})
                            })
                            return newOptions
                        })
                )
            }else{
                resolve()
            }
        })
    }

    useEffect(()=>{
        floatingReady(0)
        Promise.all([loadShops,loadTags,loadOperators,loadAgreements].map(func=>func())).then((result)=>{
            let shopOptionsNew=result[0]
            setShopOptions(shopOptionsNew)
            let tagOptionsNew=result[1]
            setTagOptions(tagOptionsNew)
            if(context=="activity" || context=="reports"){
                let operatorOptionsNew=result[2]
                setOperatorOptions(operatorOptionsNew)
            }
            if(context=="activity" || context=="records" || context=="reports" || context=="analytics" || context=="links" || context=="subscriptions"){
                let agreementOptionsNew=result[3]
                agreementOptionsNew.unshift({label:"Todos",value:""})
                setAgreementOptions(agreementOptionsNew)
            }
            floatingReady(1)
        })
    },[])

    const apply=()=>{
        let operatorsList=""
        let agreementsList=""
        if(context=="activity" || context=="reports"){
            operatorsList=filter3.current.getValue()[0]?filter3.current.getValue():""
        }
        if(context=="activity" || context=="records" || context=="reports" || context=="analytics" || context=="links" || context=="subscriptions"){
            agreementsList=filter4.current.getValue()[0]?filter4.current.getValue():""
        }
        setValue({
            shops:filter1.current.getValue()[0]?filter1.current.getValue():"",
            tags:filter2.current.getValue()[0]?filter2.current.getValue():"",
            operators:operatorsList,
            agreements:agreementsList
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val("")
        filter1.current.setValue("")
        setFilter2Val("")
        filter2.current.setValue("")
        if(context=="activity" || context=="reports"){
            setFilter3Val("")
            filter3.current.setValue("")
        }
        if(context=="activity" || context=="records" || context=="reports" || context=="analytics" || context=="links" || context=="subscriptions"){
            setFilter4Val("")
            filter4.current.setValue("")
        }
        setValue("")
        manageFloating("hide")
    }

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-group">Comercios</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter1} val={filter1Val} setVal={setFilter1Val} placeholder="Buscar comercio..." multiple={1} searchable={1} options={shopOptions} />
                    </div>
                </div>
                <div style={{display:checkPermission({alias:"master_admin",reference:""})?"block":"none"}}>
                    <div className="floating-group">Etiquetas</div>
                    <div className="floating-options">
                        <div className="floating-full">
                            <Dropdown ref={filter2} val={filter2Val} setVal={setFilter2Val} placeholder="Buscar etiqueta..." multiple={1} searchable={1} options={tagOptions} />
                        </div>
                    </div>                   
                </div>
                {(context=="activity" || context=="records" || context=="reports" || context=="analytics" || context=="links" || context=="subscriptions") &&
                <Fragment>
                    <div className="floating-group">Otras opciones</div>
                    <div className="floating-options">
                        <div className="floating-full">
                            {(context=="activity" || context=="reports") && <Dropdown ref={filter3} val={filter3Val} setVal={setFilter3Val} placeholder="Operador/a" multiple={1} searchable={1} options={operatorOptions} />}
                            <div style={{display:checkPermission({alias:"master_admin",reference:""})?"block":"none"}}>
                                <Dropdown ref={filter4} val={filter4Val} setVal={setFilter4Val} placeholder="Acuerdo comercial" multiple={0} searchable={0} options={agreementOptions} />
                            </div>
                        </div>
                    </div>                    
                </Fragment>}
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}