import {useContext} from "react"
import ToastContext from "../contexts/ToastContext"

export default function Toast(){

    const [toastContext]=useContext(ToastContext)

    let classes="toast noselect"
    if(["pay_error",
    "pay_invalid_data",
    "pay_invalid_card_number",
    "pay_invalid_expiry_date",
    "pay_invalid_security_code"].includes(toastContext.toast)){
        classes="toast toast-alt noselect"
    }

    return(
        <div id="toast" className={classes}>
            <div className="toast-box">
                {toastContext.toast=="saved" && 
                <div>¡Cambios guardados!</div>}
                {toastContext.toast=="created_m" && 
                <div>¡Creado!</div>}
                {toastContext.toast=="created_f" && 
                <div>¡Creada!</div>}
                {toastContext.toast=="removed" && 
                <div>Elemento eliminado.</div>}
                {toastContext.toast=="deactivated" && 
                <div>Usuario desactivado.</div>}
                {toastContext.toast=="blocked" && 
                <div>Usuario bloqueado.</div>}
                {toastContext.toast=="activated" && 
                <div>Usuario activado.</div>}
                {toastContext.toast=="unblocked" && 
                <div>Usuario desbloqueado.</div>}
                {toastContext.toast=="ticket_created" && 
                <div>¡Solicitud enviada!</div>}
                {toastContext.toast=="message_sent" && 
                <div>¡Mensaje enviado!</div>}
                {toastContext.toast=="file_not_allow" && 
                <div>Tipo de archivo no permitido.</div>}
                {toastContext.toast=="links_imported" && 
                <div>¡Links creados desde el archivo!</div>}
                {toastContext.toast=="link_copied" && 
                <div>¡Enlace copiado!</div>}
                {toastContext.toast=="link_sent" && 
                <div>¡Enlace enviado!</div>}
                {toastContext.toast=="access_token_copied" && 
                <div>¡Clave copiada!</div>}
                {toastContext.toast=="refunded" && 
                <div>¡Devolución concretada!</div>}
                {toastContext.toast=="liquidated" && 
                <div>¡Liquidación registrada!</div>}
                {toastContext.toast=="pay_error" && 
                <div>
                    {toastContext.params["errorCode"]=="CLIENT_300"?
                    "Revisá todos los datos..."
                    :toastContext.params["errorCode"]=="CLIENT_301"?
                    "Número de tarjeta incorrecto..."
                    :toastContext.params["errorCode"]=="CLIENT_302"?
                    "Fecha de vencimiento incorrecta..."
                    :toastContext.params["errorCode"]=="CLIENT_303"?
                    "Código de seguridad incorrecto..."
                    :toastContext.params["errorCode"]=="CLIENT_304"?
                    "Completá todos los campos..."
                    :toastContext.params["errorCode"]=="PSP_023"?
                    "Fecha de vencimiento no válida..."
                    :(toastContext.params["errorCode"]=="PSP_024" || toastContext.params["errorCode"]=="PSP_509")?
                    "Código de seguridad no válido..."
                    :(toastContext.params["errorCode"]=="PSP_026" || toastContext.params["errorCode"]=="PSP_526" || toastContext.params["errorCode"]=="PSP_527" || toastContext.params["errorCode"]=="PSP_528" || toastContext.params["errorCode"]=="PSP_529" || toastContext.params["errorCode"]=="PSP_530" || toastContext.params["errorCode"]=="PSP_531" || toastContext.params["errorCode"]=="PSP_532" || toastContext.params["errorCode"]=="PSP_533")?
                    "Número de tarjeta no válido..."
                    :(toastContext.params["errorCode"]=="PSP_021" || toastContext.params["errorCode"]=="PSP_519" || toastContext.params["errorCode"]=="PSP_606")?
                    "Moneda desconocida..."
                    :(toastContext.params["errorCode"]=="PSP_020" || toastContext.params["errorCode"]=="PSP_040" || toastContext.params["errorCode"]=="PSP_506" || toastContext.params["errorCode"]=="PSP_583")?
                    "El monto no es válido..."
                    :(toastContext.params["errorCode"]=="PSP_665" || toastContext.params["errorCode"]=="PSP_666" || toastContext.params["errorCode"]=="PSP_667" || toastContext.params["errorCode"]=="PSP_668")?
                    "Revisá número y tipo de documento..."
                    :"Algo no anduvo bien..."}
                </div>}
                {toastContext.toast=="cbu_empty" && 
                <div>Completá el CBU o alias...</div>}
                {toastContext.toast=="cuit_empty" && 
                <div>Completá el CUIT del/la titular...</div>}
                {toastContext.toast=="cuit_invalid" && 
                <div>El CUIT no es válido...</div>}
                {toastContext.toast=="debin_failed" && 
                <div>CBU, alias o CUIT incorrecto...</div>}
                {toastContext.toast=="webpos_empty" && 
                <div>Completá al menos un concepto...</div>}
                {toastContext.toast=="webpos_select_point" && 
                <div>Seleccioná un punto de cobro...</div>}
                {toastContext.toast=="webpos_expired_1" && 
                <div>¡Vencido el {toastContext.params["date"]}! (1° vencimiento)</div>}
                {toastContext.toast=="webpos_expired_2" && 
                <div>¡Vencido el {toastContext.params["date"]}! (2° vencimiento)</div>}
                {toastContext.toast=="cuit_verified" && 
                <div>¡CUIT verificado!</div>}
                {toastContext.toast=="cuit_unverified" && 
                <div>CUIT no encontrado...</div>}
                {toastContext.toast=="image_not_uploaded" && 
                <div>No se pudo cambiar la imagen...</div>}
            </div>
        </div>
    )
    
}