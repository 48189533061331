import {Fragment} from "react"
import {useParams} from "react-router-dom"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import Payment from "../components/Payment"
import Dialog from "../components/Dialog"
import Toast from "../components/Toast"

export default function Pay(){

    const params=useParams()
    const payContext=params.context
    const payReference=params.reference

    return(
        <Fragment>
            <div className="pay">
                <Payment context={payContext} reference={payReference} />
            </div>
            <Dialog />
            <Toast />
            <Ga />
            <ConnectionChecker />
        </Fragment>
    )
    
}