import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function FaqsAdm(){
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("ticket-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="FAQs"
                intro="¿Con qué podemos ayudarte?"
                back=""
                button={{label:"Solicitar asistencia",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Atención al cliente",labelShort:"Atención",to:"/c24/soporte"},
                    {label:"Preguntas frecuentes",labelShort:"FAQs",to:"/c24/soporte/faqs"},
                    {label:"Tickets",to:"/c24/soporte/tickets"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"faqs",
                    endpoint:"faqs/groups",
                    reference:{context:"public"},
                    limit:0,
                    counter:{
                        singular:"grupo",
                        plural:"grupos"
                    },
                    action:{
                        mode:"link",
                        context:"faq_group",
                        reference:"/c24/soporte/faqs/"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}