import {Fragment,useContext,useRef,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import ModalTitle from "../components/ModalTitle"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonCard from "../commons/ButtonCard"

export default function ShopPayments(){
    
    const [modalContext]=useContext(ModalContext)
    const {changeModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const requestApi=useApi()
    const [payments,setPayments]=useState()

    useEffect(()=>{
        modalReady(0)
        requestApi("shops/payments_define",{
                point_id:modalContext.reference["point"]
            })
            .then(data=>{
                let getPayments=data.items
                getPayments.map((item,i)=>{
                    getPayments[i]["new_assigned"]=item["assigned"]
                })
                setPayments(getPayments)
                modalReady(1)
            })
    },[])

    const activate=(i)=>{
        let updatedPayments=[...payments]
        let updatedPayment={
            ...updatedPayments[i],
            new_assigned:1
        }
        updatedPayments[i]=updatedPayment
        setPayments(updatedPayments)
    }

    const deactivate=(i)=>{
        let updatedPayments=[...payments]
        let updatedPayment={
            ...updatedPayments[i],
            new_assigned:0
        }
        updatedPayments[i]=updatedPayment
        setPayments(updatedPayments)
    }

    const saveData=()=>{
        requestApi("shops/payments_save",{
                point_id:modalContext.reference["point"],
                payments:payments
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("saved","")
                    changeModal("shop-point","",modalContext.reference["point"])
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("shop-point","",modalContext.reference["point"])}} />
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    <ModalTitle title="Definir medios de pago" />
                    {payments &&
                    <Card title="" icon={{element:""}} action="" actionIcon="" actionClickEvent={()=>{}}>
                        {payments.length>0?
                            payments.map((item,i)=>{
                                return(
                                    <CardRow unique={"savedCard"+item["id"]} label={item["name"]} key={item["id"]} detail="" off={item["status"]} symbol={{element:"card",context:"small",reference:item["class"]=="debin"?"debin":(item["card_has_picture"]==1?item["card_id"]:0)}} symbolExtra={item["entity_id"]!=0?{element:"entity",context:"small",reference:(item["entity_has_picture"]==1?item["entity_id"]:0)}:null}>
                                        {item["new_assigned"]==0?
                                        <ButtonCard label="Agregar" style="primary" clickEvent={()=>{activate(i)}} />:
                                        <ButtonCard label="Eliminar" style="secondary" clickEvent={()=>{deactivate(i)}} />}
                                    </CardRow>
                                )
                            })
                        :<CardRow label={"Aún no hay medios de pago asignados."} detail="" />}
                    </Card>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}