import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useApi from "../hooks/useApi"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Dropdown from "../commons/Dropdown"
import SwitchButton from "../commons/SwitchButton"
import close from "../assets/close-pink.svg"

export default function UsersFilters(){

    const {manageFloating,floatingReady}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const requestApi=useApi()
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const filter4=useRef()
    const filter5=useRef()
    const filter6=useRef()
    const filter7=useRef()
    const filter8=useRef()
    const filter9=useRef()
    const filter10=useRef()
    const filter11=useRef()
    const filter12=useRef()
    const [filter1Val,setFilter1Val]=useState([])
    const [filter2Val,setFilter2Val]=useState("")
    const [filter3Val,setFilter3Val]=useState("")
    const [filter4Val,setFilter4Val]=useState("")
    const [filter5Val,setFilter5Val]=useState("")
    const [filter6Val,setFilter6Val]=useState("")
    const [filter7Val,setFilter7Val]=useState([])
    const [filter8Val,setFilter8Val]=useState([])
    const [filter9Val,setFilter9Val]=useState([])
    const [filter10Val,setFilter10Val]=useState("")
    const [filter11Val,setFilter11Val]=useState("")
    const [filter12Val,setFilter12Val]=useState("")

    const [cityOptions,setCityOptions]=useState([])
    const [regionOptions,setRegionOptions]=useState([])
    const [countryOptions,setCountryOptions]=useState([])

    const loadCities=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/cities",{context:"users"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(city){
                            newOptions.push({label:city.name,value:city.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadRegions=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/regions",{context:"users"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(region){
                            newOptions.push({label:region.name,value:region.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadCountries=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/countries",{context:"users"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(country){
                            newOptions.push({label:country.name,value:country.name})
                        })
                        return newOptions
                    })
            )
        })
    }

    useEffect(()=>{
        floatingReady(0)
        Promise.all([loadCities,loadRegions,loadCountries].map(func=>func())).then((result)=>{
            setCityOptions(result[0])
            setRegionOptions(result[1])
            setCountryOptions(result[2])
            floatingReady(1)
        })
    },[])

    const apply=()=>{
        let filter7List=[]
        if(filter7.current.getValue()){
            filter7.current.getValue().forEach(function(item){
                filter7List.push(item["value"])
            })
        }
        let filter8List=[]
        if(filter8.current.getValue()){
            filter8.current.getValue().forEach(function(item){
                filter8List.push(item["value"])
            })
        }
        let filter9List=[]
        if(filter9.current.getValue()){
            filter9.current.getValue().forEach(function(item){
                filter9List.push(item["value"])
            })
        }
        setValue({
            status:filter1Val,
            activity:filter2.current.getValue()[0]?filter2.current.getValue()[0].value:"",
            transactions:filter3Val,
            created:filter4.current.getValue()[0]?filter4.current.getValue()[0].value:"",
            month:filter5.current.getValue()[0]?filter5.current.getValue()[0].value:"",
            year:filter6.current.getValue()[0]?filter6.current.getValue()[0].value:"",
            city:filter7List,
            region:filter8List,
            country:filter9List,
            device:filter10.current.getValue()[0]?filter10.current.getValue()[0].value:"",
            os:filter11.current.getValue()[0]?filter11.current.getValue()[0].value:"",
            browser:filter12.current.getValue()[0]?filter12.current.getValue()[0].value:""
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val([])
        setFilter2Val("")
        setFilter3Val("")
        setFilter4Val("")
        setFilter5Val("")
        setFilter6Val("")
        setFilter7Val([])
        setFilter8Val([])
        setFilter9Val([])
        setFilter10Val("")
        setFilter11Val("")
        setFilter12Val("")
        filter2.current.setValue("")
        filter4.current.setValue("")
        filter5.current.setValue("")
        filter6.current.setValue("")
        filter7.current.setValue("")
        filter8.current.setValue("")
        filter9.current.setValue("")
        filter10.current.setValue("")
        filter11.current.setValue("")
        filter12.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(filter4Val!="" && filter4Val!=undefined){
            restrict("period")
        }
    },[filter4Val])

    useEffect(()=>{
        if((filter5Val!="" && filter5Val!=undefined) || (filter6Val!="" && filter6Val!=undefined)){
            restrict("month_year")
        }
    },[filter5Val])

    useEffect(()=>{
        if((filter5Val!="" && filter5Val!=undefined) || (filter6Val!="" && filter6Val!=undefined)){
            restrict("month_year")
        }
    },[filter6Val])

    const restrict=(filter)=>{
        if(filter=="period"){
            setFilter5Val("")
            filter5.current.setValue("")
            setFilter6Val("")
            filter6.current.setValue("")
        }else if(filter=="month_year"){
            setFilter4Val("")
            filter4.current.setValue("")
        }
    }

    useEffect(()=>{
        if(value!=""){
            if(value["status"].length>0){
                setFilter1Val(value["status"])
            }
            if(value["activity"]!=""){
                filter2.current.props["options"].forEach(function(item){
                    if(value["activity"]==item.value){
                        filter2.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["transactions"].length>0){
                setFilter3Val(value["transactions"])
            }
            if(value["created"]!=""){
                filter4.current.props["options"].forEach(function(item){
                    if(value["created"]==item.value){
                        filter4.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["month"]!=""){
                filter5.current.props["options"].forEach(function(item){
                    if(value["month"]==item.value){
                        filter5.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["year"]!=""){
                filter6.current.props["options"].forEach(function(item){
                    if(value["year"]==item.value){
                        filter6.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["device"]!=""){
                filter10.current.props["options"].forEach(function(item){
                    if(value["device"]==item.value){
                        filter10.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["os"]!=""){
                filter11.current.props["options"].forEach(function(item){
                    if(value["os"]==item.value){
                        filter11.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["browser"]!=""){
                filter12.current.props["options"].forEach(function(item){
                    if(value["browser"]==item.value){
                        filter12.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
        }
    },[value])

    useEffect(()=>{
        if(value){
            if(value["city"].length>0){
                let newValue=[];
                filter7.current.props["options"].forEach(function(item){
                    if(value["city"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter7.current.setValue(newValue)
            }
        }
    },[cityOptions])

    useEffect(()=>{
        if(value){
            if(value["region"].length>0){
                let newValue=[];
                filter8.current.props["options"].forEach(function(item){
                    if(value["region"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter8.current.setValue(newValue)
            }
        }
    },[regionOptions])

    useEffect(()=>{
        if(value){
            if(value["country"].length>0){
                let newValue=[];
                filter9.current.props["options"].forEach(function(item){
                    if(value["country"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter9.current.setValue(newValue)
            }
        }
    },[countryOptions])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-options floating-options-space">
                    <div className="floating-half">
                        <SwitchButton ref={filter1} val={filter1Val} setVal={setFilter1Val} multiple={true}
                            options={[
                                {label:"Activos",value:"active"},
                                {label:"Inactivos",value:"inactive"},
                                {label:"Bloqueados",value:"blocked"},
                                {label:"Verificados",value:"verified"},
                                {label:"Sin verificar",value:"unverified"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Última actividad</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter2} val={filter2Val} setVal={setFilter2Val} placeholder="Período" multiple={0} searchable={0} options={[
                            {label:"Indiferente",value:""},
                            {label:"Hace una hora",value:"hour_ago"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Esta semana",value:"this_week"},
                            {label:"Hace quince días",value:"15_days_ago"},
                            {label:"Hace un mes",value:"month_ago"},
                            {label:"Hace tres meses",value:"three_months_ago"},
                            {label:"Hace seis meses",value:"six_months_ago"},
                            {label:"Hace un año",value:"year_ago"},
                            {label:"Más de un año",value:"more_than_year"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Cantidad de transacciones</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter3} val={filter3Val} setVal={setFilter3Val}
                            options={[
                                {label:"Ninguna",value:"none"},
                                {label:"Sólo una",value:"one"},
                                {label:"Entre 10~50",value:"10_50"},
                                {label:"Entre 50~100",value:"50_100"},
                                {label:"Más de 100",value:"100"},
                                {label:"Más de 500",value:"500"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Fecha de registro</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter4} val={filter4Val} setVal={setFilter4Val} placeholder="Período" multiple={0} searchable={0} options={[
                            {label:"Indiferente",value:""},
                            {label:"Última hora",value:"last_hour"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Última semana",value:"last_week"},
                            {label:"Últimos quince días",value:"last_15_days"},
                            {label:"Este mes",value:"this_month"},
                            {label:"Últimos tres meses",value:"last_three_months"},
                            {label:"Últimos seis meses",value:"last_six_months"},
                            {label:"Este año",value:"this_year"}
                        ]} />
                    </div>
                    <div className="floating-half" >
                        <Dropdown ref={filter5} val={filter5Val} setVal={setFilter5Val} placeholder="Mes" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"Enero",value:"01"},
                            {label:"Febrero",value:"02"},
                            {label:"Marzo",value:"03"},
                            {label:"Abril",value:"04"},
                            {label:"Mayo",value:"05"},
                            {label:"Junio",value:"06"},
                            {label:"Julio",value:"07"},
                            {label:"Agosto",value:"08"},
                            {label:"Septiembre",value:"09"},
                            {label:"Octubre",value:"10"},
                            {label:"Noviembre",value:"11"},
                            {label:"Diciembre",value:"12"}
                        ]} />
                        <Dropdown ref={filter6} val={filter6Val} setVal={setFilter6Val} placeholder="Año" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"2022",value:"2022"},
                            {label:"2023",value:"2023"},
                            {label:"2024",value:"2024"},
                            {label:"2025",value:"2025"},
                            {label:"2026",value:"2026"},
                            {label:"2027",value:"2027"},
                            {label:"2028",value:"2028"},
                            {label:"2029",value:"2029"},
                            {label:"2030",value:"2030"},
                            {label:"2031",value:"2031"},
                            {label:"2032",value:"2032"},
                            {label:"2033",value:"2033"},
                            {label:"2034",value:"2034"},
                            {label:"2034",value:"2034"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Ubicación</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter7} val={filter7Val} setVal={setFilter7Val} placeholder="Ciudad" multiple={1} searchable={1} options={cityOptions} />
                        <Dropdown ref={filter8} val={filter8Val} setVal={setFilter8Val} placeholder="Provincia" multiple={1} searchable={1} options={regionOptions} />
                        <Dropdown ref={filter9} val={filter9Val} setVal={setFilter9Val} placeholder="País" multiple={1} searchable={0} options={countryOptions} />
                    </div>
                </div>
                <div className="floating-group">Dispositivos</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter10} val={filter10Val} setVal={setFilter10Val} placeholder="Tipo" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"Computadora",value:"browser"},
                            {label:"Teléfono",value:"mobile"},
                            {label:"Tablet",value:"tablet"},
                            {label:"Smart TV",value:"smart_tv"},
                            {label:"Consola de juegos",value:"console"},
                            {label:"Otros",value:"others"}
                        ]} />
                        <Dropdown ref={filter11} val={filter11Val} setVal={setFilter11Val} placeholder="Sistema operativo" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"Windows",value:"windows"},
                            {label:"Mac OS",value:"mac_os"},
                            {label:"Linux",value:"linux"},
                            {label:"Android",value:"android"},
                            {label:"iOS",value:"ios"},
                            {label:"Windows Phone",value:"windows_phone"},
                            {label:"Otros",value:"others"}
                        ]} />
                        <Dropdown ref={filter12} val={filter12Val} setVal={setFilter12Val} placeholder="Navegador" multiple={0} searchable={0} options={[
                            {label:"Todos",value:""},
                            {label:"Chrome",value:"chrome"},
                            {label:"Edge Chromium",value:"edge_chromium"},
                            {label:"Firefox",value:"firefox"},
                            {label:"Safari",value:"safari"},
                            {label:"Mobile Safari",value:"mobile_safari"},
                            {label:"Opera",value:"opera"},
                            {label:"Chromium",value:"chromium"},
                            {label:"Edge",value:"edge"},
                            {label:"Internet Explorer",value:"internet_explorer"},
                            {label:"Yandex",value:"yandex"},
                            {label:"MIUI Browser",value:"miui_browser"},
                            {label:"Samsung Browser",value:"samsung_browser"},
                            {label:"Otros",value:"others"},
                        ]} />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}