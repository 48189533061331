import {useContext} from "react"
import axios from "axios"
import GlobalContext from "../contexts/GlobalContext"
import useLoader from "../hooks/useLoader"

export default function useApi(){

    const [globalContext]=useContext(GlobalContext)
    const {manageLoader}=useLoader()

    const requestApi=async (endpoint,post)=>{

        let url=globalContext.base_url+"api/"+endpoint

        manageLoader("show")
        if(post.not_loader===true){
            manageLoader("hide")
        }

        let postData={
            ...post,
            session_token:globalContext.session_token
        }
        if(post.upload){
            postData=new FormData()
            for(var key in post){
                postData.append(key,post[key])
            }
            postData.append("session_token",globalContext.session_token)
        }
        
        return new Promise((resolve,reject)=>{
            axios({
                method:"post",
                url:url,
                data:postData,
                headers:post.upload?{"Content-Type":"multipart/form-data"}:null
            }).then((response)=>{
                if(globalContext.dev_mode){
                    console.log(endpoint)
                    console.log(postData)
                    console.log(response.data)
                    console.log("-------------------------")
                }
                manageLoader("hide")
                resolve(response.data)
            }).catch((error)=>{
                manageLoader("hide")
                reject(error)
            })
        })

    }

    return requestApi

}