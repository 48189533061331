import searchIcon from "../assets/search.svg"

export default function Searcher({placeholder,keyword,setKeyword}){

    const handleChange=event=>{
        setKeyword(event.target.value)
    }

    return(
        <div className="searcher">
            <input className="searcher-box" type="text" value={keyword} onChange={handleChange} placeholder={placeholder} />
            <div className="searcher-icon"><img src={searchIcon} /></div>
        </div>
    )
    
}