import {useContext} from "react"
import ModalContext from "../contexts/ModalContext"
import UserInfo from "../modal/UserInfo"
import UserEdit from "../modal/UserEdit"
import UserPassword from "../modal/UserPassword"
import UserLocation from "../modal/UserLocation"
import CardEdit from "../modal/CardEdit"
import EntityEdit from "../modal/EntityEdit"
import FaqsInfo from "../modal/FaqsInfo"
import FaqsEdit from "../modal/FaqsEdit"
import FaqsGroupEdit from "../modal/FaqsGroupEdit"
import NewsEdit from "../modal/NewsEdit"
import LinkEdit from "../modal/LinkEdit"
import LinkImport from "../modal/LinkImport"
import PaymentEdit from "../modal/PaymentEdit"
import ShopEdit from "../modal/ShopEdit"
import ShopBillingEdit from "../modal/ShopBillingEdit"
import ShopContact from "../modal/ShopContact"
import ShopPoint from "../modal/ShopPoint"
import ShopPointEdit from "../modal/ShopPointEdit"
import ShopAffiliationEdit from "../modal/ShopAffiliationEdit"
import ShopCredentialsEdit from "../modal/ShopCredentialsEdit"
import ShopAccessToken from "../modal/ShopAccessToken"
import ShopBarcodeEdit from "../modal/ShopBarcodeEdit"
import ShopPayments from "../modal/ShopPayments"
import ShopInstallments from "../modal/ShopInstallments"
import ShopInstallmentEdit from "../modal/ShopInstallmentEdit"
import ShopAssign from "../modal/ShopAssign"
import TagEdit from "../modal/TagEdit"
import AgreementEdit from "../modal/AgreementEdit"
import Share from "../modal/Share"
import Download from "../modal/Download"
import DownloadLinks from "../modal/DownloadLinks"
import SubscriptionEdit from "../modal/SubscriptionEdit"
import TicketEdit from "../modal/TicketEdit"
import TicketInfo from "../modal/TicketInfo"
import TransactionInfo from "../modal/TransactionInfo"
import TransactionAnswer from "../modal/TransactionAnswer"
import TransactionRefund from "../modal/TransactionRefund"
import TransactionLiquidate from "../modal/TransactionLiquidate"
import LiquidationInfo from "../modal/LiquidationInfo"
import RejectionInfo from "../modal/RejectionInfo"
import ProjectionInfo from "../modal/ProjectionInfo"
import Record from "../modal/Record"
import Vouchers from "../modal/Vouchers"
import Voucher from "../modal/Voucher"
import Contact from "../modal/Contact"
import About from "../modal/About"
import Faqs from "../modal/Faqs"
import ArticleEdit from "../modal/ArticleEdit"
import MessageInfo from "../modal/MessageInfo"

export default function Modal(){

    const [modalContext]=useContext(ModalContext)

    return(
        <div id="modal" className="modal">
            <div className="modal-overlay"></div>
            <div className="modal-frame">
                <div className="modal-window">
                    {modalContext.modal=="user-info" && <UserInfo />}
                    {modalContext.modal=="user-edit" && <UserEdit />}
                    {modalContext.modal=="user-location" && <UserLocation />}
                    {modalContext.modal=="user-password" && <UserPassword />}
                    {modalContext.modal=="shop-edit" && <ShopEdit />}
                    {modalContext.modal=="shop-billing-edit" && <ShopBillingEdit />}
                    {modalContext.modal=="shop-contact" && <ShopContact />}
                    {modalContext.modal=="shop-point" && <ShopPoint />}
                    {modalContext.modal=="shop-point-edit" && <ShopPointEdit />}
                    {modalContext.modal=="shop-affiliation-edit" && <ShopAffiliationEdit />}
                    {modalContext.modal=="shop-credentials-edit" && <ShopCredentialsEdit />}
                    {modalContext.modal=="shop-access-token" && <ShopAccessToken />}
                    {modalContext.modal=="shop-barcode-edit" && <ShopBarcodeEdit />}
                    {modalContext.modal=="shop-payments" && <ShopPayments />}
                    {modalContext.modal=="shop-installments" && <ShopInstallments />}
                    {modalContext.modal=="shop-installment-edit" && <ShopInstallmentEdit />}
                    {modalContext.modal=="shop-assign" && <ShopAssign />}
                    {modalContext.modal=="tag-edit" && <TagEdit />}
                    {modalContext.modal=="agreement-edit" && <AgreementEdit />}
                    {modalContext.modal=="share" && <Share />}
                    {modalContext.modal=="download" && <Download />}
                    {modalContext.modal=="download-links" && <DownloadLinks />}
                    {modalContext.modal=="subscription-edit" && <SubscriptionEdit />}
                    {modalContext.modal=="link-edit" && <LinkEdit />}
                    {modalContext.modal=="link-import" && <LinkImport />}
                    {modalContext.modal=="payment-edit" && <PaymentEdit />}
                    {modalContext.modal=="card-edit" && <CardEdit />}
                    {modalContext.modal=="entity-edit" && <EntityEdit />}
                    {modalContext.modal=="faqs-info" && <FaqsInfo />}
                    {modalContext.modal=="faqs-edit" && <FaqsEdit />}
                    {modalContext.modal=="faqs-group-edit" && <FaqsGroupEdit />}
                    {modalContext.modal=="news-edit" && <NewsEdit />}
                    {modalContext.modal=="ticket-edit" && <TicketEdit />}
                    {modalContext.modal=="ticket-info" && <TicketInfo />}
                    {modalContext.modal=="transaction-info" && <TransactionInfo />}
                    {modalContext.modal=="transaction-answer" && <TransactionAnswer />}
                    {modalContext.modal=="transaction-refund" && <TransactionRefund />}
                    {modalContext.modal=="transaction-liquidate" && <TransactionLiquidate />}
                    {modalContext.modal=="record" && <Record />}
                    {modalContext.modal=="vouchers" && <Vouchers />}
                    {modalContext.modal=="voucher" && <Voucher />}
                    {modalContext.modal=="liquidation-info" && <LiquidationInfo />}
                    {modalContext.modal=="rejection-info" && <RejectionInfo />}
                    {modalContext.modal=="projection-info" && <ProjectionInfo />}
                    {modalContext.modal=="contact" && <Contact />}
                    {modalContext.modal=="about" && <About />}
                    {modalContext.modal=="faqs" && <Faqs />}
                    {modalContext.modal=="article-edit" && <ArticleEdit />}
                    {modalContext.modal=="message-info" && <MessageInfo />}
                    {!modalContext.ready &&<div className="modal-disabled"></div>}
                </div>
            </div>
        </div>
    )
    
}