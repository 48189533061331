import {useState,useEffect} from "react"
import {BrowserRouter,Route,Routes} from "react-router-dom"
import GlobalContext from "./contexts/GlobalContext"
import RefreshContext from "./contexts/RefreshContext"
import LoaderContext from "./contexts/LoaderContext"
import ModalContext from "./contexts/ModalContext"
import DialogContext from "./contexts/DialogContext"
import FloatingContext from "./contexts/FloatingContext"
import ToastContext from "./contexts/ToastContext"
import "./App.css"
import Home from "./views/Home"
import Blog from "./views/Blog"
import Article from "./views/Article"
import NotFound from "./views/NotFound"
import Login from "./views/Login"
import Activation from "./views/Activation"
import PasswordCreate from "./views/PasswordCreate"
import PasswordReset from "./views/PasswordReset"
import Verification from "./views/Verification"
import Dashboard from "./views/Dashboard"
import Start from "./sections/Start"
import News from "./sections/News"
import NewsAdm from "./sections/NewsAdm"
import Activity from "./sections/Activity"
import Liquidations from "./sections/Liquidations"
import Records from "./sections/Records"
import Shops from "./sections/Shops"
import Shop from "./sections/Shop"
import Agreements from "./sections/Agreements"
import Tags from "./sections/Tags"
import Reports from "./sections/Reports"
import Analytics from "./sections/Analytics"
import Payments from "./sections/Payments"
import Cards from "./sections/Cards"
import Entities from "./sections/Entities"
import Users from "./sections/Users"
import Webpos from "./sections/Webpos"
import Links from "./sections/Links"
import Subscriptions from "./sections/Subscriptions"
import Subscribers from "./sections/Subscribers"
import Qr from "./sections/Qr"
import Integrations from "./sections/Integrations"
import Plugins from "./sections/Plugins"
import Support from "./sections/Support"
import Faqs from "./sections/Faqs"
import FaqsTopics from "./sections/FaqsTopics"
import FaqsAdm from "./sections/FaqsAdm"
import FaqsAdmTopics from "./sections/FaqsAdmTopics"
import Tickets from "./sections/Tickets"
import TicketsAdm from "./sections/TicketsAdm"
import Messages from "./sections/Messages"
import Articles from "./sections/Articles"
import Pay from "./views/Pay"

export default function App(){

    const devMode= process.env.REACT_APP_APP_ENV === 'local'

    const [globalContext,setGlobalContext]=useState({
        dev_mode:devMode,
        initiated:false,
        refresh:0,
        base_url: devMode === false ? "https://cajero24.co/" : process.env.REACT_APP_API_URL,
        user_id:0,
        user_name:"",
        user_capitals:"",
        user_has_picture:0,
        session_token:"",
        session_token_id:0,
        profile:"",
        panel_opened:0
    })

    const [refreshContext,setRefreshContext]=useState({
        list:{refresh:0,reference:""},
        info:{refresh:0,reference:""},
        avatar:{refresh:0,reference:{element:"",id:""}}
    })

    const [loaderContext,setLoaderContext]=useState({
        show:0
    })

    const [modalContext,setModalContext]=useState({
        modal:"",
        context:"",
        reference:"",
        ready:1
    })

    const [dialogContext,setDialogContext]=useState({
        dialog:"",
        reference:"",
        action:""
    })

    const [floatingContext,setFloatingContext]=useState({
        floating:"",
        context:"",
        reference:"",
        value:"",
        setValue:"",
        ready:1
    })

    const [toastContext,setToastContext]=useState({
        toast:"",
        params:""
    })

    useEffect(()=>{

        //Detect touch (mouse hover event)
        var isTouch=false
        var isTouchTimer
        var touchClass=""

        //Touch events on Safari
        document.addEventListener("touchstart",function(){},true)

        //Detect touch input
        document.addEventListener("touchstart",function(event){
            clearTimeout(isTouchTimer)
            isTouch=true
            if(touchClass!=="touch"){
                touchClass="touch"
                document.body.className="touch"
            }
            isTouchTimer=setTimeout(function(){isTouch=false},2000)
        },false)

        //Detect all inputs (touch, mouse, trackpad)
        document.addEventListener("mouseover",function(event){
            if(!isTouch && touchClass==="touch"){
                isTouch=false
                touchClass=""
                document.body.className=""
            }
        },false)

        //Service worker
        if("serviceWorker" in navigator){
            navigator.serviceWorker.register("/sw.js",{
                scope:"."
            }).then(function(registration){
                //Registration was successful
            },function(err){
                //Registration failed
            })
        }

    },[])

    return(
        <GlobalContext.Provider value={[globalContext,setGlobalContext]}>
        <RefreshContext.Provider value={[refreshContext,setRefreshContext]}>
        <LoaderContext.Provider value={[loaderContext,setLoaderContext]}>
        <ModalContext.Provider value={[modalContext,setModalContext]}>
        <DialogContext.Provider value={[dialogContext,setDialogContext]}>
        <FloatingContext.Provider value={[floatingContext,setFloatingContext]}>
        <ToastContext.Provider value={[toastContext,setToastContext]}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="blog/:alias" element={<Article />} />
                    <Route path="/entrar" element={<Login />} />
                    <Route path="/registrar" element={<Activation />} />
                    <Route path="/activar" element={<PasswordCreate />} />
                    <Route path="/resetear" element={<PasswordReset />} />
                    <Route path="/verificar" element={<Verification />} />
                    <Route path="/p/:context/:reference" element={<Pay />} />
                    <Route path="/c24" element={<Dashboard />}>
                        <Route path="tablero" element={<Start />} />
                        <Route path="tablero/novedades" element={<News />} />
                        <Route path="actividad" element={<Activity />} />
                        <Route path="actividad/liquidaciones" element={<Liquidations />} />
                        <Route path="actividad/cuenta" element={<Records />} />
                        <Route path="comercios" element={<Shops />} />
                        <Route path="comercios/:shopId" element={<Shop />} />
                        <Route path="comercios/etiquetas" element={<Tags />} />
                        <Route path="comercios/acuerdos" element={<Agreements />} />
                        <Route path="reportes" element={<Reports />} />
                        <Route path="reportes/analitica" element={<Analytics />} />
                        <Route path="medios-de-pago" element={<Payments />} />
                        <Route path="medios-de-pago/tarjetas" element={<Cards />}/ >
                        <Route path="medios-de-pago/entidades" element={<Entities />} />
                        <Route path="usuarios" element={<Users />} />
                        <Route path="webpos" element={<Webpos />} />
                        <Route path="links-de-pago" element={<Links />} />
                        <Route path="suscripciones" element={<Subscriptions />} />
                        <Route path="suscripciones/:subscriptionId" element={<Subscribers />} />
                        <Route path="pagos-con-qr" element={<Qr />} />
                        <Route path="integraciones" element={<Integrations />} />
                        <Route path="complementos" element={<Plugins />} />
                        <Route path="soporte" element={<Support />} />
                        <Route path="soporte/faqs" element={<Faqs />} />
                        <Route path="soporte/faqs/:groupId" element={<FaqsTopics />} />
                        <Route path="soporte/tickets" element={<Tickets />} />
                        <Route path="asistencia" element={<TicketsAdm />} />
                        <Route path="asistencia/faqs" element={<FaqsAdm />} />
                        <Route path="asistencia/faqs/:groupId" element={<FaqsAdmTopics />} />
                        <Route path="asistencia/novedades" element={<NewsAdm />} />
                        <Route path="mensajes" element={<Messages />} />
                        <Route path="articulos" element={<Articles />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </BrowserRouter>
        </ToastContext.Provider>
        </FloatingContext.Provider>
        </DialogContext.Provider>
        </ModalContext.Provider>
        </LoaderContext.Provider>
        </RefreshContext.Provider>
        </GlobalContext.Provider>
    )
}