import {Fragment} from "react"
import {NavLink} from "react-router-dom"
import ArrowImg from "../assets/arrow.svg"

export default function ButtonBox({label,clickEvent,to,link}){

    return(
        <Fragment>
            {clickEvent!="" && <div className="box-button" onClick={clickEvent}>{label}<img src={ArrowImg} /></div>}
            {to!="" && <NavLink className="box-button" to={to}>{label}<img src={ArrowImg} /></NavLink>}
            {link!="" && <a className="box-button" href={link} target="_blank">{label}<img src={ArrowImg} /></a>}
        </Fragment>
    )
    
}