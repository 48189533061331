import {forwardRef,useEffect, Fragment} from "react"
import WebposInput from "../commons/WebposInput"
import eraserIcon from "../assets/eraser.svg";

const WebposItem=forwardRef(({detailVal,setDetailVal,referenceVal,setReferenceVal,amountVal,setAmountVal,itemVal,setItemVal,main,shown},ref)=>{

    useEffect(()=>{
        setItemVal({detail:detailVal,reference:referenceVal,number:parseFloat(amountVal["number"]),formatted:amountVal["formatted"]})
    },[amountVal,detailVal,referenceVal])

    const eraser=()=>{
        setDetailVal("")
        setReferenceVal("")
        setAmountVal({number:0,formatted:""})
    }

    return(
        <Fragment>
            {main==1?
            <div>
                <div className="webpos-concept">
                    <div className="webpos-concept-detail">
                        <WebposInput val={detailVal} setVal={setDetailVal} mode="text" type="detail" context="main" label="Concepto:" />
                    </div>
                    <div className="webpos-concept-reference">
                        <WebposInput val={referenceVal} setVal={setReferenceVal} mode="text" type="reference" context="main" label="Referencia:" />
                    </div>
                </div>
                <div className="webpos-block">
                    <div className="webpos-label">¿Cuánto vas a cobrar?</div>
                    <WebposInput val={amountVal} setVal={setAmountVal} mode="number" type="amount" context="main" label="$0,00" />
                </div>
            </div>:
            <div ref={ref} className="webpos-item" style={shown?{"display":"flex"}:{"display":"none"}}>
                <div className="webpos-item-detail">
                    <WebposInput val={detailVal} setVal={setDetailVal} mode="text" type="detail" context="item" label="Concepto:" />
                </div>
                <div className="webpos-item-reference">
                    <WebposInput val={referenceVal} setVal={setReferenceVal} mode="text" type="reference" context="item" label="Referencia:" />
                </div>
                <div className="webpos-item-amount">
                    <WebposInput val={amountVal} setVal={setAmountVal} mode="number" type="amount" context="item" label="$0,00" />
                </div>
                <div className={(detailVal=="" && referenceVal=="" && amountVal["formatted"]=="")?"webpos-item-eraser webpos-item-eraser-off":"webpos-item-eraser"} title="Borrar" onClick={eraser}><img src={eraserIcon} /></div>
            </div>}
        </Fragment>
    )
    
})

export default WebposItem