import {useRef,useEffect} from "react"
import {Link} from "react-router-dom"
import Networks from "../components/Networks"
import brandImg from "../assets/brand-inverse.svg"
import addressImg from "../assets/address.svg"
import emailImg from "../assets/email.svg"
import phoneImg from "../assets/phone.svg"
import arrowImg from "../assets/arrow-blue.svg"

export default function HomeFooter({context,contactOpen,aboutOpen,faqsOpen}){

    const map=useRef()

    const googleMapsChecker=()=>{
        if(!window.google.maps){
            setTimeout(googleMapsChecker,100)
        }else{
            let gmap=new window.google.maps.Map(map.current,{
                zoom:16,
                center:{
                    lat:-32.8899379,
                    lng:-68.8408485
                },
                disableDefaultUI:true,
                clickableIcons:false,
                draggable:true,
                keyboardShortcuts:false,
                scrollwheel:false,
                styles:[
                    {"featureType":"poi","stylers":[{"visibility":"off"}]},
                    {"featureType":"transit","stylers":[{"visibility":"off"}]}
                ]
            })
            let marker=new window.google.maps.Marker({
                map:gmap,
                position:{
                    lat:-32.8899379,
                    lng:-68.8408485
                },
                draggable:false
            })
        }
    }

    useEffect(()=>{
        googleMapsChecker()
    },[])

    return(
        <div className="footer noselect">
            <div className="footer-networks-title">¡Seguinos en nuestras redes!</div>
            <div className="footer-networks-links">
                <Networks context="home" />
            </div>
            <div className="footer-divisor"><div></div></div>
            <div className="footer-layout">
                <div className="footer-logo">
                    <div className="footer-brand">
                        <Link className="footer-brand-link" to="/">
                            <img src={brandImg} />
                        </Link>
                    </div>
                    <div className="footer-caption">
                        Facilitamos el procesamiento de transacciones digitales. Nuestros servicios son utilizados por gobiernos, empresas, asociaciones profesionales, instituciones públicas y privadas.
                    </div>
                </div>
                <div className="footer-contact">
                    <div>
                        <div className="footer-title">Contacto</div>
                        <div className="footer-map">
                            <div className="footer-gmap" ref={map}></div>
                        </div>                        
                        <a className="footer-contact-link" href="https://goo.gl/maps/c46mVEHMFm3DgVoFA" target="_blank">
                            <div><img src={addressImg} /></div>
                            <div>9 de Julio 1126, Ciudad de Mendoza, Argentina (M5500DOX)</div>
                        </a><br />
                        <a className="footer-contact-link" href="https://wa.me/542612185757" target="_blank">
                            <div><img src={phoneImg} /></div>
                            <div>+54 261 2185757</div>
                        </a><br />
                        <a className="footer-contact-link" href="mailto:hola@cajero24.co">
                            <div><img src={emailImg} /></div>
                            <div>hola@cajero24.co</div>
                        </a>
                    </div>
                </div>
                <div className="footer-nav">
                    <div className="footer-title">Contenido</div>
                    <a className="footer-nav-link" href="" onClick={(e)=>{aboutOpen(e)}}>
                        <div><img src={arrowImg} /></div>
                        <div>Sobre Cajero24</div>
                    </a><br />
                    {context=="home"?
                    <a className="footer-nav-link" href="#canales-de-cobro">
                        <div><img src={arrowImg} /></div>
                        <div>Canales de cobro</div>
                    </a>:
                    <Link className="footer-nav-link" to="/">
                        <div><img src={arrowImg} /></div>
                        <div>Canales de cobro</div>
                    </Link>}<br />
                    {context=="home"?
                    <a className="footer-nav-link" href="#entidades">
                        <div><img src={arrowImg} /></div>
                        <div>Entidades</div>
                    </a>:
                    <Link className="footer-nav-link" to="/">
                        <div><img src={arrowImg} /></div>
                        <div>Entidades</div>
                    </Link>}<br />
                    {context=="home"?
                    <a className="footer-nav-link" href="#precios">
                        <div><img src={arrowImg} /></div>
                        <div>Precios</div>
                    </a>:
                    <Link className="footer-nav-link" to="/">
                        <div><img src={arrowImg} /></div>
                        <div>Precios</div>
                    </Link>}<br />
                    <a className="footer-nav-link" href="" onClick={(e)=>{contactOpen(e)}}>
                        <div><img src={arrowImg} /></div>
                        <div>Contacto</div>
                    </a><br />
                    <Link className="footer-nav-link" to="/blog">
                        <div><img src={arrowImg} /></div>
                        <div>Blog</div>
                    </Link><br />
                    <a className="footer-nav-link" href="" onClick={(e)=>{faqsOpen(e)}}>
                        <div><img src={arrowImg} /></div>
                        <div>Preguntas frecuentes</div>
                    </a><br />
                    <Link className="footer-nav-link" to="/entrar">
                        <div><img src={arrowImg} /></div>
                        <div>Acceso clientes</div>
                    </Link>
                </div>
            </div>
            <div className="footer-copy"><strong>Cajero24 ©2023.</strong> Todos los derechos reservados.</div>
        </div>
    )
    
}