import {forwardRef,useEffect} from "react"
import AirDatepicker from "air-datepicker"
import "../assets/air-datepicker.css"
import localeEs from "air-datepicker/locale/es"
import calendar from "../assets/calendar.svg"

const Datepicker=forwardRef(({label,val,setVal},ref)=>{

    const change=(event)=>{
        setVal(event.target.value)
    }

    useEffect(()=>{
        if(val==""){
            ref.current.classList.remove("datepicker-textbox-on")
        }else{
            ref.current.classList.add("datepicker-textbox-on")
        }
    },[val])

    useEffect(()=>{
        let datepicker=new AirDatepicker(ref.current,{
            locale:localeEs,
            isMobile:true,
            dateFormat:"dd/MM/yyyy",
            autoClose:true,
            buttons:["clear"],
            onSelect:({formattedDate})=>{
                if(formattedDate==undefined){
                    setVal("")
                }else{
                    setVal(formattedDate)
                }
            }
        })
    },[])

    return(
        <div className="datepicker">
            <input ref={ref} className="datepicker-textbox" value={val} placeholder={label} onChange={change} />
            <div className="datepicker-icon"><img src={calendar} /></div>
        </div>
    )
    
})

export default Datepicker