import {Fragment} from "react"
import {NavLink} from "react-router-dom"

export default function EntranceHelp({label,clickEvent,link}){

    return(
        <Fragment>
            {link!="" &&
            <NavLink className="entrance-help" target="_blank" to={link}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><circle cx="10" cy="10" r="10" fill="#624de3"/><path d="M9.93,12.91H9.06a.26.26,0,0,1-.29-.24v0a8.92,8.92,0,0,1,.17-2.25,2.12,2.12,0,0,1,.54-1c.24-.25.5-.49.76-.72L11.1,8a1,1,0,0,0,.16-1.42,1.06,1.06,0,0,0-.37-.29,1.68,1.68,0,0,0-2.28.69.61.61,0,0,0-.07.14,4,4,0,0,0-.15.45A.29.29,0,0,1,8,7.79L6.32,7.58A.3.3,0,0,1,6,7.27v0a3.5,3.5,0,0,1,2.91-3.1,5,5,0,0,1,3,.28,3.25,3.25,0,0,1,1.83,1.91h0a2.52,2.52,0,0,1-.39,2.53A8.88,8.88,0,0,1,12,10.19c-.16.14-.31.29-.48.42h0a1.1,1.1,0,0,0-.39.88c0,.37,0,.74,0,1.1a.29.29,0,0,1-.26.32H9.93Z" fill="#fff"/><path d="M11.17,14.8A1.18,1.18,0,0,1,10,16a1.2,1.2,0,0,1-1.19-1.21A1.2,1.2,0,0,1,10.83,14,1.18,1.18,0,0,1,11.17,14.8Z" fill="#fff"/></svg>
                {label}
            </NavLink>}
            {clickEvent!="" &&
            <div className="entrance-help" onClick={clickEvent}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><circle cx="10" cy="10" r="10" fill="#624de3"/><path d="M9.93,12.91H9.06a.26.26,0,0,1-.29-.24v0a8.92,8.92,0,0,1,.17-2.25,2.12,2.12,0,0,1,.54-1c.24-.25.5-.49.76-.72L11.1,8a1,1,0,0,0,.16-1.42,1.06,1.06,0,0,0-.37-.29,1.68,1.68,0,0,0-2.28.69.61.61,0,0,0-.07.14,4,4,0,0,0-.15.45A.29.29,0,0,1,8,7.79L6.32,7.58A.3.3,0,0,1,6,7.27v0a3.5,3.5,0,0,1,2.91-3.1,5,5,0,0,1,3,.28,3.25,3.25,0,0,1,1.83,1.91h0a2.52,2.52,0,0,1-.39,2.53A8.88,8.88,0,0,1,12,10.19c-.16.14-.31.29-.48.42h0a1.1,1.1,0,0,0-.39.88c0,.37,0,.74,0,1.1a.29.29,0,0,1-.26.32H9.93Z" fill="#fff"/><path d="M11.17,14.8A1.18,1.18,0,0,1,10,16a1.2,1.2,0,0,1-1.19-1.21A1.2,1.2,0,0,1,10.83,14,1.18,1.18,0,0,1,11.17,14.8Z" fill="#fff"/></svg>
                {label}
            </div>}
        </Fragment>
    )
    
}