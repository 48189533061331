import {Fragment} from "react"
import {nl2p,humanDate} from "../hooks/Commons"
import Avatar from "../commons/Avatar"

export default function Message({message,type,connector}){

    return(
        <Fragment>
            {connector && <div className="message-connector"></div>}
            <div className={"message"+(type=="main"?" message-main":"")}>
                <div className="message-author">
                    <div className="message-author-layout">
                        <div className="message-author-avatar">
                            {message["user_data"]?<Avatar element="user" context="small" id={message["user_data"]["id"]} picture={message["user_data"]["has_picture"]} color="#B7FAFF" capitals={message["user_data"]["capitals"]} />
                            :<Avatar element="user" context="small" id="" picture="" color="#B7FAFF" capitals="" />}
                        </div>
                        <div className="message-author-info">
                            <div>
                                <div><strong>{message["user_data"]?message["user_data"]!=""?message["user_data"]["first_name"]+" "+message["user_data"]["last_name"]:"Desconocido"
                                :((message["name"]!=""?message["name"]:"‒")+" ("+(message["company"]!=""?message["company"]:"‒")+")")}</strong>
                                </div>
                                <div>{humanDate(message["created"])}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="message-content" dangerouslySetInnerHTML={{__html:nl2p(message["content"]?message["content"]:message["message"])}}></div>
            </div>
        </Fragment>
    )
    
}