import {useEffect,useState,useContext,Fragment} from "react"
import {useParams,Link} from "react-router-dom"
import {humanDate} from "../hooks/Commons"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import GlobalContext from "../contexts/GlobalContext"
import Blocks from "editorjs-blocks-react-renderer"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import HomeBar from "../components/HomeBar"
import HomeFooter from "../components/HomeFooter"
import next2Img from "../assets/next-4.svg"
import nextImg from "../assets/next.svg"

export default function Article(){

    const [globalContext]=useContext(GlobalContext)
    const params=useParams()
    const alias=params.alias
    const requestApi=useApi()
    const {changeModal}=useModal()
    const [article,setArticle]=useState()
    const [articles,setArticles]=useState()

    const contact=(e)=>{
        e.preventDefault()
        changeModal("contact","","")
    }

    const about=(e)=>{
        e.preventDefault()
        changeModal("about","","")
    }
    
    const faqs=(e)=>{
        e.preventDefault()
        changeModal("faqs","","")
    }

    useEffect(()=>{
        window.scrollTo(0,0)
        requestApi("blog/article",{alias:alias})
            .then(data=>{
                setArticle(data.data)
            })
        requestApi("blog/articles",{context:"article",exclude:alias})
            .then(data=>{
                setArticles(data.items)
            })
    },[alias])

    return (
        <Fragment>
            <HomeBar context="blog" contactOpen={contact} aboutOpen={about} faqsOpen={faqs} />
            {article &&
            <Fragment>
                <div className="article-intro noselect">
                    <div className="article-intro-bg">
                        <div className="article-intro-picture" style={{backgroundImage:"url("+globalContext["base_url"]+"resources/articles/bg/"+article["id"]+".jpg"}}></div>
                        <div className="article-intro-overlay"></div>
                    </div>
                    <div className="article-intro-content">
                        <div>
                            <div className="article-intro-tagline">{article["tagline"]}</div>
                            <div className="article-intro-title">{article["title"]}</div>
                            <div className="article-intro-date">({humanDate(article["released"])}, por <span className="article-intro-author">{article["author"]}</span>)</div>
                        </div>
                    </div>
                </div>
                <div className="article-content">
                    <div className="article-content-summary">{article["summary"]}</div>
                    <Blocks
                        data={JSON.parse(article["content"])}
                        config={{
                            embed:{
                                className:"article-content-embed"
                            },
                            image:{
                                className:"article-image",
                                actionsClassNames:{
                                    stretched:"article-image-full",
                                    withBorder:"article-image-with-border",
                                    withBackground:"article-image-with-bg"
                                }
                            }
                        }}
                    />
                </div>
            </Fragment>}
            <div className="blog-articles-frame">
                <div id="publicaciones" className="blog-title">
                    Estos artículos te podrían interesar:
                </div>
                {articles &&
                <div className="blog-articles">
                    {articles.length>0?
                        articles.map((item,i)=>{
                            return(
                                <Link key={i} className="blog-article" to={"/blog/"+item["alias"]}>
                                    <div>
                                        <div className="blog-article-picture" style={{backgroundImage:"url("+globalContext["base_url"]+"resources/articles/"+item["id"]+".jpg"}}></div>
                                    </div>
                                    <div>
                                        <div className="blog-article-tagline">{item["tagline"]}</div>
                                        <div className="blog-article-title">{item["title"]}</div>
                                    </div>
                                    <div>
                                        <div className="blog-article-button">Seguir leyendo<img src={next2Img} /></div>
                                    </div>
                                </Link>
                            )
                        })
                    :<div>No hay artículos</div>}
                </div>}
                <div className="home-channel-button-frame">
                    <Link to="/blog" className="home-channel-button">Ver todo el blog<img src={nextImg} /></Link>
                </div>
            </div>
            <HomeFooter context="blog" contactOpen={contact} aboutOpen={about} faqsOpen={faqs} />
            <Ga />
            <ConnectionChecker />
        </Fragment>
    )
    
}