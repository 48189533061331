import {useContext,useEffect,useState} from "react"
import {useNavigate,useLocation} from "react-router-dom"
import useApi from "../hooks/useApi"
import GlobalContext from "../contexts/GlobalContext"

export default function useSession(){

    const [globalContext,setGlobalContext]=useContext(GlobalContext)
    const [manageInitSession,setManageInitSession]=useState({goto:""})
    const navigate=useNavigate()
    const location=useLocation()
    const requestApi=useApi()

    const validateSession=()=>{
        if(globalContext["initiated"]==false){
            if(localStorage.getItem("user_id") && localStorage.getItem("session_token") && localStorage.getItem("session_token_id")){
                initSession("auto",localStorage.getItem("user_id"),localStorage.getItem("session_token"),localStorage.getItem("session_token_id"),"")
            }else{
                navigate("/entrar")
            }
        }
    }

    const initSession=(context,user_id,session_token,session_token_id,goto)=>{
        let globalContextNew={...globalContext}
        globalContextNew["user_id"]=user_id
        globalContextNew["session_token"]=session_token
        globalContextNew["session_token_id"]=session_token_id
        setGlobalContext(globalContextNew)
        setManageInitSession({goto:goto})
    }

    useEffect(()=>{
        if(globalContext["user_id"]!=0){
            requestApi("users/info",{
                    user_id:globalContext.user_id
                })
                .then(data=>{
                    if(data.status=="success"){
                        let globalContextNew={...globalContext}
                        globalContextNew["user_name"]=data.data.first_name+" "+data.data.last_name
                        globalContextNew["user_capitals"]=data.data.capitals
                        globalContextNew["user_has_picture"]=data.data.has_picture
                        globalContextNew["profile"]=data.data.profile
                        globalContextNew["initiated"]=true
                        localStorage.setItem("user_id",globalContext["user_id"])
                        localStorage.setItem("session_token",globalContext["session_token"])
                        localStorage.setItem("session_token_id",globalContext["session_token_id"])
                        setGlobalContext(globalContextNew)
                        if(manageInitSession["goto"]!=""){
                            navigate(manageInitSession["goto"])
                        }
                        if(location.pathname=="/entrar"){
                            navigate("/c24/tablero")
                        }
                    }else{
                        terminateSession()
                    }
                })
        }
    },[manageInitSession])

    const terminateSession=()=>{
        let globalContextNew={...globalContext}
        globalContextNew["user_id"]=0
        globalContextNew["session_token"]=""
        globalContextNew["session_token_id"]=0
        globalContextNew["user_name"]=""
        globalContextNew["user_capitals"]=""
        globalContextNew["user_has_picture"]=""
        globalContextNew["profile"]=""
        globalContextNew["initiated"]=false
        localStorage.removeItem("user_id")
        localStorage.removeItem("session_token")
        localStorage.removeItem("session_token_id")
        setGlobalContext(globalContextNew)
        window.location.href="/entrar"
    }

    return {validateSession,initSession,terminateSession}

}