import {forwardRef,useEffect,useState} from "react"
import useApi from "../hooks/useApi"
import Select from "react-select"

const Changer=forwardRef(({placeholder,context,searchable,changer},ref)=>{

    const requestApi=useApi()
    const [changerVal,setChangerVal]=changer
    const [changerOptions,setChangerOptions]=useState([])
    const [selected]=useState(
        (context=="webpos" && localStorage.getItem("webpos_point"))?
        localStorage.getItem("webpos_point")
        :(context=="records" && localStorage.getItem("records_shop"))?
        localStorage.getItem("records_shop")
        :""
    )

    useEffect(()=>{
        requestApi("shops/shops_points",{context:context=="webpos"?"only_points":context=="records"?"only_shops":""})
            .then(data=>{
                let newOptions=[]
                let selectedPoint=""
                data.items.forEach(function(shop){
                    newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                    if(selected!=""){
                        if(context=="webpos"){
                            if(selected==shop.point_id){
                                selectedPoint={label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}}
                            }
                        }else if(context=="records"){
                            if(selected==shop.shop_id){
                                selectedPoint={label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}}
                            }
                        }
                    }else{
                        selectedPoint={label:newOptions[0].label,value:newOptions[0].value}
                    }
                })
                setChangerOptions(newOptions)
                if(selectedPoint!=""){
                    ref.current.setValue(selectedPoint)
                }
            })
    },[])

    const customStyles={
        control:(provided,state)=>({
            ...provided,
            width:"100%",
            minWidth:"216px",
            height:"42px",
            paddingLeft:"11px",
            borderRadius:"10px",
            backgroundColor:"#FFFFFF",
            boxShadow:"none",
            border:state.isFocused?"solid 2px rgba(98,77,227,0) !important":"solid 2px rgba(98,77,227,0)",
            "&:hover":{border:"solid 2px rgba(98,77,227,0)"},
            border:state.hasValue?"solid 2px rgba(98,77,227,1) !important":"solid 2px rgba(98,77,227,0)"
        }),
        input:(provided,state)=>({
            ...provided,
            padding:"0px",
            paddingTop:"0px",
            paddingBottom:"1px !important",
            color:"#624DE3",
            fontSize:"15px",
            lineHeight:"15px",
            boxSizing:"border-box",
            fontWeight:500
        }),
        placeholder:(provided,state)=>({
            ...provided,
            position:"absolute",
            width:context=="webpos"?"100%":"auto",
            textAlign:context=="webpos"?"center":"left",
            top:"11px",
            left:"0px",
            padding:"0px",
            paddingTop:"0px",
            color:"#B0A6F1",
            fontWeight:400,
            fontSize:"15px",
            lineHeight:"15px"
        }),
        dropdownIndicator:(provided)=>({
            ...provided,
            color:"#624DE3",
            "&:hover":{color:"#624DE3"},
            "& svg":{width:"17px",height:"17px"}
        }),
        indicatorSeparator:(provided)=>({
            ...provided,
            backgroundColor:"rgba(98,77,227,0)"
        }),
        valueContainer:(provided,state)=>({
            ...provided,
            display:"flex",
            height:"100%",
            padding:"0px",
            boxSizing:"border-box",
            flexWrap:"unset !important"
        }),
        singleValue:(provided,state)=>({
            ...provided,
            position:context=="webpos"?"absolute":"relative",
            width:context=="webpos"?"100%":"auto",
            textAlign:context=="webpos"?"center":"left",
            padding:"0px",
            paddingBottom:"2px",
            color:"#624DE3",
            fontSize:"15px",
            lineHeight:"15px",
            fontWeight:500
        }),
        menu:(provided,state)=>({
            ...provided,
            width:context=="webpos"?"100%":"310px",
            paddingTop:"0px",
            border:"none",
            borderRadius:"6px",
            overflow:"hidden",
            animationName:"selector-options-anima",
            animationDuration:"0.18s",
            boxShadow:"0px 0px 40px 0px rgba(50,23,122,0.35)"
        }),
        menuPortal:(provided)=>({
            ...provided,
            zIndex:9999
        }),
        menuList:(provided,state)=>({
            ...provided,
            padding:"0px",
            "&::-webkit-scrollbar":{
                width:"11px",
                height:"11px",
                borderRadius:"30px"
            },
            "&::-webkit-scrollbar-thumb":{
                background:"rgba(209,99,171,0.5)",
                borderRadius:"30px",
                border:"solid 3px #FFFFFF"
            },
            "&::-webkit-scrollbar-thumb:hover":{
                background:"rgba(209,99,171,1)"
            },
            "&::-webkit-scrollbar-track":{
                background:"transparent",
                borderRadius:"30px"
            }
        }),
        option:(provided,state)=>({
            ...provided,
            paddingTop:"8px",
            paddingBottom:"8px",
            paddingLeft:"14px",
            paddingRight:"14px",
            boxSizing:"border-box",
            fontWeight:"500",
            color:"#D163AB",
            fontSize:"14px",
            lineHeight:"20px",
            backgroundColor:state.isFocused?"rgba(209,99,171,0.1)":"#FFFFFF",
            "&:hover":{backgroundColor:"rgba(209,99,171,0.1)"},
            "&:first-of-type":{paddingTop:"14px"},
            "&:last-of-type":{paddingBottom:"14px"},
            cursor:"pointer",
            transition:"0.18s"
        }),
        noOptionsMessage:(provided,state)=>({
            ...provided,
            padding:"16px",
            paddingBottom:"17px",
            paddingLeft:"20px",
            paddingRight:"20px",
            boxSizing:"border-box",
            fontWeight:"500",
            color:"#D163AB",
            fontSize:"14px",
            lineHeight:"16px",
            backgroundColor:"#FFFFFF"
        })
    }

    const handleChange=(selectedValue)=>{
        setChangerVal(selectedValue)
        if(context=="webpos"){
            localStorage.setItem("webpos_point",selectedValue.value["point_id"])
        }else if(context=="records"){
            localStorage.setItem("records_shop",selectedValue.value["shop_id"])
        }
    }

    return(
        <Select ref={ref} options={changerOptions} blurInputOnSelect={true} placeholder={placeholder} styles={customStyles} isClearable={false} isSearchable={searchable} isMulti={false} menuPortalTarget={document.body} noOptionsMessage={()=>{return "Sin opciones"}} onChange={handleChange} />
    )
    
})

export default Changer