import React,{forwardRef} from "react"
import Select,{components} from "react-select"
import AsyncSelect from "react-select/async"
import useApi from "../hooks/useApi"

const {ValueContainer,Placeholder}=components
const CustomValueContainer=({children,...props})=>{
    return(
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children,child=>child && child.type!==Placeholder?child:null)}
        </ValueContainer>
    )
}

const Selector=forwardRef(({options,placeholder,multiple,searchable,disabled,style,val,setVal,async},ref)=>{

    let items=[]
    options.map((option,i)=>{
        items.push({value:option.value,label:option.label})
    })
    
    let isDisabled=false;
    if(disabled){
        isDisabled=true
    }

    let isMulti=false;
    if(multiple){
        isMulti=true
    }

    const customStyles={
        control:(provided,state)=>({
            ...provided,
            minHeight:57,
            paddingLeft:"0px",
            borderRadius:"8px",
            backgroundColor:"#FFFFFF",
            borderColor:style=="gray"?(state.isFocused?"#624DE3 !important":"#CCC6F8"):(state.isFocused?"#624DE3 !important":"#FFFFFF"),
            "&:hover":{borderColor:style=="gray"?"#CCC6F8":"#FFFFFF"},
            boxShadow:style=="gray"?(state.isFocused?"0 0 0 1px #624DE3":"0 0 0 1px #CCC6F8"):(state.isFocused?"0 0 0 1px #624DE3":"none")
        }),
        input:(provided,state)=>({
            ...provided,
            position:"absolute",
            top:"0px",
            left:"18px",
            paddingLeft:"0px",
            paddingBottom:"4px",
            paddingRight:"0px",
            minWidth:(state.selectProps.inputValue)?"200px":"auto",
            backgroundColor:(state.selectProps.inputValue)?"#FFFFFF":"transparent",
            color:"#675AB0",
            fontSize:"15px",
            lineHeight:"15px",
            fontWeight:500,
            margin:"0px",
            height:"57px"
        }),
        placeholder:(provided,state)=>({
            ...provided,
            position:"absolute",
            top:state.hasValue?"10px":"20px",
            left:"0px",
            color:"#675AB0",
            fontWeight:500,
            fontSize:state.hasValue?"13px":"15px",
            lineHeight:state.hasValue?"13px":"15px",
            paddingLeft:state.hasValue?"10px":"18px",
            paddingBottom:state.hasValue?"38px":"18px",
            transition:"0.18s"
        }),
        dropdownIndicator:(provided)=>({
            ...provided,
            color:"#624DE3",
            padding:"14px",
            "&:hover":{color:"#624DE3"}
        }),
        indicatorSeparator:(provided)=>({
            ...provided,
            backgroundColor:"rgba(98,77,227,0)"
        }),
        valueContainer:(provided,state)=>({
            ...provided,
            display:"flex",
            minHeight:"55px",
            padding:"0px",
            paddingTop:state.isMulti?"24px":"21px",
            paddingBottom:state.isMulti?"1px":"5px",
            paddingBottom:(state.isMulti && state.hasValue) && "7px",
            paddingLeft:(state.isMulti && state.hasValue)?"7px":"0px",
            boxSizing:"border-box",
            flexWrap:"unset !important"
        }),
        singleValue:(provided,state)=>({
            ...provided,
            paddingTop:"0px",
            paddingBottom:"4px",
            paddingLeft:"10px",
            color:"#41319C",
            fontSize:"16px",
            lineHeight:"18px",
            fontWeight:600
        }),
        multiValue:(provided,state)=>({
            ...provided,
            borderRadius:"100px",
            marginBottom:"2px",
            marginRight:"2px",
            backgroundColor:"rgba(209,99,171,0.2)"
        }),
        multiValueContainer:(provided,state)=>({
            ...provided
        }),
        multiValueLabel:(provided,state)=>({
            ...provided,
            fontSize:"15px",
            lineHeight:"15px",
            fontWeight:500,
            color:"#D163AB",
            paddingLeft:"10px",
            paddingTop:"4px",
            paddingBottom:"5px"
        }),
        multiValueRemove:(provided,state)=>({
            ...provided,
            color:"#D163AB",
            paddingRight:"8px",
            opacity:0.5,
            borderTopRightRadius:"100px",
            borderBottomRightRadius:"100px",
            transition:"0.18s",
            "&:hover":{backgroundColor:"rgba(209,99,171,0.2)",color:"#D163AB",opacity:1}
        }),
        menu:(provided,state)=>({
            ...provided,
            paddingTop:"0px",
            border:"solid 2px rgba(209, 99, 171, 0.25)",
            boxShadow:"none",
            borderRadius:"8px",
            overflow:"hidden",
            animationName:"selector-options-anima",
            animationDuration:"0.18s"
        }),
        menuPortal:(provided)=>({
            ...provided,
            zIndex:9999
        }),
        menuList:(provided,state)=>({
            ...provided,
            padding:"0px",
            "&::-webkit-scrollbar":{
                width:"11px",
                height:"11px",
                borderRadius:"30px"
            },
            "&::-webkit-scrollbar-thumb":{
                background:"rgba(209, 99, 171, 0.5)",
                borderRadius:"30px",
                border:"solid 3px #FFFFFF"
            },
            "&::-webkit-scrollbar-thumb:hover":{
                background:"rgba(209, 99, 171, 1)"
            },
            "&::-webkit-scrollbar-track":{
                background:"transparent",
                borderRadius:"30px"
            }
        }),
        option:(provided,state)=>({
            ...provided,
            padding:"10px",
            paddingBottom:"11px",
            paddingLeft:"17px",
            paddingRight:"17px",
            boxSizing:"border-box",
            fontWeight:"500",
            color:"#D163AB",
            fontSize:"15px",
            lineHeight:"21px",
            backgroundColor:state.isFocused?"rgba(209, 99, 171, 0.1)":"#FFFFFF",
            "&:hover":{backgroundColor:"rgba(209, 99, 171, 0.1)"},
            "&:first-of-type":{paddingTop:"14px"},
            "&:last-of-type":{paddingBottom:"14px"},
            cursor:"pointer",
            transition:"0.18s"
        }),
        noOptionsMessage:(provided,state)=>({
            ...provided,
            padding:"21px",
            paddingBottom:"22px",
            paddingLeft:"25px",
            paddingRight:"25px",
            boxSizing:"border-box",
            fontWeight:"500",
            color:"#D163AB",
            fontSize:"15px",
            lineHeight:"17px",
            backgroundColor:"#FFFFFF"
        }),
        loadingIndicator:(provided,state)=>({
            ...provided,
            color:"#624DE3",
            fontSize:"5px"
        }),
        loadingMessage:(provided,state)=>({
            ...provided,
            padding:"21px",
            paddingBottom:"22px",
            paddingLeft:"25px",
            paddingRight:"25px",
            boxSizing:"border-box",
            fontWeight:"500",
            color:"#D163AB",
            fontSize:"15px",
            lineHeight:"17px",
            backgroundColor:"#FFFFFF"
        })
    }

    const handleChange=(selectedValue)=>{
        setVal(selectedValue.value)
    }

    let asyncEndpoint=""
    let asyncDefaultOptions=false
    let asyncGetOptionLabel=""
    let asyncGetOptionValue=""
    if(async){
        if(async.context=="users"){
            asyncEndpoint="users/autocomplete"
            asyncDefaultOptions=false
            asyncGetOptionLabel="full_name"
            asyncGetOptionValue="id"
        }
    }

    const requestApi=useApi()
    const loadOptions=async(keywords)=>{
        const options=await requestApi(asyncEndpoint,{keywords:keywords,context:async.context,not_loader:true})
        return await options.items
    }

    return(
        <div className="form-block noselect">
            {!async?
            <Select
            ref={ref}
            options={items}
            blurInputOnSelect={true}
            isDisabled={isDisabled}
            components={{ValueContainer:CustomValueContainer}}
            placeholder={placeholder}
            styles={customStyles}
            isClearable={false}
            isSearchable={searchable}
            isMulti={isMulti}
            noOptionsMessage={()=>{return "Sin opciones"}}
            onChange={handleChange} />
            :<AsyncSelect
            ref={ref}
            cacheOptions
            defaultOptions={asyncDefaultOptions}
            loadOptions={loadOptions}
            getOptionLabel={e=>e[asyncGetOptionLabel]}
            getOptionValue={e=>e[asyncGetOptionValue]}
            blurInputOnSelect={true}
            isDisabled={isDisabled}
            components={{ValueContainer:CustomValueContainer}}
            placeholder={placeholder}
            styles={customStyles}
            isClearable={false}
            isSearchable={searchable}
            isMulti={isMulti}
            menuPortalTarget={document.body}
            noOptionsMessage={()=>{return "Escribe para buscar..."}}
            loadingMessage={()=>{return "Buscando..."}}
            onChange={handleChange} />}
        </div>
    )
    
})

export default Selector