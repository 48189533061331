import {useEffect,useState,useContext,Fragment} from "react"
import {Link} from "react-router-dom"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import useApi from "../hooks/useApi"
import GlobalContext from "../contexts/GlobalContext"
import useModal from "../hooks/useModal"
import HomeBar from "../components/HomeBar"
import HomeFooter from "../components/HomeFooter"
import nextImg from "../assets/next-2.svg"
import next2Img from "../assets/next-4.svg"

export default function Blog(){

    const [globalContext]=useContext(GlobalContext)
    const requestApi=useApi()
    const {changeModal}=useModal()
    const [articles,setArticles]=useState()

    const contact=(e)=>{
        e.preventDefault()
        changeModal("contact","","")
    }

    const about=(e)=>{
        e.preventDefault()
        changeModal("about","","")
    }
    
    const faqs=(e)=>{
        e.preventDefault()
        changeModal("faqs","","")
    }

    useEffect(()=>{
        window.scrollTo(0,0)
        requestApi("blog/articles",{context:"list"})
            .then(data=>{
                setArticles(data.items)
            })
    },[])

    return (
        <Fragment>
            <HomeBar context="blog" contactOpen={contact} aboutOpen={about} faqsOpen={faqs} />
            <div className="blog-intro noselect">
                <div className="blog-intro-bg">
                    <div className="blog-intro-anima"></div>
                </div>
                <div className="blog-intro-content">
                    <div>
                        <div className="blog-intro-title">
                            Blog <strong>:)</strong> Cajero24
                        </div>
                        <div className="blog-intro-caption">
                            Este es un espacio de aprendizaje sobre pagos online, tarjetas de crédito y débito, criptomonedas, el ecosistema fintech y la nueva economía digital. Artículos, videos y todo lo que necesitás para estar siempre al día.
                        </div>
                        <div className="blog-intro-action">
                            <a href="#publicaciones" className="blog-intro-button">Últimos artículos<img src={nextImg} /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="publicaciones" className="blog-title">
                Mirá nuestras publicaciones más recientes y convertite en quien más sabe de pagos online.
            </div>
            <div className="blog-articles-frame">
                {articles &&
                <div className="blog-articles">
                    {articles.length>0?
                        articles.map((item,i)=>{
                            return(
                                <Link key={i} className="blog-article" to={"/blog/"+item["alias"]}>
                                    <div>
                                        <div className="blog-article-picture" style={{backgroundImage:"url("+globalContext["base_url"]+"resources/articles/"+item["id"]+".jpg"}}></div>
                                    </div>
                                    <div>
                                        <div className="blog-article-tagline">{item["tagline"]}</div>
                                        <div className="blog-article-title">{item["title"]}</div>
                                    </div>
                                    <div>
                                        <div className="blog-article-button">Seguir leyendo<img src={next2Img} /></div>
                                    </div>
                                </Link>
                            )
                        })
                    :<div>No hay artículos</div>}
                </div>}
            </div>
            <HomeFooter context="blog" contactOpen={contact} aboutOpen={about} faqsOpen={faqs} />
            <Ga />
            <ConnectionChecker />
        </Fragment>
    )
    
}