import {useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function FaqsAdm(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("faqs-group-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="FAQs"
                intro="Grupos de temas de ayuda."
                back=""
                button={{label:"Nuevo grupo",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Tickets",to:"/c24/asistencia"},
                    {label:"Preguntas frecuentes",labelShort:"FAQs",to:"/c24/asistencia/faqs"},
                    {label:"Novedades",to:"/c24/asistencia/novedades"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"faqs-adm",
                    endpoint:"faqs/groups",
                    reference:{context:"adm"},
                    limit:0,
                    counter:{
                        singular:"grupo",
                        plural:"grupos"
                    },
                    action:{
                        mode:"link",
                        context:"faq_group",
                        reference:"/c24/asistencia/faqs/"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}