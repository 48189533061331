import useDialog from "../hooks/useDialog"
import remove from "../assets/remove.svg"
import verify from "../assets/verify.svg"
import block from "../assets/block.svg"
import deactivate from "../assets/deactivate.svg"

export default function ButtonCommand({label,action,clickEvent}){

    const {changeDialog,manageDialog}=useDialog()

    const confirm=()=>{
        changeDialog("remove","",()=>{clickEvent();manageDialog("hide")})
    }

    return(
        <div>
            {action=="remove" &&
            <div className="command-button noselect" onClick={confirm}>
                <div><img src={remove} /></div>
                <div>{label}</div>
            </div>}
            {(action=="deactivate" || action=="activate") &&
            <div className="command-button noselect" onClick={clickEvent}>
                <div><img src={deactivate} /></div>
                <div>{label}</div>
            </div>}
            {(action=="block" || action=="unblock") &&
            <div className="command-button noselect" onClick={clickEvent}>
                <div><img src={block} /></div>
                <div>{label}</div>
            </div>}
            {action=="verify" &&
            <div className="command-button command-button-up noselect" onClick={clickEvent}>
                <div><img src={verify} /></div>
                <div>{label}</div>
            </div>}
        </div>
    )
    
}