import {Fragment,useContext,useEffect,useState} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import {formatDate,formatAmount} from "../hooks/Commons"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonBack from "../commons/ButtonBack"
import Amount from "../commons/Amount"

export default function LiquidationInfo(){
    
    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {changeModal,modalReady}=useModal()
    const [info,setInfo]=useState()
    let liquidationId=modalContext.context=="from-transaction"?modalContext.reference["liquidation"]:modalContext.reference
    let transactionId=modalContext.context=="from-transaction"?modalContext.reference["transaction"]:0

    useEffect(()=>{
        modalReady(0)
        requestApi("liquidations/info",{
                liquidation_id:liquidationId
            })
            .then(data=>{
                setInfo(data.data)
                modalReady(1)
            })
    },[])

    const openTransaction=(transaction)=>{
        changeModal("transaction-info","from-liquidation",{transaction:transaction,liquidation:liquidationId})
    }

    return(
        <Fragment>
            <ModalHeader>
                {modalContext.context=="from-transaction" && <ButtonBack clickEvent={()=>{changeModal("transaction-info","",transactionId)}} />}
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {info &&
                    <Fragment>
                        <ModalTitle title={"Liquidación #"+String(info["liquidation_type"]=="debin"?info["id"]:info["liquidation_number"]).padStart(10,"0")} />
                        <Card title={info["liquidation_type"]=="debin"?"Débito inmediato (Debin)":(info["card"]["name"]+" ("+info["company"]+")")} icon={info["liquidation_type"]=="debin"?{element:"payment",reference:"debin"}:{element:"payment",reference:info["card"]["has_picture"]?info["card"]["id"]:0}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Fecha" detail="">
                                <span className="card-row-featured card-row-medium">{formatDate(info["liquidation_date"],"standard")}</span>
                            </CardRow>
                            {info["liquidation_type"]=="debin" &&
                            <CardRow label="Total descontado" detail="">
                                <span className="card-row-semibold">{formatAmount(info["discounted_total"],"ARS","display")}</span>
                            </CardRow>}
                            <CardRow label="Total acreditado" detail="">
                                <Amount number={info["total_accredited"]} currency="ARS" operation="liquidation" />
                            </CardRow>
                            {info["liquidation_type"]=="card" &&
                            <CardRow label="Banco" detail="">
                                {info["bank"]}
                            </CardRow>}
                            {info["liquidation_type"]=="debin" &&
                            <Fragment>
                                <CardRow label="N° de operación" detail="">
                                    {info["operation_number"]?info["operation_number"]:"‒"}
                                </CardRow>
                                <CardRow label="CBU/alias" detail="">
                                    {info["cbu_alias"]?info["cbu_alias"]:"‒"}
                                </CardRow>
                            </Fragment>}
                            <CardRow label="Comercio" detail="">
                                {info["shop"]!=""?info["shop"]["name"]:"(Desconocido)"}
                            </CardRow>
                            <CardRow label="Punto de cobro" detail="">
                                {info["point"]!=""?info["point"]["name"]:"(Desconocido)"}
                            </CardRow>
                        </Card>
                        {info["liquidation_type"]=="card" &&
                        <Card title="Ítems facturados" icon={{element:""}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Débito" detail="">
                                {formatAmount(info["invoiced_debit"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Crédito" detail="">
                                {formatAmount(info["invoiced_credit"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Crédito en cuotas" detail="">
                                {formatAmount(info["invoiced_credit_installments"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Descuentos" detail="">
                                {formatAmount(info["invoiced_discounts"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Otros" detail="">
                                {formatAmount(info["invoiced_others"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Total facturado" detail="">
                                <span className="card-row-semibold">{formatAmount(info["invoiced_total"],"ARS","display")}</span>
                            </CardRow>
                        </Card>}
                        {info["liquidation_type"]=="card" &&
                        <Card title="Ítems descontados" icon={{element:""}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Cargo del servicio" detail="">
                                {formatAmount(info["discounted_fee"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Promociones" detail="">
                                {formatAmount(info["discounted_promotion"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Impuestos" detail="">
                                {formatAmount(info["discounted_tax"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Pago en cuotas" detail="">
                                {formatAmount(info["discounted_installment"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Adelantos" detail="">
                                {formatAmount(info["discounted_advance"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Otros" detail="">
                                {formatAmount(info["discounted_others"],"ARS","display")}
                            </CardRow>
                            <CardRow label="Total descontado" detail="">
                                <span className="card-row-semibold">{formatAmount(info["discounted_total"],"ARS","display")}</span>
                            </CardRow>
                        </Card>}
                        {(info["sales"].length>0 && (info["liquidation_type"]=="card" || info["liquidation_type"]=="debin")) &&
                        <Card title={"Operaciones ("+info["sales"].length+")"} icon={{element:""}} action="" actionIcon="" actionClickEvent="">
                            {info["sales"].map((item,i)=>{
                                return(
                                    <CardRow 
                                        key={i}
                                        label={
                                            info["liquidation_type"]=="card"?
                                                item["description"]+" ‒ Cupón #"+item["voucher"]
                                            :info["liquidation_type"]=="debin"?
                                                item["description"]
                                            :""
                                        }
                                        detail={
                                            <Fragment>
                                                {info["liquidation_type"]=="card"?
                                                <Fragment>
                                                    {"Lote "+item["batch"]+", "+"Terminal "+item["terminal"]+" (Tarjeta ****"+item["card"]+")"}
                                                </Fragment>
                                                :""}
                                                {item["transaction"]!=0 &&
                                                <div className="card-link" onClick={()=>{openTransaction(item["transaction"])}}>Transacción #{String(item["transaction"]).padStart(6,"0")}</div>}                                        
                                            </Fragment>
                                        }>
                                        <span className={item["amount"]>0?"card-row-green":"card-row-red"}>{formatAmount(item["amount"],"ARS","display")}</span>
                                    </CardRow>
                                )
                            })}
                            <CardRow label="Total" detail="">
                                <span className="card-row-semibold">{formatAmount(info["total_sales"],"ARS","display")}</span>
                            </CardRow>
                        </Card>}
                        {(info["discounts"].length>0 && info["liquidation_type"]=="card") &&
                        <Card title={"Descuentos ("+info["discounts"].length+")"} icon={{element:""}} action="" actionIcon="" actionClickEvent="">
                            {info["discounts"].map((item,i)=>{
                                return(
                                    <CardRow key={i} label={item["description"]+" ("+item["percentage"]+"%)"} detail={item["detail"]}>
                                        <span className="card-row-featured">{formatAmount(item["amount"],"ARS","display")}</span>
                                    </CardRow>
                                )
                            })}
                            <CardRow label="Total" detail="">
                                <span className="card-row-semibold">{formatAmount(info["total_discounts"],"ARS","display")}</span>
                            </CardRow>
                        </Card>}
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}