import {Fragment,useContext,useEffect,useState,useRef,} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import {formatAmount} from "../hooks/Commons"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonBack from "../commons/ButtonBack"
import ButtonPrimary from "../commons/ButtonPrimary"
import Numbox from "../commons/Numbox"

export default function TransactionRefund(){
    
    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {refresh}=useRefresh()
    const {changeModal,modalReady}=useModal()
    const {changeToast}=useToast()
    const {changeDialog,manageDialog}=useDialog()
    const [info,setInfo]=useState()
    const amountInput=useRef()
    const [amountVal,setAmountVal]=useState()

    useEffect(()=>{
        modalReady(0)
        requestApi("transactions/info",{
                transaction_id:modalContext.reference
            })
            .then(data=>{
                setInfo(data.data)
                setAmountVal(data.data.amount-data.data.partial_refunds.amount)
                modalReady(1)
            })
    },[])

    const refund=()=>{
        manageDialog("hide")
        if(amountVal=="" || amountVal=="0" || amountVal==0 || amountVal=="0.00"){
            amountInput.current.focus()
        }else{
            if(amountVal>info.amount-info.partial_refunds.amount){
                setTimeout(()=>{
                    changeDialog("refund_max_reached","","")
                    amountInput.current.focus()
                },700)
            }else{
                requestApi("pay/refund",{
                        transaction_id:info["id"],
                        amount:amountVal,
                        cashier:globalContext.user_id
                    })
                    .then(data=>{
                        if(data.status=="success"){
                            changeToast("refunded","")
                            changeModal("transaction-info","",modalContext.reference)
                            refresh(["list"],"")
                        }else{
                            changeDialog("refund_error","","")
                        }
                    })
            }
        }
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("transaction-info","",modalContext.reference)}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    <Fragment>
                        <ModalTitle title={"Devolver transacción #"+(info && String(info["id"]).padStart(6,"0"))} />
                        <div className="modal-explanation modal-explanation-alt-2 modal-explanation-center">
                            Al realizar una devolución total o parcial, la transacción se reintegrará al usuario a través del mismo medio de pago. Es posible que el reintegro no se vea reflejado en forma instantánea (puede demorar hasta 30 días).
                        </div>
                        <Numbox ref={amountInput} label="Monto a devolver" val={amountVal} setVal={setAmountVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                        <div className="uploader-submit">
                            <ButtonPrimary label="Devolver" clickEvent={()=>{changeDialog("refund","",refund)}} />
                        </div>
                    </Fragment>
                </div>
            </ModalContent>
        </Fragment>
    )
    
}