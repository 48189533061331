import {NavLink} from "react-router-dom"

export default function SectionTabs({tabs}){

    return(
        <div className="tabs">
            <div className="tabs-buttons">
                {tabs.map((item,i)=>
                    <NavLink key={i} className={({isActive})=>isActive?"tab-button tab-button-on":"tab-button"} to={item.to} end>
                        <span className="tab-label-long">{item.label}</span>
                        <span className="tab-label-short">{item.labelShort?item.labelShort:item.label}</span>
                        {(item.counter!="" && item.counter!=undefined) && <span className="tab-counter">{"("+item.counter+")"}</span>}
                    </NavLink>
                )}
            </div>
        </div>
    )
    
}