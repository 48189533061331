import {Fragment} from "react"
import {Link} from "react-router-dom"
import {humanDate,formatDate,formatAmount} from "../hooks/Commons"
import useModal from "../hooks/useModal"
import Avatar from "../commons/Avatar"
import Icon from "../commons/Icon"
import Symbol from "../commons/Symbol"
import ButtonRounded from "../commons/ButtonRounded"
import Amount from "../commons/Amount"
import okIcon from "../assets/ok.svg"
import notOkIcon from "../assets/not-ok.svg"

export default function Item({config,item}){

    const {changeModal}=useModal()

    let clickEvent=""
    let linkTo=""
    if(config.action){
        if(config.action.mode=="modal"){
            clickEvent=(event)=>{
                event.preventDefault()
                changeModal(config.action.reference,config.action.context,item.id)
            }
        }else if(config.action.mode=="link"){
            linkTo=config.action.reference
            if(config.action.context=="shop"){
                linkTo+=item["id"]
            }
            if(config.action.context=="faq_group"){
                linkTo+=item["id"]
            }
            if(config.action.context=="subscription"){
                linkTo+=item["id"]
            }
        }
    }

    const linkStatus=(item)=>{
        if(item["status"]==0){
            return "inactive"
        }
        if(item["usage_limit"]>0){
            if(item["usages"]>=item["usage_limit"]){
                return "limit_reached"
            }
        }
        let now=Math.floor(Date.now()/1000)
        if(item["start_date"]!=0 && item["start_date"]>now){
            return "waiting"
        }
        if(item["end_date"]!=0 && item["end_date"]<now){
            return "expired"
        }
        return "active"
    }

    return(
        <Fragment>
            
            {config.section=="activity" &&
            <Link className="item item-row" onClick={clickEvent} to={linkTo}>
                <div>
                    <Icon element={
                        item["operation"]=="payment"?
                            (item["status"]==1?
                                (item["liquidation"]==0?"approved":"liquidated")
                            :item["status"]==0?"pending"
                            :item["status"]==2?"rejected"
                            :item["status"]==3?"canceled"
                            :item["status"]==4?"approved"
                            :item["status"]==5?"approved"
                            :"")
                        :item["operation"]=="refund"?"refund"
                        :item["operation"]=="partial_refund"?"refund"
                        :""
                    } context="medium" />
                </div>
                <div className="item-data item-data-alt">
                    <div className="item-data-full">
                        <div>
                            <span className="item-semibold">
                            {item["operation"]=="payment"?
                            "Pago de "+(item["user"]!=""?item["user"]["first_name"]+" "+item["user"]["last_name"]:"(desconocido)")
                            :item["operation"]=="refund"?
                            "Devolución a "+(item["user"]!=""?item["user"]["first_name"]+" "+item["user"]["last_name"]:"(desconocido)")
                            :item["operation"]=="partial_refund"?
                            "Devolución parcial a "+(item["user"]!=""?item["user"]["first_name"]+" "+item["user"]["last_name"]:"(desconocido)")
                            :"‒"}
                            </span> (#{String(item["id"]).padStart(6,"0")}) 
                            {item["operation"]=="payment"?
                                (item["status"]==1?
                                    (item["liquidation"]==0?
                                    <Fragment>
                                        &nbsp;<span className="item-point item-point-green"></span>&nbsp;
                                        <span className="item-xsmall item-medium item-uppercase item-green">Aprobado</span>
                                    </Fragment>
                                    :<Fragment>
                                        <div className="liquidation-button" onClick={(event)=>{event.preventDefault();event.stopPropagation();changeModal("liquidation-info","",item["liquidation"])}}>
                                            &nbsp;<div className="liquidation" title="Liquidado"><img src={okIcon} /></div>&nbsp;
                                            <span className="item-xsmall item-medium item-uppercase item-green">Liquidado</span>
                                        </div>
                                    </Fragment>)
                                    :item["status"]==0?
                                    <Fragment>
                                        &nbsp;<span className="item-point item-point-yellow"></span>&nbsp;
                                        <span className="item-xsmall item-medium item-uppercase item-yellow">Pendiente</span>
                                    </Fragment>
                                    :item["status"]==2?
                                    <Fragment>
                                        &nbsp;<span className="item-point item-point-red"></span>&nbsp;
                                        <span className="item-xsmall item-medium item-uppercase item-red">Rechazado</span>
                                    </Fragment>
                                    :item["status"]==3?
                                    <Fragment>
                                        &nbsp;<span className="item-point item-point-red"></span>&nbsp;
                                        <span className="item-xsmall item-medium item-uppercase item-red">Cancelado</span>
                                    </Fragment>
                                    :item["status"]==4?
                                    <Fragment>
                                        &nbsp;<span className="item-point item-point-orange"></span>&nbsp;
                                        <span className="item-xsmall item-medium item-uppercase item-orange">Devuelto</span>
                                    </Fragment>
                                    :item["status"]==5?
                                    <Fragment>
                                        &nbsp;<span className="item-point item-point-orange"></span>&nbsp;
                                        <span className="item-xsmall item-medium item-uppercase item-orange">Devuelto parcialmente</span>
                                    </Fragment>
                                    :<Fragment></Fragment>
                                )
                            :item["operation"]=="refund"?
                                <Fragment>
                                    &nbsp;<span className="item-point item-point-green"></span>&nbsp;
                                    <span className="item-xsmall item-medium item-uppercase item-green">Aceptada</span>
                                </Fragment>
                            :item["operation"]=="partial_refund"?
                                <Fragment>
                                    &nbsp;<span className="item-point item-point-green"></span>&nbsp;
                                    <span className="item-xsmall item-medium item-uppercase item-green">Aceptada</span>
                                </Fragment>
                            :""}
                            {item["rejection"]!=0 &&
                            <div className="rejection-button" onClick={(event)=>{event.preventDefault();event.stopPropagation();changeModal("rejection-info","",item["rejection"])}}>
                                &nbsp;<div className="rejection" title="Contracargado"><img src={notOkIcon} /></div>&nbsp;
                                <span className="item-xsmall item-medium item-uppercase item-red">Contracargado</span>
                            </div>}
                        </div>
                        <div>
                            <span className="item-violet"><span className="item-medium">{item["shop"]!="" && item["shop"]["name"]}</span>{(item["point"]!="" && item["point"]["show"]==1) && " ("+item["point"]["name"]+")"}</span>
                        </div>
                        <div className="item-small">
                            <span className="item-medium">{humanDate(item["created"])}</span>&nbsp;
                            <span className="item-medium">
                                <Symbol element="card" context="tiny" reference={ item["payment"]["class"] === "qrInteroperableTransfer" ? "qr" : item["payment"]["class"] === "debin" ? "debin" : item["payment"]["card_has_picture"] ? item["payment"]["card_id"] : 0 } />
                                {item["payment"]!=""?(item["payment"]["name"]!=""?item["payment"]["name"]:"‒"):"‒"}
                                {item["installments"]>1 &&" / "+item["installments"]+" cuotas"}
                            </span>&nbsp;
                            <span>
                                {(item["operation"]=="refund" || item["operation"]=="partial_refund")?
                                    "(Ref. pago #"+String(item["reference"]).padStart(6,"0")+")"
                                :(
                                    item["origin"]=="link"?"(Link de pago)"
                                    :item["origin"]=="qr"?"(Pago con QR)"
                                    :item["origin"]=="webpos"?"(Webpos)"
                                    :item["origin"]=="subscription"?"(Suscripción)"
                                    :item["origin"]=="integration"?"(Integración)"
                                    :item["origin"]=="backend"?"(Backend)"
                                    :item["origin"]=="externalCheckout"?"(WooCommerce)"
                                    :"‒"
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="item-data-center">
                        <Amount number={item["amount"]} currency={item["currency"]} operation={
                            item["operation"]=="payment"?(
                                item["status"]==1?"payment"
                                :item["status"]==0?"pending_payment"
                                :item["status"]==2?"rejected"
                                :item["status"]==3?"canceled"
                                :item["status"]==4?"payment"
                                :item["status"]==5?"payment"
                                :""
                            )
                            :item["operation"]=="refund"?"refund"
                            :item["operation"]=="partial_refund"?"refund"
                            :""
                        } />
                    </div>
                </div>
            </Link>}

            {config.section=="liquidations" &&
            <Link className="item item-row" onClick={clickEvent} to={linkTo}>
                <div><Icon element="liquidation" context="medium" /></div>
                <div className="item-data">
                    <div className="item-data-full">
                        <div>
                            <span className="item-semibold">Liquidación #{String(item["liquidation_number"]).padStart(10,"0")}</span> ({formatDate(item["liquidation_date"],"standard")}) {item["liquidation_type"]=="card" && <Fragment><span className="item-gray">/</span> <span className="item-xsmall item-uppercase item-gray">{item["sales"]+(item["sales"]==1?" operación":" operaciones")}</span></Fragment>}
                        </div>
                        <div>
                            <span className="item-violet">
                                <span className="item-medium">{item["shop"]!="" && item["shop"]["name"]}</span> {(item["point"]!="" && item["point"]["show"]==1) && " ("+item["point"]["name"]+")"}
                            </span>
                        </div>
                        <div className="item-small">
                            {item["liquidation_type"]=="card" &&
                            <Fragment><Symbol element="card" context="tiny" reference={item["card"]["has_picture"]?item["card"]["id"]:0} /><span className="item-medium">{item["card"]["name"]}</span> <span className="item-medium">({item["company"]})</span> / {item["bank"]!=""?item["bank"]:"‒"}</Fragment>}
                            {item["liquidation_type"]=="debin" &&
                            <Fragment><Symbol element="card" context="tiny" reference="debin" /><span className="item-medium">Debin</span> <span className="item-medium">(Cajero24)</span> / N° {item["operation_number"]!=""?item["operation_number"]:"‒"}</Fragment>}
                        </div>
                    </div>
                    <div className="item-data-center">
                        <Amount number={item["total_accredited"]} currency="ARS" operation="liquidation" />
                    </div>
                </div>
            </Link>}

            {config.section=="records" &&
            <div className="item item-row item-record">
                <div className="item-data item-record-1">
                    <div className="item-align-right">
                        {formatDate(item["created"],"standard")}
                        <div className="item-gray item-small">{formatDate(item["created"],"time")}</div>
                    </div>
                    <div className="item-data-full">
                        <div>
                            <span className="item-semibold">{item["detail"]}</span>
                            {item["reference"]!=0 && <span className="item-tag" onClick={()=>{changeModal("transaction-info","",item["reference"])}}>Transacción #{String(item["reference"]).padStart(6,"0")}</span>}
                        </div>
                        <div>
                            <span className="item-violet">
                                {item["shop"]!="" && item["shop"]["name"]} {(item["point"]!="" && item["point"]["show"]==1) && " ("+item["point"]["name"]+")"}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="item-record-2">
                    <div className="item-data-center item-justify-right">
                        <Amount number={item["amount"]} currency="ARS" operation={item["amount"]<0?"negative":"positive"} />
                    </div>
                    <div className="item-data-center item-justify-right">
                        <Amount number={item["balance"]} currency="ARS" operation="balance" />
                    </div>
                </div>
            </div>}

            {config.section=="shops" &&
            <Link className={"item item-block"+((item["status"]==0 || item["status"]==2 || item["status"]==-1)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div className="item-block-icon">
                    <Avatar element="shop" context="big" id={item["id"]} picture={item["has_picture"]} color="" capitals={item["capitals"]} />
                </div>
                <div className="item-block-data">
                    <div>
                        <div className="item-title">{item["name"]}</div>
                        <div className="item-gray item-medium">{item["description"]}</div>
                    </div>
                </div>
            </Link>}
            
            {config.section=="users" &&
            <Link className={"item item-row"+((item["status"]==0 || item["status"]==2 || item["status"]==-1)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div>
                    <Avatar element="user" context="medium" id={item["id"]} picture={item["has_picture"]} color="#B7FAFF" capitals={item["capitals"]} />
                </div>
                <div className="item-data">
                    <div>
                        <div><span className="item-semibold">{item["first_name"]} {item["last_name"]}</span> (#{item["id"]}) <span className={"item-point "+(item["status"]==1?"item-point-green":item["status"]==0?"item-point-yellow":(item["status"]==2 || item["status"]==-1)?"item-point-red":"")}></span> <span className={"item-xsmall item-medium item-uppercase "+(item["status"]==1?"item-green":item["status"]==0?"item-yellow":(item["status"]==2 || item["status"]==-1)?"item-red":"")}>{item["status"]==1?"Activo":item["status"]==0?"Inactivo":item["status"]==2?"Bloqueado":item["status"]==-1?"Eliminado":""}</span></div>
                        <div className="item-semibold item-violet">{item["email"]}</div>
                        {item["location"]!="" &&
                        <div>
                            {item["location"]["region"]!="" && item["location"]["region"]+", "}
                            {item["location"]["country"]!="" && item["location"]["country"]}.
                        </div>}
                        <div className="item-gray item-small"><span className="item-medium">Activo {humanDate(item.activity).toLowerCase()}</span> ({item["transactions"]} {item["transactions"]!=1?"transacciones":"transacción"})</div>
                    </div>
                </div>
            </Link>}

            {config.section=="payments" &&
            <Link className={"item item-row"+((item["status"]==0 || item["status"]==-1)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div>
                    <Symbol element="card" context="medium" reference={item["class"]=="debin"?"debin":(item["card_has_picture"]?item["card_id"]:0)} />
                </div>
                <div className="item-data">
                    <div>
                        <span className="item-semibold">{item["name"]}</span>
                        <div className="item-small item-violet">{item["card_name"]}, {item["entity_name"]} ({item["class"]=="credit"?"Crédito":item["class"]=="debit"?"Débito":item["class"]=="debin"?"Debin":"‒"})</div>
                        <div className="item-gray">({item["transactions"]} {item["transactions"]!=1?"transacciones":"transacción"})</div>
                    </div>
                </div>
            </Link>}

            {config.section=="cards" &&
            <Link className="item item-row" onClick={clickEvent} to={linkTo}>
                <div>
                    <Symbol element="card" context="medium" reference={item["has_picture"]?item["id"]:0} />
                </div>
                <div className="item-data">
                    <div>
                        <span className="item-semibold">{item["name"]}</span>
                        <div className="item-small item-violet">
                            {item["payments"]} {item["payments"]!=1?"medios de pago asociados":"medio de pago asociado"}
                        </div>
                        <div className="item-gray">({item["transactions"]} {item["transactions"]!=1?"transacciones":"transacción"})</div>
                    </div>
                </div>
            </Link>}

            {config.section=="entities" &&
            <Link className="item item-row" onClick={clickEvent} to={linkTo}>
                <div>
                    <Symbol element="entity" context="medium" reference={item["has_picture"]?item["id"]:0} />
                </div>
                <div className="item-data">
                    <div>
                        <span className="item-semibold">{item["name"]}</span>
                        <div className="item-small item-violet">
                            {item["payments"]} {item["payments"]!=1?"medios de pago asociados":"medio de pago asociado"}
                        </div>
                        <div className="item-gray">({item["transactions"]} {item["transactions"]!=1?"transacciones":"transacción"})</div>
                    </div>
                </div>
            </Link>}

            {config.section=="links" &&
            <Link className={"item item-row"+((item["status"]==0)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div>
                    <Icon element="link" context="medium" />
                </div>
                <div className="item-data item-data-alt">
                    <div className="item-data-full">
                        <div>
                            <span className="item-semibold">{item["name"]}</span> ({formatAmount(item["amount"],"ARS","display")})&nbsp;
                            {linkStatus({status:item.status,usage_limit:item.usage_limit,start_date:item.start_date,end_date:item.end_date})=="active"?
                            <Fragment>
                                <span className="item-point item-point-green"></span> <span className="item-xsmall item-medium item-uppercase item-green">Activo ({item["usages"]}{item["usage_limit"]>0 && "/"+item["usage_limit"]} {item["usages"]!=1?"usos":"uso"})</span>
                            </Fragment>
                            :linkStatus({status:item.status,usage_limit:item.usage_limit,start_date:item.start_date,end_date:item.end_date})=="inactive"?
                            <Fragment>
                                <span className="item-point item-point-red"></span> <span className="item-xsmall item-medium item-uppercase item-red">Inactivo ({item["usages"]}{item["usage_limit"]>0 && "/"+item["usage_limit"]} {item["usages"]!=1?"usos":"uso"})</span>
                            </Fragment>
                            :linkStatus({status:item.status,usage_limit:item.usage_limit,start_date:item.start_date,end_date:item.end_date})=="limit_reached"?
                            <Fragment>
                                <span className="item-point item-point-yellow"></span> <span className="item-xsmall item-medium item-uppercase item-yellow">Límite alcanzado ({item["usages"]}{item["usage_limit"]>0 && "/"+item["usage_limit"]} {item["usages"]!=1?"usos":"uso"})</span>
                            </Fragment>
                            :linkStatus({status:item.status,usage_limit:item.usage_limit,start_date:item.start_date,end_date:item.end_date})=="waiting"?
                            <Fragment>
                                <span className="item-point item-point-yellow"></span> <span className="item-xsmall item-medium item-uppercase item-yellow">Desde el {formatDate(item["start_date"],"minimal")} ({item["usages"]}{item["usage_limit"]>0 && "/"+item["usage_limit"]} {item["usages"]!=1?"usos":"uso"})</span>
                            </Fragment>
                            :linkStatus({status:item.status,usage_limit:item.usage_limit,start_date:item.start_date,end_date:item.end_date})=="expired"?
                            <Fragment>
                                <span className="item-point item-point-yellow"></span> <span className="item-xsmall item-medium item-uppercase item-yellow">Vencido el {formatDate(item["end_date"],"minimal")} ({item["usages"]}{item["usage_limit"]>0 && "/"+item["usage_limit"]} {item["usages"]!=1?"usos":"uso"})</span>
                            </Fragment>
                            :<Fragment></Fragment>}
                        </div>
                        <div className="item-small">
                            {item["description"]}{item["reference"]!="" && <span className="item-medium"> ({item["reference"]})</span>}
                        </div>
                        <div className="item-small item-violet">
                            <span className="item-medium">{item["shop_name"]!=""?item["shop_name"]:"‒"}</span> ({item["point_name"]!=""?item["point_name"]:"‒"})
                        </div>
                    </div>
                    <div className="item-data-center">
                        {linkStatus({status:item.status,usage_limit:item.usage_limit,start_date:item.start_date,end_date:item.end_date})=="active" && <ButtonRounded label="Compartir" icon="share" color="151,72,163" textColor="#FFFFFF" clickEvent={(event)=>{event.preventDefault();event.stopPropagation();changeModal("share","link",item["id"])}} />}
                    </div>
                </div>
            </Link>}

            {config.section=="subscriptions" &&
            <Link className={"item item-row"+((item["status"]==0)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div>
                    <Icon element="subscription" context="medium" />
                </div>
                <div className="item-data item-data-alt">
                    <div className="item-data-full">
                        <span className="item-semibold">{item["name"]}</span> ({formatAmount(item["amount"],"ARS","display")}) / {item["frequency"]=="weekly"?"Semanal":item["frequency"]=="biweekly"?"Quincenal":item["frequency"]=="monthly"?"Mensual":item["frequency"]=="bimonthly"?"Bimestral":item["frequency"]=="trimestral"?"Trimestral":item["frequency"]=="quarterly"?"Cuatrimestral":item["frequency"]=="biannual"?"Semestral":item["frequency"]=="annual"?"Anual":"‒"}
                        <div className="item-small">
                            {item["description"]}
                        </div>
                        <div className="item-small item-violet">
                            <span className="item-medium">{item["shop_name"]!=""?item["shop_name"]:"‒"}</span> ({item["point_name"]!=""?item["point_name"]:"‒"}) / {item["subscribers"]} {item["subscribers"]!=1?"suscriptores":"suscriptor"} ‒ <span className="item-small item-violet item-button item-medium" onClick={(event)=>{event.preventDefault();changeModal("subscription-edit","",item["id"])}}>Editar</span>
                        </div>
                    </div>
                    <div className="item-data-center">
                        {item["status"]==1 && <ButtonRounded label="Compartir" icon="share" color="151,72,163" textColor="#FFFFFF" clickEvent={(event)=>{event.preventDefault();event.stopPropagation();changeModal("share","subscription",item["id"])}} />}
                    </div>
                </div>
            </Link>}

            {config.section=="subscribers" &&
            <Link className={"item item-row"+((item["status"]==0)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div>
                    <Avatar element="user" context="medium" id={item["user"]} picture={item["has_picture"]} color="#B7FAFF" capitals={item["capitals"]} />
                </div>
                <div className="item-data">
                    <div>
                        <div><span className="item-semibold">{item["first_name"]} {item["last_name"]}</span> <span className={"item-point "+(item["status"]==1?"item-point-green":item["status"]==0?"item-point-yellow":"")}></span> <span className={"item-xsmall item-medium item-uppercase "+(item["status"]==1?"item-green":item["status"]==0?"item-yellow":"")}>{item["status"]==1?"Activo":item["status"]==0?"Inactivo":""} ({item["payments"]} {item["payments"]!=1?"pagos":"pago"})</span></div>
                        <div className="item-medium item-violet">{item["email"]}</div>
                        <div className="item-gray item-small">Suscripto {humanDate(item["created"]).toLowerCase()}</div>
                    </div>
                </div>
            </Link>}

            {config.section=="faqs" &&
            <Link className="item item-row" onClick={clickEvent} to={linkTo}>
                <div className="item-data-single">
                    <div>
                        <span className="item-semibold">{item["name"]}</span> ({item["topics_quantity"]} {item["topics_quantity"]!=1?"temas":"tema"})
                    </div>
                </div>
            </Link>}

            {config.section=="faqs-topics" &&
            <Link className="item item-row" onClick={clickEvent} to={linkTo}>
                <div className="item-data-single">
                    <div>
                        <span className="item-semibold">{item["topic"]}</span>
                    </div>
                </div>
            </Link>}

            {config.section=="faqs-adm" &&
            <Link className={"item item-row"+((item["status"]==0 || item["status"]==-1)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div className="item-data-single">
                    <div>
                        <span className="item-semibold">{item["name"]}</span> ({item["topics_quantity"]} {item["topics_quantity"]!=1?"temas":"tema"})<br />
                        <div className="item-small item-violet item-button" onClick={(event)=>{event.preventDefault();changeModal("faqs-group-edit","",item["id"])}}>Editar</div>
                    </div>
                </div>
            </Link>}

            {config.section=="faqs-adm-topics" &&
            <Link className={"item item-row"+((item["status"]==0 || item["status"]==-1)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div className="item-data-single">
                    <div>
                        <span className="item-semibold">{item["topic"]}</span>
                    </div>
                </div>
            </Link>}

            {config.section=="news" &&
            <Link className={"item item-row"+((item["status"]==0 || item["status"]==-1)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div className="item-data-single">
                    <div>
                        <div className="item-small item-violet item-medium">{item["tag"]!=""?item["tag"]:"‒"}</div>
                        <span className="item-semibold">{item["title"]}</span>
                    </div>
                </div>
            </Link>}

            {config.section=="articles" &&
            <Link className={"item item-row"+((item["status"]==0 || item["status"]==-1)?" item-off":"")} onClick={clickEvent} to={linkTo}>
                <div className="item-data-single">
                    <div>
                        <div className="item-small item-violet"><span className="item-medium">{item["tagline"]!=""?item["tagline"]:"‒"}</span> ({item["author"]!=""?item["author"]:"‒"})</div>
                        <span className="item-semibold">{item["title"]}</span>
                    </div>
                </div>
            </Link>}

            {config.section=="tags" &&
            <Link className={"item item-row"} onClick={clickEvent} to={linkTo}>
                <div className="item-data-single">
                    <div>
                        <span className="item-semibold">{item["name"]}</span> <span className="item-violet">({item["shops"]} {item["shops"]!=1?"comercios":"comercio"})</span>
                    </div>
                </div>
            </Link>}

            {config.section=="agreements" &&
            <Link className={"item item-row"} onClick={clickEvent} to={linkTo}>
                <div className="item-data-single">
                    <div>
                        <span className="item-semibold">{item["name"]}</span> <span className="item-violet">({item["points"]} {item["points"]!=1?"puntos de cobro":"punto de cobro"})</span>
                    </div>
                </div>
            </Link>}

            {config.section=="tickets" &&
            <Link className="item item-row" onClick={clickEvent} to={linkTo}>
                <div>
                    {item["shop_data"]!=""?<Avatar element="shop" context="medium" id={item["shop_data"]["id"]} picture={item["shop_data"]["has_picture"]} color="#B7FAFF" capitals="" />
                    :item["user_data"]!=""?<Avatar element="user" context="medium" id={item["user_data"]["id"]} picture={item["user_data"]["has_picture"]} color="#B7FAFF" capitals={item["user_data"]["capitals"]} />
                    :<Avatar element="user" context="medium" id="" picture="" color="#B7FAFF" capitals="" />}
                </div>
                <div className="item-data">
                    <div>
                        <div>
                            <span className="item-semibold">Ticket #{item["id"]}</span>&nbsp;
                            {item["status"]==1 &&
                            <Fragment>
                                <span className="item-point item-point-orange"></span> <span className="item-xsmall item-medium item-uppercase item-orange">En espera</span>
                            </Fragment>}
                            {item["status"]==2 &&
                            <Fragment>
                                <span className="item-point item-point-green"></span> <span className="item-xsmall item-medium item-uppercase item-green">Abierto ({item["responses"]} {item["responses"]!=1?"respuestas":"respuesta"})</span>
                            </Fragment>}
                            {item["status"]==3 &&
                            <Fragment>
                                <span className="item-point item-point-yellow"></span> <span className="item-xsmall item-medium item-uppercase item-yellow">Resuelto ({item["responses"]} {item["responses"]!=1?"respuestas":"respuesta"})</span>
                            </Fragment>}
                        </div>
                        <div>
                            <span className="item-violet"><span className="item-medium">{item["shop_data"]!=""?item["shop_data"]["name"]:item["user_data"]!=""?(item["user_data"]["first_name"]+" "+item["user_data"]["last_name"]):"‒"}</span> ({item["point_data"]!=""?item["point_data"]["name"]:"‒"})</span>
                        </div>
                        <div className="item-gray item-small"><span className="item-medium">Iniciado {humanDate(item.created).toLowerCase()}</span></div>
                    </div>
                </div>
            </Link>}

            {config.section=="messages" &&
            <Link className="item item-row" onClick={clickEvent} to={linkTo}>
                <div>
                    <Icon element="message" context="medium" />
                </div>
                <div className="item-data">
                    <div>
                        <div>
                            <span className="item-semibold">Mensaje #{item["id"]}</span>&nbsp;
                            {item["status"]==1 &&
                            <Fragment>
                                <span className="item-point item-point-orange"></span> <span className="item-xsmall item-medium item-uppercase item-orange">Recibido</span>
                            </Fragment>}
                            {item["status"]==2 &&
                            <Fragment>
                                <span className="item-point item-point-green"></span> <span className="item-xsmall item-medium item-uppercase item-green">En proceso ({item["responses"]} {item["responses"]!=1?"respuestas":"respuesta"})</span>
                            </Fragment>}
                            {item["status"]==3 &&
                            <Fragment>
                                <span className="item-point item-point-yellow"></span> <span className="item-xsmall item-medium item-uppercase item-yellow">Finalizado ({item["responses"]} {item["responses"]!=1?"respuestas":"respuesta"})</span>
                            </Fragment>}
                        </div>
                        <div>
                            <span className="item-violet"><span className="item-medium">{item["name"]!=""?item["name"]:"‒"}</span> ({item["company"]!=""?item["company"]:"‒"})</span>
                        </div>
                        <div className="item-gray item-small"><span className="item-medium">Recibido {humanDate(item.created).toLowerCase()}</span></div>
                    </div>
                </div>
            </Link>}

            {config.section=="analytics" &&
            <div className={"item item-row-alt"+(item["element"]=="point"?" item-row-transparent":"")}>
                <div className="item-data">
                    <div className="item-data-full">
                        <span className="item-semibold">{item["name"]}</span>
                    </div>
                    <div className="item-data-center item-justify-right">
                        <span className="item-semibold item-violet">{item["transactions"]}</span>
                    </div>
                    <div className="item-data-center item-justify-right">
                        <Amount number={item["amounts_base"]} currency="ARS" operation="analytics-alt" />
                    </div>
                    <div className="item-data-center item-justify-right">
                        <Amount number={item["amounts"]} currency="ARS" operation="analytics" />
                    </div>
                    <div className="item-data-center item-justify-right">
                        <Amount number={item["charges"]} currency="ARS" operation="charges" />
                    </div>
                </div>
            </div>}

        </Fragment>
    )
    
}