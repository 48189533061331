import {NavLink} from "react-router-dom"
import activity from "../assets/activity.svg"
import webpos from "../assets/webpos.svg"
import links from "../assets/links.svg"
import subscriptions from "../assets/subscriptions.svg"
import qr from "../assets/qr.svg"

export default function ButtonStart({label,context,to}){

    let icon=""
    if(context=="activity"){
        icon=activity
    }else if(context=="webpos"){
        icon=webpos
    }else if(context=="links"){
        icon=links
    }else if(context=="subscriptions"){
        icon=subscriptions
    }else if(context=="qr"){
        icon=qr
    }

    return(
        <NavLink className="shortcut" to={to}>
            <div className="shortcut-icon"><img src={icon} /></div>
            <div className="shortcut-caption">{label}</div>
        </NavLink>
    )
    
}