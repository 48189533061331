import {useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Entities(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("entity-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="Entidades"
                intro="Entidades bancarias emisoras de medios de pago."
                back=""
                button={{label:"Nueva entidad",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Medios de pago",labelShort:"Medios",to:"/c24/medios-de-pago"},
                    {label:"Tarjetas",to:"/c24/medios-de-pago/tarjetas"},
                    {label:"Entidades",to:"/c24/medios-de-pago/entidades"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"entities",
                    endpoint:"payments/entities",
                    limit:0,
                    counter:{
                        singular:"entidad",
                        plural:"entidades"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"entity-edit"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}