import {Fragment,useEffect,useState,useRef,useContext} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonSecondary from "../commons/ButtonSecondary"
import ButtonCommand from "../commons/ButtonCommand"
import Selector from "../commons/Selector"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import Divisor from "../commons/Divisor"
import Textbox from "../commons/Textbox"
import {DragDropContext,Droppable,Draggable} from "react-beautiful-dnd"
import SwitchMini from "../commons/SwitchMini"
import dragIcon from "../assets/drag.svg"

export default function Download(){

    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {changeModal,manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const mode=modalContext.context
    const [ready,setReady]=useState(0)
    const nameInput=useRef()
    const pointInput=useRef()
    const dataFormatInput=useRef()
    const intervalInput=useRef()
    const periodInput=useRef()
    const monthInput=useRef()
    const yearInput=useRef()
    const dateInput=useRef()
    const startDateInput=useRef()
    const endDateInput=useRef()
    const statusInput=useRef()
    const typeInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [nameVal,setNameVal]=useState("")
    const [pointVal,setPointVal]=useState()
    const [pointOptions,setPointOptions]=useState([])
    const [sectionVal,setSectionVal]=useState("")
    const [dataFormatVal,setDataFormatVal]=useState()
    const [intervalVal,setIntervalVal]=useState("")
    const [periodVal,setPeriodVal]=useState()
    const [monthVal,setMonthVal]=useState()
    const [yearVal,setYearVal]=useState()
    const [dateVal,setDateVal]=useState("")
    const [startDateVal,setStartDateVal]=useState("")
    const [endDateVal,setEndDateVal]=useState("")
    const [statusVal,setStatusVal]=useState()
    const statusOptions=[
        {label:"Aprobadas",value:"approved"},
        {label:"Pendientes",value:"pending"},
        {label:"Devueltas",value:"refund"},
        {label:"Devueltas parcialmente",value:"partial_refund"},
        {label:"Canceladas",value:"canceled"},
        {label:"Rechazadas",value:"rejected"},
        {label:"Liquidadas",value:"liquidated"},
        {label:"Sin liquidar",value:"non_liquidated"},
        {label:"Contracargadas",value:"countercharged"}
    ]
    const [typeVal,setTypeVal]=useState()
    const typeOptions=[
        {label:"Pagos",value:"payment"},
        {label:"Devoluciones",value:"refund"},
        {label:"Devoluciones parciales",value:"partial_refund"}
    ]
    const [rows,setRows]=useState([])
    const [defaultData,setDefaultData]=useState("")
    const dataFormatOptions=[
        {label:"JSON",value:"json"},
        {label:"Excel",value:"excel"},
        {label:"CSV",value:"csv"},
        {label:"Texto plano",value:"text"},
        {label:"Texto plano (Guadalupe)",value:"text_guadalupe"},
        {label:"Texto plano (Muni Mza 1)",value:"text_munimza1"},
        {label:"Texto plano (Muni Mza 2)",value:"text_munimza2"},
        {label:"Texto plano (CEGC)",value:"text_cegc"},
        {label:"Texto plano (Major)",value:"text_major"}
    ]
    const intervalOptions=[
        {label:"Un período",value:"period"},
        {label:"Un mes/año",value:"month_year"},
        {label:"Una fecha específica",value:"date"},
        {label:"Un intervalo de fechas",value:"interval"}
    ]
    const periodOptions=[
        {label:"Última hora",value:"last_hour"},
        {label:"Hoy",value:"today"},
        {label:"Ayer",value:"yesterday"},
        {label:"Última semana",value:"last_week"},
        {label:"Últimos quince días",value:"last_15_days"},
        {label:"Este mes",value:"this_month"},
        {label:"Últimos tres meses",value:"last_three_months"},
        {label:"Últimos seis meses",value:"last_six_months"},
        {label:"Este año",value:"this_year"}
    ]
    const monthOptions=[
        {label:"Todos",value:""},
        {label:"Enero",value:"1"},
        {label:"Febrero",value:"2"},
        {label:"Marzo",value:"3"},
        {label:"Abril",value:"4"},
        {label:"Mayo",value:"5"},
        {label:"Junio",value:"6"},
        {label:"Julio",value:"7"},
        {label:"Agosto",value:"8"},
        {label:"Septiembre",value:"9"},
        {label:"Octubre",value:"10"},
        {label:"Noviembre",value:"11"},
        {label:"Diciembre",value:"12"}
    ]
    const yearOptions=[
        {label:"2022",value:"2022"},
        {label:"2023",value:"2023"},
        {label:"2024",value:"2024"},
        {label:"2025",value:"2025"},
        {label:"2026",value:"2026"},
        {label:"2027",value:"2027"},
        {label:"2028",value:"2028"},
        {label:"2029",value:"2029"},
        {label:"2030",value:"2030"},
        {label:"2031",value:"2031"},
        {label:"2032",value:"2032"},
        {label:"2033",value:"2033"},
        {label:"2034",value:"2034"},
        {label:"2034",value:"2034"}
    ]

    const loadPoints=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/shops_points",{context:"only_points"})
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }
    
    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("download/info",{
                            point:modalContext.reference["point"],
                            link_id:modalContext.reference["link_id"]
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadPoints,loadData].map(func=>func())).then((result)=>{
            let newOptions=[]
            result[0].forEach(function(shop){
                newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
            })
            setPointOptions(newOptions)
            pointInput.current.setValue({label:newOptions[0]["label"],value:newOptions[0]["value"]})
            setDefaultData(result[1])
            let defineRows=[
                {id:1,name:"Identificador único",alias:"id",field:"id",status:1},
                {id:2,name:"Fecha",alias:"fecha",field:"date",status:1},
                {id:3,name:"Estado",alias:"estado",field:"status",status:1},
                {id:4,name:"Nombre del pagador",alias:"nombre_pagador",field:"payer",status:1},
                {id:5,name:"Identificador del pagador",alias:"id_pagador",field:"payer_id",status:1},
                {id:6,name:"Comercio",alias:"comercio",field:"shop",status:1},
                {id:7,name:"Identificador del comercio",alias:"id_comercio",field:"shop_id",status:0},
                {id:8,name:"Punto de cobro",alias:"punto_cobro",field:"point",status:1},
                {id:9,name:"Identificador del punto de cobro",alias:"id_punto_cobro",field:"point_id",status:0},
                {id:10,name:"Nombre del cajero",alias:"cajero",field:"cashier",status:0},
                {id:11,name:"Identificador del cajero",alias:"id_cajero",field:"cashier_id",status:0},
                {id:12,name:"Tipo de operación",alias:"operacion",field:"operation_type",status:1},
                {id:13,name:"Origen de la operación",alias:"origen",field:"origin",status:1},
                {id:14,name:"Identificador del enlace de pago",alias:"id_enlace",field:"link_token",status:0},
                {id:15,name:"Identificador de la suscripción",alias:"id_suscripcion",field:"subscription_id",status:0},
                {id:16,name:"Medio de pago",alias:"medio_de_pago",field:"payment_name",status:1},
                {id:17,name:"Tipo de tarjeta",alias:"tipo_tarjeta",field:"card_type",status:1},
                {id:18,name:"Número de tarjeta",alias:"numero_tarjeta",field:"card_number",status:1},
                {id:19,name:"CBU/Alias (Debin)",alias:"cbu_alias",field:"cbu_alias",status:1},
                {id:20,name:"CUIT (Debin)",alias:"cuit",field:"cuit",status:0},
                {id:21,name:"Identificador de la transacción",alias:"uuid",field:"uuid",status:0},
                {id:22,name:"Número de autorización",alias:"numero_autorizacion",field:"authorization",status:0},
                {id:23,name:"Referencia externa",alias:"referencia",field:"external_reference",status:1},
                {id:24,name:"Ítems cobrados",alias:"items",field:"items",status:1},
                {id:25,name:"Ítem: nombre",alias:"item_nombre",field:"item_name",status:1},
                {id:26,name:"Ítem: monto",alias:"item_monto",field:"item_amount",status:1},
                {id:27,name:"Ítem: referencia externa",alias:"item_referencia",field:"item_external_reference",status:1},
                {id:28,name:"Moneda",alias:"moneda",field:"currency",status:0},
                {id:29,name:"Monto total",alias:"monto",field:"amount",status:1},
                {id:30,name:"Monto base",alias:"monto_base",field:"amount_base",status:1},
                {id:31,name:"Cantidad de cuotas",alias:"cuotas",field:"installments",status:1},
                {id:32,name:"Tasa de financiación",alias:"tasa_financiacion",field:"installments_fee",status:0},
                {id:33,name:"Cargo del servicio",alias:"cargo_servicio",field:"charge",status:1},
                {id:34,name:"Tasa de cargo del servicio",alias:"tasa_cargo_servicio",field:"charge_fee",status:0},
                {id:35,name:"Estado de liquidación",alias:"liquidacion",field:"liquidation",status:0},
                {id:36,name:"Fecha estimada de liquidación",alias:"liquidacion_estimada",field:"liquidation_estimated",status:0},
                {id:37,name:"Contracargado",alias:"contracargado",field:"rejection",status:0}
            ]
            setRows(defineRows)
            setSectionVal(modalContext.reference["section"])
            setReady(1)
            modalReady(1)
        })
    },[])

    const reorder=(startIndex,endIndex)=>{
        const result=Array.from(rows)
        const [removed]=result.splice(startIndex,1)
        result.splice(endIndex,0,removed)
        return result
    }

    const onDragEnd=(result)=>{
        if(!result.destination){
            return
        }
        const items=reorder(rows,result.source.index,result.destination.index)
        setRows(items)
    }

    const statusChange=(i)=>{
        const newRows=[...rows]
        if(newRows[i]["status"]==0){
            newRows[i]["status"]=1
        }else if(newRows[i]["status"]==1){
            newRows[i]["status"]=0
        }
        setRows(newRows)
    }

    const aliasChange=(event,i)=>{
        const newRows=[...rows]
        newRows[i]["alias"]=event.target.value
        setRows(newRows)
    }

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData["id"])
            setNameVal(defaultData["name"])
            setSectionVal(defaultData["section"])
            let newPointValue=""
            pointOptions.forEach(function(item){
                if(defaultData["point"]==item.value["point_id"]){
                    newPointValue={label:item.label,value:item.value}
                }
                pointInput.current.setValue(newPointValue)
            })
            let newDataFormatValue=""
            dataFormatOptions.forEach(function(item){
                if(defaultData["data_format"]==item.value){
                    newDataFormatValue={label:item.label,value:item.value}
                }
                dataFormatInput.current.setValue(newDataFormatValue)
            })
            let newIntervalValue=""
            intervalOptions.forEach(function(item){
                if(defaultData["period"]==item.value){
                    newIntervalValue={label:item.label,value:item.value}
                }
                intervalInput.current.setValue(newIntervalValue)
            })
            let newStatusValue=[]
            defaultData["transactions_status"].forEach(function(item){
                statusOptions.forEach(function(itemx){
                    if(item==itemx.value){
                        newStatusValue.push({label:itemx.label,value:itemx.value})
                    }
                })
            })
            statusInput.current.setValue(newStatusValue)
            let newTypeValue=[]
            typeOptions.forEach(function(item){
                defaultData["transactions_types"].forEach(function(itemx){
                    if(itemx==item.value){
                        newTypeValue.push({label:item.label,value:item.value})
                    }
                })
            })
            typeInput.current.setValue(newTypeValue)
            let newRows=[]
            defaultData["rows_scheme"].forEach(function(item){
                rows.forEach(function(row){
                    if(item["id"]==row["id"]){
                        newRows.push({id:row["id"],name:row["name"],alias:item["alias"],field:row["field"],status:1})
                    }
                })
            })
            rows.forEach(function(item){
                let exists=false
                newRows.forEach(function(row){
                    if(item["id"]==row["id"]){
                        exists=true
                    }
                })
                if(exists==false){
                    newRows.push({id:item["id"],name:item["name"],alias:item["alias"],field:item["field"],status:0})
                }
            })
            setRows(newRows)
        }
        if(mode=="create" && ready){
            statusInput.current.setValue([{label:"Aprobadas",value:"approved"}])
            typeInput.current.setValue([{label:"Pagos",value:"payment"}])
        }
    },[ready])

    useEffect(()=>{
        if(mode=="edit" && ready){
            if(intervalVal=="period"){
                let newPeriodValue=""
                periodOptions.forEach(function(item){
                    if(defaultData["period_date"]["period"]==item.value){
                        newPeriodValue={label:item.label,value:item.value}
                    }
                    periodInput.current.setValue(newPeriodValue)
                })
            }else if(intervalVal=="month_year"){
                if(defaultData["period_date"]["month"]!=0){
                    let newMonthValue=""
                    monthOptions.forEach(function(item){
                        if(defaultData["period_date"]["month"]==item.value){
                            newMonthValue={label:item.label,value:item.value}
                        }
                        monthInput.current.setValue(newMonthValue)
                    })
                }
                let newYearValue=""
                yearOptions.forEach(function(item){
                    if(defaultData["period_date"]["year"]==item.value){
                        newYearValue={label:item.label,value:item.value}
                    }
                    yearInput.current.setValue(newYearValue)
                })
            }else if(intervalVal=="date"){
                setDateVal(defaultData["period_date"]["date"])
            }else if(intervalVal=="interval"){
                setStartDateVal(defaultData["period_date"]["start"])
                setEndDateVal(defaultData["period_date"]["end"])
            }
        }
    },[intervalVal])

    const saveData=()=>{
        if(pointVal==undefined){
            pointInput.current.focus()
        }else{
            if(dataFormatVal==undefined){
                dataFormatInput.current.focus()
            }else{
                if(nameVal==""){
                    nameInput.current.focus()
                }else{
                    if(intervalVal==""){
                        intervalInput.current.focus()
                    }else{
                        if((intervalVal=="period") && periodVal==undefined){
                            periodInput.current.focus()
                        }else if((intervalVal=="month_year") && ((monthVal==undefined && yearVal==undefined) ||  (monthVal!=undefined && yearVal==undefined))){
                            if(monthVal==undefined){
                                monthInput.current.focus()
                            }else{
                                if(yearVal==undefined){
                                    yearInput.current.focus()
                                }                            
                            }
                        }else if((intervalVal=="date") && dateVal==""){
                            dateInput.current.focus()
                        }else if((intervalVal=="interval") && (startDateVal=="" || endDateVal=="")){
                            if(startDateVal==""){
                                startDateInput.current.focus()
                            }else{
                                if(endDateVal==""){
                                    endDateInput.current.focus()
                                }
                            }
                        }else{
                            requestApi("download/save",{
                                    point:pointVal,
                                    id:idVal,
                                    mode:mode,
                                    section:sectionVal,
                                    name:nameVal,
                                    data_format:dataFormatVal,
                                    interval:intervalVal,
                                    period:periodVal==undefined?"":periodVal,
                                    month:monthVal==undefined?"":monthVal,
                                    year:yearVal==undefined?"":yearVal,
                                    date:dateVal,
                                    start_date:startDateVal,
                                    end_date:endDateVal,
                                    transactions_status:statusInput.current.getValue(),
                                    transactions_types:typeInput.current.getValue(),
                                    rows:rows
                                })
                                .then(data=>{
                                    if(data.status=="success"){
                                        if(mode=="create"){
                                            changeToast("created_m","")
                                        }else if(mode=="edit"){
                                            changeToast("saved","")
                                        }
                                        changeModal("download-links","",{section:sectionVal,point:pointVal})
                                    }else{
                                        changeDialog("generic_error","","")
                                    }
                                })
                        }
                    }
                }
            }
        }
    }

    const remove=()=>{
        requestApi("download/remove",{
                point:pointVal,
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label={mode=="create"?"Generar enlace":"Guardar"} clickEvent={saveData} />
                <ButtonSecondary label="Ver enlaces" clickEvent={()=>{changeModal("download-links","",{section:sectionVal,point:pointVal})}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info noselect">
                    <ModalTitle title="Descarga dinámica" />
                    {mode=="create" &&
                    <Fragment>
                        <div className="modal-explanation modal-explanation-center">
                            Genera enlaces de descarga dinámicos que podrás usar en forma recurrente sin haber accedido al administrador de Cajero24, incluso podrás integrarlos a tu sistema de administración a través de la API (formato JSON). <strong>Los enlaces contienen las credenciales de acceso del punto de cobro seleccionado: deben ser utilizados en forma privada, no los compartas.</strong>
                        </div>
                        <Divisor />
                    </Fragment>}
                    <Selector val={pointVal} setVal={setPointVal} ref={pointInput} placeholder="Punto de cobro" searchable={1} options={pointOptions} />
                    <Divisor />
                    <Selector ref={dataFormatInput} val={dataFormatVal} setVal={setDataFormatVal} placeholder="Formato" searchable={0} options={dataFormatOptions} />
                    <Textbox ref={nameInput} label="Nombre identificativo" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Divisor />
                    <Selector ref={intervalInput} val={intervalVal} setVal={setIntervalVal} placeholder="Fecha" searchable={0} options={intervalOptions} />
                    {intervalVal=="period" &&
                    <Selector ref={periodInput} val={periodVal} setVal={setPeriodVal} placeholder="Período" searchable={0} options={periodOptions} />}
                    {intervalVal=="month_year" &&
                    <div className="modal-form-columns">
                        <Selector ref={monthInput} val={monthVal} setVal={setMonthVal} placeholder="Mes" searchable={0} options={monthOptions} />
                        <Selector ref={yearInput} val={yearVal} setVal={setYearVal} placeholder="Año" searchable={0} options={yearOptions} />
                    </div>}
                    {intervalVal=="date" &&
                    <Textbox ref={dateInput} label="Fecha" type="date" val={dateVal} setVal={setDateVal} disabled={false} />}
                    {intervalVal=="interval" &&
                    <div className="modal-form-columns">
                        <Textbox ref={startDateInput} label="Desde" type="date" val={startDateVal} setVal={setStartDateVal} disabled={false} />
                        <Textbox ref={endDateInput} label="Hasta" type="date" val={endDateVal} setVal={setEndDateVal} disabled={false} />
                    </div>}
                    <Divisor />
                    <Selector ref={statusInput} val={statusVal} setVal={setStatusVal} placeholder="Estados de las transacciones" multiple={1} searchable={0} options={statusOptions} />
                    <Selector ref={typeInput} val={typeVal} setVal={setTypeVal} placeholder="Tipos de transacciones" multiple={1} searchable={0} options={typeOptions} />
                    {(dataFormatVal!="text_guadalupe" && dataFormatVal!="text_munimza1" && dataFormatVal!="text_munimza2" && dataFormatVal!="text_major" && dataFormatVal!="text_cegc") &&
                    <Fragment>
                        <Divisor />
                        <Card title="Columnas" icon={{element:""}} action="" actionIcon="" actionClickEvent="">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable" direction="vertical">
                                    {(provided)=>(
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {rows.map((item,i)=>
                                                <Draggable key={i} draggableId={"draggable-"+i} index={i}>
                                                    {(provided)=>(
                                                        <div style={provided.draggableProps.style} className="card-row-draggable" ref={provided.innerRef} {...provided.draggableProps}>
                                                            <CardRow key={i} 
                                                                label={<SwitchMini label={item["name"]} initial={item["status"]} clickEvent={()=>statusChange(i)} />}
                                                                detail={<input className="card-textbox-mini" type="text" value={item["alias"]} onChange={(event)=>aliasChange(event,i)} />}>
                                                                <div className="card-row-drag" {...provided.dragHandleProps}><img src={dragIcon} /></div>
                                                            </CardRow>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </Card>
                    </Fragment>}
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}