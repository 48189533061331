import {useState,useEffect,useRef,Fragment} from "react"
import {osName,osVersion,browserName,browserVersion,mobileVendor,mobileModel,deviceType} from "react-device-detect"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import Countdown from "react-countdown"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useSession from "../hooks/useSession"
import Entrance from "../components/Entrance"
import EntranceBar from "../commons/EntranceBar"
import EntranceSteps from "../commons/EntranceSteps"
import EntranceButton from "../commons/EntranceButton"
import EntranceTextbox from "../commons/EntranceTextbox"
import EntranceHelp from "../commons/EntranceHelp"
import Avatar from "../commons/Avatar"
import back from "../assets/back.svg"

export default function Login(){

    const {validateSession}=useSession()
    useEffect(()=>{
        validateSession()
    },[])
    const requestApi=useApi()
    const {initSession}=useSession()
    const {changeDialog}=useDialog()
    const [loginStep,setloginStep]=useState("username")
    const usernameInput=useRef()
    const passwordInput=useRef()
    const [usernameVal,setUsernameVal]=useState("")
    const [passwordVal,setPasswordVal]=useState("")
    const [welcomeName,setWelcomeName]=useState("")
    const [avatarId,setAvatarId]=useState("")
    const [avatarPicture,setAvatarPicture]=useState(false)
    const [avatarCapitals,setAvatarCapitals]=useState("")
    const [resetCountdown,setResetCountdown]=useState(false)
    const [activateCountdown,setActivateCountdown]=useState(false)
    const [firstTimeCountdown,setFirstTimeCountdown]=useState(false)

    const password=(event)=>{
        event.preventDefault()
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(usernameVal)){
            requestApi("users/login",{
                    email:usernameVal
                })
                .then(data=>{
                    setWelcomeName("")
                    if(data.status=="active"){
                        setWelcomeName(data.first_name)
                        setAvatarId(data.user_id)
                        setAvatarPicture(data.has_picture)
                        setAvatarCapitals(data.capitals)
                        setloginStep("password")
                        setTimeout(
                            ()=>{passwordInput.current.focus()
                        },500)
                    }else if(data.status=="first_time"){
                        setWelcomeName(data.first_name)
                        setAvatarId(data.user_id)
                        setAvatarPicture(data.has_picture)
                        setAvatarCapitals(data.capitals)
                        setloginStep("first_time")
                    }else if(data.status=="not_exist"){
                        //setloginStep("new")
                        //setActivateCountdown(true)
                        changeDialog("user_not_exists","","")
                    }else if(data.status=="inactive"){
                        changeDialog("user_inactive","","")
                    }else if(data.status=="blocked"){
                        changeDialog("user_blocked","","")
                    }else if(data.status=="deleted"){
                        changeDialog("user_deleted","","")
                    }
                })
        }else{
            usernameInput.current.focus()
        }
    }

    const authenticate=(event)=>{
        event.preventDefault()
        if(passwordVal.length>3){
            requestApi("users/authorize",{
                    email:usernameVal,
                    password:passwordVal,
                    os_name:osName,
                    os_version:osVersion,
                    browser_name:browserName,
                    browser_version:browserVersion,
                    device_type:deviceType,
                    device_vendor:mobileVendor,
                    device_model:mobileModel
                })
                .then(data=>{
                    if(data.status=="not_authorized"){
                        changeDialog("user_not_authorized","","")
                    }else if(data.status=="wrong_password"){
                        changeDialog("user_wrong_password","","")
                        setPasswordVal("")
                    }else if(data.status=="authorized"){
                        initSession("login",data.user_id,data.session_token,data.session_token_id,"/c24/tablero")
                    }
                })
        }else{
            passwordInput.current.focus()
        }
    }

    const stepBack=()=>{
        if(loginStep=="password"){
            setloginStep("username")
        }else if(loginStep=="first_time"){
            setloginStep("username")
        }else if(loginStep=="new"){
            setloginStep("username")
        }else if(loginStep=="reset"){
            setloginStep("password")
        }
    }
    
    const resetPassword=()=>{
        if(resetCountdown==false){
            requestApi("users/password_reset_token",{
                    context:"forget",
                    email:usernameVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        setResetCountdown(true)
                    }else if(data.status=="failed"){
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const createPassword=()=>{
        if(firstTimeCountdown==false){
            requestApi("users/password_reset_token",{
                    context:"auto_generated",
                    email:usernameVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        setFirstTimeCountdown(true)
                    }else if(data.status=="failed"){
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    return(
        <Fragment>
            <Entrance>
                <EntranceBar>
                    {loginStep!="username" && 
                    <div className="entrance-back" onClick={stepBack}><img src={back} alt="Volver" /></div>}
                </EntranceBar>
                <EntranceSteps>
                    {loginStep=="username" && 
                    <div className="entrance-step">
                        <div className="entrance-caption">
                            <strong>¡Te damos la bienvenida!</strong> Para ingresar con tu cuenta o crear una nueva, <strong>escribe tu correo electrónico.</strong>
                        </div>
                        <form>
                            <EntranceTextbox ref={usernameInput} placeholder="Email:" type="text" val={usernameVal} onChange={(event)=>{setUsernameVal(event.target.value)}} />
                            <EntranceButton label="Continuar" clickEvent={password} style="primary" arrow="next" />
                            <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                        </form>
                    </div>}
                    {loginStep=="password" && 
                    <div className="entrance-step">
                        <div className="entrance-caption">
                            <Avatar element="user" context="login" id={avatarId} picture={avatarPicture} color="" capitals={avatarCapitals} />
                            {welcomeName!="" && <div>¡Hola, <strong>{welcomeName}</strong>!</div>}
                            {welcomeName=="" && <div>¡Hola!</div>}
                        </div>
                        <form>
                            <EntranceTextbox ref={passwordInput} placeholder="Contraseña:" type="password" val={passwordVal} onChange={(event)=>{setPasswordVal(event.target.value)}} />
                            <EntranceButton label="Continuar" style="primary" arrow="next" clickEvent={authenticate} />
                            <EntranceHelp label="¿Olvidaste tu contraseña?" clickEvent={()=>{setloginStep("reset")}} link="" />
                        </form>
                    </div>}
                    {loginStep=="first_time" && 
                    <div className="entrance-step">
                        <div className="entrance-caption entrance-caption-small">
                            <Avatar element="user" context="login" id={avatarId} picture={avatarPicture} color="" capitals={avatarCapitals} />
                            {firstTimeCountdown==false?
                            <Fragment>
                                <strong>{welcomeName}</strong>, es la primera vez que ingresás a <strong>Cajero24</strong>. Vamos a enviar un correo a <strong>{usernameVal}</strong> para crear una contraseña.
                                <div className="entrance-caption-note">
                                    Apretá en <strong>Enviar correo</strong> y seguí las indicaciones del email, recordá revisar la carpeta de spam.
                                </div>
                            </Fragment>:
                            <Fragment>
                                <strong>¡Enviado!</strong>
                                <div className="entrance-caption-note">
                                    Ingresá a <strong>{usernameVal}</strong>, buscá el correo de <strong>Cajero24</strong> y seguí las indicaciones. Si no lo encontrás, revisá la carpeta de spam o intentá reenviarlo.
                                </div>
                            </Fragment>}
                        </div>
                        {firstTimeCountdown && 
                        <div className="entrance-waiting">
                            <div>Reenviar en <Countdown date={Date.now()+300000} renderer={({minutes,seconds})=>{return <Fragment>{(Math.pow(10,2)+~~minutes).toString().substring(1)}:{(Math.pow(10,2)+~~seconds).toString().substring(1)}</Fragment>}} onComplete={()=>{setFirstTimeCountdown(false)}} /></div>
                        </div>}
                        {firstTimeCountdown==false && <EntranceButton label="Enviar correo" style="primary" arrow="none" clickEvent={createPassword} />}
                        <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                    </div>}
                    {loginStep=="new" && 
                    <div className="entrance-step">
                        <div className="entrance-caption entrance-caption-small">
                            <strong>¡Hola!</strong> Enviamos un correo a <strong>{usernameVal}</strong> con el código de activación para tu nueva cuenta.
                            <div className="entrance-caption-note">
                                Buscá el correo de <strong>Cajero24</strong> y seguí las indicaciones que allí aparecen. Si no lo encontrás, revisá la carpeta de spam o intentá reenviarlo.
                            </div>
                        </div>
                        {activateCountdown && 
                        <div className="entrance-waiting">
                            <div>Reenviar en <Countdown date={Date.now()+300000} renderer={({minutes,seconds})=>{return <Fragment>{(Math.pow(10,2)+~~minutes).toString().substring(1)}:{(Math.pow(10,2)+~~seconds).toString().substring(1)}</Fragment>}} onComplete={()=>{setActivateCountdown(false)}} /></div>
                        </div>}
                        {activateCountdown==false && <EntranceButton label="Reenviar correo" style="primary" arrow="none" clickEvent={password} />}
                    </div>}
                    {loginStep=="reset" && 
                    <div className="entrance-step">
                        <div className="entrance-caption entrance-caption-small">
                            {resetCountdown==false?
                            <Fragment>
                                Vamos a enviar un email a <strong>{usernameVal}</strong> para que podás recuperar tu contraseña.
                                <div className="entrance-caption-note">
                                    Apretá en <strong>Enviar correo</strong> y seguí las indicaciones del email, recordá revisar la carpeta de spam.
                                </div>
                            </Fragment>:
                            <Fragment>
                                <strong>¡Enviado!</strong>
                                <div className="entrance-caption-note">
                                    Ingresá a <strong>{usernameVal}</strong>, buscá el correo de <strong>Cajero24</strong> y seguí las indicaciones. Si no lo encontrás, revisá la carpeta de spam o intentá reenviarlo.
                                </div>
                            </Fragment>}
                        </div>
                        {resetCountdown && 
                        <div className="entrance-waiting">
                            <div>Reenviar en <Countdown date={Date.now()+300000} renderer={({minutes,seconds})=>{return <Fragment>{(Math.pow(10,2)+~~minutes).toString().substring(1)}:{(Math.pow(10,2)+~~seconds).toString().substring(1)}</Fragment>}} onComplete={()=>{setResetCountdown(false)}} /></div>
                        </div>}
                        {resetCountdown==false && <EntranceButton label="Enviar correo" style="primary" arrow="none" clickEvent={resetPassword} />}
                        {resetCountdown==false && <EntranceButton label="Cancelar" style="secondary" arrow="none" clickEvent={()=>{setloginStep("password")}} />}
                    </div>}
                </EntranceSteps>
            </Entrance>
            <Ga />
            <ConnectionChecker />
        </Fragment>
    )
    
}