import {useState,useRef,useContext,Fragment} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useModal from "../hooks/useModal"
import useToast from "../hooks/useToast"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import ModalTitle from "../components/ModalTitle"
import Textbox from "../commons/Textbox"

export default function UserPassword(){

    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const {changeDialog}=useDialog()
    const {changeModal}=useModal()
    const {changeToast}=useToast()
    const requestApi=useApi()
    const passwordInput=useRef()
    const repasswordInput=useRef()
    const [passwordVal,setPasswordVal]=useState("")
    const [repasswordVal,setRepasswordVal]=useState("")
    const context=modalContext.context
    let userId=0
    if(context=="account"){
        userId=globalContext.user_id
    }else if(context=="user"){
        userId=modalContext.reference
    }

    const changePassword=()=>{
        if(passwordVal==""){
            passwordInput.current.focus()
        }else{
            if(repasswordVal==""){
                repasswordInput.current.focus()
            }else{
                if(passwordVal!=repasswordVal){
                    changeDialog("password_mismatch","","")
                }else{
                    if(passwordVal.length<7){
                        changeDialog("password_length","","")
                    }else{
                        requestApi("users/password_change",{
                                user_id:userId,
                                new_password:passwordVal
                            })
                            .then(data=>{
                                if(data.status=="success"){
                                    changeModal("user-info",modalContext.context,userId)
                                    changeToast("saved","")
                                }else{
                                    changeDialog("generic_error","","")
                                }
                            })
                    }
                }
            }
        }
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("user-info",modalContext.context,userId)}} />
                <ButtonPrimary label="Guardar" clickEvent={changePassword} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title="Cambiar contraseña" />
                    <Textbox ref={passwordInput} label="Nueva contraseña:" type="password" val={passwordVal} disabled={false} setVal={setPasswordVal} />
                    <Textbox ref={repasswordInput} label="Repetir contraseña:" type="password" val={repasswordVal} disabled={false} setVal={setRepasswordVal} />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}