import {useContext,useState,useEffect} from "react"
import GlobalContext from "../contexts/GlobalContext"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import {nl2p} from "../hooks/Commons"
import Section from "../components/Section"
import SectionContent from "../components/SectionContent"
import SectionTabs from "../components/SectionTabs"
import Networks from "../components/Networks";

export default function News(){

    const {checkPermission,checkShops}=usePermissions()
    const [globalContext]=useContext(GlobalContext)
    const requestApi=useApi()
    const [newsQuantity,setNewsQuantity]=useState(0)
    const [news,setNews]=useState()
    
    const loadStart=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("general/start",{})
                    .then(data=>{
                        return data
                    })
            )
        })
    }

    const loadNews=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("news/public",{})
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }

    useEffect(()=>{
        Promise.all([loadStart,loadNews].map(func=>func())).then((result)=>{
            setNewsQuantity(result[0]["news"])
            setNews(result[1])
        })
    },[])

    return(
        <Section>
            <SectionContent>
                <div className="content-focused">
                    <div className="greeting">Hola, {globalContext["user_name"]}.</div>
                    <SectionTabs tabs={
                        (checkPermission({alias:"master_admin",reference:""}) || checkShops())?
                        [{label:"Principal",to:"/c24/tablero"},
                        {label:"Novedades",to:"/c24/tablero/novedades",counter:newsQuantity}]:
                        [{label:"Novedades",to:"/c24/tablero/novedades",counter:newsQuantity}]
                    } />
                    <Networks context="news" />
                    {news &&
                    <div className="news">
                        {news.length>0?
                            news.map((item,i)=>{
                                return(
                                    <div key={i} className="news-item">
                                        {item["tag"]!="" && <div className="news-tag">{item["tag"]}</div>}
                                        <div className="news-title">{item["title"]}</div>
                                        <div className="news-content" dangerouslySetInnerHTML={{__html:nl2p(item["content"])}}></div>
                                    </div>
                                )
                            })
                        :<div className="news-empty">Conocé las últimas novedades en nuestras redes sociales...</div>}
                    </div>}
                </div>
            </SectionContent>
        </Section>
    )
    
}