import {useEffect,useState,useRef,useContext} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import IntegrationsImg from "../assets/integrations.svg"
import Selector from "../commons/Selector"
import ButtonBox from "../commons/ButtonBox"

export default function Integrations(){

    const [globalContext]=useContext(GlobalContext)
    const {changeModal}=useModal()
    const requestApi=useApi()
    const pointInput=useRef()
    const [pointVal,setPointVal]=useState()
    const [pointOptions,setPointOptions]=useState([])

    useEffect(()=>{
        requestApi("shops/shops_points",{context:"only_points"})
            .then(data=>{
                let newOptions=[]
                data.items.forEach(function(shop){
                    newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                })
                setPointOptions(newOptions)
                pointInput.current.setValue({label:newOptions[0]["label"],value:newOptions[0]["value"]})
            })
    },[])

    return(
        <Section>
            <SectionHeader
                title="Integraciones"
                intro="Conectá Cajero24 con tu sitio web."
                back=""
                button={{label:"Descargar guía",icon:"download",link:"",href:globalContext["base_url"]+"resources/Guia_Integracion_Cajero24.pdf",clickEvent:""}}
                tabs=""
            />
            <SectionContent>
                <div className="boxes">
                    <div className="box">
                        <div className="box-info">
                            <div className="box-title">Cobrá directamente desde tu sitio web</div>
                            <div className="box-description">Con esta herramienta podrás integrar el flujo de pagos de Cajero24 dentro del sitio web de tu comercio. El proceso de implementación es sencillo y se adapta perfectamente a la interfaz y experiencia de usuario de tu aplicación.</div>
                            <ButtonBox label="Guía de integración" clickEvent="" to="" link={globalContext["base_url"]+"resources/Guia_Integracion_Cajero24.pdf"} />
                        </div>
                        <div className="box-picture">
                            <img src={IntegrationsImg} />
                        </div>
                    </div>
                    <div className="box">
                        <div className="box-info">
                            <div className="box-description box-description-featured box-description-space">
                                Tus comercios tienen asignada una clave de acceso única requerida para realizar llamadas a la API de Cajero24 desde tu aplicación web.
                            </div>
                            <Selector val={pointVal} setVal={setPointVal} ref={pointInput} placeholder="Punto de cobro" searchable={1} options={pointOptions} style="gray" />
                            <ButtonBox label="Ver clave de acceso" clickEvent={()=>{changeModal("shop-access-token","",(pointVal?pointVal.point_id:""))}} to="" link="" />
                        </div>
                    </div>
                </div>
            </SectionContent>
        </Section>
    )
    
}