import {Fragment,useContext} from "react"
import {Link} from "react-router-dom"
import GlobalContext from "../contexts/GlobalContext"
import usePermissions from "../hooks/usePermissions"
import useModal from "../hooks/useModal"
import PanelButton from "../components/PanelButton"
import PanelGroup from "../components/PanelGroup"
import Avatar from "../commons/Avatar"
import brand from "../assets/brand-inverse.svg"
import options from "../assets/options.svg"

export default function Panel(){
    
    const [globalContext,setGlobalContext]=useContext(GlobalContext)
    const {checkPermission,checkShops}=usePermissions()
    const {changeModal}=useModal()

    const closePanel=()=>{
        let globalContextNew={...globalContext}
        globalContextNew["panel_opened"]=0
        setGlobalContext(globalContextNew)
    }

    return(
        <Fragment>
            <div className={"panel-bg"+(globalContext.panel_opened==1?" panel-bg-opened":"")} onClick={closePanel}></div>
            <div className={"panel noselect "+(globalContext.panel_opened==1?" panel-opened":"")} onClick={closePanel}>
                <div className="panel-brand">
                    <Link to="/c24/tablero" className="panel-brand-link"><img src={brand} /></Link>
                </div>
                <div className="panel-close">
                    <div className="panel-close-btn">
                        <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.16 83.32"><g id="c"><path d="M41.66,83.32c-1.46,0-2.86-.58-3.89-1.61L1.61,45.55c-2.15-2.15-2.15-5.63,0-7.78,0,0,0,0,0,0L37.77,1.61c2.15-2.15,5.63-2.15,7.78,0,2.15,2.15,2.15,5.63,0,7.78L13.28,41.66l32.27,32.27c2.15,2.15,2.15,5.63,0,7.78-1.03,1.03-2.43,1.61-3.89,1.61Z" fill="#fff"/></g></svg>
                    </div>
                </div>
                <div className="panel-nav">
                    <PanelButton label="Inicio" to="/c24/tablero" icon="start" />
                    {(checkPermission({alias:"master_admin",reference:""}) || checkShops()) &&
                    <Fragment>
                        <PanelButton label="Actividad" to="/c24/actividad" icon="activity" />
                        <PanelButton label="Comercios" to="/c24/comercios" icon="stores" />
                        <PanelButton label="Reportes" to="/c24/reportes" icon="reports" />
                    </Fragment>}
                    {(checkPermission({alias:"master_admin",reference:""})) &&
                    <Fragment>
                        <PanelButton label="Medios de pago" to="/c24/medios-de-pago" icon="payments" />
                        <PanelButton label="Usuarios" to="/c24/usuarios" icon="users" />
                    </Fragment>}
                    {(checkPermission({alias:"master_admin",reference:""}) || checkShops()) &&
                    <Fragment>
                        <PanelGroup label="Herramientas" />
                        <PanelButton label="Webpos" to="/c24/webpos" icon="webpos" />
                        <PanelButton label="Links de pago" to="/c24/links-de-pago" icon="links" />
                        {1==2 && <PanelButton label="Suscripciones" to="/c24/suscripciones" icon="subscriptions" />}
                        <PanelButton label="Pagos con QR" to="/c24/pagos-con-qr" icon="qr" />
                        <PanelButton label="Integraciones" to="/c24/integraciones" icon="integrations" />
                        <PanelButton label="Complementos" to="/c24/complementos" icon="addons" />
                    </Fragment>}
                    <PanelGroup label="Ayuda" />
                    <PanelButton label="Soporte" to="/c24/soporte" icon="support" />
                    {(checkPermission({alias:"master_admin",reference:""})) &&
                    <Fragment>
                        <PanelButton label="Asistencia" to="/c24/asistencia" icon="faqs" />
                        <PanelButton label="Mensajes" to="/c24/mensajes" icon="contact" />
                        <PanelButton label="Blog" to="/c24/articulos" icon="blog" />
                    </Fragment>}
                </div>
                <div className="panel-account" onClick={()=>{changeModal("user-info","account",globalContext.user_id)}}>
                    <div className="panel-account-avatar">
                        <Avatar element="user" context="panel" id={globalContext.user_id} picture={globalContext.user_has_picture} color="" capitals={globalContext.user_capitals} />
                    </div>
                    <div className="panel-account-name">{globalContext.user_name}</div>
                    <div className="panel-account-options"><img src={options} /></div>
                </div>
            </div>
        </Fragment>
    )
    
}