import usePermissions from "../hooks/usePermissions"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Shops(){
    
    const {checkPermission}=usePermissions()

    const {changeModal}=useModal()
    const edit=()=>{
        changeModal("shop-edit","",0)
    }
    
    return(
        <Section>
            <SectionHeader
                title="Comercios"
                intro="Entidades que operan con Cajero24."
                back=""
                button={
                    checkPermission({alias:"master_admin",reference:""})?
                    {label:"Nuevo comercio",icon:"create",link:"",href:"",clickEvent:edit}:""
                }
                tabs={
                    checkPermission({alias:"master_admin",reference:""})?
                    [{label:"Todos los comercios",labelShort:"Comercios",to:"/c24/comercios"},
                    {label:"Etiquetas",to:"/c24/comercios/etiquetas"},
                    {label:"Acuerdos comerciales",labelShort:"Acuerdos",to:"/c24/comercios/acuerdos"}]:
                    [{label:"Tus comercios",to:"/c24/comercios"}]
                }
            />
            <SectionContent>
                <List config={{
                    section:"shops",
                    endpoint:"shops/list",
                    limit:100,
                    counter:{
                        singular:"comercio",
                        plural:"comercios"
                    },
                    action:{
                        mode:"link",
                        context:"shop",
                        reference:"/c24/comercios/"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}