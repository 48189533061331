import Symbol from "../commons/Symbol"
import PaymentIcon from "../commons/PaymentIcon"

export default function CardRow({unique,label,detail,children,off,symbol,symbolExtra}){

    return(
        <div id={unique} className={"card-row"+(off==0?" card-row-off":"")}>
            <div className="card-row-left">
                {symbol && <div className="card-symbol">
                    {symbol.element=="installment"?
                    <PaymentIcon context="list" type={symbol.context} reference={symbol.reference} />:
                    <Symbol element={symbol.element} context={symbol.context} reference={symbol.reference} />}
                    {symbolExtra && 
                    <Symbol element={symbolExtra.element} context={symbolExtra.context} reference={symbolExtra.reference} />}
                </div>}
                <div className="card-row-left-content">
                    {label}
                    {detail!="" && <div className="card-detail">{detail}</div>}
                </div>
            </div>
            <div className="card-row-right">{children}</div>
        </div>
    )
    
}