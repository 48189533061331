import {useContext} from "react"
import GlobalContext from "../contexts/GlobalContext"
import debin from "../assets/transfer.svg"
import card from "../assets/card.svg"
import entity from "../assets/entity.svg"
import qr from "../assets/qr-payment.svg"

export default function Symbol({element,context,reference}){

    const [globalContext]=useContext(GlobalContext)
    let symbolUrl=""

    if(element=="card"){
        if(reference==0){
            symbolUrl=card
        }else{
            symbolUrl=globalContext["base_url"]+"resources/cards/"+reference+".svg"
        }
    }else if(element=="entity"){
        if(reference==0){
            symbolUrl=entity
        }else{
            symbolUrl=globalContext["base_url"]+"resources/entities/"+reference+".svg"
        }
    }

    if(reference=="debin"){
        symbolUrl=debin
    }

    if (reference === "qr") {
        symbolUrl = qr
    }

    return(
        <div className={"symbol"+(element=="card"?" symbol-circle":element=="entity"?" symbol-square":"")+(context=="small"?" symbol-small":context=="tiny"?" symbol-tiny":context=="card"?" symbol-card":"")} style={{backgroundImage:"url("+symbolUrl+")"}}></div>
    )
    
}