import {useState,useEffect,Fragment} from "react"

export default function ConnectionChecker(){

    const [isOnline,setIsOnline]=useState(navigator.onLine)

    useEffect(()=>{
        const handleStatusChange=()=>{
            setIsOnline(navigator.onLine)
        }
        window.addEventListener("online",handleStatusChange)
        window.addEventListener("offline",handleStatusChange)
        return()=>{
            window.removeEventListener("online",handleStatusChange)
            window.removeEventListener("offline",handleStatusChange)
        }
    },[isOnline])

    return(
        <Fragment>
            {!isOnline &&
            <div className="offline">
                <div>
                    <svg className="offline-spinner" viewBox="0 0 50 50">
                        <circle className="offline-spinner-path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
                    </svg>
                    <div className="offline-caption"><strong>Sin conexión a Internet.</strong><br />Podrás continuar usando Cajero24 cuando se retome la conexión...</div>
                </div>
            </div>}
        </Fragment>
    )
    
}