import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useApi from "../hooks/useApi"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Dropdown from "../commons/Dropdown"
import SwitchButton from "../commons/SwitchButton"
import close from "../assets/close-pink.svg"

export default function PaymentsFilters(){

    const {manageFloating,floatingReady}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const requestApi=useApi()
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const [filter1Val,setFilter1Val]=useState("")
    const [filter2Val,setFilter2Val]=useState([])
    const [filter3Val,setFilter3Val]=useState([])

    const [cardOptions,setCardOptions]=useState([])
    const [entityOptions,setEntityOptions]=useState([])

    const loadCards=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("payments/cards",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(card){
                            newOptions.push({label:card.name,value:card.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadEntities=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("payments/entities",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(entity){
                            newOptions.push({label:entity.name,value:entity.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    useEffect(()=>{
        floatingReady(0)
        Promise.all([loadCards,loadEntities].map(func=>func())).then((result)=>{
            setCardOptions(result[0])
            setEntityOptions(result[1])
            floatingReady(1)
        })
    },[])

    const apply=()=>{
        let filter2List=[]
        if(filter2.current.getValue()){
            filter2.current.getValue().forEach(function(item){
                filter2List.push(item["value"])
            })
        }
        let filter3List=[]
        if(filter3.current.getValue()){
            filter3.current.getValue().forEach(function(item){
                filter3List.push(item["value"])
            })
        }
        setValue({
            status:filter1Val,
            card:filter2List,
            entity:filter3List
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val("")
        setFilter2Val([])
        setFilter3Val([])
        filter2.current.setValue("")
        filter3.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(value!=""){
            if(value["status"]!=""){
                setFilter1Val(value["status"])
            }
        }
    },[value])

    useEffect(()=>{
        if(value){
            if(value["card"].length>0){
                let newValue=[];
                filter2.current.props["options"].forEach(function(item){
                    if(value["card"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter2.current.setValue(newValue)
            }
        }
    },[cardOptions])

    useEffect(()=>{
        if(value){
            if(value["entity"].length>0){
                let newValue=[];
                filter3.current.props["options"].forEach(function(item){
                    if(value["entity"].includes(item.value)){
                        newValue.push({label:item.label,value:item.value})
                    }
                })
                filter3.current.setValue(newValue)
            }
        }
    },[entityOptions])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-options floating-options-space">
                    <div className="floating-half">
                        <SwitchButton ref={filter1} val={filter1Val} setVal={setFilter1Val}
                            options={[
                                {label:"Activos",value:"active"},
                                {label:"Inactivos",value:"inactive"}
                        ]} />
                    </div>
                </div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter2} val={filter2Val} setVal={setFilter2Val} placeholder="Tarjeta" multiple={1} searchable={0} options={cardOptions} />
                        <Dropdown ref={filter3} val={filter3Val} setVal={setFilter3Val} placeholder="Entidad" multiple={1} searchable={0} options={entityOptions} />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}