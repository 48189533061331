import {Fragment,useContext,useEffect,useState} from "react"
import usePermissions from "../hooks/usePermissions"
import ReactJson from "react-json-view"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonBack from "../commons/ButtonBack"
import Card from "../components/Card"

export default function TransactionAnswer(){
    
    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {changeModal,modalReady}=useModal()
    const [info,setInfo]=useState()

    useEffect(()=>{
        modalReady(0)
        requestApi("transactions/answer",{
                transaction_id:modalContext.reference
            })
            .then(data=>{
                setInfo(data.data)
                modalReady(1)
            })
    },[])

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("transaction-info","",modalContext.reference)}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {info &&
                    <Fragment>
                        <ModalTitle title={"Transacción #"+info["uuid"]} />
                        <Card title="" icon={{element:""}} action="" actionIcon="" actionClickEvent="">
                            <div className="card-code">
                                <ReactJson src={JSON.parse(info["answer"])} enableClipboard={false} displayObjectSize={false} displayDataTypes={false} />  
                            </div>
                        </Card>
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}