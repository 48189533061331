import {forwardRef} from "react"
import Select from "react-select"

const Dropdown=forwardRef(({options,placeholder,multiple,searchable,val,setVal},ref)=>{

    let items=[]
    options.map((option,i)=>{
        items.push({value:option.value,label:option.label})
    })

    let isMulti=false;
    if(multiple){
        isMulti=true
    }

    const customStyles={
        control:(provided,state)=>({
            ...provided,
            minHeight:40,
            paddingLeft:(state.isMulti && state.hasValue)?"4px":"10px",
            borderRadius:"6px",
            backgroundColor:"#FFFFFF",
            boxShadow:"none",
            border:state.isFocused?"solid 2px rgba(98,77,227,1) !important":"solid 2px rgba(98,77,227,0.3)",
            "&:hover":{border:"solid 2px rgba(98,77,227,0.3)"},
            border:state.hasValue?"solid 2px rgba(98,77,227,1) !important":"solid 2px rgba(98,77,227,0.3)"
        }),
        input:(provided,state)=>({
            ...provided,
            paddingLeft:"0px",
            paddingBottom:"2px",
            color:"#624DE3",
            fontSize:"14px",
            lineHeight:"16px",
            fontWeight:500
        }),
        placeholder:(provided,state)=>({
            ...provided,
            position:"absolute",
            top:"10px",
            left:"0px",
            paddingLeft:"0px",
            paddingBottom:"0px",
            color:"#624DE3",
            fontWeight:500,
            fontSize:"14px",
            lineHeight:"16px"
        }),
        dropdownIndicator:(provided)=>({
            ...provided,
            color:"#624DE3",
            "&:hover":{color:"#624DE3"},
            "& svg":{width:"17px",height:"17px"}
        }),
        indicatorSeparator:(provided)=>({
            ...provided,
            backgroundColor:"rgba(98,77,227,0)"
        }),
        valueContainer:(provided,state)=>({
            ...provided,
            display:"flex",
            minHeight:"36px",
            padding:"0px",
            paddingTop:"5px",
            paddingBottom:"5px",
            paddingLeft:"0px",
            paddingRight:"0px",
            boxSizing:"border-box",
            flexWrap:"unset !important"
        }),
        singleValue:(provided,state)=>({
            ...provided,
            paddingTop:"0px",
            paddingBottom:"0px",
            paddingLeft:"0px",
            color:"#624DE3",
            fontSize:"14px",
            lineHeight:"16px",
            fontWeight:500
        }),
        multiValue:(provided,state)=>({
            ...provided,
            borderRadius:"100px",
            marginBottom:"2px",
            marginRight:"2px",
            backgroundColor:"rgba(209,99,171,0.2)"
        }),
        multiValueContainer:(provided,state)=>({
            ...provided
        }),
        multiValueLabel:(provided,state)=>({
            ...provided,
            fontSize:"15px",
            lineHeight:"15px",
            fontWeight:500,
            color:"#D163AB",
            paddingLeft:"10px",
            paddingTop:"4px",
            paddingBottom:"5px"
        }),
        multiValueRemove:(provided,state)=>({
            ...provided,
            color:"#D163AB",
            paddingRight:"8px",
            opacity:0.5,
            borderTopRightRadius:"100px",
            borderBottomRightRadius:"100px",
            transition:"0.18s",
            "&:hover":{backgroundColor:"rgba(209,99,171,0.2)",color:"#D163AB",opacity:1}
        }),
        menu:(provided,state)=>({
            ...provided,
            paddingTop:"0px",
            border:"none",
            borderRadius:"6px",
            overflow:"hidden",
            animationName:"selector-options-anima",
            animationDuration:"0.18s",
            boxShadow:"0px 0px 40px 0px rgba(50,23,122,0.35)"
        }),
        menuPortal:(provided)=>({
            ...provided,
            zIndex:9999
        }),
        menuList:(provided,state)=>({
            ...provided,
            padding:"0px",
            "&::-webkit-scrollbar":{
                width:"11px",
                height:"11px",
                borderRadius:"30px"
            },
            "&::-webkit-scrollbar-thumb":{
                background:"rgba(209,99,171,0.5)",
                borderRadius:"30px",
                border:"solid 3px #FFFFFF"
            },
            "&::-webkit-scrollbar-thumb:hover":{
                background:"rgba(209,99,171,1)"
            },
            "&::-webkit-scrollbar-track":{
                background:"transparent",
                borderRadius:"30px"
            }
        }),
        option:(provided,state)=>({
            ...provided,
            paddingTop:"8px",
            paddingBottom:"8px",
            paddingLeft:"14px",
            paddingRight:"14px",
            boxSizing:"border-box",
            fontWeight:"500",
            color:"#D163AB",
            fontSize:"14px",
            lineHeight:"20px",
            backgroundColor:state.isFocused?"rgba(209,99,171,0.1)":"#FFFFFF",
            "&:hover":{backgroundColor:"rgba(209,99,171,0.1)"},
            "&:first-of-type":{paddingTop:"14px"},
            "&:last-of-type":{paddingBottom:"14px"},
            cursor:"pointer",
            transition:"0.18s"
        }),
        noOptionsMessage:(provided,state)=>({
            ...provided,
            padding:"16px",
            paddingBottom:"17px",
            paddingLeft:"20px",
            paddingRight:"20px",
            boxSizing:"border-box",
            fontWeight:"500",
            color:"#D163AB",
            fontSize:"14px",
            lineHeight:"16px",
            backgroundColor:"#FFFFFF"
        })
    }

    const handleChange=(selectedValue)=>{
        setVal(selectedValue.value)
    }

    return(
        <Select ref={ref} options={items} blurInputOnSelect={true} placeholder={placeholder} styles={customStyles} isClearable={false} isSearchable={searchable} isMulti={isMulti} menuPortalTarget={document.body} noOptionsMessage={()=>{return "Sin opciones"}} onChange={handleChange} />
    )
    
})

export default Dropdown