import {useContext} from "react"
import {useNavigate} from "react-router-dom"
import GlobalContext from "../contexts/GlobalContext"

export default function useSession(){

    const [globalContext]=useContext(GlobalContext)
    const navigate=useNavigate()
    
    const checkPermission=(permission)=>{
        let exists=false
        if(globalContext.profile.permissions_granted.find(({alias})=>alias===permission.alias)){
            exists=true
        }
        return exists
    }

    const restrictSection=(context,reference)=>{
        if(context=="master"){
            if(!globalContext.profile.permissions_granted.find(({alias})=>alias==="master_admin")){
                navigate("/c24/tablero")
            }
        }else if(context=="start"){
            if(checkShops()==false && !globalContext.profile.permissions_granted.find(({alias})=>alias==="master_admin")){
                navigate("/c24/tablero/novedades")
            }
        }else if(context=="shop"){
            if(!globalContext.profile.permissions_granted.find(({alias})=>alias==="master_admin") && !globalContext.profile.shops_admin.includes(parseInt(reference))){
                navigate("/c24/comercios")
            }
        }
    }

    const checkShops=()=>{
        if(globalContext.profile.shops_admin.length>0){
            return true
        }else{
            return false
        }
    }

    return {checkPermission,restrictSection,checkShops}

}