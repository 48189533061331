import {Fragment,useContext,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import ModalTitle from "../components/ModalTitle"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonCard from "../commons/ButtonCard"

export default function ShopInstallments(){
    
    const [modalContext]=useContext(ModalContext)
    const {changeModal,modalReady}=useModal()
    const requestApi=useApi()
    const [paymentName,setPaymentName]=useState("")
    const [installments,setInstallments]=useState()

    useEffect(()=>{
        modalReady(0)
        requestApi("shops/installments",{
                point_id:modalContext.reference["point"],
                assignment:modalContext.reference["assignment"]
            })
            .then(data=>{
                setPaymentName(data.payment_name)
                setInstallments(data.items)
                modalReady(1)
            })
    },[])

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("shop-point","",modalContext.reference["point"])}} />
                <ButtonPrimary label="Agregar" clickEvent={()=>{changeModal("shop-installment-edit","create",{point:modalContext.reference["point"],assignment:modalContext.reference["assignment"],installment:0})}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    <ModalTitle title={"Cuotas para "+paymentName} />
                    {installments &&
                    <Card title="" icon={{element:""}} action="" actionIcon="" actionClickEvent={()=>{}}>
                        {installments.length>0?
                            installments.map((item,i)=>{
                                return(
                                    <CardRow unique={"installment"+item["id"]} label={item["installments"]+(item["installments"]==1?" cuota":" cuotas")+(item["ahora"]==1?" (Ahora 12)":"")} key={item["id"]} detail={item["fee"]+"%"} symbol={{element:"installment",context:item["ahora"]==0?"installment":"installment-ahora",reference:item["installments"]}}>
                                        <ButtonCard label="Modificar" style="secondary" clickEvent={()=>{changeModal("shop-installment-edit","edit",{point:modalContext.reference["point"],assignment:modalContext.reference["assignment"],installment:item["id"]})}} />
                                    </CardRow>
                                )
                            })
                        :<CardRow label={"Aún no hay cuotas asignadas."} detail="" />}
                    </Card>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}