import {Fragment} from "react"

export default function Counter({quantity,singular,plural}){
    
    return(
        <Fragment>
            {quantity!="" &&
            <div className="counter">
                {quantity} {quantity==1?singular:plural}
            </div>}
        </Fragment>
    )

}