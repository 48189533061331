import {useContext,useEffect,Fragment} from "react"
import {Outlet} from "react-router-dom"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import GlobalContext from "../contexts/GlobalContext"
import useSession from "../hooks/useSession"
import Loader from "../components/Loader"
import Bar from "../components/Bar"
import Panel from "../components/Panel"
import Modal from "../components/Modal"
import Dialog from "../components/Dialog"
import Floating from "../components/Floating"
import Toast from "../components/Toast"

export default function Dashboard(){

    const [globalContext]=useContext(GlobalContext)
    const {validateSession}=useSession()

    useEffect(()=>{
        validateSession()
    },[])

    return(
        <Fragment>
            {globalContext["initiated"] &&
            <Fragment>
                <div className="dashboard">
                    <Bar />
                    <Outlet />
                </div>
                <Panel />
                <Modal />
                <Dialog />
                <Floating />
                <Toast />
                <Loader />
                <Ga />
                <ConnectionChecker />
            </Fragment>}
            {!globalContext["initiated"] &&
            <div className="dashboard-loading noselect">
                <div>
                    <svg className="dashboard-spinner" viewBox="0 0 50 50">
                        <circle className="dashboard-spinner-path" cx="25" cy="25" r="20" fill="none" strokeWidth="3"></circle>
                    </svg>
                    <div className="dashboard-loading-caption">Iniciando sesión...</div>
                </div>
            </div>}
        </Fragment>
    )
    
}