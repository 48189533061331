import {Fragment,useContext,useEffect,useState} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useToast from "../hooks/useToast"
import {formatDate,formtatCuit} from "../hooks/Commons"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonCard from "../commons/ButtonCard"
import ButtonBack from "../commons/ButtonBack"
import Advice from "../components/Advice"
import Amount from "../commons/Amount"

export default function Voucher(){
    
    const {checkPermission}=usePermissions()
    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const {changeModal,modalReady}=useModal()
    const {changeToast}=useToast()
    const requestApi=useApi()
    let shopId=modalContext.reference["shop"]
    const [voucher,setVoucher]=useState()

    const loadVouchers=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("records/voucher",{
                        shop_id:shopId,
                        voucher_id:modalContext.reference["voucher"]
                    })
                    .then(data=>{
                        return data.data
                    })
            )
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadVouchers].map(func=>func())).then((result)=>{
            setVoucher(result[0])
            modalReady(1)
        })
    },[])

    const downloadVoucher=(event,voucherId)=>{
        event.preventDefault()
        let link=document.createElement("a")
        link.setAttribute("download","")
        link.href=globalContext["base_url"]+"api/plain/voucher/"+voucherId
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const asPaid=(event,voucherId)=>{
        event.preventDefault()
        requestApi("records/voucher_paid",{
                voucher_id:voucherId
            })
            .then(data=>{
                changeToast("saved","")
                changeModal("vouchers","",modalContext.reference["shop"])
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("vouchers","",modalContext.reference["shop"])}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {voucher &&
                    <Fragment>
                        <ModalTitle title={"Factura "+voucher["voucher_type"]+", N° "+String(voucher["billing_point"]).padStart(4,"0")+"-"+String(voucher["numeration"]).padStart(8,"0")} />
                        {voucher["status"]==0 &&
                        <Advice color="red"><strong>Sin pagar.</strong> Pagá esta factura a través de los canales habilitados (verás el pago reflejado dentro de un plazo de hasta 48 horas)...</Advice>}
                        {voucher["status"]==1 &&
                        <Advice color="green"><strong>Pagada.</strong> Pago registrado correctamente...</Advice>}
                        <Card title="Comprobante" icon={{element:"billing"}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Fecha de emisión" detail="">
                                <span className="card-row-featured">{formatDate(voucher["date"],"standard")}</span>
                            </CardRow>
                            <CardRow label="Tipo" detail="">
                                <span className="card-row-featured">Factura {voucher["voucher_type"]}</span>
                            </CardRow>
                            <CardRow label="Número" detail="">
                                <span className="card-row-featured">{String(voucher["billing_point"]).padStart(4,"0")+"-"+String(voucher["numeration"]).padStart(8,"0")}</span>
                            </CardRow>
                            <CardRow label="CAE" detail="">
                                {voucher["cae"]}
                            </CardRow>
                            <CardRow label="Vencimiento CAE" detail="">
                                {voucher["cae_expiration"]}
                            </CardRow>
                            <CardRow label={
                                <Fragment>
                                    {checkPermission({alias:"master_admin",reference:""}) && 
                                    <ButtonCard label={voucher["status"]==0?"Marcar como pagada":voucher["status"]==1?"Desmarcar como pagada":""} style="secondary" clickEvent={(event)=>{asPaid(event,voucher["id"])}} />}
                                </Fragment>} detail="">
                                <ButtonCard label="Descargar" style="primary" clickEvent={(event)=>{downloadVoucher(event,voucher["id"])}} />
                            </CardRow>
                        </Card>
                        <Card title="Detalles" icon={{element:"detail"}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Comercio" detail="">
                                {voucher["shop"]?voucher["shop"]:"‒"}
                            </CardRow>
                            <CardRow label="Concepto" detail="">
                                <span className="card-row-featured">{voucher["detail"]?voucher["detail"]:"‒"}</span>
                            </CardRow>
                            <CardRow label="Periodo" detail="">
                                <span className="card-row-featured">{voucher["period"]?voucher["period"]:"‒"}</span>
                            </CardRow>
                            <CardRow label="Subtotal" detail="">
                                <Amount number={voucher["subtotal"]} currency={"ARS"} operation={"balance"} />
                            </CardRow>
                            <CardRow label="IVA (21%)" detail="">
                                <Amount number={voucher["iva"]} currency={"ARS"} operation={"balance"} />
                            </CardRow>
                            <CardRow label="Total" detail="">
                                <Amount number={voucher["total"]} currency={"ARS"} operation={"positive"} />
                            </CardRow>
                        </Card>
                        <Card title="Cliente" icon={{element:"store"}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Razón social" detail="">
                                {voucher["client"]["name"]?voucher["client"]["name"]:"‒"}
                            </CardRow>
                            <CardRow label="CUIT" detail="">
                                {voucher["client"]["cuit"]?formtatCuit(voucher["client"]["cuit"]):"‒"}
                            </CardRow>
                            <CardRow label="Condición de IVA" detail="">
                                {!voucher["client"]["iva_condition"] && "‒"}
                                {voucher["client"]["iva_condition"]=="responsable_inscripto"?"Responsable Inscripto":voucher["client"]["iva_condition"]=="monotributista"?"Monotributista":voucher["client"]["iva_condition"]=="responsable_no_inscripto"?"Responsable no Inscripto":voucher["client"]["iva_condition"]=="exento"?"Exento":voucher["client"]["iva_condition"]=="consumidor_final"?"Consumidor Final":""}
                            </CardRow>
                            <CardRow label="Domicilio fiscal" detail="">
                                {voucher["client"]["address"]?voucher["client"]["address"]:"‒"}
                            </CardRow>
                            <CardRow label="CBU (Para FCE)" detail="">
                                {voucher["client"]["cbu"]?voucher["client"]["cbu"]:"‒"}
                            </CardRow>
                        </Card>
                        <Card title="Emisor" icon={{element:"info"}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Razón social" detail="">
                                {voucher["entity"]["name"]}
                            </CardRow>
                            <CardRow label="CUIT" detail="">
                                {formtatCuit(voucher["entity"]["cuit"])}
                            </CardRow>
                            <CardRow label="Condición de IVA" detail="">
                                {voucher["entity"]["iva_condition"]}
                            </CardRow>
                            <CardRow label="Ingresos Brutos" detail="">
                                {voucher["entity"]["ib"]}
                            </CardRow>
                            <CardRow label="Inicio de actividades" detail="">
                                {voucher["entity"]["initiation"]}
                            </CardRow>
                            <CardRow label="Domicilio fiscal" detail="">
                                {voucher["entity"]["address"]}
                            </CardRow>
                        </Card>
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )

}