import {forwardRef,Fragment,useEffect} from "react"
import {NumericFormat} from "react-number-format"

const WebposInput=forwardRef(({mode,type,context,label,val,setVal},ref)=>{
    
    let className=""
    if(type=="detail"){
        if(context=="main"){
            className="webpos-detail"
        }else if(context=="item"){
            className="webpos-detail webpos-detail-alt"
        }
    }else if(type=="reference"){
        if(context=="main"){
            className="webpos-detail webpos-reference"
        }else if(context=="item"){
            className="webpos-detail webpos-detail-alt webpos-reference"
        }
    }else if(type=="amount"){
        if(context=="main"){
            className="webpos-amount"
        }else if(context=="item"){
            className="webpos-amount webpos-amount-alt"
        }
    }

    const changeText=(event)=>{
        setVal(event.target.value)
    }

    const changeNumber=(numberValue)=>{
        let number=numberValue.value
        if(number==undefined || number==""){
            number=0
        }else{
            parseFloat(number)
        }
        setVal({number:number,formatted:numberValue.formattedValue})
    }

    return(
        <Fragment>
            {mode=="number"?
            <NumericFormat
                getInputRef={ref}
                className={className}
                value={val["formatted"]}
                onValueChange={changeNumber}
                placeholder={label}
                decimalSeparator={","}
                prefix="$"
            />:
            <input
                ref={ref}
                type="text"
                className={className}
                placeholder={label}
                value={val}
                onChange={changeText}
            />
        }
        </Fragment>
    )
    
})

export default WebposInput