import {Fragment,useContext,useEffect,useState} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import {formatDate} from "../hooks/Commons"
import useModal from "../hooks/useModal"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonCard from "../commons/ButtonCard"

export default function ShopPoint(){

    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {changeModal,modalReady}=useModal()
    const [info,setInfo]=useState()
    const [payments,setPayments]=useState()
    const [barcodes,setBarcodes]=useState()
    const [affiliations,setAffiliations]=useState()
    const [credentials,setCredentials]=useState()
    let pointId=modalContext.reference

    const loadInfo=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/point_info",{
                        point_id:pointId
                    })
                    .then(data=>{
                        return data.data
                    })
            )
        })
    }

    const loadPayments=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/payments",{
                        point_id:pointId
                    })
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }

    const loadBarcodes=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/barcodes",{
                        point_id:pointId
                    })
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }

    const loadAffiliations=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/affiliations",{
                        point_id:pointId
                    })
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }

    const loadCredentials=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/credentials",{
                        point_id:pointId
                    })
                    .then(data=>{
                        return data.data
                    })
            )
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadInfo,loadPayments,loadBarcodes,loadAffiliations,loadCredentials].map(func=>func())).then((result)=>{
            setInfo(result[0])
            setPayments(result[1])
            setBarcodes(result[2])
            setAffiliations(result[3])
            setCredentials(result[4])
            modalReady(1)
        })
    },[])

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {info &&
                    <Fragment>
                        <Card title="Punto de cobro" icon={{element:"point"}} action="Modificar" actionIcon="edit" actionClickEvent={()=>{changeModal("shop-point-edit","edit",{shop:info["shop"],point:info["id"]})}}>
                            <CardRow label="Nombre" detail="">
                                {info.name?info.name:"‒"}
                            </CardRow>
                            <CardRow label="Acuerdo comercial" detail="">
                                {info.agreement_name?info.agreement_name+" ("+info.agreement+")":"‒"}
                            </CardRow>
                            <CardRow label="Identificador" detail="">
                                #{String(info["id"]).padStart(4,"0")}
                            </CardRow>
                            <CardRow label="Principal" detail="">
                                {info["is_default"]?"Sí":"No"}
                            </CardRow>
                            <CardRow label="Fecha de creación" detail="">
                                {formatDate(info["created"],"short")}
                            </CardRow>
                            <CardRow label="Estado" detail="">
                                {info.status==1?"Activo":"Inactivo"}
                            </CardRow>
                        </Card>
                    </Fragment>}
                    {payments &&
                    <Card title="Medios de pago" icon={{element:"cards"}} action="Definir" actionIcon="edit" actionClickEvent={()=>{changeModal("shop-payments","",{shop:info["shop"],point:info["id"]})}}>
                        {payments.length>0?
                            payments.map((item,i)=>{
                                return(
                                    <CardRow unique={"paymentAssignment"+item["id"]} label={item["name"]} key={item["id"]} detail="" off={item["status"]} symbol={{element:"card",context:"small",reference:item["class"]=="debin"?"debin":(item["card_has_picture"]==1?item["card_id"]:0)}}>
                                        {item["class"]=="credit" &&
                                        <ButtonCard label="Cuotas" style="primary" clickEvent={()=>{changeModal("shop-installments","",{shop:info["shop"],point:info["id"],assignment:item["id"]})}} />}
                                    </CardRow>
                                )
                            })
                        :<CardRow label={"Aún no hay medios de pago asignados."} detail="" />}
                    </Card>}
                    {barcodes &&
                    <Card title="Códigos de barra" icon={{element:"barcode"}} action="Agregar" actionIcon="add" actionClickEvent={()=>{changeModal("shop-barcode-edit","create",{point:info["id"],barcode:0})}}>
                        {barcodes.length>0?
                            barcodes.map((item,i)=>{
                                return(
                                    <CardRow unique={"barcode"+item["id"]} label={item["name"]} key={item["id"]} detail={item["length"]+(item["length"]==1?" dígito":" dígitos")}>
                                        <ButtonCard label="Definir" style="secondary" clickEvent={()=>{changeModal("shop-barcode-edit","edit",{point:info["id"],barcode:item["id"]})}} />
                                    </CardRow>
                                )
                            })
                        :<CardRow label="Aún no hay códigos definidos." detail="" />}
                    </Card>}
                    {affiliations &&
                    <Card title="Afiliaciones" icon={{element:"affiliation"}} action="Agregar" actionIcon="add" actionClickEvent={()=>{changeModal("shop-affiliation-edit","create",{point:info["id"],affiliation:0})}}>
                        {affiliations.length>0?
                            affiliations.map((item,i)=>{
                                return(
                                    <CardRow unique={"affiliation"+item["id"]} label={item["company"]} key={item["id"]} detail={item["number"]}>
                                        <ButtonCard label="Modificar" style="secondary" clickEvent={()=>{changeModal("shop-affiliation-edit","edit",{point:info["id"],affiliation:item["id"]})}} />
                                    </CardRow>
                                )
                            })
                        :<CardRow label="Aún no hay afiliaciones asignadas." detail="" />}
                    </Card>}
                    {credentials &&
                    <Fragment>
                        <Card title="Credenciales APIs" icon={{element:"config"}} action="Configurar" actionIcon="edit" actionClickEvent={()=>{changeModal("shop-credentials-edit","edit",{shop:info["shop"],point:info["id"]})}}>
                            <CardRow label="PayZen: ID de tienda" detail="">
                                {credentials.payzen_id?credentials.payzen_id:"‒"}
                            </CardRow>
                            <CardRow label="PayZen: Clave privada (Produccción)" detail="">
                                {credentials.payzen_private_prod?credentials.payzen_private_prod.substring(0,14)+"...":"‒"}
                            </CardRow>
                            <CardRow label="PayZen: Clave pública (Producción)" detail="">
                                {credentials.payzen_public_prod?credentials.payzen_public_prod.substring(0,14)+"...":"‒"}
                            </CardRow>
                            <CardRow label="PayZen: Clave HMAC (Producción)" detail="">
                                {credentials.payzen_hmac_prod?credentials.payzen_hmac_prod.substring(0,14)+"...":"‒"}
                            </CardRow>
                            <CardRow label="PayZen: Clave privada (Test)" detail="">
                                {credentials.payzen_private_test?credentials.payzen_private_test.substring(0,14)+"...":"‒"}
                            </CardRow>
                            <CardRow label="PayZen: Clave pública (Test)" detail="">
                                {credentials.payzen_public_test?credentials.payzen_public_test.substring(0,14)+"...":"‒"}
                            </CardRow>
                            <CardRow label="PayZen: Clave HMAC (Test)" detail="">
                                {credentials.payzen_hmac_test?credentials.payzen_hmac_test.substring(0,14)+"...":"‒"}
                            </CardRow>
                            <CardRow label="Datapos: ID de comercio" detail="">
                                {credentials.datapos_id?credentials.datapos_id:"‒"}
                            </CardRow>
                        </Card>
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}