import iconApproved from "../assets/approved.svg"
import iconLiquidated from "../assets/liquidated.svg"
import iconPending from "../assets/pending.svg"
import iconRefund from "../assets/refund.svg"
import iconRejected from "../assets/rejected.svg"
import iconSubscription from "../assets/subscription.svg"
import iconLink from "../assets/link.svg"
import iconMessage from "../assets/message.svg"

export default function Icon({element,context}){

    let icon=""
    let color=""
    let className="icon"
    let styles={}

    if(context=="medium"){
        className+=" icon-medium"
    }else if(context=="small"){
        className+=" icon-small"
    }
    
    if(element=="link"){
        icon=iconLink
        color="#379ED1"
    }else if(element=="subscription"){
        icon=iconSubscription
        color="#32177A"
    }else if(element=="approved"){
        icon=iconApproved
        color="#2DCA73"
    }else if(element=="liquidated"){
        icon=iconLiquidated
        color="#2DCA73"
    }else if(element=="liquidation"){
        icon=iconLiquidated
        color="#624DE3"
    }else if(element=="pending"){
        icon=iconPending
        color="#F5BD00"
    }else if(element=="refund"){
        icon=iconRefund
        color="#FF6600"
    }else if(element=="rejected"){
        icon=iconRejected
        color="#EB3941"
    }else if(element=="canceled"){
        icon=iconRefund
        color="#EB3941"
    }else if(element=="message"){
        icon=iconMessage
        color="#D163AB"
    }

    if(color!=""){
        styles.background=color
    }

    return(
        <div className={className} style={styles}>
            <img src={icon} />
        </div>
    )
    
}