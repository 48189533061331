import Avatar from "../commons/Avatar"
import Symbol from "../commons/Symbol"
import edit from "../assets/edit.svg"
import add from "../assets/add-2.svg"
import info from "../assets/info.svg"
import billing from "../assets/billing.svg"
import location from "../assets/location.svg"
import password from "../assets/password.svg"
import cards from "../assets/cards.svg"
import sessions from "../assets/sessions.svg"
import config from "../assets/config.svg"
import contact from "../assets/contact.svg"
import store from "../assets/store.svg"
import affiliation from "../assets/affiliation.svg"
import team from "../assets/team.svg"
import point from "../assets/point.svg"
import barcode from "../assets/barcode.svg"
import detail from "../assets/detail.svg"
import code from "../assets/code.svg"

export default function Card({title,icon,action,actionIcon,actionClickEvent,children}){

    let iconPic=""
    if(icon.element=="info"){
        iconPic=info
    }else if(icon.element=="location"){
        iconPic=location
    }else if(icon.element=="billing"){
        iconPic=billing
    }else if(icon.element=="password"){
        iconPic=password
    }else if(icon.element=="cards"){
        iconPic=cards
    }else if(icon.element=="sessions"){
        iconPic=sessions
    }else if(icon.element=="config"){
        iconPic=config
    }else if(icon.element=="contact"){
        iconPic=contact
    }else if(icon.element=="store"){
        iconPic=store
    }else if(icon.element=="affiliation"){
        iconPic=affiliation
    }else if(icon.element=="team"){
        iconPic=team
    }else if(icon.element=="point"){
        iconPic=point
    }else if(icon.element=="barcode"){
        iconPic=barcode
    }else if(icon.element=="detail"){
        iconPic=detail
    }else if(icon.element=="code"){
        iconPic=code
    }

    let actionIconPic=""
    if(actionIcon=="edit"){
        actionIconPic=edit
    }else if(actionIcon=="add"){
        actionIconPic=add
    }

    return(
        <div className={"card"+((title=="" && icon.element=="" && action=="")?" card-single":"")}>
            {(title!="" || icon.element!="" || action!="") &&
            <div className="card-header noselect">
                {icon.element!="" &&
                <div className="card-icon">
                    {icon.element=="user"?
                    <Avatar element="user" context="card" id={icon.reference} picture={icon.has_picture} color="#B7FAFF" capitals={icon.capitals} />
                    :icon.element=="shop"?
                    <Avatar element="shop" context="card" id={icon.reference} picture={icon.has_picture} color="" capitals={icon.capitals} />
                    :icon.element=="payment"?
                    <Symbol element="card" context="card" reference={icon.reference} />
                    :<div className="card-icon-circle"><img src={iconPic} /></div>}
                </div>}
                <div className={icon.element==""?"card-title card-title-single":"card-title"}>{title}</div>
                {action!="" &&
                <div className="card-actions">
                    <div className="card-action" onClick={actionClickEvent}>
                        {actionIcon!="" &&
                        <div className="card-action-icon"><img src={actionIconPic} /></div>}
                        <div className="card-action-label">{action}</div>
                    </div>
                </div>}
            </div>}
            <div className={"card-data"+(((title=="" && action=="") || icon.element=="")?" card-data-single":"")}>
                {children}
            </div>
        </div>
    )
    
}