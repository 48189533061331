import {forwardRef} from "react"

const ButtonCircle=forwardRef(({icon,active,clickEvent},ref)=>{

    return(
        <div ref={ref} className={(active && icon=="columns")?"button-circle button-circle-on":(active && icon!="columns")?"button-circle button-circle-on button-circle-on-blocked":"button-circle"} onClick={clickEvent}>
            {icon=="columns" && <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><path d="M27.44,11H14.56A3.57,3.57,0,0,0,11,14.56V27.44A3.57,3.57,0,0,0,14.56,31H27.44A3.57,3.57,0,0,0,31,27.44V14.56A3.57,3.57,0,0,0,27.44,11ZM13,27.44V14.56A1.56,1.56,0,0,1,14.56,13H20V29H14.56A1.56,1.56,0,0,1,13,27.44Zm16,0A1.56,1.56,0,0,1,27.44,29H22V13h5.44A1.56,1.56,0,0,1,29,14.56Z" fill="#624de3"/><circle cx="21" cy="21" r="21" transform="matrix(0.98, -0.21, 0.21, 0.98, -3.92, 4.84)" fill="none"/></svg>}
            {icon=="rows" && <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><circle cx="21" cy="21" r="21" transform="translate(-6.48 9.74) rotate(-22.73)" fill="none"/><path d="M28.25,9.87H13.75a3.88,3.88,0,0,0-3.88,3.88v14.5a3.88,3.88,0,0,0,3.88,3.88h14.5a3.88,3.88,0,0,0,3.88-3.88V13.75A3.88,3.88,0,0,0,28.25,9.87ZM11.87,18.62H30.13v4.76H11.87Zm1.88-6.75h14.5a1.88,1.88,0,0,1,1.88,1.88v2.87H11.87V13.75A1.88,1.88,0,0,1,13.75,11.87Zm14.5,18.26H13.75a1.88,1.88,0,0,1-1.88-1.88V25.38H30.13v2.87A1.88,1.88,0,0,1,28.25,30.13Z" fill="#624de3"/></svg>}
            {icon=="blocks" && <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><circle cx="21" cy="21" r="21" transform="translate(-4.29 5.43) rotate(-13.37)" fill="none"/><path d="M30.05,11.24H12a2,2,0,0,0-2.08,1.93v4.66A2,2,0,0,0,12,19.76h18.1a2,2,0,0,0,2.08-1.93V13.17A2,2,0,0,0,30.05,11.24Zm.08,6.5a.15.15,0,0,1-.08,0H12a.15.15,0,0,1-.08,0V13.26l.08,0h18.1l.08,0Zm-.08,4.5H12a2,2,0,0,0-2.08,1.93v4.66A2,2,0,0,0,12,30.76h18.1a2,2,0,0,0,2.08-1.93V24.17A2,2,0,0,0,30.05,22.24Zm.08,6.5a.15.15,0,0,1-.08,0H12a.15.15,0,0,1-.08,0V24.26a.15.15,0,0,1,.08,0h18.1a.15.15,0,0,1,.08,0Z" fill="#624de3"/></svg>}
            {icon=="download" && <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42"><path d="M29.72,30a1,1,0,0,1-1,1H13.28a1,1,0,0,1,0-2H28.72A1,1,0,0,1,29.72,30Zm-9.43-3.43a1,1,0,0,0,.33.22,1,1,0,0,0,.38.08,1.09,1.09,0,0,0,.39-.08,1,1,0,0,0,.32-.22l4.94-4.94a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L22,23.45V12a1,1,0,0,0-2,0V23.45l-3.24-3.24a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42Z" fill="#624de3"/><circle cx="21" cy="21" r="21" transform="translate(-4.29 5.43) rotate(-13.37)" fill="none"/></svg>}
            {icon=="next" && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><g id="c"><g><path d="M19.51,28.99c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41l6.28-6.28-6.28-6.28c-.39-.39-.39-1.02,0-1.41s1.02-.39,1.41,0l6.99,6.98c.19,.19,.29,.44,.29,.71s-.11,.52-.29,.71l-6.99,6.99c-.2,.2-.45,.29-.71,.29Z" fill="#624de3"/><circle cx="21" cy="21" r="21" transform="translate(-4.29 5.43) rotate(-13.37)" fill="none"/></g></g></svg>}
            {icon=="back" && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><g id="c"><g><path d="M23.49,28.99c-.26,0-.51-.1-.71-.29l-6.99-6.99c-.19-.19-.29-.44-.29-.71s.11-.52,.29-.71l6.99-6.98c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41l-6.28,6.28,6.28,6.28c.39,.39,.39,1.02,0,1.41-.2,.2-.45,.29-.71,.29Z" fill="#624de3"/><circle cx="21" cy="21" r="21" transform="translate(-4.29 36.57) rotate(-76.63)" fill="none"/></g></g></svg>}
            {icon=="add" && <svg id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42"><g id="c"><g><circle cx="21" cy="21" r="21" transform="translate(-4.29 5.43) rotate(-13.37)" fill="none"/><path d="M29,21c0,.55-.45,1-1,1h-6v6c0,.55-.45,1-1,1s-1-.45-1-1v-6h-6c-.55,0-1-.45-1-1s.45-1,1-1h6v-6c0-.55,.45-1,1-1s1,.45,1,1v6h6c.55,0,1,.45,1,1Z" fill="#624de3"/></g></g></svg>}
        </div>
    )
    
})

export default ButtonCircle