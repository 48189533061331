import {Fragment,useContext,useRef,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Numbox from "../commons/Numbox"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import Divisor from "../commons/Divisor"
import ButtonCommand from "../commons/ButtonCommand"

export default function LinkEdit(){

    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const requestApi=useApi()
    const mode=modalContext.reference==0?"create":"edit"
    const pointInput=useRef()
    const nameInput=useRef()
    const descriptionInput=useRef()
    const amountInput=useRef()
    const frequencyInput=useRef()
    const periodsInput=useRef()
    const billingDayInput=useRef()
    const urlSuccessInput=useRef()
    const urlPendingInput=useRef()
    const urlFailureInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [pointVal,setPointVal]=useState()
    const [nameVal,setNameVal]=useState("")
    const [descriptionVal,setDescriptionVal]=useState("")
    const [amountVal,setAmountVal]=useState("")
    const [frequencyVal,setFrequencyVal]=useState()
    const [periodsVal,setPeriodsVal]=useState("")
    const [billingDayVal,setBillingDayVal]=useState()
    const [urlSuccessVal,setUrlSuccessVal]=useState("")
    const [urlPendingVal,setUrlPendingVal]=useState("")
    const [urlFailureVal,setUrlFailureVal]=useState("")
    const [statusVal,setStatusVal]=useState(1)
    const [defaultData,setDefaultData]=useState("")
    const [pointOptions,setPointOptions]=useState([])
    const [frequencyOptions]=useState([
        {label:"Semanal",value:"weekly"},
        {label:"Quincenal",value:"biweekly"},
        {label:"Mensual",value:"monthly"},
        {label:"Bimestral",value:"bimonthly"},
        {label:"Trimestral",value:"trimestral"},
        {label:"Cuatrimestral",value:"quarterly"},
        {label:"Semestral",value:"biannual"},
        {label:"Anual",value:"annual"}
    ])
    const [billingDayOptions]=useState([
        {label:"Indiferente",value:"0"},
        {label:"El 1 de cada mes",value:"1"},
        {label:"El 2 de cada mes",value:"2"},
        {label:"El 3 de cada mes",value:"3"},
        {label:"El 4 de cada mes",value:"4"},
        {label:"El 5 de cada mes",value:"5"},
        {label:"El 6 de cada mes",value:"6"},
        {label:"El 7 de cada mes",value:"7"},
        {label:"El 8 de cada mes",value:"8"},
        {label:"El 9 de cada mes",value:"9"},
        {label:"El 10 de cada mes",value:"10"},
        {label:"El 11 de cada mes",value:"11"},
        {label:"El 12 de cada mes",value:"12"},
        {label:"El 13 de cada mes",value:"13"},
        {label:"El 14 de cada mes",value:"14"},
        {label:"El 15 de cada mes",value:"15"},
        {label:"El 16 de cada mes",value:"16"},
        {label:"El 17 de cada mes",value:"17"},
        {label:"El 18 de cada mes",value:"18"},
        {label:"El 19 de cada mes",value:"19"},
        {label:"El 20 de cada mes",value:"20"},
        {label:"El 21 de cada mes",value:"21"},
        {label:"El 22 de cada mes",value:"22"},
        {label:"El 23 de cada mes",value:"23"},
        {label:"El 24 de cada mes",value:"24"},
        {label:"El 25 de cada mes",value:"25"},
        {label:"El 26 de cada mes",value:"26"},
        {label:"El 27 de cada mes",value:"27"},
        {label:"El 28 de cada mes",value:"28"}
    ])

    const loadPoints=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/shops_points",{context:"only_points"})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(shop){
                            newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                        })
                        return newOptions
                    })
            )
        })
    }

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("subscriptions/info",{
                            subscription_id:modalContext.reference
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadPoints,loadData].map(func=>func())).then((result)=>{
            let pointOptionsNew=result[0]
            setPointOptions(pointOptionsNew)
            setDefaultData(result[1])
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData.id)
            setNameVal(defaultData.name)
            setDescriptionVal(defaultData.description)
            setAmountVal(defaultData.amount)
            setPeriodsVal(defaultData.periods)
            setUrlSuccessVal(defaultData.url_success)
            setUrlPendingVal(defaultData.url_pending)
            setUrlFailureVal(defaultData.url_failure)
            setStatusVal(defaultData.status)
            pointOptions.forEach(function(item){
                if(item.value["point_id"]==defaultData.point){
                    pointInput.current.setValue({label:item.label,value:{shop_id:item.value["shop_id"],point_id:item.value["point_id"]}})
                }
            })
            frequencyOptions.forEach(function(item){
                if(item.value==defaultData.frequency){
                    frequencyInput.current.setValue({label:item.label,value:defaultData.frequency})
                }
            })
            billingDayOptions.forEach(function(item){
                if(item.value==defaultData.billing_day){
                    billingDayInput.current.setValue({label:item.label,value:defaultData.billing_day})
                }
            })
        }
    },[ready])

    const saveData=()=>{
        if(nameVal==""){
            nameInput.current.focus()
        }else{
            if(pointInput.current.getValue().length==0){
                pointInput.current.focus()
            }else{
                if(frequencyInput.current.getValue().length==0){
                    frequencyInput.current.focus()
                }else{
                    if(amountVal==""){
                        amountInput.current.focus()
                    }else{
                        requestApi("subscriptions/save",{
                                id:idVal,
                                mode:mode,
                                point:pointInput.current.getValue()[0]?pointInput.current.getValue()[0].value:0,
                                name:nameVal,
                                description:descriptionVal,
                                amount:amountVal,
                                frequency:frequencyInput.current.getValue()[0]?frequencyInput.current.getValue()[0].value:"",
                                periods:periodsVal,
                                billing_day:billingDayInput.current.getValue()[0]?billingDayInput.current.getValue()[0].value:"",
                                url_success:urlSuccessVal,
                                url_pending:urlPendingVal,
                                url_failure:urlFailureVal,
                                status:statusVal
                            })
                            .then(data=>{
                                if(data.status=="success"){
                                    if(mode=="create"){
                                        changeToast("created_f","")
                                    }else if(mode=="edit"){
                                        changeToast("saved","")
                                    }
                                    manageModal("hide")
                                    refresh(["list"],"")
                                }else{
                                    changeDialog("generic_error","","")
                                }    
                            })
                    }
                }
            }
        }
    }

    const remove=()=>{
        requestApi("subscriptions/remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nueva suscripción":"Editar suscripción"} />
                    <Textbox ref={nameInput} label="Nombre" type="text" val={nameVal} setVal={setNameVal} disabled={false} />
                    <Textbox ref={descriptionInput} label="Descripción" type="text" val={descriptionVal} setVal={setDescriptionVal} disabled={false} />
                    <Selector ref={pointInput} val={pointVal} setVal={setPointVal} placeholder="Punto de cobro" searchable={1} options={pointOptions} />
                    <Divisor />
                    <Selector ref={frequencyInput} val={frequencyVal} setVal={setFrequencyVal} placeholder="Frecuencia" searchable={0} options={frequencyOptions} />
                    <Numbox ref={amountInput} label="Monto a cobrar" val={amountVal} setVal={setAmountVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                    <Textbox ref={periodsInput} label="Cantidad de períodos (0 infinitos)" type="number" val={periodsVal} setVal={setPeriodsVal} disabled={false} />
                    <Selector ref={billingDayInput} val={billingDayVal} setVal={setBillingDayVal} placeholder="Día de cobro" searchable={0} options={billingDayOptions} />
                    <Divisor />
                    <Textbox ref={urlSuccessInput} label="URL suscripción completada" type="text" val={urlSuccessVal} setVal={setUrlSuccessVal} disabled={false} />
                    <Textbox ref={urlPendingInput} label="URL suscripción pendiente" type="text" val={urlPendingVal} setVal={setUrlPendingVal} disabled={false} />
                    <Textbox ref={urlFailureInput} label="URL error en la suscripción" type="text" val={urlFailureVal} setVal={setUrlFailureVal} disabled={false} />
                    <Divisor />
                    <Switch onLabel="Activa" offLabel="Inactiva" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}