import {useState,useEffect,useRef,Fragment} from "react"
import {useSearchParams,useNavigate} from "react-router-dom"
import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import Entrance from "../components/Entrance"
import EntranceBar from "../commons/EntranceBar"
import EntranceSteps from "../commons/EntranceSteps"
import EntranceButton from "../commons/EntranceButton"
import EntranceTextbox from "../commons/EntranceTextbox"
import EntranceHelp from "../commons/EntranceHelp"

export default function PasswordCreate(){

    const requestApi=useApi()
    const {changeDialog}=useDialog()
    const [searchParams]=useSearchParams()
    let token=""
    if(searchParams.get("t")!=null){
        token=searchParams.get("t")
    }
    const navigate=useNavigate()
    const [tokenStatus,setTokenStatus]=useState("")
    const [passwordVal,setPasswordVal]=useState("")
    const [rePasswordVal,setRePasswordVal]=useState("")
    const passwordInput=useRef()
    const rePasswordInput=useRef()
    
    useEffect(()=>{
        requestApi("users/password_reset_request",{
                reset_token:token
            })
            .then(data=>{
                if(data.status=="valid_token"){
                    setTokenStatus("valid_token")
                }else if(data.status=="missed_token"){
                    setTokenStatus("missed_token")
                }else if(data.status=="expired_token"){
                    setTokenStatus("expired_token")
                }
            })
    },[])

    const changePassword=(event)=>{
        event.preventDefault()
        if(passwordVal==""){
            passwordInput.current.focus()
        }else{
            if(rePasswordVal==""){
                rePasswordInput.current.focus()
            }else{
                if(passwordVal!=rePasswordVal){
                    changeDialog("password_mismatch","","")
                }else{
                    if(passwordVal.length<7){
                        changeDialog("password_length","","")
                    }else{
                        requestApi("users/password_reset",{
                                context:"create",
                                reset_token:token,
                                new_password:passwordVal
                            })
                            .then(data=>{
                                if(data.status=="success"){
                                    setTokenStatus("changed")
                                }else if(data.status=="failed"){
                                    changeDialog("generic_error","","")
                                }
                            })
                    }
                }
            }
        }
    }

    return(
        <Fragment>
            <Entrance>
                <EntranceBar />
                <EntranceSteps>
                    <div className="entrance-step">
                        {tokenStatus=="valid_token" &&
                        <Fragment>
                            <div className="entrance-caption">
                                <strong>Vas a crear tu contraseña.</strong> Para continuar, elegí una clave.
                            </div>
                            <form>
                                <EntranceTextbox ref={passwordInput} placeholder="Contraseña:" type="password" val={passwordVal} onChange={(event)=>{setPasswordVal(event.target.value)}} />
                                <EntranceTextbox ref={rePasswordInput} placeholder="Reescribir contraseña:" type="password" val={rePasswordVal} onChange={(event)=>{setRePasswordVal(event.target.value)}} />
                                <EntranceButton label="Crear contraseña" clickEvent={changePassword} style="primary" arrow="none" />
                                <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                            </form>
                        </Fragment>}
                        {tokenStatus=="missed_token" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>Algo no anduvo bien.</strong> No pudimos validar el enlace para crear la contraseña.
                                <div className="entrance-caption-note">
                                    Revisá que el link sea exactamente igual al que te enviamos por correo.
                                </div>
                            </div>
                            <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                        </Fragment>}
                        {tokenStatus=="expired_token" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>Algo no anduvo bien.</strong> El enlace para crear la contraseña ya no puede utilizarse.
                                <div className="entrance-caption-note">
                                    Asegurate de ingresar al link a los pocos minutos de que te llegue el correo.
                                </div>
                            </div>
                            <EntranceButton label="Reintentar" clickEvent={()=>{navigate("/entrar")}} style="primary" arrow="none" />
                            <EntranceHelp label="¿Necesitás ayuda?" clickEvent={()=>{changeDialog("help","","")}} link="" />
                        </Fragment>}
                        {tokenStatus=="changed" &&
                        <Fragment>
                            <div className="entrance-caption entrance-caption-small">
                                <strong>¡Todo listo!</strong> Ya podés ingresar a tu cuenta con la nueva contraseña.
                            </div>
                            <EntranceButton label="Entrar" clickEvent={()=>{navigate("/entrar")}} style="primary" arrow="none" />
                        </Fragment>}
                    </div>
                </EntranceSteps>
            </Entrance>
            <Ga />
            <ConnectionChecker />
        </Fragment>
    )
    
}