import {Fragment,useContext,useEffect,useState,useRef} from "react"
import ModalContext from "../contexts/ModalContext"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonBack from "../commons/ButtonBack"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import Numbox from "../commons/Numbox"

export default function TransactionLiquidate(){
    
    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])

    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const {changeModal,modalReady}=useModal()
    const {changeToast}=useToast()
    const {changeDialog,manageDialog}=useDialog()
    const [info,setInfo]=useState()
    const liquidationDateInput=useRef()
    const [liquidationDateVal,setLiquidationDateVal]=useState("")
    const cbuAliasInput=useRef()
    const [cbuAliasVal,setCbuAliasVal]=useState("")
    const operationNumberInput=useRef()
    const [operationNumberVal,setOperationNumberVal]=useState("")
    const discountedTotalInput=useRef()
    const [discountedTotalVal,setDiscountedTotalVal]=useState("")
    const totalAccreditedInput=useRef()
    const [totalAccreditedVal,setTotalAccreditedVal]=useState("")

    const loadInfo=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("transactions/info",{
                        transaction_id:modalContext.reference
                    })
                    .then(data=>{
                        return data.data
                    })
            )
        })
    }

    const transactionsInput=useRef()
    const [transactionsVal,setTransactionsVal]=useState()
    const [transactionsOptions,setTransactionsOptions]=useState([])
    const loadTransactions=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("liquidations/debins",{
                        transaction_id:modalContext.reference
                    })
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(transaction){
                            newOptions.push({label:transaction["label"],value:transaction["id"]})
                        })
                        return newOptions
                    })
            )
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadInfo,loadTransactions].map(func=>func())).then((result)=>{
            setInfo(result[0])
            setTransactionsOptions(result[1])
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(ready){
            let newTransactionsValue=[]
            transactionsOptions.forEach(function(item){
                if(info["id"]==item.value){
                    newTransactionsValue.push({label:item.label,value:item.value})
                }
            })
            transactionsInput.current.setValue(newTransactionsValue)
        }
    },[ready])

    const liquidate=()=>{
        manageDialog("hide")
        if(liquidationDateVal==""){
            liquidationDateInput.current.focus()
        }else{
            if(operationNumberVal==""){
                operationNumberInput.current.focus()
            }else{
                if(totalAccreditedVal==""){
                    totalAccreditedInput.current.focus()
                }else{
                    requestApi("liquidations/liquidate",{
                            transaction_id:info["id"],
                            shop_id:info["shop"],
                            point_id:info["point"],
                            transactions:transactionsInput.current.getValue(),
                            liquidation_date:liquidationDateVal,
                            cbu_alias:cbuAliasVal,
                            operation_number:operationNumberVal,
                            discounted_total:discountedTotalVal,
                            total_accredited:totalAccreditedVal
                        })
                        .then(data=>{
                            if(data.status=="success"){
                                changeToast("liquidated","")
                                changeModal("transaction-info","",modalContext.reference)
                                refresh(["list"],"")
                            }else{
                                changeDialog("generic_error","","")
                            }
                        })
                }
            }
        }
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("transaction-info","",modalContext.reference)}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {info &&
                    <Fragment>
                        <ModalTitle title={"Liquidar debines"} />
                        <Selector ref={transactionsInput} val={transactionsVal} setVal={setTransactionsVal} placeholder="Transacciones a liquidar" multiple={1} searchable={1} options={transactionsOptions} />
                        <Textbox ref={liquidationDateInput} label="Fecha de liquidación" type="date" val={liquidationDateVal} setVal={setLiquidationDateVal} disabled={false} />
                        <Textbox ref={cbuAliasInput} label="CBU/alias" type="text" val={cbuAliasVal} setVal={setCbuAliasVal} disabled={false} />
                        <Textbox ref={operationNumberInput} label="N° de operación" type="text" val={operationNumberVal} setVal={setOperationNumberVal} disabled={false} />
                        <Numbox ref={discountedTotalInput} label="Total descontado" val={discountedTotalVal} setVal={setDiscountedTotalVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                        <Numbox ref={totalAccreditedInput} label="Total acreditado" val={totalAccreditedVal} setVal={setTotalAccreditedVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={"$"} suffix={""} placeholder="" />
                        <div className="uploader-submit">
                            <ButtonPrimary label="Liquidar" clickEvent={()=>{changeDialog("liquidate","",liquidate)}} />
                        </div>
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}