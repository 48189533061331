import {useState,useRef,useEffect,useContext,Fragment} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import {formatDate} from "../hooks/Commons"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonCommand from "../commons/ButtonCommand"
import Textarea from "../commons/Textarea"
import Message from "../components/Message"
import Switch from "../commons/Switch"
import Advice from "../components/Advice"
import Avatar from "../commons/Avatar"

export default function TicketInfo(){

    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const [ticket,setTicket]=useState()
    const contentInput=useRef()
    const [contentVal,setContentVal]=useState("")
    const [closeVal,setCloseVal]=useState(0)

    useEffect(()=>{
        modalReady(0)
        requestApi("tickets/info",{
                ticket_id:modalContext.reference
            })
            .then(data=>{
                setTicket(data.data)
                modalReady(1)
            })
    },[])    
    
    const saveData=()=>{
        if(contentVal==""){
            contentInput.current.focus()
        }else{
            requestApi("tickets/response",{
                    user_id:globalContext.user_id,
                    ticket_id:modalContext.reference,
                    content:contentVal,
                    close:closeVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        changeToast("message_sent","")
                        manageModal("hide")
                        refresh(["list"],"")
                    }else{
                        changeDialog("generic_error","","")
                    }    
                })
        }
    }

    const remove=()=>{
        requestApi("tickets/remove",{
                id:modalContext.reference
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    {ticket &&
                    <Fragment>
                        <ModalTitle title={"Ticket de asistencia #"+ticket["id"]} />
                        {(ticket["status"]==1 || ticket["status"]==2) &&
                        <div className="ticket-immediate">Si se requiere resolución inmediata, podés contactarte directamente desde WhatsApp al <a href="https://wa.me/542612185757" target="_blank">+54 261 218-5757</a>.</div>}
                        {ticket.status==1 &&
                        <Advice color="orange">
                            <strong>En espera.</strong> Nuestro equipo está revisando la solicitud...
                        </Advice>}
                        {ticket.status==2 &&
                        <Advice color="green">
                            <strong>Abierto.</strong> El ticket está en proceso de resolución...
                        </Advice>}
                        {ticket.status==3 &&
                        <Advice color="yellow">
                            <strong>Resuelto.</strong> El ticket está cerrado.
                        </Advice>}
                        {(ticket["status"]==1 || ticket["status"]==2) &&
                        <Fragment>
                            <Textarea ref={contentInput} label="Escribí una respuesta o agregá contenido..." val={contentVal} setVal={setContentVal} disabled={false} nomargin={true} />
                            <div className="modal-inline-actions">
                                <div className="modal-inline-actions-left">
                                    <Switch onLabel="Cerrar ticket" offLabel="Cerrar ticket" val={[closeVal,setCloseVal]} nomargin={true} clickEvent={()=>{closeVal==0&&changeDialog("ticket_close","","")}} />
                                </div>
                                <div className="modal-inline-actions-right">
                                    <ButtonPrimary label="Enviar mensaje" clickEvent={saveData} />
                                </div>
                            </div>
                        </Fragment>}
                        {ticket.responses.map((item,i)=>{
                            return(
                                <Message key={i} message={item} type="response" connector={(i==0 && ticket.status==3)?false:true} />
                            )
                        })}
                        <Message message={ticket} type="main" connector={(ticket.status==3 && ticket.responses.length==0)?false:true} />
                        <div className="ticket-info">
                            <div className="ticket-avatar">
                                {ticket["shop_data"]!=""?<Avatar element="shop" context="medium" id={ticket["shop_data"]["id"]} picture={ticket["shop_data"]["has_picture"]} color="#B7FAFF" capitals="" />
                                :ticket["user_data"]!=""?<Avatar element="user" context="medium" id={ticket["user_data"]["id"]} picture={ticket["user_data"]["has_picture"]} color="#B7FAFF" capitals={ticket["user_data"]["capitals"]} />
                                :<Avatar element="user" context="medium" id="" picture="" color="#B7FAFF" capitals="" />}
                            </div>
                            <div className="ticket-shop">{ticket["shop_data"]!=""?ticket["shop_data"]["name"]:ticket["user_data"]!=""?(ticket["user_data"]["first_name"]+" "+ticket["user_data"]["last_name"]):"‒"}</div>
                            <div>Iniciado el {formatDate(ticket["created"],"long")}</div>
                            <div>({ticket["responses"].length+1} {ticket["responses"].length+1!=1?"mensajes":"mensaje"})</div>
                        </div>
                        <div className="modal-commands">
                            <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                        </div>
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}