import {Fragment} from "react"
import next from "../assets/next.svg"
import secure from "../assets/secure-2.svg"

export default function PaymentButton({label,symbol,clickEvent}){

    return(
        <button type="submit" className={symbol=="secure"?"payment-button payment-button-alt":"payment-button"} onClick={clickEvent}>
            {symbol=="next" && <Fragment>{label}<img src={next} /></Fragment>}
            {symbol=="secure" && <Fragment><img src={secure} />{label}</Fragment>}
        </button>
    )
    
}