import {Fragment} from "react"
import {NavLink} from "react-router-dom"
import create from "../assets/create.svg"
import download from "../assets/download.svg"
import projections from "../assets/projections.svg"
import liquidations from "../assets/liquidations.svg"

export default function ButtonBig({button}){

    let icon=""
    if(button.icon=="create"){
        icon=create
    }else if(button.icon=="download"){
        icon=download
    }else if(button.icon=="projections"){
        icon=projections
    }else if(button.icon=="liquidations"){
        icon=liquidations
    }

    return(
        <Fragment>
            {button.link!="" &&
            <NavLink className="big-button" to={button.link}>
                {button.icon && <div className="big-button-icon"><img src={icon} /></div>}
                <div className="big-button-caption">{button.label}</div>
            </NavLink>}
            {button.href!="" &&
            <a href={button.href} className="big-button" target="_blank">
                {button.icon && <div className="big-button-icon"><img src={icon} /></div>}
                <div className="big-button-caption">{button.label}</div>
            </a>}
            {button.clickEvent!="" &&
            <div className="big-button" onClick={button.clickEvent}>
                {button.icon && <div className="big-button-icon"><img src={icon} /></div>}
                <div className="big-button-caption">{button.label}</div>
            </div>}
        </Fragment>
    )
    
}