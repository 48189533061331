import {useState,useContext,useEffect,Fragment,useRef} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useDialog from "../hooks/useDialog"
import useModal from "../hooks/useModal"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import ModalTitle from "../components/ModalTitle"
import Selector from "../commons/Selector"
import ButtonCommand from "../commons/ButtonCommand"

export default function ShopAssign(){

    const [modalContext]=useContext(ModalContext)
    const {modalReady,manageModal}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const requestApi=useApi()
    const [ready,setReady]=useState(0)
    const mode=modalContext.reference["team"]==0?"create":"edit"

    const [idVal,setIdVal]=useState(0)
    const [userNameVal,setUserNameVal]=useState("")
    const [defaultData,setDefaultData]=useState("")
    const [shopData,setShopData]=useState("")

    const userInput=useRef()
    const [userVal,setUserVal]=useState()

    const roleInput=useRef()
    const [roleVal,setRoleVal]=useState()
    const [roleOptions]=useState([
        {label:"Administrador del comercio",value:"shop_admin"},
        {label:"Administrador de un punto de cobro",value:"point_admin"},
        {label:"Operador de un punto de cobro",value:"point_operator"}
    ])

    const pointInput=useRef()
    const [pointVal,setPointVal]=useState()
    const [pointOptions,setPointOptions]=useState([])

    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("shops/team_info",{
                            permission_id:modalContext.reference["team"]
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    const loadShop=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/info",{
                        shop_id:modalContext.reference["shop"]
                    })
                    .then(data=>{
                        return data.data
                    })
            )
        })
    }

    const loadPoints=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("shops/points",{shop_id:modalContext.reference["shop"]})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(point){
                            newOptions.push({label:point.name,value:point.id})
                        })
                        return newOptions
                    })
            )
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadData,loadShop,loadPoints].map(func=>func())).then((result)=>{
            setDefaultData(result[0])
            setShopData(result[1])
            let pointOptionsNew=result[2]
            setPointOptions(pointOptionsNew)
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData["id"])
            setUserNameVal(defaultData["name"])
            setUserVal(defaultData["user_id"])
            roleOptions.forEach(function(item){
                if(item.value==defaultData.role){
                    roleInput.current.setValue({label:item.label,value:item.value})
                }
            })
            if(defaultData["role"]=="point_admin" || defaultData["role"]=="point_operator"){
                pointOptions.forEach(function(item){
                    if(item.value==defaultData.reference){
                        pointInput.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
        }
    },[ready])

    const [showPoints,setShowPoints]=useState(false)
    useEffect(()=>{
        if(roleVal=="shop_admin"){
            setShowPoints(false)
        }else if(roleVal=="point_admin"){
            setShowPoints(true)
        }else if(roleVal=="point_operator"){
            setShowPoints(true)
        }else{
            setShowPoints(false)
        }
    },[roleVal])

    const saveData=()=>{
        let validated=false
        if(mode=="create"){
            if(userInput.current.getValue().length==0){
                userInput.current.focus()
            }else{
                if(roleInput.current.getValue().length==0){
                    roleInput.current.focus()
                }else{
                    if((roleInput.current.getValue()[0].value=="point_admin" || roleInput.current.getValue()[0].value=="point_operator") && pointInput.current.getValue().length==0){
                        pointInput.current.focus()
                    }else{
                        validated=true
                    }
                }
            }
        }else if(mode=="edit"){
            if(roleInput.current.getValue().length==0){
                roleInput.current.focus()
            }else{
                if((roleInput.current.getValue()[0].value=="point_admin" || roleInput.current.getValue()[0].value=="point_operator") && pointInput.current.getValue().length==0){
                    pointInput.current.focus()
                }else{
                    validated=true
                }
            }
        }
        if(validated==true){
            requestApi("shops/team_save",{
                    id:idVal,
                    mode:mode,
                    shop:modalContext.reference["shop"],
                    user:mode=="create"?(userInput.current.getValue()[0]?userInput.current.getValue()[0].id:0):userVal,
                    role:roleInput.current.getValue()[0]?roleInput.current.getValue()[0].value:"",
                    point:pointInput.current.getValue()[0]?pointInput.current.getValue()[0].value:0
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_f","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        manageModal("hide")
                        refresh(["info"],"")
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("shops/team_remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["info"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nueva asignación":"Editar asignación"} />
                    <div className="modal-explanation modal-explanation-center">
                        Equipo: <strong>{shopData["name"]==undefined?"‒":shopData["name"]}</strong>
                    </div>
                    {mode=="create" &&
                    <Selector ref={userInput} val={userVal} setVal={setUserVal} placeholder="Usuario" searchable={1} options={[]} async={{context:"users"}} />}
                    {mode=="edit" &&
                    <Textbox ref={userInput} label="Usuario" type="text" val={userNameVal} setVal={setUserNameVal} disabled={true} />}
                    <Selector ref={roleInput} val={roleVal} setVal={setRoleVal} placeholder="Rol asignado" searchable={0} options={roleOptions} />
                    <div style={{display:showPoints?"block":"none"}}>
                        <Selector ref={pointInput} val={pointVal} setVal={setPointVal} placeholder="Punto de cobro" searchable={1} options={pointOptions} />
                    </div>
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}