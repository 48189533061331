import useModal from "../hooks/useModal"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import ButtonBox from "../commons/ButtonBox"
import PluginsImg from "../assets/plugins.svg"
import ModulesImg from "../assets/modules.svg"

export default function Plugins(){
    
    const {changeModal}=useModal()

    const create=()=>{
        changeModal("ticket-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="Complementos"
                intro="Configurá Cajero24 en las principales plataformas de ecommerce."
                back=""
                button={{label:"Solicitar asistencia",icon:"create",link:"",href:"",clickEvent:create}}
                tabs=""
            />
            <SectionContent>
                <div className="boxes">
                    <div className="box">
                        <div className="box-info">
                            <div className="box-title">Agregá Cajero24 a tu tienda</div>
                            <div className="box-description">Instalá los módulos de pago disponibles para integrar en soluciones de comercio electrónico, tales como: WooCommerce (WordPress), Adobe Commerce (Magento), PrestaShop, entre otras. Para obtener los módulos, te pedimos que envíes una solicitud de soporte, nuestro equipo te guiará para realizar la implementación.</div>
                            <ButtonBox label="Solicitar asistencia" clickEvent={create} to="" link="" />
                        </div>
                        <div className="box-picture">
                            <img src={PluginsImg} />
                        </div>
                    </div>
                    <div className="box noselect">
                        <img className="box-modules" src={ModulesImg} />
                    </div>
                </div>
            </SectionContent>
        </Section>
    )
    
}