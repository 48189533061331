import {useRef,Fragment} from "react"
import useFloating from "../hooks/useFloating"
import useModal from "../hooks/useModal"
import ButtonSquare from "../commons/ButtonSquare"
import ButtonCircle from "../commons/ButtonCircle"
import Searcher from "../commons/Searcher"
import Changer from "../commons/Changer"
import Counter from "./Counter"

export default function Tools({keyword,config,filter,changer,swapper,total,calendar}){

    const {changeModal}=useModal()
    const {changeFloating}=useFloating()
    const [filters,setFilters]=filter
    const [changers,setChangers]=changer
    const changerInput=useRef()
    const [changerVal,setChangerVal]=swapper

    return(
        <div className="content-frame-top noselect">
            <div className="tools">
                <div className="tools-left">
                    <div className="tools-left-layout">
                        {keyword && <Searcher keyword={keyword[0]} setKeyword={keyword[1]} placeholder="Buscar..." />}
                        {(config.section=="activity" || config.section=="liquidations" || config.section=="records" || config.section=="users" || config.section=="payments" || config.section=="shops" || config.section=="links" || config.section=="subscriptions" || config.section=="subscribers" || config.section=="tickets" || config.section=="reports" || config.section=="messages" || config.section=="articles" || config.section=="analytics") &&
                        <ButtonSquare icon="filters" on={filters!=""?true:false} clickEvent={()=>{
                            changeFloating(
                                config.section=="activity"?"activity-filters":
                                config.section=="records"?"records-filters":
                                config.section=="liquidations"?"liquidations-filters":
                                config.section=="users"?"users-filters":
                                config.section=="payments"?"payments-filters":
                                config.section=="shops"?"shops-filters":
                                config.section=="links"?"links-filters":
                                config.section=="subscriptions"?"subscriptions-filters":
                                config.section=="subscribers"?"subscribers-filters":
                                config.section=="tickets"?"tickets-filters":
                                config.section=="messages"?"messages-filters":
                                config.section=="articles"?"articles-filters":
                                config.section=="reports"?"reports-filters":
                                config.section=="analytics"?"analytics-filters":""
                                ,"","",filters,setFilters)
                        }} />}
                        {(config.section=="activity" || config.section=="liquidations" || config.section=="reports" || config.section=="links" || config.section=="subscriptions" || config.section=="tickets" || config.section=="projections" || config.section=="analytics") &&
                        <ButtonSquare icon="shops" on={changers!=""?true:false} clickEvent={()=>{changeFloating("shops-changers",config.section,"",changers,setChangers)}} />}
                        {calendar && <Fragment>{calendar}</Fragment>}
                    </div>
                    {config.section=="records" &&
                    <Changer ref={changerInput} context="records" changer={[changerVal,setChangerVal]} placeholder="Comercio:" searchable={1} />}
                </div>
                <div className="tools-right">
                    {config.counter && <Counter quantity={total} singular={config.counter.singular} plural={config.counter.plural} />}
                    {(config.section=="records") &&
                    <ButtonCircle icon="add" active={0} clickEvent={()=>{changeModal("record","",changerVal)}} />}
                    {config.section=="activity" &&
                    <ButtonCircle icon="download" active={0} clickEvent={()=>{changeModal("download","create",{section:config.section=="activity"?"transactions":config.section})}} />}
                </div>
            </div>
        </div>
    )

}