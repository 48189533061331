import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Dropdown from "../commons/Dropdown"
import SwitchButton from "../commons/SwitchButton"
import close from "../assets/close-pink.svg"

export default function MessagesFilters(){

    const {manageFloating,floatingReady}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const [filter1Val,setFilter1Val]=useState("")
    const [filter2Val,setFilter2Val]=useState("")
    const [filter3Val,setFilter3Val]=useState("")

    useEffect(()=>{
        floatingReady(1)
    },[])

    const apply=()=>{
        setValue({
            status:filter1Val,
            responses:filter2Val,
            period:filter3.current.getValue()[0]?filter3.current.getValue()[0].value:""
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val("")
        setFilter2Val("")
        setFilter3Val("")
        filter3.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(value!=""){
            if(value["status"]!=""){
                setFilter1Val(value["status"])
            }
            if(value["responses"]!=""){
                setFilter2Val(value["responses"])
            }
            if(value["period"]!=""){
                filter3.current.props["options"].forEach(function(item){
                    if(value["period"]==item.value){
                        filter3.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
        }
    },[value])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-options floating-options-space">
                    <div className="floating-half">
                        <SwitchButton ref={filter1} val={filter1Val} setVal={setFilter1Val}
                            options={[
                                {label:"Recibidos",value:"received"},
                                {label:"En proceso",value:"in_process"},
                                {label:"Finalizados",value:"finished"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Cantidad de respuestas</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter2} val={filter2Val} setVal={setFilter2Val}
                            options={[
                                {label:"Ninguna",value:"none"},
                                {label:"Sólo una",value:"one"},
                                {label:"Entre 5~10",value:"5_10"},
                                {label:"Más de 10",value:"10"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Fecha de recepción</div>
                <div className="floating-options">
                    <div className="floating-full">
                        <Dropdown ref={filter3} val={filter3Val} setVal={setFilter3Val} placeholder="Período" multiple={0} searchable={1} options={[
                            {label:"Indiferente",value:""},
                            {label:"Última hora",value:"last_hour"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Última semana",value:"last_week"},
                            {label:"Últimos quince días",value:"last_15_days"},
                            {label:"Este mes",value:"this_month"},
                            {label:"Últimos tres meses",value:"last_three_months"},
                            {label:"Últimos seis meses",value:"last_six_months"},
                            {label:"Este año",value:"this_year"}
                        ]} />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}