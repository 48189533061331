import {useState,useRef,useEffect,Fragment} from "react"
import {Link} from "react-router-dom"
import Modal from "./Modal"
import Dialog from "./Dialog"
import brandImg from "../assets/brand.svg"
import brandImgInverse from "../assets/brand-inverse.svg"
import nextImg from "../assets/next.svg"
import next2Img from "../assets/next-3.svg"
import close from "../assets/close-pink.svg"
import Networks from "./Networks"

export default function HomeBar({context,contactOpen,aboutOpen,faqsOpen}){

    const bar=useRef()
    const [mobileMenu,setMobileMenu]=useState(0)

    const menu=()=>{
        if(mobileMenu==0){
            setMobileMenu(1)
        }else{
            setMobileMenu(0)
        }
    }

    useEffect(()=>{
        document.querySelectorAll('a[href^="#"]').forEach(anchor=>{
            anchor.addEventListener("click",function(e){
                e.preventDefault()
                document.querySelector(this.getAttribute("href")).scrollIntoView({
                    behavior:"smooth"
                })
            })
        })
        const handleScroll=event=>{
            if(window.scrollY>0){
                bar.current.classList.add("home-bar-on")
            }else{
                bar.current.classList.remove("home-bar-on")
            }
        }
        window.addEventListener("scroll",handleScroll)
        return()=>{
            window.removeEventListener("scroll",handleScroll)
        }
    },[])

    return(
        <Fragment>
            <div ref={bar} className="home-bar noselect">
                <div className="home-bar-frame">
                    <div className="home-bar-brand">
                        <Link className="home-bar-brand-link" to="/">
                            <img src={brandImgInverse} />
                            <img src={brandImg} />
                        </Link>
                    </div>
                    <div className="home-bar-buttons">
                        
                    </div>
                    <div className="home-bar-actions">
                        <div className="home-action home-pulse" onClick={(e)=>{contactOpen(e)}}>Contactanos</div>
                        <Link className="home-action-alt" to="/entrar">Clientes<img className="home-action-alt-arrow-1" src={nextImg} /><img className="home-action-alt-arrow-2" src={next2Img} /></Link>
                    </div>
                    <div className="home-bar-menu">
                        <div className="home-bar-menu-btn" onClick={menu}>
                            <div className="home-bar-menu-line"></div>
                            <div className="home-bar-menu-line"></div>
                            <div className="home-bar-menu-line"></div>
                        </div>
                    </div>
                </div>
            </div>
            {mobileMenu==1 &&
            <div className="mobile-menu" onClick={menu}>
                <div className="mobile-menu-close"><img src={close} /></div>
                <div className="mobile-menu-content">
                    <div>
                        <div className="mobile-menu-actions">
                            <div className="home-action home-pulse" onClick={(e)=>{contactOpen(e)}}>Contactanos</div>
                            <Link className="home-action-alt" to="/entrar">Clientes<img className="home-action-alt-arrow-1" src={nextImg} /><img className="home-action-alt-arrow-2" src={next2Img} /></Link>
                        </div>
                        <div>
                            <a className="mobile-menu-link" href="" onClick={(e)=>{aboutOpen(e)}}>Sobre Cajero24</a><br />
                            {context=="home"?
                            <a className="mobile-menu-link" href="#canales-de-cobro">Canales de cobro</a>:
                            <Link className="mobile-menu-link" to="/">Canales de cobro</Link>}<br />
                            {context=="home"?
                            <a className="mobile-menu-link" href="#entidades">Entidades</a>:
                            <Link className="mobile-menu-link" to="/">Entidades</Link>}<br />
                            {context=="home"?
                            <a className="mobile-menu-link" href="#precios">Precios</a>:
                            <Link className="mobile-menu-link" to="/">Precios</Link>}<br />
                            <Link className="mobile-menu-link" to="/blog">Blog</Link><br />
                            <a className="mobile-menu-link" href="" onClick={(e)=>{faqsOpen(e)}}>Preguntas frecuentes</a><br />
                        </div>
                        <div className="mobile-menu-networks">
                            <Networks context="mobile" />
                        </div>
                    </div>
                </div>
            </div>}
            <Modal />
            <Dialog />
        </Fragment>
    )
    
}