import {Fragment,useEffect,useState,useRef,useContext} from "react"
import {CopyToClipboard} from "react-copy-to-clipboard"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useToast from "../hooks/useToast"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonBack from "../commons/ButtonBack"
import ButtonCard from "../commons/ButtonCard"
import Selector from "../commons/Selector"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import Divisor from "../commons/Divisor"
import {format} from "date-fns"
import copyImg from "../assets/copy.svg"

export default function DownloadLinks(){

    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {changeToast}=useToast()
    const {changeModal,modalReady}=useModal()
    const pointInput=useRef()
    const [pointVal,setPointVal]=useState(modalContext.reference["point"])
    const [pointOptions,setPointOptions]=useState([])
    const [links,setLinks]=useState()

    useEffect(()=>{
        modalReady(0)
        requestApi("shops/shops_points",{context:"only_points"})
            .then(data=>{
                let newOptions=[]
                let selectedOption=""
                data.items.forEach(function(shop){
                    newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                    if(shop.point_id==modalContext.reference["point"]["point_id"]){
                        selectedOption={label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}}
                    }
                })
                setPointOptions(newOptions)
                pointInput.current.setValue(selectedOption)
                modalReady(1)
            })
    },[])

    useEffect(()=>{
        modalReady(0)
        requestApi("download/links",{
                point:pointVal
            })
            .then(data=>{
                setLinks(data.items)
                modalReady(1)
            })
    },[pointVal])

    const defineStatus=(elements)=>{
        let caption=[]
        elements.forEach(function(item){
            if(item=="approved"){
                caption.push("aprobadas")
            }else if(item=="pending"){
                caption.push("pendientes")
            }else if(item=="refund"){
                caption.push("devueltas")
            }else if(item=="partial_refund"){
                caption.push("devueltas parcialmente")
            }else if(item=="canceled"){
                caption.push("canceladas")
            }else if(item=="rejected"){
                caption.push("rechazadas")
            }else if(item=="liquidated"){
                caption.push("liquidadas")
            }else if(item=="non_liquidated"){
                caption.push("sin liquidar")
            }else if(item=="rejected"){
                caption.push("contracargadas")
            }
        })
        if(caption.length==0){
            caption="todos"
        }else{
            caption=caption.join(", ")
        }
        return caption
    }

    const defineTypes=(elements)=>{
        let caption=[]
        elements.forEach(function(item){
            if(item=="payment"){
                caption.push("pagos")
            }else if(item=="refund"){
                caption.push("devoluciones")
            }else if(item=="partial_refund"){
                caption.push("devoluciones parciales")
            }
        })
        if(caption.length==0){
            caption="todos"
        }else{
            caption=caption.join(", ")
        }
        return caption
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("download","create",{section:modalContext.reference["section"]})}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-info noselect">
                    <ModalTitle title="Descarga dinámica" />
                    <div className="modal-explanation modal-explanation-center">
                        Utilizá los enlaces generados para descargar datos recurrentes desde el navegador, o para integrar los datos a tu sistema de administración.
                    </div>
                    <Divisor />
                    <Selector val={pointVal} setVal={setPointVal} ref={pointInput} placeholder="Punto de cobro" searchable={1} options={pointOptions} />
                    {links &&
                    <Card title="Enlaces generados" icon={{element:""}} action="" actionIcon="" actionClickEvent="">
                        {links.length>0?
                            links.map((item,i)=>{
                                return(
                                    <CardRow key={i} label={item["name"]+" ("+(item["data_format"]=="json"?"JSON"
                                        :item["data_format"]=="text"?"Texto plano"
                                        :item["data_format"]=="text_guadalupe"?"Texto plano, Guadalupe"
                                        :item["data_format"]=="text_munimza1"?"Texto plano, Muni Mza 1"
                                        :item["data_format"]=="text_munimza2"?"Texto plano, Muni Mza 2"
                                        :item["data_format"]=="text_cegc"?"Texto plano, CEGC"
                                        :item["data_format"]=="text_major"?"Texto plano, Major"
                                        :item["data_format"]=="excel"?"Excel"
                                        :item["data_format"]=="csv"?"CSV":"‒")+")"}
                                        detail={
                                            <Fragment>
                                                <span className="card-row-medium">{item["period"]=="period"?"Período: ":item["period"]=="month_year"?"Mes/Año: ":item["period"]=="date"?"Fecha: ":item["period"]=="interval"?"Intervalo: ":"‒"}</span>
                                                {item["period"]=="period"?(item["period_date"]["period"]=="last_hour"?"última hora"
                                                    :item["period_date"]["period"]=="today"?"hoy"
                                                    :item["period_date"]["period"]=="yesterday"?"ayer"
                                                    :item["period_date"]["period"]=="last_week"?"última semana"
                                                    :item["period_date"]["period"]=="last_15_days"?"últimos quince días"
                                                    :item["period_date"]["period"]=="this_month"?"este mes"
                                                    :item["period_date"]["period"]=="last_three_months"?"últimos tres meses"
                                                    :item["period_date"]["period"]=="last_six_months"?"últimos seis meses"
                                                    :item["period_date"]["period"]=="this_year"?"este año"
                                                    :"‒"
                                                )
                                                :item["period"]=="month_year"?((item["period_date"]["month"]!=0 && item["period_date"]["year"]!=0)?((item["period_date"]["month"]==1?"enero":item["period_date"]["month"]==2?"febrero":item["period_date"]["month"]==3?"marzo":item["period_date"]["month"]==4?"abril":item["period_date"]["month"]==5?"mayo":item["period_date"]["month"]==6?"junio":item["period_date"]["month"]==7?"julio":item["period_date"]["month"]==8?"agosto":item["period_date"]["month"]==9?"septiembre":item["period_date"]["month"]==10?"octubre":item["period_date"]["month"]==11?"noviembre":item["period_date"]["month"]==12?"diciembre":"‒")+" "+item["period_date"]["year"]):(item["period_date"]["month"]==0 && item["period_date"]["year"]!=0)?item["period_date"]["year"]:"‒")
                                                :item["period"]=="date"?format(new Date(item["period_date"]["date"]*1000),"dd/MM/yyyy")
                                                :item["period"]=="interval"?" del "+format(new Date(item["period_date"]["start"]*1000),"dd/MM/yyyy")+" al "+format(new Date(item["period_date"]["end"]*1000),"dd/MM/yyyy")
                                                :"‒"}
                                                <br />
                                                <span className="card-row-medium">Estados: </span>{defineStatus(item["transactions_status"])}<br />
                                                <span className="card-row-medium">Tipos: </span>{defineTypes(item["transactions_types"])}
                                                <div className="card-textbox-mini-copy">
                                                    <input className="card-textbox-mini card-textbox-mini-full" type="text" value={item["link"]} onChange={()=>{}} readOnly />
                                                    <CopyToClipboard text={item["link"]} onCopy={()=>{changeToast("link_copied","")}}>
                                                        <div className="share-link-copy share-link-copy-small"><img src={copyImg} /></div>
                                                    </CopyToClipboard>
                                                </div>
                                            </Fragment>
                                        }>
                                        <div className="card-buttons">
                                            <ButtonCard label="Descargar" style="primary" clickEvent={()=>{window.open(item["link"])}} />
                                            <ButtonCard label="Editar" style="secondary" clickEvent={()=>{changeModal("download","edit",{point:pointVal,link_id:item["id"]})}} />
                                        </div>
                                    </CardRow>
                                )
                            })
                        :<CardRow label="Aún no hay enlaces." detail="" />}
                    </Card>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}