import {useState,useRef,useEffect,useContext,Fragment} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import Textbox from "../commons/Textbox"
import Textarea from "../commons/Textarea"
import Selector from "../commons/Selector"
import Switch from "../commons/Switch"
import ButtonCommand from "../commons/ButtonCommand"

export default function FaqsEdit(){

    const [modalContext]=useContext(ModalContext)
    const {manageModal,modalReady}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const {refresh}=useRefresh()
    const [ready,setReady]=useState(0)
    const requestApi=useApi()
    const mode=modalContext.reference==0?"create":"edit"
    const topicInput=useRef()
    const contentInput=useRef()
    const groupsInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [topicVal,setTopicVal]=useState("")
    const [contentVal,setContentVal]=useState("")
    const [groupsVal,setGroupsVal]=useState()
    const [statusVal,setStatusVal]=useState(0)

    const [groupsOptions,setGroupsOptions]=useState([])
    const loadGroups=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("faqs/groups",{})
                    .then(data=>{
                        let newOptions=[]
                        data.items.forEach(function(group){
                            newOptions.push({label:group["name"],value:group["id"]})
                        })
                        return newOptions
                    })
            )
        })
    }

    const [defaultData,setDefaultData]=useState("")
    const loadData=()=>{
        return new Promise(function(resolve){
            if(mode=="edit"){
                resolve(
                    requestApi("faqs/topic_info",{
                            topic_id:modalContext.reference
                        })
                        .then(data=>{
                            return data.data
                        })
                )
            }else{
                resolve(
                    ()=>{return ""}
                )
            }
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadGroups,loadData].map(func=>func())).then((result)=>{
            setGroupsOptions(result[0])
            setDefaultData(result[1])
            setReady(1)
            modalReady(1)
        })
    },[])

    useEffect(()=>{
        if(mode=="edit" && ready){
            setIdVal(defaultData["id"])
            setTopicVal(defaultData["topic"])
            setContentVal(defaultData["content"])
            setStatusVal(defaultData["status"])
            let newGroupsValue=[]
            groupsOptions.forEach(function(item){
                defaultData["groups"].forEach(function(itemx){
                    if(itemx.id==item.value){
                        newGroupsValue.push({label:itemx["name"],value:itemx["id"]})
                    }
                })
                groupsInput.current.setValue(newGroupsValue)
            })
        }
    },[ready])

    const saveData=()=>{
        if(topicVal==""){
            topicInput.current.focus()
        }else{
            requestApi("faqs/topic_save",{
                    id:idVal,
                    mode:mode,
                    topic:topicVal,
                    content:contentVal,
                    groups:groupsInput.current.getValue(),
                    status:statusVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_m","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        manageModal("hide")
                        refresh(["list"],"")
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("faqs/topic_remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    manageModal("hide")
                    refresh(["list"],"")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nuevo tema":"Editar tema"} />
                    <Selector ref={groupsInput} val={groupsVal} setVal={setGroupsVal} placeholder="Grupos" multiple={1} searchable={1} options={groupsOptions} />
                    <Textbox ref={topicInput} label="Tema" type="text" val={topicVal} setVal={setTopicVal} disabled={false} />
                    <Textarea ref={contentInput} label="Contenido" val={contentVal} setVal={setContentVal} disabled={false} />
                    <Switch onLabel="Activo" offLabel="Inactivo" val={[statusVal,setStatusVal]} clickEvent="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}