import {useEffect,useContext,Fragment} from "react"
import DialogContext from "../contexts/DialogContext"
import useDialog from "../hooks/useDialog"
import ButtonDialogPrimary from "../commons/ButtonDialogPrimary"
import ButtonDialogSecondary from "../commons/ButtonDialogSecondary"

export default function Dialog(){

    const [dialogContext]=useContext(DialogContext)
    const {manageDialog}=useDialog()

    useEffect(()=>{
        if(dialogContext.dialog!=""){
            manageDialog("show")
        }
    },[dialogContext])

    return(
        <div id="dialog" className="dialog noselect">
            <div className="dialog-overlay"></div>
            <div className="dialog-frame">
                <div className="dialog-window">
                    <div className="dialog-message">
                        {dialogContext.dialog=="generic_error" && 
                        <div><strong>Algo no anduvo bien.</strong> Intentá nuevamente en unos minutos.</div>}
                        {dialogContext.dialog=="user_inactive" && 
                        <div><strong>La cuenta está inactiva.</strong> Consultá más detalles desde el Centro de Ayuda.</div>}
                        {dialogContext.dialog=="user_blocked" && 
                        <div><strong>La cuenta está bloqueada.</strong> Consultá más detalles desde el Centro de Ayuda.</div>}
                        {dialogContext.dialog=="user_deleted" && 
                        <div><strong>La cuenta fue eliminada.</strong> Consultá más detalles desde el Centro de Ayuda.</div>}
                        {dialogContext.dialog=="user_not_exists" && 
                        <div><strong>Por ahora, no es posible crear cuentas nuevas.</strong> Si utilizás Cajero24 en tu comercio y tenés problemas para ingresar, te pedimos consultar con el Centro de Ayuda.</div>}
                        {dialogContext.dialog=="user_not_authorized" && 
                        <div><strong>El acceso no ha sido autorizado.</strong> Consultá más detalles desde el Centro de Ayuda.</div>}
                        {dialogContext.dialog=="user_wrong_password" && 
                        <div><strong>La contraseña es incorrecta.</strong> Revisala para poder continuar.</div>}
                        {dialogContext.dialog=="password_mismatch" && 
                        <div><strong>Las dos contraseñas no coinciden.</strong> Revisalas para poder continuar.</div>}
                        {dialogContext.dialog=="password_length" && 
                        <div><strong>La contraseña debe tener al menos 7 caracteres.</strong> Revisala para poder continuar.</div>}
                        {dialogContext.dialog=="email_exists" && 
                        <div><strong>El email ya está asignado a un usuario.</strong> Elige otro para poder continuar.</div>}
                        {dialogContext.dialog=="forget_card" && 
                        <div><strong>Deberás volver a completar todos datos cuando quieras usar esta tarjeta otra vez.</strong> ¿Seguro querés continuar?</div>}
                        {dialogContext.dialog=="terminate_session" && 
                        <div><strong>Se cerrará la sesión activa en ese dispositivo.</strong> ¿Seguro querés continuar?</div>}
                        {dialogContext.dialog=="terminate_this_session" && 
                        <div><strong>Vas a salir de Cajero24 en este dispositivo.</strong> ¿Seguro querés continuar?</div>}
                        {dialogContext.dialog=="card_error" && 
                        <div><strong>La tarjeta que ingresaste no es Visa Galicia.</strong> Revisá los datos o elegí un medio de pago diferente.</div>}
                        {dialogContext.dialog=="remove" && 
                        <div><strong>Se va a eliminar este elemento y no podrá recuperarse luego.</strong> ¿Seguro querés continuar?</div>}
                        {dialogContext.dialog=="ticket_close" && 
                        <div>Al cerrar el ticket se dará por resuelta esta solicitud de asistencia.</div>}
                        {dialogContext.dialog=="message_close" && 
                        <div>Al finalizar el mensaje se dará por resuelta esta consulta.</div>}
                        {dialogContext.dialog=="user_not_allowed" && 
                        <div><strong>La dirección de email no está habilitada para realizar pagos.</strong> Probá ingresando otro correo electrónico.</div>}
                        {dialogContext.dialog=="refund" && 
                        <div><strong>Se va a devolver esta transacción y la operación no podrá revertirse.</strong> ¿Seguro querés continuar?</div>}
                        {dialogContext.dialog=="refund_max_reached" && 
                        <div>El valor a devolver debe ser igual o inferior al monto total del pago que aún no haya sido devuelto.</div>}
                        {dialogContext.dialog=="refund_error" && 
                        <div><strong>No es posible continuar con la devolución.</strong> El procesador de pagos no permite realizar la operación.</div>}
                        {dialogContext.dialog=="liquidate" && 
                        <div><strong>Se va a liquidar esta transacción y la operación no podrá revertirse.</strong> ¿Seguro querés continuar?</div>}
                        {dialogContext.dialog=="contact_sent" && 
                        <div><strong>¡Gracias por comunicarte con nosotros!</strong> Una persona del equipo de Cajero24 se contactará a la brevedad.</div>}
                        {dialogContext.dialog=="link_send" && 
                        <div>Vas a enviar un correo a <strong>{dialogContext.reference}</strong> con un enlace para que pueda hacer este pago.</div>}
                        {dialogContext.dialog=="help" && 
                        <div>Para solicitar asistencia <strong>comunicate con el equipo de atención al cliente de Cajero24:</strong> hola@cajero24.co</div>}
                    </div>
                    <div className="dialog-actions">
                        {(dialogContext.dialog=="generic_error" || dialogContext.dialog=="user_inactive" || dialogContext.dialog=="user_blocked" || dialogContext.dialog=="user_deleted" || dialogContext.dialog=="user_not_exists" || dialogContext.dialog=="user_not_authorized" || dialogContext.dialog=="password_mismatch" || dialogContext.dialog=="password_length" || dialogContext.dialog=="email_exists" || dialogContext.dialog=="ticket_close" || dialogContext.dialog=="message_close" || dialogContext.dialog=="user_not_allowed") && <Fragment>
                        <ButtonDialogPrimary label="Lo entiendo" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="user_wrong_password" && <Fragment>
                        <ButtonDialogPrimary label="Reintentar" clickEvent={()=>{
                            manageDialog("hide")
                        }} />
                        </Fragment>}
                        {dialogContext.dialog=="forget_card" && <Fragment>
                        <ButtonDialogPrimary label="Olvidar tarjeta" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {(dialogContext.dialog=="terminate_session" || dialogContext.dialog=="terminate_this_session") && <Fragment>
                        <ButtonDialogPrimary label="Cerrar sesión" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="card_error" && <Fragment>
                        <ButtonDialogPrimary label="Elegir otra tarjeta" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Reintentar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="remove" && <Fragment>
                        <ButtonDialogPrimary label="Sí, eliminar" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="refund" && <Fragment>
                        <ButtonDialogPrimary label="Sí, devolver" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {(dialogContext.dialog=="refund_max_reached" || dialogContext.dialog=="refund_error") && <Fragment>
                        <ButtonDialogPrimary label="Lo entiendo" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="liquidate" && <Fragment>
                        <ButtonDialogPrimary label="Sí, liquidar" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {(dialogContext.dialog=="contact_sent") && <Fragment>
                        <ButtonDialogPrimary label="Cerrar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="link_send" && <Fragment>
                        <ButtonDialogPrimary label="Enviar email" clickEvent={dialogContext.action} />
                        <ButtonDialogSecondary label="Cancelar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                        {dialogContext.dialog=="help" && <Fragment>
                        <ButtonDialogPrimary label="WhatsApp" clickEvent={()=>{window.open("https://wa.me/542612185757")}} />
                        <ButtonDialogSecondary label="Cerrar" clickEvent={()=>{manageDialog("hide")}} />
                        </Fragment>}
                    </div>
                </div>
            </div>
        </div>
    )
    
}