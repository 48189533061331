import {Fragment,useState,useRef,useContext,useEffect} from "react"
import GlobalContext from "../contexts/GlobalContext"
import useModal from "../hooks/useModal"
import useApi from "../hooks/useApi"
import useToast from "../hooks/useToast"
import useDialog from "../hooks/useDialog"
import useRefresh from "../hooks/useRefresh"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonBack from "../commons/ButtonBack"
import Uploader from "../commons/Uploader"
import Selector from "../commons/Selector"

export default function LinkImport(){

    const [globalContext]=useContext(GlobalContext)
    const {modalReady,changeModal,manageModal}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const requestApi=useApi()
    const {refresh}=useRefresh()
    const pointInput=useRef()
    const fileInput=useRef()
    const [pointVal,setPointVal]=useState()
    const [pointOptions,setPointOptions]=useState([])
    
    useEffect(()=>{
        modalReady(0)
        requestApi("shops/shops_points",{context:"only_points"})
            .then(data=>{
                let newOptions=[]
                data.items.forEach(function(shop){
                    newOptions.push({label:shop.name,value:{shop_id:shop.shop_id,point_id:shop.point_id}})
                })
                setPointOptions(newOptions)
                modalReady(1)
            })
    },[])

    const importFile=()=>{
        if(pointInput.current.getValue().length==0){
            pointInput.current.focus()
        }else{
            for(let i=0;i<fileInput.current.files.length;i++){
                let file=fileInput.current.files[i]
                var reader=new FileReader()
                reader.readAsDataURL(file);
                reader.onload=()=>{
                    requestApi("general/upload",{
                            point:pointInput.current.getValue()[0]?pointInput.current.getValue()[0].value:0,
                            upload:file
                        })
                        .then(data=>{
                            if(data.status=="success"){
                                requestApi("links/import",{
                                        point:pointInput.current.getValue()[0]?pointInput.current.getValue()[0].value:0,
                                        uploaded:data.uploaded
                                    })
                                    .then(data=>{
                                        if(data.status=="success"){
                                            changeToast("links_imported","")
                                            manageModal("hide")
                                            refresh(["list"],"")
                                        }else{
                                            changeDialog("generic_error","","")
                                        }
                                    })

                            }else{
                                changeDialog("generic_error","","")
                            }
                        })
                }
            }
        }
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("link-edit","",0)}} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title="Importar desde Excel" />
                    <div className="modal-explanation modal-explanation-center">
                        Para crear links de pago en forma masiva desde Excel, <a href={globalContext["base_url"]+"resources/Plantilla_Links_Cajero24.xlsx"} target="_blank">descargá la plantilla de guía desde aquí</a>. Completá la tabla con tus links y subila desde esta ventana. Es fundamental respetar el formato de la planilla y de los datos solicitados.
                    </div>
                    <Selector ref={pointInput} val={pointVal} setVal={setPointVal} placeholder="Punto de cobro" searchable={1} options={pointOptions} />
                    <Uploader ref={fileInput} label="Subir archivo..." note="Formato Excel (XLSX)" multiple={false} restrict={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]} action={{label:"Crear links de pago",clickEvent:importFile}} />
                </div>
            </ModalContent>
        </Fragment>
    )
    
}