import {useState,useEffect} from "react"

export default function SwitchMini({label,initial,clickEvent}){

    const [status,setStatus]=useState()

    useEffect(()=>{
        setStatus(initial)
    })

    const changeStatus=()=>{
        if(status){
            setStatus(0)
        }else{
            setStatus(1)
        }
        if(clickEvent!=""){
            clickEvent()
        }
    }

    return(
        <div className={status?"switch-mini switch-mini-on":"switch-mini"} onClick={changeStatus}>
            <div className="switch-mini-chip">
                <div className="switch-mini-chip-bg">
                    <div className="switch-mini-chip-point"></div>
                </div>
            </div>
            <div className="switch-mini-label">{label}</div>
        </div>
    )
    
}