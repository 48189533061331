import {Fragment,useContext,useEffect,useState} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import {formatDate,formatAmount} from "../hooks/Commons"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonCard from "../commons/ButtonCard"
import ButtonBack from "../commons/ButtonBack"
import Advice from "../components/Advice"
import Amount from "../commons/Amount"

export default function TransactionInfo(){
    
    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const {checkPermission}=usePermissions()
    const requestApi=useApi()
    const {changeModal,modalReady,manageModal}=useModal()
    const [info,setInfo]=useState()
    let transactionId=(modalContext.context=="from-liquidation" || modalContext.context=="from-rejection")?modalContext.reference["transaction"]:modalContext.context=="from-transaction"?modalContext.reference.reference:modalContext.reference
    let liquidationId=modalContext.context=="from-liquidation"?modalContext.reference["liquidation"]:0
    let rejectionId=modalContext.context=="from-rejection"?modalContext.reference["rejection"]:0

    useEffect(()=>{
        modalReady(0)
        requestApi("transactions/info",{
                transaction_id:transactionId
            })
            .then(data=>{
                setInfo(data.data)
                modalReady(1)
            })
    },[])

    const openLiquidation=(liquidation)=>{
        changeModal("liquidation-info","from-transaction",{transaction:transactionId,liquidation:liquidation})
    }

    const openRejection=(rejection)=>{
        changeModal("rejection-info","from-transaction",{transaction:transactionId,rejection:rejection})
    }

    const openTransaction=(transaction)=>{
        manageModal("hide")
        setTimeout(()=>{
            changeModal("transaction-info","from-transaction",{transaction:transactionId,reference:transaction})
        },500)
    }

    const downloadReceipt=(event,transaction)=>{
        event.preventDefault()
        let link=document.createElement("a")
        link.setAttribute("download","")
        link.href=globalContext["base_url"]+"api/plain/receipt/"+transaction
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    return(
        <Fragment>
            <ModalHeader>
                {modalContext.context=="from-liquidation" && <ButtonBack clickEvent={()=>{changeModal("liquidation-info","",liquidationId)}} />}
                {modalContext.context=="from-rejection" && <ButtonBack clickEvent={()=>{changeModal("rejection-info","",rejectionId)}} />}
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {info &&
                    <Fragment>
                        <ModalTitle title={"Transacción #"+String(info["id"]).padStart(6,"0")} />
                            {info["operation"]=="payment"?
                                (info["status"]==1?
                                    (info["liquidation"]==0?
                                    <Advice color="green"><strong>Aprobado.</strong> Pago realizado correctamente...</Advice>
                                    :<Advice color="green">
                                        <strong>Liquidado.</strong> Pago realizado y acreditado correctamente...<br/>
                                        <div className="advice-button" onClick={()=>{openLiquidation(info["liquidation"])}}>Ver liquidación</div>
                                    </Advice>)
                                    :info["status"]==0?
                                    <Advice color="yellow"><strong>Pendiente.</strong> Esperando acreditación...</Advice>
                                    :info["status"]==2?
                                    <Advice color="red"><strong>Rechazado.</strong> El pago no pudo realizarse...</Advice>
                                    :info["status"]==3?
                                    <Advice color="red"><strong>Cancelado.</strong> Cancelación aprobada...<br/></Advice>
                                    :info["status"]==4?
                                    <Advice color="orange">
                                        <strong>Devuelto.</strong> Reintegro aprobado...<br/>
                                        <div className="advice-button" onClick={()=>{openTransaction(info["refund"])}}>Ver devolución</div>
                                    </Advice>
                                    :info["status"]==5?
                                    <Advice color="orange">
                                        <strong>Devuelto.</strong> Reintegro parcial aprobado...
                                        {info.partial_refunds.refunds.map((item,index)=>{
                                            return(
                                                <Fragment key={index}>
                                                    <br /><div className="advice-button" onClick={()=>{openTransaction(item)}}>Ver devolución #{item}</div>
                                                </Fragment>
                                            )
                                        })}
                                    </Advice>
                                    :""
                                )
                            :info["operation"]=="refund"?
                                <Advice color="green">
                                    <strong>Aceptada.</strong> Devolución realizada correctamente...<br/>
                                    <div className="advice-button" onClick={()=>{openTransaction(info["reference"])}}>Ir a la transacción</div>
                                </Advice>
                            :info["operation"]=="partial_refund"?
                                <Advice color="green">
                                    <strong>Aceptada.</strong> Devolución parcial realizada correctamente...<br/>
                                    <div className="advice-button" onClick={()=>{openTransaction(info["reference"])}}>Ir a la transacción</div>
                                </Advice>
                            :""}
                            {info["rejection"]!=0 &&
                            <Advice color="red">
                                <strong>Contracargado.</strong> El pago fue cancelado por el banco...<br/>
                                <div className="advice-button" onClick={()=>{openRejection(info["rejection"])}}>Ver contracargo</div>
                            </Advice>}
                        <Card title="Transacción" icon={{element:"payment",reference:info["payment"]["class"]=="debin"?"debin":(info["payment"]["card_has_picture"]?info["payment"]["card_id"]:0)}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label={info["payment"]!=""?(info["payment"]["name"]!=""?info["payment"]["name"]:"‒"):"‒"} detail={info["card_number"]?("****"+info["card_number"]):""}>
                                {info["payment"]!=""?
                                    (info["payment"]["class"]=="card"?
                                        (info["card_type"]=="CREDIT"?"Tarjeta de crédito"
                                        :info["card_type"]=="DEBIT"?"Tarjeta de débito"
                                        :info["card_type"]=="PREPAID"?"Tarjeta prepaga"
                                        :"")
                                    :info["payment"]["class"]=="debin"?
                                        "Débito inmediato"
                                    :"‒")
                                :"‒"}
                            </CardRow>
                            <CardRow label="Total" detail="">
                                <Amount number={info["amount"]} currency={info["currency"]} operation={
                                    info["operation"]=="payment"?(
                                        info["status"]==1?"payment"
                                        :info["status"]==0?"pending_payment"
                                        :info["status"]==2?"rejected"
                                        :info["status"]==3?"canceled"
                                        :info["status"]==4?"payment"
                                        :info["status"]==5?"payment"
                                        :""
                                    )
                                    :info["operation"]=="refund"?"refund"
                                    :info["operation"]=="partial_refund"?"refund"
                                    :""
                                } />
                            </CardRow>
                            {info["installments"]>1 &&
                            <Fragment>
                                <CardRow label={"Total (sin recargos)"} detail="">
                                    {formatAmount(info["amount_base"],info["currency"],"display")}
                                </CardRow>                                
                                <CardRow label={"En "+info["installments"]+" cuotas"} detail={"Interés del "+info["installments_fee"]+"%"}>
                                    {formatAmount(info["installments_amount"],info["currency"],"display")} c/u
                                </CardRow>
                            </Fragment>}
                            {info["payment"]["class"]=="card" &&
                            <CardRow label="Núm. de autorización" detail="">
                                {info["authorization"]!=""?info["authorization"]:"‒"}
                            </CardRow>}
                            {(info["operation"]=="payment" && info["status"]==1) &&
                            <CardRow label="Cargo Cajero24" detail={info["charge_fee"]+(info["charge_agreement"]!=""?" ("+info["charge_agreement"]["name"]+")":"")}>
                                <span className="card-row-featured">{formatAmount(info["charge"],info["currency"],"display")}</span>
                            </CardRow>}
                            {(info["operation"]=="payment" && info["liquidation"]==0 && info["liquidation_estimated"]!=0) &&
                            <CardRow label="Liquidación estimada" detail={"(La fecha puede variar)"}>
                                <span className="card-row-featured">{formatDate(info["liquidation_estimated"],"standard")}</span>
                            </CardRow>}
                            {info["payment"]["class"]=="debin" &&
                            <Fragment>
                                <CardRow label="Estado del debin" detail="">
                                    {info["debin_status"]=="INICIADO"?"Esperando autorización"
                                    :info["debin_status"]=="EN_CURSO"?"Acreditación en curso"
                                    :info["debin_status"]=="ACREDITADO"?"Acreditado"
                                    :info["debin_status"]=="SIN_SALDO"?"Sin saldo disponible"
                                    :info["debin_status"]=="ERROR_DATOS"?"Error en los datos"
                                    :info["debin_status"]=="ERROR_DATOS_OPERACION"?"Error en los datos de la operación"
                                    :info["debin_status"]=="ERROR_DATOS_VENDEDOR"?"Error en datos del vendedor"
                                    :info["debin_status"]=="ERROR_DATOS_COMPRADOR"?"Error en datos del comprador"
                                    :info["debin_status"]=="VENCIDO"?"Se venció el plazo para la autorización"
                                    :info["debin_status"]=="SIN_GARANTIA"?"El banco no opera con debin"
                                    :info["debin_status"]=="ERROR_ACREDITACION"?"Error en la acreditación"
                                    :info["debin_status"]=="ERROR_DEBITO"?"Error en el débito"
                                    :info["debin_status"]=="DESCONOCIDO"?"Error desconocido"
                                    :info["debin_status"]=="RECHAZO_CLIENTE"?"Fue rechazado por el usuario"
                                    :"‒"}
                                </CardRow>
                            </Fragment>}
                            <Fragment>
                                <CardRow label="CBU/Alias" detail="">
                                    {info["cbu_alias"]!=""?info["cbu_alias"]:"‒"}
                                </CardRow>
                                <CardRow label="CUIT" detail="">
                                    {info["cuit"]!=""?info["cuit"]:"‒"}
                                </CardRow>
                            </Fragment>
                            {(info["operation"]=="payment" && (info["payment"]["class"]=="credit" || info["payment"]["class"]=="debit") && (info["status"]==1 || info["status"]==5)) &&
                            <CardRow label="" detail="">
                                <ButtonCard label="Devolver" style="secondary" clickEvent={()=>{changeModal("transaction-refund","",transactionId)}} />
                            </CardRow>}
                            {(checkPermission({alias:"master_admin",reference:""}) && info["operation"]=="payment" && info["status"]==1 && info["payment"]["class"]=="debin" && info["liquidation"]==0) &&
                            <CardRow label="" detail="">
                                <ButtonCard label="Liquidar" style="secondary" clickEvent={()=>{changeModal("transaction-liquidate","",transactionId)}} />
                            </CardRow>}
                        </Card>
                        {info["items"].length>0 &&
                        <Card title="Detalle" icon={{element:"detail"}} action="" actionIcon="" actionClickEvent="">
                            {info["items"].map((item,i)=>{
                                return(
                                    <CardRow key={i} label={item["name"]!=""?item["name"]:"‒"} detail={item["external_reference"]!=""?item["external_reference"]:""}>
                                        {formatAmount(item["amount"],info["currency"],"display")}
                                    </CardRow>
                                )
                            })}
                        </Card>}
                        <Card title="Operación" icon={{element:"point"}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Fecha" detail="">
                                {formatDate(info["created"],"semilong")}
                            </CardRow>
                            <CardRow label="Tipo" detail="">
                                {info["operation"]=="payment"?"Pago"
                                :info["operation"]=="refund"?"Devolución"
                                :info["operation"]=="partial_refund"?"Devolución parcial"
                                :"‒"}
                            </CardRow>
                            {info["status"]==2 &&
                            <CardRow label="Pago rechazado" detail="">
                                Ref. #{String(info["reference"]).padStart(6,"0")}
                            </CardRow>}
                            {info["status"]==3 &&
                            <CardRow label="Pago cancelado" detail="">
                                Ref. #{String(info["reference"]).padStart(6,"0")}
                            </CardRow>}
                            {info["status"]==4 &&
                            <CardRow label="Pago devuelto" detail="">
                                Ref. #{String(info["reference"]).padStart(6,"0")}
                            </CardRow>}
                            {info["status"]==5 &&
                            <CardRow label="Pago devuelto parcialmente" detail="">
                                Ref. #{String(info["reference"]).padStart(6,"0")}
                            </CardRow>}
                            {(info["operation"]=="payment") &&
                            <CardRow label="Origen" detail="">
                                {info["origin"]["class"]=="link"?"Link de pago ("+(info["origin"]["reference_name"]!=""?info["origin"]["reference_name"]:"‒")+")"
                                :info["origin"]["class"]=="qr"?"Pago con QR"
                                :info["origin"]["class"]=="webpos"?"Webpos"
                                :info["origin"]["class"]=="subscription"?"Suscripción ("+(info["origin"]["reference_name"]!=""?info["origin"]["reference_name"]:"‒")+")"
                                :info["origin"]["class"]=="integration"?"Integración"
                                :info["origin"]["class"]=="backend"?"Backend"
                                :info["origin"]["class"]=="externalCheckout"?"WooCommerce"
                                :"‒"}
                            </CardRow>}
                            <CardRow label="Referencia" detail="">
                                <span className="card-row-featured">{info["external_reference"]!=""?info["external_reference"]:"‒"}</span>
                            </CardRow>
                            {(info["operation"]=="payment" && info["status"]==1) &&
                            <CardRow label="" detail="">
                                <ButtonCard label="Descargar comprobante" style="primary" clickEvent={(event)=>{downloadReceipt(event,transactionId)}} />
                            </CardRow>}
                        </Card>
                        <Card title="Cliente" icon={{element:"user",reference:(info["user"]!=""?info["user"]["id"]:""),has_picture:(info["user"]!=""?info["user"]["has_picture"]:0),capitals:(info["user"]!=""?info["user"]["capitals"]:"")}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Nombre" detail="">
                                {info["user"]!=""?info["user"]["first_name"]+" "+info["user"]["last_name"]:"(Desconocido)"}
                            </CardRow>
                            {info["user"]!="" &&
                            <Fragment>
                                <CardRow label="DNI" detail="">
                                    {info["user"]["dni"]!=""?info["user"]["dni"]:"‒"}
                                </CardRow>
                                <CardRow label="Email" detail="">
                                    {info["user"]["email"]!=""?info["user"]["email"]:"‒"}
                                </CardRow>
                                <CardRow label="Teléfono" detail="">
                                    {info["user"]["phone"]!=""?info["user"]["phone"]:"‒"}
                                </CardRow>
                                <CardRow label="Ubicación" detail="">
                                    {info["user"]["location"]!=""?info["user"]["location"]:"‒"}
                                </CardRow>
                            </Fragment>}
                        </Card>
                        <Card title="Comercio" icon={{element:"shop",reference:(info["shop"]!=""?info["shop"]["id"]:""),has_picture:(info["shop"]!=""?info["shop"]["has_picture"]:0),capitals:(info["shop"]!=""?info["shop"]["capitals"]:"")}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Nombre" detail="">
                                {info["shop"]!=""?info["shop"]["name"]:"(Desconocido)"}
                            </CardRow>
                            <CardRow label="Punto de cobro" detail="">
                                {info["point"]!=""?info["point"]["name"]:"(Desconocido)"}
                            </CardRow>
                            <CardRow label="Operador/a" detail="">
                                {info["cashier"]!=""?info["cashier"]["first_name"]+" "+info["cashier"]["last_name"]:"(Desconocido)"}
                            </CardRow>
                        </Card>
                        {checkPermission({alias:"master_admin",reference:""}) &&
                        <Card title="Procesamiento" icon={{element:"code"}} action="" actionIcon="" actionClickEvent="">
                            <CardRow label="Transacción" detail={info["uuid"]!=""?"#"+info["uuid"]:""}>
                                <ButtonCard label="Ver resultado" style="primary" clickEvent={()=>{changeModal("transaction-answer","",transactionId)}} />
                            </CardRow>
                        </Card>}
                    </Fragment>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}