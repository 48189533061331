import {useState,useEffect,useContext} from "react"
import {NavLink} from "react-router-dom"
import GlobalContext from "../contexts/GlobalContext"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import Section from "../components/Section"
import SectionContent from "../components/SectionContent"
import SectionTabs from "../components/SectionTabs"
import ButtonStart from "../commons/ButtonStart"
import {formatAmount} from "../hooks/Commons"
import ReportsImg from "../assets/reports.svg"
import BalanceImg from "../assets/balance.svg"
import ArrowImg from "../assets/arrow-white.svg"

export default function Start(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("start","")
    },[])

    const [globalContext]=useContext(GlobalContext)
    const requestApi=useApi()
    const [balanceToday,setBalanceToday]=useState(0)
    const [balanceTodayQuantity,setBalanceTodayQuantity]=useState(0)
    const [newsQuantity,setNewsQuantity]=useState(0)

    useEffect(()=>{
        requestApi("general/start",{})
            .then(data=>{
                setBalanceToday(data.balance_today)
                setBalanceTodayQuantity(data.balance_today_quantity)
                setNewsQuantity(data.news)
            })
    },[])

    return(
        <Section>
            <SectionContent>
                <div className="content-focused">
                    <div className="greeting">Hola, {globalContext["user_name"]}.</div>
                    <SectionTabs tabs={[
                        {label:"Principal",to:"/c24/tablero"},
                        {label:"Novedades",to:"/c24/tablero/novedades",counter:newsQuantity}
                    ]} />
                    <div className="start-boxes">
                        <NavLink to="/c24/actividad" className="box-white">
                            <div className="box-white-info">
                                <div className="box-white-title">Cobros de hoy</div>
                                <div className="box-white-amount">{formatAmount(balanceToday,"ARS","display")} <span className="box-white-amount-alt">({balanceTodayQuantity})</span></div>
                            </div>
                            <div className="box-white-pic"><img src={BalanceImg} /></div>
                        </NavLink>
                        <NavLink to="/c24/reportes" className="box-blue">
                            <div className="box-blue-info">
                                <div className="box-blue-title">Reportes</div>
                                <div className="box-blue-caption">Analizá información detallada de tus operaciones<img src={ArrowImg} /></div>
                            </div>
                            <div className="box-blue-pic"><img src={ReportsImg} /></div>
                        </NavLink>
                    </div>
                    <div className="shortcuts">
                        <ButtonStart label="Actividad" context="activity" to="/c24/actividad" />
                        <ButtonStart label="Webpos" context="webpos" to="/c24/webpos" />
                        <ButtonStart label="Links de pago" context="links" to="/c24/links-de-pago" />
                        {1==2 && <ButtonStart label="Suscripciones" context="subscriptions" to="/c24/suscripciones" />}
                        <ButtonStart label="Pagos con QR" context="qr" to="/c24/pagos-con-qr" />
                    </div>
                </div>
            </SectionContent>
        </Section>
    )
    
}