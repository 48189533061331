import {useContext, useState} from "react"
import FloatingContext from "../contexts/FloatingContext"
import ActivityFilters from "../floating/ActivityFilters"
import RecordsFilters from "../floating/RecordsFilters"
import LinksFilters from "../floating/LinksFilters"
import LiquidationsFilters from "../floating/LiquidationsFilters"
import PaymentsFilters from "../floating/PaymentsFilters"
import ReportsFilters from "../floating/ReportsFilters"
import AnalyticsFilters from "../floating/AnalyticsFilters"
import ShopsFilters from "../floating/ShopsFilters"
import SubscribersFilters from "../floating/SubscribersFilters"
import SubscriptionsFilters from "../floating/SubscriptionsFilters"
import TicketsFilters from "../floating/TicketsFilters"
import UsersFilters from "../floating/UsersFilters"
import MessagesFilters from "../floating/MessagesFilters"
import ArticlesFilters from "../floating/ArticlesFilters"
import ShopsChangers from "../floating/ShopsChangers"

export default function Floating(){

    const [floatingContext]=useContext(FloatingContext)
    const [optionsState, setOptionsState] = useState({})

    return(
        <div id="floating" className="floating noselect">
            <div className="floating-overlay"></div>
            <div className="floating-frame">
                <div className="floating-window">
                    {floatingContext.floating=="activity-filters" && <ActivityFilters optionsState={optionsState} setOptionsState={setOptionsState} />}
                    {floatingContext.floating=="records-filters" && <RecordsFilters />}
                    {floatingContext.floating=="links-filters" && <LinksFilters />}
                    {floatingContext.floating=="liquidations-filters" && <LiquidationsFilters />}
                    {floatingContext.floating=="payments-filters" && <PaymentsFilters />}
                    {floatingContext.floating=="reports-filters" && <ReportsFilters />}
                    {floatingContext.floating=="analytics-filters" && <AnalyticsFilters />}
                    {floatingContext.floating=="shops-filters" && <ShopsFilters />}
                    {floatingContext.floating=="subscribers-filters" && <SubscribersFilters />}
                    {floatingContext.floating=="subscriptions-filters" && <SubscriptionsFilters />}
                    {floatingContext.floating=="tickets-filters" && <TicketsFilters />}
                    {floatingContext.floating=="users-filters" && <UsersFilters />}
                    {floatingContext.floating=="messages-filters" && <MessagesFilters />}
                    {floatingContext.floating=="articles-filters" && <ArticlesFilters />}
                    {floatingContext.floating=="shops-changers" && <ShopsChangers />}
                    {!floatingContext.ready &&<div className="floating-disabled"></div>}
                </div>
            </div>
        </div>
    )
    
}