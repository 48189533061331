import {Fragment,useContext,useRef,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import Textbox from "../commons/Textbox"
import Numbox from "../commons/Numbox"
import Selector from "../commons/Selector"
import ModalTitle from "../components/ModalTitle"
import ButtonCommand from "../commons/ButtonCommand"

export default function InstallmentEdit(){

    const [modalContext]=useContext(ModalContext)
    const {modalReady,changeModal}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const requestApi=useApi()
    const installmentsInput=useRef()
    const feeInput=useRef()
    const ahoraInput=useRef()
    const [idVal,setIdVal]=useState(0)
    const [installmentsVal,setInstallmentsVal]=useState("")
    const [feeVal,setFeeVal]=useState("")
    const [ahoraVal,setAhoraVal]=useState()
    const [ahoraOptions]=useState([
        {label:"Plan estándar",value:0},
        {label:"Plan Ahora",value:1}
    ])
    const mode=modalContext.context

    useEffect(()=>{
        if(mode=="edit"){
            modalReady(0)
            requestApi("shops/installment_info",{
                    installment_id:modalContext.reference["installment"],
                    point_id:modalContext.reference["point"]
                })
                .then(data=>{
                    setIdVal(data.data.id)
                    setInstallmentsVal(data.data.installments)
                    setFeeVal(data.data.fee)
                    ahoraOptions.forEach(function(item){
                        if(item.value==data.data.ahora){
                            ahoraInput.current.setValue({label:data.data.ahora==0?"Plan estándar":"Plan Ahora",value:data.data.ahora})
                        }
                    })
                    modalReady(1)
                })
        }else{
            ahoraInput.current.setValue({label:"Plan estándar",value:0})
        }
    },[])

    const saveData=()=>{
        if(installmentsVal==""){
            installmentsInput.current.focus()
        }else{
            requestApi("shops/installment_save",{
                    id:idVal,
                    point_id:modalContext.reference["point"],
                    assignment_id:modalContext.reference["assignment"],
                    mode:mode,
                    installments:installmentsVal,
                    fee:feeVal,
                    ahora:ahoraInput.current.getValue()[0]?ahoraInput.current.getValue()[0].value:0,
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_f","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        changeModal("shop-installments","",{shop:modalContext.reference["shop"],point:modalContext.reference["point"],assignment:modalContext.reference["assignment"]})
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("shops/installment_remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    changeModal("shop-installments","",{shop:modalContext.reference["shop"],point:modalContext.reference["point"],assignment:modalContext.reference["assignment"]})
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("shop-installments","",{shop:modalContext.reference["shop"],point:modalContext.reference["point"],assignment:modalContext.reference["assignment"]})}} />
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nuevo plan de cuotas":"Editar plan de cuotas"} />
                    <Selector ref={ahoraInput} val={ahoraVal} setVal={setAhoraVal} placeholder="Tipo de plan" searchable={0} options={ahoraOptions} />
                    <Textbox ref={installmentsInput} label="Número de cuotas" type="number" max={2} val={installmentsVal} setVal={setInstallmentsVal} disabled={false} />
                    <Numbox ref={feeInput} label="Porcentanje de interés" val={feeVal} setVal={setFeeVal} disabled={false} format={null} mask={""} decimalSeparator={"."} prefix={""} suffix={"%"} placeholder="" />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}