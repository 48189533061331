import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Records(){
    return(
        <Section>
            <SectionHeader
                title="Cuenta comercial"
                intro="Cargos por uso del servicio y facturación."
                back=""
                button=""
                tabs={[
                    {label:"Transacciones",to:"/c24/actividad"},
                    {label:"Liquidaciones",labelShort:"Liquida...",to:"/c24/actividad/liquidaciones"},
                    {label:"Cuenta comercial",labelShort:"Cuenta",to:"/c24/actividad/cuenta"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"records",
                    endpoint:"records/list",
                    limit:100,
                    counter:{
                        singular:"registro",
                        plural:"registros"
                    },
                    action:{
                        mode:"",
                        context:"",
                        reference:""
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}