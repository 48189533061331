import {useContext} from "react"
import RefreshContext from "../contexts/RefreshContext"

export default function useRefresh(){
    
    const [refreshContext,setRefreshContext]=useContext(RefreshContext)

    const refresh=(context,reference)=>{
        let refreshContextNew={...refreshContext}
        if(context.includes("list")){
            refreshContextNew["list"]={
                refresh:1,
                reference:reference
            }
        }
        if(context.includes("info")){
            refreshContextNew["info"]={
                refresh:1,
                reference:reference
            }
        }
        if(context.includes("avatar")){
            refreshContextNew["avatar"]={
                refresh:1,
                reference:reference
            }
        }
        setRefreshContext(refreshContextNew)
    }

    const refreshClear=(context)=>{
        let refreshContextNew={...refreshContext}
        if(context=="list"){
            refreshContextNew["list"]={
                refresh:0,
                reference:""
            }
        }else if(context=="info"){
            refreshContextNew["info"]={
                refresh:0,
                reference:""
            }
        }else if(context=="avatar"){
            refreshContextNew["avatar"]={
                refresh:0,
                reference:""
            }
        }
        setRefreshContext(refreshContextNew)
    }

    return {refresh,refreshClear}
    
}