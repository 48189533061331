import {Fragment} from "react"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"

export default function About(){

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title="Conocé más sobre Cajero24" />
                    <div className="modal-explanation modal-explanation-alt">
                        <p>Estamos ubicados en Mendoza; gran parte del equipo está conformado por mendocinos, cordobeses, santafesinos y bonaerenses.</p>
                        <p><strong>Cajero24</strong> está integrado por profesionales de finanzas, administración y sistemas. Trabajamos en equipo para generar soluciones robustas, eficaces y de alta usabilidad.</p>
                        <p>Tenemos experiencia en diversas industrias, dentro y fuera del país. Ofrecemos servicios basados en herramientas tecnológicas que optimizan la recaudación de instituciones públicas y privadas, con las comisiones más bajas del mercado.</p>
                        <p>Comenzamos este proyecto en el año 2006, cuando las transacciones electrónicas eran poco frecuentes y había un gran desconocimiento sobre ellas. Hoy procesamos casi 120 mil transacciones por año que involucran más de 500 millones de pesos; cada día más de 7 millones de argentinos tienen a su disposición nuestro servicio.</p>
                        <p>El sistema incorpora los medios de pago que se procesan electrónicamente: tarjetas de crédito, débito y prepagas; estamos trabajando para incorporar transferencias bancarias y criptomonedas.</p>
                        <p>Ponemos el foco en facilitar, optimizar y agilizar procesos de la vida cotidiana que requieran de algún pago. Creemos que las personas no deben seguir perdiendo su tiempo cuando pagan por lo que consumen. Quizás se tomen más o menos tiempo en decidir adquirir algún bien o servicio, pero realizar el pago debe ser fácil y accesible.</p>
                        <p>Por ello hemos desarrollado un conjunto de herramientas y canales de servicio que se adaptan a las distintas necesidades de nuestros clientes.</p>
                    </div>
                </div>
            </ModalContent>
        </Fragment>
    )
    
}