import useModal from "../hooks/useModal"
import ButtonCard from "../commons/ButtonCard"
import Amount from "../commons/Amount"
import waitingImg from "../assets/waiting.svg"
import alertImg from "../assets/alert.svg"
import { Fragment } from "react"

export default function Spotlight({context,data}){

    const {changeModal}=useModal()

    return(
        <Fragment>
            {data!="" &&
            <div className="spotlight noselect">
                {context=="vouchers" &&
                <div className="spotlight-rows">
                    {data["unpaid"].map((item,i)=>{
                        return(
                            <div key={i} className="spotlight-row">
                                <div className="spotlight-row-detail">
                                    <div>
                                        <div className="spotlight-title spotlight-title-alt"><img src={alertImg} />{item["date"]}</div>
                                        <div className="spotlight-note spotlight-note-alt">Pagá esta factura a través de los canales habilitados (verás el pago reflejado dentro de un plazo de hasta 48 horas).</div>
                                        <div className="spotlight-button">
                                            <ButtonCard label="Ver factura" style="primary" clickEvent={()=>{changeModal("voucher","",{shop:data["shop_id"],voucher:item["id"]})}} />
                                        </div>
                                    </div>
                                </div>
                                <div className="spotlight-row-amount">
                                    <Amount number={item["amount"]} currency="ARS" operation="unpaid" />
                                </div>
                            </div>
                        )
                    })}
                    {data["in_process"] &&
                    <div className="spotlight-row">
                        <div className="spotlight-row-detail">
                            <div>
                                <div className="spotlight-title"><img src={waitingImg} />{data["in_process"]["date"]}</div>
                                <div className="spotlight-note">La factura estará disponible el {data["in_process"]["available_date"]}.</div>
                                <div className="spotlight-button">
                                    <ButtonCard label="Todas las facturas" style="violet" clickEvent={()=>{changeModal("vouchers","",data["shop_id"])}} />
                                </div>
                            </div>
                        </div>
                        <div className="spotlight-row-amount">
                            <Amount number={data["in_process"]["amount"]} currency="ARS" operation="" />
                        </div>
                    </div>}
                </div>}
                {context=="analytics" &&
                <div className="spotlight-rows spotlight-rows-alt">
                    <div className="spotlight-row">
                        <div className="spotlight-row-detail">
                            <div className="spotlight-title spotlight-title-alt-2">Cargos del servicio</div>
                        </div>
                        <div className="spotlight-row-amount">
                            <Amount number={data.charges} currency="ARS" operation="unpaid" />
                        </div>
                    </div>
                    <div className="spotlight-row">
                        <div className="spotlight-row-detail">
                            <div className="spotlight-title spotlight-title-alt-2">Cantidad de transacciones</div>
                        </div>
                        <div className="spotlight-row-amount">
                            <div className="amount amount-violet">{data.transactions}</div>
                        </div>
                    </div>
                    <div className="spotlight-row">
                        <div className="spotlight-row-detail">
                            <div className="spotlight-title spotlight-title-alt-2">Total cobrado</div>
                        </div>
                        <div className="spotlight-row-amount">
                            <Amount number={data.amounts} currency="ARS" operation="" />
                        </div>
                    </div>
                    <div className="spotlight-row">
                        <div className="spotlight-row-detail">
                            <div className="spotlight-title spotlight-title-alt-2">Total sin financiación</div>
                        </div>
                        <div className="spotlight-row-amount">
                            <Amount number={data.amounts_base} currency="ARS" operation="" />
                        </div>
                    </div>
                </div>}
            </div>}
        </Fragment>
    )

}