import {useState,useEffect} from "react"
import {useParams} from "react-router-dom"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function FaqsAdmTopics(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    let params=useParams()
    const requestApi=useApi()
    const {changeModal}=useModal()
    const [info,setInfo]=useState()

    useEffect(()=>{
        requestApi("faqs/group_info",{
                group_id:params.groupId
            })
            .then(data=>{
                setInfo(data.data)
            })
    },[])

    const edit=()=>{
        changeModal("faqs-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title={info?info["name"]:""}
                intro="Temas, preguntas y respuestas."
                back="/c24/asistencia/faqs"
                button={{label:"Nuevo tema",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Tickets",to:"/c24/asistencia"},
                    {label:"Preguntas frecuentes",labelShort:"FAQs",to:"/c24/asistencia/faqs/"+params.groupId},
                    {label:"Novedades",to:"/c24/asistencia/novedades"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"faqs-adm-topics",
                    endpoint:"faqs/topics",
                    reference:{context:"adm",group_id:params.groupId},
                    limit:0,
                    counter:{
                        singular:"tema",
                        plural:"temas"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"faqs-edit"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}