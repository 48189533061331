import {NavLink} from "react-router-dom"
import ButtonBig from "../commons/ButtonBig"
import SectionTabs from "./SectionTabs"
import backIcon from "../assets/back-4.svg"

export default function SectionHeader({title,back,intro,button,tabs}){

    return(
        <div className="header noselect">
            <div className="header-data">
                <div className="header-info">
                    <div className="header-title">
                        {back!="" && <div className="header-back-frame"><NavLink className="header-back" to={back} end><img src={backIcon} /></NavLink></div>}
                        {title}
                    </div>
                    {intro && <div className="header-intro">{intro}</div>}
                </div>
                {button && 
                <div className="header-actions">
                    <ButtonBig button={button} />
                </div>}
            </div>
            {tabs && <SectionTabs tabs={tabs} />}
        </div>
    )
    
}