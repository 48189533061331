import {useState,useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import useApi from "../hooks/useApi"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import Tools from "../components/Tools"
import Chart from "../components/Chart"
import {ResponsivePie} from "@nivo/pie"
import {ResponsiveFunnel} from "@nivo/funnel"
import {ResponsiveWaffle} from "@nivo/waffle"
import {ResponsiveBar} from "@nivo/bar"
import {ResponsiveLine} from "@nivo/line"
import {ResponsiveTreeMap} from "@nivo/treemap"
import {BasicTooltip} from "@nivo/tooltip"

export default function Reports(){
    
    const {checkPermission}=usePermissions()
    const requestApi=useApi()
    const [filters,setFilters]=useState({"period": "today"})
    const [changers,setChangers]=useState()
    const [swapper,setSwapper]=useState({label:"",value:""})

    const [chartTransactions,setChartTransactions]=useState()
    const [chartAmount,setChartAmount]=useState()
    const [chartStatus,setChartStatus]=useState()
    const [chartLiquidation,setChartLiquidation]=useState()
    const [chartClass,setChartClass]=useState()
    const [chartPayments,setChartPayments]=useState()
    const [chartInstallments,setChartInstallments]=useState()
    const [chartLocation,setChartLocation]=useState()

    const getReports=()=>{
        requestApi("transactions/reports",{
                filters:filters,
                changers:changers
            })
            .then(data=>{
                setChartTransactions(data.chartTransactions)
                setChartAmount(data.chartAmount)
                setChartStatus(data.chartStatus)
                setChartLiquidation(data.chartLiquidation)
                setChartClass(data.chartClass)
                setChartPayments(data.chartPayments)
                setChartInstallments(data.chartInstallments)
                setChartLocation(data.chartLocation)
            })
    }

    useEffect(()=>{
        if(changers!=undefined){
            getReports()
        }
    },[changers])

    useEffect(()=>{
        if(filters!=undefined){
            getReports()
        }
    },[filters])

    const CustomCell=({position,size,x,y,color,fill,opacity,borderWidth,borderColor,data,onHover,onLeave,onClick})=>(<circle r={size/2} cx={x+size/2} cy={y+size/2} fill={fill || color} strokeWidth={borderWidth} stroke={borderColor} opacity={opacity} onMouseEnter={onHover} onMouseMove={onHover} onMouseLeave={onLeave} onClick={event=>{onClick({position,color,x,y,data},event)}} />)

    return(
        <Section>
            <SectionHeader
                title="Reportes"
                intro="Información detallada de las operaciones."
                back=""
                button=""
                tabs={
                    checkPermission({alias:"master_admin",reference:""})?
                    [{label:"Reportes",to:"/c24/reportes"},
                    {label:"Analítica",to:"/c24/reportes/analitica"}]:
                    [{label:"Reportes",to:"/c24/reportes"}]
                }
            />
            <SectionContent>
                <Tools config={{section:"reports"}} keyword="" filter={[filters,setFilters]} changer={[changers,setChangers]} swapper={[swapper,setSwapper]} total="" />
                <div className="charts">
                    {chartTransactions &&
                    <Chart
                        title="Cantidad de operaciones"
                        subtitle={chartTransactions["subtitle"]}
                        note="Canales de pago"
                        legends={[
                            {label:"Webpos",color:"hsl(320,54%,30%)"},
                            {label:"Links",color:"hsl(320,54%,40%)"},
                            {label:"Integraciones",color:"hsl(320,54%,50%)"},
                            {label:"QR",color:"hsl(320,54%,60%)"},
                            {label:"Suscripciones",color:"hsl(320,54%,70%)"},
                            {label:"Backend",color:"hsl(320,54%,80%)"},
                            {label:"WooCommerce", color: "hsl(320,54%,90%)"}
                        ]} >
                        <ResponsivePie
                            data={chartTransactions["data"]}
                            colors={{datum:"data.color"}}
                            tooltip={(props)=>(<BasicTooltip id={props.datum.label} value={props.datum.value} color={props.datum.color} enableChip />)}
                            margin={{top:16,right:16,bottom:16,left:16}}
                            innerRadius={0.5}
                            padAngle={0.8}
                            cornerRadius={3}
                            activeOuterRadiusOffset={8}
                            borderWidth={0}
                            enableArcLinkLabels={false}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{from:"color",modifiers:[["darker",2]]}}
                        />
                    </Chart>}
                    {chartAmount &&
                    <Chart
                        title="Monto recaudado"
                        subtitle={chartAmount["subtitle"]}
                        note="Canales de pago"
                        legends={[
                            {label:"Webpos",color:"#9E0142"},
                            {label:"Links",color:"#D53E4F"},
                            {label:"Integraciones",color:"#F46D43"},
                            {label:"QR",color:"#FDAE61"},
                            {label:"Suscripciones",color:"#FEE08B"},
                            {label:"Backend",color:"#FFFFBF"},
                            {label:"WooCommerce",color:"#E6F598"}
                        ]} >
                        <ResponsiveFunnel
                            data={chartAmount["data"]}
                            colors={{scheme:"spectral"}}
                            valueFormat={value=>`$${Number(value).toLocaleString("es-ES",{minimumFractionDigits:2})}`}
                            margin={{top:16,right:16,bottom:16,left:16}}
                            borderWidth={0}
                            borderColor={{from:"color",modifiers:[]}}
                            labelColor={{from:"color",modifiers:[["darker",3]]}}
                            beforeSeparatorLength={24}
                            beforeSeparatorOffset={10}
                            afterSeparatorLength={24}
                            afterSeparatorOffset={10}
                            currentPartSizeExtension={10}
                            currentBorderWidth={0}
                            motionConfig="wobbly"
                        />
                    </Chart>}
                    {chartStatus &&
                    <Chart
                        title="Estado de los pagos"
                        subtitle={chartStatus["subtitle"]}
                        note=""
                        legends={[
                            {label:"Aprobados",color:"#2DCA73"},
                            {label:"Pendientes",color:"#F5BD00"},
                            {label:"Devueltos",color:"#FF7B00"},
                            {label:"Rechazados",color:"#EB3941"}
                        ]} >
                        <ResponsiveBar
                            data={chartStatus["data"]}
                            keys={["approved","pending","refund","rejected"]}
                            groupMode="grouped"
                            margin={{top:16,right:16,bottom:16,left:16}}
                            padding={0}
                            innerPadding={4}
                            borderRadius={7}
                            colors={({id,data})=>String(data[`${id}_color`])}
                            valueFormat={value=>`${Number(value).toLocaleString("es-ES",{minimumFractionDigits:2})}%`}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{from:"color",modifiers:[["darker",1.6]]}}
                            tooltip={(props)=>{let amount=props.data[`${props.id}_amount`]; return <BasicTooltip id={props.data[`${props.id}_label`]} value={`${Number(props.value).toLocaleString("es-ES",{minimumFractionDigits:2})}%`+" ("+`$${Number(amount).toLocaleString("es-ES",{minimumFractionDigits:0})}`+")"} color={props.color} enableChip />}}
                            enableGridX={false}
                            enableGridY={false}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={null}
                            axisLeft={null}
                        />
                    </Chart>}
                    {chartLiquidation &&
                    <Chart
                        title="Liquidación de pagos"
                        subtitle={chartLiquidation["subtitle"]}
                        note=""
                        legends={[
                            {label:"Liquidados",color:"#2DCA73"},
                            {label:"Sin liquidar",color:"#CAF2DC"}
                        ]} >
                        <ResponsivePie
                            data={chartLiquidation["data"]}
                            margin={{top:16,right:16,bottom:16,left:16}}
                            valueFormat={value=>`${Number(value).toLocaleString("es-ES",{minimumFractionDigits:2})}%`}
                            tooltip={(props)=>(<BasicTooltip id={props.datum.label} value={`${Number(props.datum.value).toLocaleString("es-ES",{minimumFractionDigits:2})}%`+" ("+`$${Number(props.datum.data.amount).toLocaleString("es-ES",{minimumFractionDigits:0})}`+")"} color={props.datum.color} enableChip />)}
                            colors={{datum:"data.color"}}
                            cornerRadius={6}
                            activeOuterRadiusOffset={8}
                            enableArcLinkLabels={false}
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{from:"color"}}
                            arcLabelsRadiusOffset={0.55}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{from:"color",modifiers:[["darker",2]]}}
                        />
                    </Chart>}
                    {chartClass &&
                    <Chart
                        title="Forma de pago"
                        subtitle={chartClass["subtitle"]}
                        note=""
                        legends={[
                            {label:"Crédito",color:"#9748A3"},
                            {label:"Débito",color:"#379ED1"},
                            {label:"Prepaga",color:"#75E8F0"},
                            {label:"Debin",color:"#4837AB"},
                            {label:"Transferencia por QR",color:"#0507FB"}
                        ]} >
                        <ResponsiveWaffle
                            data={chartClass["data"]}
                            colors={{datum:"color"}}
                            total={100}
                            rows={24}
                            columns={18}
                            padding={2}
                            margin={{top:16,right:16,bottom:16,left:16}}
                            borderColor={{from:"color",modifiers:[["darker",0.3]]}}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={11}
                            tooltip={(props)=>(<div>{props.label}: <strong>{`${Number(props.value).toLocaleString("es-ES",{minimumFractionDigits:2})}%`+" ("+`$${Number(props.amount).toLocaleString("es-ES",{minimumFractionDigits:0})}`+")"}</strong></div>)}
                            cellComponent={CustomCell}
                            legends={[]}
                        />
                    </Chart>}
                    {chartPayments &&
                    <Chart
                        title="Medios de pago"
                        subtitle={chartPayments["subtitle"]}
                        note=""
                        legends={[]}>
                        <ResponsiveBar
                            data={chartPayments["data"]}
                            keys={["amount"]}
                            indexBy="label"
                            margin={{top:20,right:32,bottom:52,left:154}}
                            padding={0.1}
                            groupMode="stacked"
                            layout="horizontal"
                            borderRadius={4}
                            valueFormat={value=>`$${Number(value).toLocaleString("es-ES",{minimumFractionDigits:0})}`}
                            colors={({id,data})=>String(data[`${id}_color`])}
                            tooltip={(props)=>(<BasicTooltip id={props.data.label} value={"\n"+`$${Number(props.value).toLocaleString("es-ES",{minimumFractionDigits:0})}`+"\n("+props.data[`${props.id}_transactions`]+(props.data[`${props.id}_transactions`]==1?" operación":" operaciones")+")"} color={props.color} />)}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                tickSize:5,
                                tickPadding:5,
                                tickRotation:-45,
                                format:value=>"$"+Number(value).toLocaleString("es-ES",{minimumFractionDigits:0})
                            }}
                            axisLeft={{
                                tickSize:5,
                                tickPadding:5,
                                tickRotation:0
                            }}
                            enableGridX={true}
                            enableGridY={false}
                            labelSkipWidth={54}
                            labelSkipHeight={12}
                            labelTextColor="#75E8F0"
                        />
                    </Chart>}
                    {chartInstallments &&
                    <Chart
                        title="Pagos en cuotas"
                        subtitle={chartInstallments["subtitle"]}
                        note=""
                        legends={[]}>
                        <ResponsiveLine
                            data={chartInstallments["data"]}
                            margin={{top:20,right:20,bottom:30,left:48}}
                            tooltip={(props)=>(<BasicTooltip id={props.point.data.x+" cuotas"} value={"\n"+props.point.data.y+(props.point.data.y==1?" operación":" operaciones")+"\n"+"("+`$${Number(props.point.data.amount).toLocaleString("es-ES",{minimumFractionDigits:0})}`+")"} color={props.color} />)}
                            colors={{datum:"color"}}
                            curve="cardinal"
                            enableArea={true}
                            areaOpacity={0.1}
                            xScale={{type:"point"}}
                            yScale={{
                                type:"linear",
                                min:"auto",
                                max:"auto",
                                stacked:true,
                                reverse:false
                            }}
                            yFormat=" >-.2f"
                            axisTop={null}
                            axisRight={null}
                            axisBottom={{
                                orient:"bottom",
                                tickSize:5,
                                tickPadding:5,
                                tickRotation:0
                            }}
                            axisLeft={{
                                orient:"left",
                                tickSize:5,
                                tickPadding:5,
                                tickRotation:0
                            }}
                            pointSize={13}
                            pointColor={{from:"color",modifiers:[]}}
                            useMesh={true}
                            enableCrosshair={false}
                            legends={[]}
                        />
                    </Chart>}
                    {chartLocation &&
                    <Chart
                        title="Ubicación de los usuarios"
                        subtitle={chartLocation["subtitle"]}
                        note=""
                        legends={[]}>
                        <ResponsiveTreeMap
                            data={chartLocation["data"]}
                            identity="name"
                            leavesOnly={true}
                            colors={{scheme:"spectral"}}
                            tooltip={(props)=>(<BasicTooltip id={props.node.label} value={"\n"+props.node.value+(props.node.value==1?" operación":" operaciones")+"\n"+"("+`$${Number(props.node.data.amount).toLocaleString("es-ES",{minimumFractionDigits:0})}`+")"} color={props.node.color} />)}
                            nodeOpacity={1}
                            borderWidth={0}
                            orientLabel={false}
                            value="users"
                            valueFormat=".02s"
                            margin={{top:16,right:0,bottom:0,left:0}}
                            label={function(e){return e.id}}
                            labelSkipSize={48}
                            labelTextColor={{from:"color",modifiers:[["darker",3]]}}
                            borderColor={{from:"color",modifiers:[["darker",0.1]]}}
                        />
                    </Chart>}
                </div>
            </SectionContent>
        </Section>
    )
    
}