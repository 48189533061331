import {Fragment,useContext,useEffect,useState} from "react"
import GlobalContext from "../contexts/GlobalContext"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useSession from "../hooks/useSession"
import {timeAgo,formatDate} from "../hooks/Commons"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ButtonPrimary from "../commons/ButtonPrimary"
import Card from "../components/Card"
import CardRow from "../components/CardRow"
import ButtonCard from "../commons/ButtonCard"

export default function UserInfo(){

    const [globalContext]=useContext(GlobalContext)
    const [modalContext]=useContext(ModalContext)
    const requestApi=useApi()
    const {changeDialog,manageDialog}=useDialog()
    const {changeModal,manageModal,modalReady}=useModal()
    const {terminateSession}=useSession()
    const [info,setInfo]=useState()
    const [cards,setCards]=useState()
    const [sessions,setSessions]=useState()
    const context=modalContext.context
    let userId=0
    if(context=="account"){
        userId=globalContext.user_id
    }else if(context=="user"){
        userId=modalContext.reference
    }

    const loadInfo=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("users/info",{
                        user_id:userId
                    })
                    .then(data=>{
                        return data.data
                    })
            )
        })
    }

    const loadTokens=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("payments/tokens",{
                        user_id:userId,
                        email:"",
                        payments:[]
                    })
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }

    const loadSessions=()=>{
        return new Promise(function(resolve){
            resolve(
                requestApi("users/sessions",{
                        user_id:userId
                    })
                    .then(data=>{
                        return data.items
                    })
            )
        })
    }

    useEffect(()=>{
        modalReady(0)
        Promise.all([loadInfo,loadTokens,loadSessions].map(func=>func())).then((result)=>{
            setInfo(result[0])
            setCards(result[1])
            setSessions(result[2])
            modalReady(1)
        })
    },[])

    const forgetCard=(reference)=>{
        requestApi("payments/token_remove",{
                payment_token_id:reference
            })
            .then(data=>{
                if(data.status=="success"){
                    document.getElementById("savedCard"+reference).remove()
                    manageDialog("hide")
                }else if(data.status=="failed"){
                    changeDialog("generic_error","","")
                }
            })
    }

    const endSession=(reference)=>{
        requestApi("users/session_terminate",{
                session_token_id:reference
            })
            .then(data=>{
                if(data.status=="success"){
                    document.getElementById("activeSession"+reference).remove()
                    manageDialog("hide")
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    const endThisSession=()=>{
        requestApi("users/session_terminate",{
                session_token_id:globalContext.session_token_id
            })
            .then(data=>{
                if(data.status=="success"){
                    manageDialog("hide")
                    manageModal("hide")
                    terminateSession()
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                {context=="account" &&
                <ButtonPrimary label="Cerrar esta sesión" clickEvent={()=>{changeDialog("terminate_this_session","",endThisSession)}} />}
            </ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    {info &&
                    <Fragment>
                        <Card title="Datos personales" icon={{element:"info"}} action="Modificar" actionIcon="edit" actionClickEvent={()=>{changeModal("user-edit",context,userId)}}>
                            <CardRow label="Email" detail="">
                                {info.email?info.email:"‒"}
                            </CardRow>
                            <CardRow label="Nombre" detail="">
                                {info.first_name?info.first_name:"‒"}
                            </CardRow>
                            <CardRow label="Apellido" detail="">
                                {info.last_name?info.last_name:"‒"}
                            </CardRow>
                            <CardRow label="DNI" detail="">
                                {info.dni?info.dni:"‒"}
                            </CardRow>
                            <CardRow label="Teléfono" detail="">
                                {info.phone?info.phone:"‒"}
                            </CardRow>
                        </Card>
                        <Card title="Ubicación" icon={{element:"location"}} action="Actualizar" actionIcon="edit" actionClickEvent={()=>{changeModal("user-location",context,userId)}}>
                            <CardRow label="Dirección" detail="">
                                {info.location.street?info.location.street:""} {info.location.numeration?""+info.location.numeration:"‒"}{info.location.additional?" ("+info.location.additional+")":""}
                            </CardRow>
                            <CardRow label="Región" detail="">
                                {info.location.city?info.location.city:"‒"}, {info.location.region?info.location.region:"‒"}, {info.location.country?info.location.country:"‒"}
                            </CardRow>
                            <CardRow label="Código postal" detail="">
                                {info.location.postal_code?info.location.postal_code:"‒"}
                            </CardRow>
                        </Card>
                        <Card title="Contraseña" icon={{element:"password"}} action="Cambiar" actionIcon="edit" actionClickEvent={()=>{changeModal("user-password",context,userId)}}>
                            <CardRow label={info.password_changed==0?"Nunca "+(context=="account"?"has":"ha")+" cambiado la contraseña.":(context=="account"?"Cambiaste":"Cambió")+" la contraseña por última vez el "+formatDate(info.password_changed,"long")+"."} detail="" />
                        </Card>
                    </Fragment>}
                    {cards &&
                    <Card title="Tarjetas guardadas" icon={{element:"cards"}} action="" actionIcon="" actionClickEvent={()=>{}}>
                        {cards.length>0?
                            cards.map((item,i)=>{
                                return(
                                    <CardRow unique={"savedCard"+item.id} label={item.payment_name+" "+item.card_numbers} key={item.id} detail={"(Usada en "+item.shop_name+")"}>
                                        <ButtonCard label="Olvidar" style="secondary" clickEvent={()=>{changeDialog("forget_card","",()=>{forgetCard(item.id)})}} />
                                    </CardRow>
                                )
                            })
                        :<CardRow label={context=="account"?"Aún no tienes tarjetas guardadas.":"Aún no tiene tarjetas guardadas."} detail="" />}
                    </Card>}
                    {sessions &&
                    <Card title="Sesiones activas" icon={{element:"sessions"}} action="" actionIcon="" actionClickEvent={()=>{}}>
                        {sessions.length>0?
                            sessions.map((item,i)=>{
                                let deviceCaption=""
                                if(item.device_vendor!="" && item.device_vendor!="none"){
                                    deviceCaption=item.device_vendor
                                    if(item.device_model!="" && item.device_model!="none"){
                                        deviceCaption+=" "+item.device_model
                                    }else{
                                        deviceCaption+=" "+item.os_name+" "+item.os_version
                                    }
                                }else{
                                    if(item.deviceType=="browser"){
                                        deviceCaption="Computadora "+item.os_name+" "+item.os_version
                                    }else{
                                        deviceCaption=item.os_name+" "+item.os_version
                                    }
                                }
                                return(
                                    <CardRow unique={"activeSession"+item.id} label={deviceCaption} key={item.id} detail={item.browser_name+" (Hace aprox. "+timeAgo(item.activity)+")"}>
                                        <ButtonCard label="Cerrar sesión" style="secondary" clickEvent={()=>{globalContext.session_token_id==item.id?changeDialog("terminate_this_session","",endThisSession):changeDialog("terminate_session","",()=>{endSession(item.id)})}} />
                                    </CardRow>
                                )
                            })
                        :<CardRow label={context=="account"?"Aún no tienes sesiones activas.":"Aún no tiene sesiones activas."} detail="" />}
                    </Card>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}