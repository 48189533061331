import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Links(){
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("link-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="Links de pago"
                intro="Enlaces para iniciar pagos con Cajero24."
                back=""
                button={{label:"Crear link",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Links",to:"/c24/links-de-pago"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"links",
                    endpoint:"links/list",
                    limit:100,
                    counter:{
                        singular:"link",
                        plural:"links"
                    },
                    action:{
                        mode:"modal",
                        context:"link",
                        reference:"link-edit"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}