import Ga from "../components/Ga"
import ConnectionChecker from "../components/ConnectionChecker"

export default function NotFound(){
    
    return (
        <div className="not-found">
            <div><strong>¡Uooops!</strong>El contenido que estás buscando no se encuentra disponible.</div>
            <Ga />
            <ConnectionChecker />
        </div>
    )

}