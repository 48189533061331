import {Fragment,useContext,useState,useEffect,useRef} from "react"
import {CopyToClipboard} from "react-copy-to-clipboard"
import useApi from "../hooks/useApi"
import ModalContext from "../contexts/ModalContext"
import useModal from "../hooks/useModal"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import useToast from "../hooks/useToast"
import copyImg from "../assets/copy.svg"

export default function ShopAccessToken(){

    const [modalContext]=useContext(ModalContext)
    const {modalReady}=useModal()
    const {changeToast}=useToast()
    const requestApi=useApi()
    const [accessTokenVal,setAccessTokenVal]=useState("")
    const [pointNameVal,setPointNameVal]=useState()
    const accessToken=useRef()

    useEffect(()=>{
        modalReady(0)
        requestApi("shops/access_token",{
                point_id:modalContext.reference
            })
            .then(data=>{
                setAccessTokenVal(data.data.access_token)
                setPointNameVal(data.data.name)
                accessToken.current.focus()
                accessToken.current.select()
                modalReady(1)
            })
    },[])

    return(
        <Fragment>
            <ModalHeader></ModalHeader>
            <ModalContent>
                <div className="modal-info">
                    <ModalTitle title="Clave de acceso" />
                    <div className="modal-explanation modal-explanation-intro modal-explanation-center">
                        Utilizá esta clave de acceso (access token) para realizar llamadas a la API de Cajero24 asociadas al punto de cobro <strong>{pointNameVal}</strong>.
                    </div>
                    <div className="share-link">
                        <div className="share-link-box"><input ref={accessToken} type="text" value={accessTokenVal} readOnly /></div>
                    </div>
                    <div className="share-link-buttons">
                        <CopyToClipboard text={accessTokenVal} onCopy={()=>{changeToast("access_token_copied","")}}>
                            <div className="share-link-copy"><img src={copyImg} /></div>
                        </CopyToClipboard>
                    </div>
                </div>
            </ModalContent>
        </Fragment>
    )
    
}