import {useRef,forwardRef,useEffect} from "react"

const PaymentTextbox=forwardRef(({type,mode,limit,label,val,setVal,disabled},ref)=>{

    const form=useRef()

    const focus=()=>{
        ref.current.focus()
    }

    const blur=()=>{
        if(ref.current.value==""){
            form.current.classList.remove("payment-field-on")
        }
    }
    
    const change=(event)=>{
        if(mode=="currency"){
            if(event.target.value.length>=1){
                if(/^([1-9]{1}[0-9]{0,5})(\,\d{0,2})?$/.test(event.target.value.replace(".",","))){
                    setVal(event.target.value.replace(".",","))
                }
            }else{
                setVal(event.target.value)
            }
        }else if(mode=="number"){
            if(event.target.value.length>=1){
                if(/^\d+$/.test(event.target.value)){
                    setVal(event.target.value)
                }
            }else{
                setVal(event.target.value)
            }
        }else{
            setVal(event.target.value)
        }
    }

    useEffect(()=>{
        if(ref.current.value!=""){
            form.current.classList.add("payment-field-on")
        }
    })

    return(
        <div ref={form} className={"noselect payment-field"+(mode=="currency"?" payment-field-currency":"")} onClick={focus}>
            <div className="payment-field-label">{label}</div>
            {mode=="currency" && <div className="payment-field-symbol">$</div>}
            <input ref={ref} className="payment-textbox" type={type=="password"?"password":"text"} disabled={disabled} value={val} maxLength={limit} onChange={change} onBlur={blur} onFocus={()=>{form.current.classList.add("payment-field-on")}} />
        </div>
    )
    
})

export default PaymentTextbox