import {useState,useEffect} from "react"
import {useParams} from "react-router-dom"
import useApi from "../hooks/useApi"
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Subscribers(){
    
    let params=useParams()
    const requestApi=useApi()
    const [info,setInfo]=useState()

    useEffect(()=>{
        requestApi("subscriptions/info",{
                subscription_id:params.subscriptionId
            })
            .then(data=>{
                setInfo(data.data)
            })
    },[])

    return(
        <Section>
            <SectionHeader
                title={info?info["name"]:""}
                intro={info?info["description"]:""}
                back="/c24/suscripciones"
                button=""
                tabs={[
                    {label:"Suscriptores",to:"/c24/suscripciones/"+params.subscriptionId}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"subscribers",
                    endpoint:"subscriptions/subscribers",
                    limit:100,
                    counter:{
                        singular:"suscriptor",
                        plural:"suscriptores"
                    },
                    reference:params.subscriptionId,
                    action:{
                        mode:"",
                        context:"",
                        reference:""
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}