import {Fragment,useContext,useRef,useState,useEffect} from "react"
import ModalContext from "../contexts/ModalContext"
import useApi from "../hooks/useApi"
import useModal from "../hooks/useModal"
import useDialog from "../hooks/useDialog"
import useToast from "../hooks/useToast"
import ModalHeader from "../components/ModalHeader"
import ModalContent from "../components/ModalContent"
import ModalTitle from "../components/ModalTitle"
import ButtonPrimary from "../commons/ButtonPrimary"
import ButtonBack from "../commons/ButtonBack"
import Textbox from "../commons/Textbox"
import Selector from "../commons/Selector"
import ButtonCommand from "../commons/ButtonCommand"

export default function ShopAffiliationEdit(){

    const [modalContext]=useContext(ModalContext)
    const {changeModal,modalReady,manageModal}=useModal()
    const {changeDialog}=useDialog()
    const {changeToast}=useToast()
    const requestApi=useApi()
    const mode=modalContext.context
    const [idVal,setIdVal]=useState(0)
    const companyInput=useRef()
    const numberInput=useRef()
    const terminalInput=useRef()
    const mccInput=useRef()
    const [companyOptions]=useState([
        {label:"Fiserv",value:"Fiserv"},
        {label:"Prisma",value:"Prisma"},
        {label:"Amex Global",value:"Amex Global"}
    ])
    const [companyVal,setCompanyVal]=useState()
    const [numberVal,setNumberVal]=useState("")
    const [terminalVal,setTerminalVal]=useState("")
    const [mccVal,setMccVal]=useState("")

    useEffect(()=>{
        if(mode=="edit"){
            modalReady(0)
            requestApi("shops/affiliation_info",{
                    affiliation_id:modalContext.reference["affiliation"]
                })
                .then(data=>{
                    setIdVal(data.data.id)
                    setNumberVal(data.data.number)
                    setTerminalVal(data.data.terminal)
                    setMccVal(data.data.mcc)
                    companyOptions.forEach(function(item){
                        if(item.value==data.data.company){
                            companyInput.current.setValue({label:data.data.company,value:item.value})
                        }
                    })
                    modalReady(1)
                })
        }
    },[])

    const saveData=()=>{
        if(numberVal==""){
            numberInput.current.focus()
        }else{
            requestApi("shops/affiliation_save",{
                    id:idVal,
                    mode:mode,
                    point:modalContext.reference["point"],
                    company:companyInput.current.getValue()[0]?companyInput.current.getValue()[0].value:"",
                    number:numberVal,
                    terminal:terminalVal,
                    mcc:mccVal
                })
                .then(data=>{
                    if(data.status=="success"){
                        if(mode=="create"){
                            changeToast("created_f","")
                        }else if(mode=="edit"){
                            changeToast("saved","")
                        }
                        changeModal("shop-point","",modalContext.reference["point"])
                    }else{
                        changeDialog("generic_error","","")
                    }
                })
        }
    }

    const remove=()=>{
        requestApi("shops/affiliation_remove",{
                id:idVal
            })
            .then(data=>{
                if(data.status=="success"){
                    changeToast("removed","")
                    changeModal("shop-point","",modalContext.reference["point"])
                }else{
                    changeDialog("generic_error","","")
                }
            })
    }

    return(
        <Fragment>
            <ModalHeader>
                <ButtonBack clickEvent={()=>{changeModal("shop-point","",modalContext.reference["point"])}} />
                <ButtonPrimary label="Guardar" clickEvent={saveData} />
            </ModalHeader>
            <ModalContent>
                <div className="modal-form">
                    <ModalTitle title={mode=="create"?"Nueva afiliación":"Editar afiliación"} />
                    <Selector ref={companyInput} val={companyVal} setVal={setCompanyVal} placeholder="Empresa" searchable={0} options={companyOptions} />
                    <Textbox ref={numberInput} label="Número" type="text" val={numberVal} setVal={setNumberVal} disabled={false} />
                    <Textbox ref={terminalInput} label="Terminal" type="text" val={terminalVal} setVal={setTerminalVal} disabled={false} />
                    <Textbox ref={mccInput} label="MCC" type="text" val={mccVal} setVal={setMccVal} disabled={false} />
                    {mode=="edit" &&
                    <div className="modal-commands">
                        <ButtonCommand label="Eliminar" action="remove" clickEvent={()=>{remove()}} />
                    </div>}
                </div>
            </ModalContent>
        </Fragment>
    )
    
}