import {useEffect} from "react"
import usePermissions from "../hooks/usePermissions"
import useModal from "../hooks/useModal";
import Section from "../components/Section"
import SectionHeader from "../components/SectionHeader"
import SectionContent from "../components/SectionContent"
import List from "../components/List"

export default function Cards(){

    const {restrictSection}=usePermissions()
    useEffect(()=>{
        restrictSection("master","")
    },[])
    
    const {changeModal}=useModal()

    const edit=()=>{
        changeModal("card-edit","",0)
    }

    return(
        <Section>
            <SectionHeader
                title="Tarjetas"
                intro="Tarjetas globales que agrupan varios medios de pago."
                back=""
                button={{label:"Nueva tarjeta",icon:"create",link:"",href:"",clickEvent:edit}}
                tabs={[
                    {label:"Medios de pago",labelShort:"Medios",to:"/c24/medios-de-pago"},
                    {label:"Tarjetas",to:"/c24/medios-de-pago/tarjetas"},
                    {label:"Entidades",to:"/c24/medios-de-pago/entidades"}
                ]}
            />
            <SectionContent>
                <List config={{
                    section:"cards",
                    endpoint:"payments/cards",
                    limit:0,
                    counter:{
                        singular:"tarjeta",
                        plural:"tarjetas"
                    },
                    action:{
                        mode:"modal",
                        context:"",
                        reference:"card-edit"
                    }
                }} />
            </SectionContent>
        </Section>
    )
    
}