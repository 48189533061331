import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Dropdown from "../commons/Dropdown"
import SwitchButton from "../commons/SwitchButton"
import close from "../assets/close-pink.svg"

export default function LinksFilters(){

    const {manageFloating}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const [filter1Val,setFilter1Val]=useState([])
    const [filter2Val,setFilter2Val]=useState("")
    const [filter3Val,setFilter3Val]=useState("")

    const apply=()=>{
        setValue({
            status:filter1Val,
            usages:filter2Val,
            last_usage:filter3.current.getValue()[0]?filter3.current.getValue()[0].value:""
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val([])
        setFilter2Val("")
        setFilter3Val("")
        filter3.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(value!=""){
            if(value["status"].length>0){
                setFilter1Val(value["status"])
            }
            if(value["usages"]!=""){
                setFilter2Val(value["usages"])
            }
            if(value["last_usage"]!=""){
                filter3.current.props["options"].forEach(function(item){
                    if(value["last_usage"]==item.value){
                        filter3.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
        }
    },[value])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-options floating-options-space">
                    <div className="floating-half">
                        <SwitchButton ref={filter1} val={filter1Val} setVal={setFilter1Val} multiple={true}
                            options={[
                                {label:"Activos",value:"active"},
                                {label:"Inactivos",value:"inactive"},
                                {label:"Vencidos",value:"expired"},
                                {label:"En espera",value:"waiting"},
                                {label:"Límite alcan.",value:"limit_reached"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Cantidad de usos</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter2} val={filter2Val} setVal={setFilter2Val} 
                            options={[
                                {label:"Ninguno",value:"none"},
                                {label:"Sólo uno",value:"one"},
                                {label:"Entre 10~50",value:"10_50"},
                                {label:"Entre 50~100",value:"50_100"},
                                {label:"Más de 100",value:"100"},
                                {label:"Más de 500",value:"500"}
                        ]} />
                    </div>
                    <div className="floating-full">
                        <Dropdown ref={filter3} val={filter3Val} setVal={setFilter3Val} placeholder="Último uso" multiple={0} searchable={0} options={[
                            {label:"Indiferente",value:""},
                            {label:"Hace una hora",value:"hour_ago"},
                            {label:"Hoy",value:"today"},
                            {label:"Ayer",value:"yesterday"},
                            {label:"Esta semana",value:"this_week"},
                            {label:"Hace quince días",value:"15_days_ago"},
                            {label:"Hace un mes",value:"month_ago"},
                            {label:"Hace tres meses",value:"three_months_ago"},
                            {label:"Hace seis meses",value:"six_months_ago"},
                            {label:"Hace un año",value:"year_ago"},
                            {label:"Más de un año",value:"more_than_year"}
                        ]} />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}