import {useRef,forwardRef,useEffect} from "react"
import {NumericFormat} from "react-number-format"

const Numbox=forwardRef(({label,val,setVal,disabled,format,mask,decimalSeparator,suffix,prefix,placeholder},ref)=>{

    const caption=useRef()

    const focus=()=>{
        ref.current.focus()
    }

    const blur=()=>{
        if(ref.current.value==""){
            caption.current.classList.remove("textbox-label-on")
        }
    }

    const change=(numberValue)=>{
        setVal(numberValue.value)
    }

    useEffect(()=>{
        if(val!=""){
            caption.current.classList.add("textbox-label-on")
        }
    })

    return(
        <div className="form-block noselect" onClick={focus}>
            <div ref={caption} className="textbox-label">{label}</div>
            <NumericFormat
                getInputRef={ref}
                className="textbox"
                value={val}
                onValueChange={change}
                onBlur={blur}
                onFocus={()=>{caption.current.classList.add("textbox-label-on")}}
                disabled={disabled}
                format={format}
                placeholder={placeholder}
                mask={mask}
                decimalSeparator={decimalSeparator}
                prefix={prefix}
                suffix={suffix}
            />
        </div>
    )
    
})

export default Numbox