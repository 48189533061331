import {useEffect} from "react"
import {useLocation} from "react-router-dom"
import ReactGA from "react-ga4"

ReactGA.initialize("G-XP635Z4S29")

export default function Ga(){
    
    const location=useLocation()

    useEffect(()=>{
        ReactGA.send({
            hitType:"pageview",
            page:location.pathname,
            title:""
        })
    },[location.pathname])

}