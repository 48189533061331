import {useEffect,useRef,useState,useContext} from "react"
import FloatingContext from "../contexts/FloatingContext"
import useFloating from "../hooks/useFloating"
import ButtonAction from "../commons/ButtonAction"
import Dropdown from "../commons/Dropdown"
import SwitchButton from "../commons/SwitchButton"
import close from "../assets/close-pink.svg"

export default function SubscriptionsFilters(){

    const {manageFloating}=useFloating()
    const [floatingContext]=useContext(FloatingContext)
    const value=floatingContext.value
    const setValue=floatingContext.setValue

    const filter1=useRef()
    const filter2=useRef()
    const filter3=useRef()
    const [filter1Val,setFilter1Val]=useState("")
    const [filter2Val,setFilter2Val]=useState("")
    const [filter3Val,setFilter3Val]=useState("")

    const apply=()=>{
        setValue({
            status:filter1Val,
            frequency:filter2Val,
            subscribers:filter3Val
        })
        manageFloating("hide")
    }

    const clear=()=>{
        setFilter1Val("")
        setFilter2Val("")
        setFilter3Val("")
        filter2.current.setValue("")
        setValue("")
        manageFloating("hide")
    }

    useEffect(()=>{
        if(value!=""){
            if(value["status"]!=""){
                setFilter1Val(value["status"])
            }
            if(value["frequency"]!=""){
                filter2.current.props["options"].forEach(function(item){
                    if(value["frequency"]==item.value){
                        filter2.current.setValue({label:item.label,value:item.value})
                    }
                })
            }
            if(value["subscribers"]!=""){
                setFilter3Val(value["subscribers"])
            }
        }
    },[value])

    return(
        <div className="floating-blocks">
            <div className="floating-close-frame">
                <div className="floating-close" onClick={()=>{manageFloating("hide")}}><img src={close} /></div>
            </div>
            <div className="floating-content">
                <div className="floating-options floating-options-space">
                    <div className="floating-half">
                        <SwitchButton ref={filter1} val={filter1Val} setVal={setFilter1Val} 
                            options={[
                                {label:"Activas",value:"active"},
                                {label:"Inactivas",value:"inactive"}
                        ]} />
                    </div>
                    <div className="floating-full">
                        <Dropdown ref={filter2} val={filter2Val} setVal={setFilter2Val} placeholder="Frecuencia" multiple={0} searchable={0} options={[
                            {label:"Todas",value:""},
                            {label:"Semanal",value:"weekly"},
                            {label:"Quincenal",value:"biweekly"},
                            {label:"Mensual",value:"monthly"},
                            {label:"Bimestral",value:"bimonthly"},
                            {label:"Trimestral",value:"trimestral"},
                            {label:"Cuatrimestral",value:"quarterly"},
                            {label:"Semestral",value:"biannual"},
                            {label:"Anual",value:"annual"}
                        ]} />
                    </div>
                </div>
                <div className="floating-group">Cantidad de suscriptores</div>
                <div className="floating-options">
                    <div className="floating-half">
                        <SwitchButton ref={filter3} val={filter3Val} setVal={setFilter3Val} 
                            options={[
                                {label:"Ninguno",value:"none"},
                                {label:"Sólo uno",value:"one"},
                                {label:"Entre 10~50",value:"10_50"},
                                {label:"Entre 50~100",value:"50_100"},
                                {label:"Más de 100",value:"100"},
                                {label:"Más de 500",value:"500"}
                        ]} />
                    </div>
                </div>
            </div>
            <div className="floating-actions">
                <ButtonAction label="Resetear" color="pink" clickEvent={clear} />
                <ButtonAction label="Aplicar" color="purple" clickEvent={apply} />
            </div>
        </div>
    )
    
}